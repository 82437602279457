import React from "react";
import { useInfluencerProfileStyle as classes } from "./InfluencerProfile.styles";
import { IContactListProps } from "./InfluencerProfile.types";
import { Box, Stack, Typography } from "@mui/material";

export default function ContactList(props: IContactListProps) {
  const { data, text, icon } = props;

  return (
    <Box sx={classes.contactListBox}>
      <Box sx={classes.contactIconBox}>{icon}</Box>
      <Stack>
        <Box>
          <Typography variant="caption" color="grey.700">
            {text} :
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            color={data ? "common.black" : "text.secondary"}
          >
            {data || `+ Add ${text}`}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}
