import React, { useEffect, useState } from "react";
import { hPurple } from "../../../theme";
import { styled } from "@mui/system";
import {
  Box,
  Checkbox,
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
  Theme,
  SxProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IMultipleSelectProps extends SelectProps {
  id: string;
  value: unknown[];
  setValue: (value: any) => void;
  placeholder: string;
  data: Array<any>;
  // data?: {
  //   id: string | number;
  //   value: any;
  //   label: string | number | React.ReactNode;
  //   icon?: React.ReactNode;
  // }[];
  sx?: SxProps<Theme>;

  valueKey: any;
  labelKey: any;
}

const SelectMultiple = styled(Select)<SelectProps>(({ theme, ...props }) => {
  const initialStyle = {
    transition: "border-radius 0.1s",

    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      padding: "6.5px 14px",
      "& .MuiChip-root .MuiSvgIcon-root": {
        marginRight: "4px",
        fontSize: "16px",

        maxHeight: "80px",
        overflow: "auto",
      },
    },
  };

  if (props.disabled) {
    return {
      ...initialStyle,
      backgroundColor: theme.palette.grey[100],
      "& .MuiSelect-select": {
        ...initialStyle["& .MuiSelect-select"],
        WebkitTextFillColor:
          (props.value as unknown[]).length > 0
            ? theme.palette.common.white
            : theme.palette.grey[300],
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[100]} !important`,
      },
    };
  } else if (props.error) {
    return {
      ...initialStyle,
    };
  } else {
    return {
      ...initialStyle,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
        },
      },
    };
  }
});

const style = {
  menu: {
    color: "common.black",
    border: "2px solid",
    borderColor: "primary.main",

    height: "50vh",
    maxHeight: "300px",
    overflow: "auto",
  },
  menuItem: {
    alignItems: "center",
    paddingLeft: "8px",
    "& svg": {
      fontSize: "20px",
      mr: 1,
    },
    "&.Mui-selected": {
      color: "primary.main",
      bgcolor: hPurple[100],
    },
  },
  chip: {
    height: "26px",
    zIndex: "9999",
    "& .MuiSvgIcon-root.MuiChip-deleteIcon": {
      zIndex: 900,
    },
  },
  selectRender: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1.5,
    marginBlock: "0px",
    paddingBlock: "0px",
  },
};

const Placeholder = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box component="div" color="grey.300" sx={{ paddingBlock: "3px" }}>
      {children}
    </Box>
  );
};

function MultipleSelectCustom({
  id,
  value,
  setValue,
  placeholder,
  valueKey,
  labelKey,
  data = [],
  sx,
  ...props
}: IMultipleSelectProps) {
  const [width, setWidth] = useState<number | string>(0);

  const target = document.getElementById(id) as HTMLElement;

  useEffect(() => {
    setWidth(target ? (target as HTMLElement).offsetWidth : "auto");
  }, [target]);

  const handleChange = (event: SelectChangeEvent<any>) => {
    setValue(event.target.value);
  };

  const onSelectOpen = (method: string) => {
    if (method === "open") {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px 20px 0px 0px"
      );
    } else {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px"
      );
    }
  };

  const deleteChip = (id: string | number) => {
    console.log("Adaw");
    const filter = value.filter((val) => val !== id);
    console.log(filter);
    setValue([...filter]);
  };

  const getInsurerName = (selected: any[], disabled?: boolean) => {
    const chipList = selected.map((value, index) => {
      const mapping = data?.find((item) => item.id === value);

      return mapping ? (
        <Chip
          key={index}
          color="primary"
          label={
            <Box display="flex" alignItems="center">
              {mapping.icon}{" "}
              <Typography variant="caption">{mapping[labelKey]}</Typography>
              {/* <Typography variant="caption">{mapping.label}</Typography> */}
            </Box>
          }
          onDelete={!disabled ? () => deleteChip(mapping.id) : undefined}
          deleteIcon={
            !disabled ? (
              <CloseIcon sx={{ fontSize: "13px !important" }} />
            ) : undefined
          }
          sx={style.chip}
        />
      ) : (
        ""
      );
    });
    return chipList;
  };

  return (
    <SelectMultiple
      id={id}
      value={value}
      size="small"
      multiple
      color="primary"
      variant="outlined"
      autoWidth
      displayEmpty
      onChange={handleChange}
      onOpen={(e) => onSelectOpen("open")}
      onClose={(e) => onSelectOpen("close")}
      sx={sx && (sx as object)}
      {...props}
      renderValue={
        value.length > 0
          ? (selected) => (
              <Box sx={style.selectRender}>
                {getInsurerName(selected as any[], props.disabled)}
              </Box>
            )
          : () => <Placeholder>{placeholder}</Placeholder>
      }
      MenuProps={{
        PaperProps: {
          sx: {
            ...style.menu,
            width: typeof width === "number" ? width - 4 : width,
          },
        },
      }}
    >
      {data.length > 0 ? (
        data.map((d, index) => {
          return (
            <MenuItem key={index} value={d[valueKey]} sx={style.menuItem}>
              {/* <MenuItem key={index} value={d.id} sx={style.menuItem}> */}
              <Checkbox checked={value.indexOf(d.id) > -1} color="primary" />
              {d.icon} <span>{d[labelKey]}</span>
              {/* {d.icon} <span>{d.label}</span> */}
            </MenuItem>
          );
        })
      ) : (
        <MenuItem disabled sx={style.menuItem}>
          <span>No data</span>
        </MenuItem>
      )}
    </SelectMultiple>
  );
}

export default MultipleSelectCustom;
