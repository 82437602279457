import { Grid, Typography, Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import "./multiImageInput.css";
import { readFileDataTo64 } from "../../../utils/helpers/readFileDataTo64";
import CancelIcon from "@mui/icons-material/Cancel";
import uniqid from "uniqid";

interface IMultiImageInput {
  register: Function;
  keyName: string;
  errors: any;
}

function MultiImageInput(props: IMultiImageInput) {
  const { register, keyName, errors } = props;
  const [imageData, setImageData] = useState<any>([]);

  const styles = {
    button: {
      position: "absolute",
      top: "-10px",
      right: "-10px",
      backgroundColor: "white",
      padding: 0,
    },
    image: {
      height: "150px",
      width: "150px",
      objectFit: "cover" as "cover",
      border: "1px solid #E0E0E0",
      borderRadius: "10px",
    },
  };

  const onInputImage = async (ev: any) => {
    const files = Array.from(ev.target.files);

    files.forEach(async (item: any) => {
      const img64 = await readFileDataTo64(item);

      const list = {
        img64,
        id: uniqid("bImg-"),
      };

      setImageData((imageData: any) => [...imageData, list]);
    });
  };

  const onHandleRemove = (data: any) => {
    const selectedItem = imageData.filter((item: any) => item.id !== data.id);

    setImageData(selectedItem);
  };

  return (
    <Grid container spacing={4} sx={{ width: "auto", mr: 1 }}>
      {imageData?.map((item: any) => {
        return (
          <Grid item>
            <Box position="relative" width="150px" key={item.id}>
              <img src={item.img64} alt="img" style={styles.image} />
              <IconButton
                sx={styles.button}
                onClick={() => onHandleRemove(item)}
              >
                {/* <CancelIcon style={{ color: "black", fontSize: "30px" }} /> */}
                <i
                  className="ico-hu-cancel-circle-solid"
                  style={{ color: "black" }}
                />
              </IconButton>
            </Box>
          </Grid>
        );
      })}

      <Grid item>
        <div>
          <label
            className="label-upload"
            style={{ borderColor: errors ? "red" : "" }}
          >
            <div>
              <img src="/assets/images/img-upload.png" alt="img" />
            </div>
            <div>+ Image</div>
            <input
              className=""
              type="file"
              multiple
              accept="image/*"
              id="input-file"
              {...register(keyName, {
                required: true,
                onChange: onInputImage,
              })}
            />
          </label>
        </div>
        {errors ? (
          <Typography variant="caption" color="error.main" fontWeight={400}>
            {errors?.message}
          </Typography>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}

export default MultiImageInput;
