import { styled } from "@mui/system";
import { Button, ButtonProps } from "@mui/material";
import { hColorful, hNavyBlue, hWhite, hYellow } from "../../theme";
// image
import BannerMainImg from "./../../assets/images/home/1_Homepage/1 - Cover/D - HP cover.png";
import BannerMainImgMobile from "./../../assets/images/home/1_Homepage/1 - Cover/P - HP cover.png";
import HashuRectangleImg from "./../../assets/images/home/1_Homepage/3 - Who We are/Rectangle 1554.png";

export const useHomeMainStyle = {
  sectionOneBox: {
    height: ["calc(100vh - 30px)", "480px", "480px", "620px", "100vh"],
    backgroundImage: [
      `url("${BannerMainImgMobile}")`,
      `url("${BannerMainImg}")`,
    ],
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  sectionOneBox2: {
    display: "flex",
    justifyContent: ["center", "start"],
    alignItems: ["flex-end", "center"],
    height: "inherit",
    paddingInline: [6, 4, 10, 14, 46],
    paddingTop: 4,
  },
  yellowText: {
    color: hYellow[100],
  },
  sectionTwoBox: {
    height: ["auto", null, null, null, "100vh"],
    bgcolor: hWhite[200],
    paddingBlock: [10, 10, 10, 0],
  },
  textColorful: {
    background: `-webkit-linear-gradient(296.45deg, ${hColorful[200]} -3.27%, ${hColorful[100]} 102.18%)`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  sectionThreeContainer: {
    height: ["auto", null, null, null, "100vh"],
    bgcolor: hWhite[200],
    paddingTop: [5, 10, 10, 25, 0],
    paddingBottom: [10, 10, 25, 0],
    backgroundImage: `url("${HashuRectangleImg}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: ["auto", "auto", "auto", "auto", "contain"],
  },
  whoWeAreMobileImg: {
    width: "60%",
    objectFit: "contain" as any,
  },
  sectionThreeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  whoWeArePaper: {
    width: ["85%", "90%", "90%", "90%", "85%"],
  },
  ourPerformance: {
    height: ["auto", null, null, null, "100vh"],
    py: [10, 10, 10, 30, 0],
    bgcolor: hWhite[200],
  },
  sectionFourBox: {
    bgcolor: hWhite[100],
  },
  trustedByHeaderBox: {
    textAlign: "center" as any,
    paddingTop: [12, 14, 14, 18],
    paddingBottom: [6, 6, 6, 10],
  },
  trustedByListGird: {
    justifyContent: "center",
    width: ["85%", "85%", "85%", "70%"],
  },
  trustedByListGirdItem: {
    display: "flex",
    justifyContent: "center",
    height: [74, 94, 94, 160],
    marginBottom: [1, 1, 1, 4],
  },
  trustedByImg: {
    height: "100%",
    width: "auto",
  },
  contentBox: {
    display: "flex",
    height: "inherit",
    width: "inherit",
    paddingBottom: ["35px", "50px", "50px", "50px"],
  },
  paper: {
    boxShadow: "0px 0px 24px 2px rgba(229, 229, 229, 0.4)",
  },
  headerText: {
    color: hNavyBlue[100],
  },
  engagementBox: {
    display: "flex",
    jusjustifyContent: "center",
    alignItems: "center",
    height: 68,
  },
};

export const ButtonSignUpColorful = styled(Button)<ButtonProps>(
  ({ theme, ...props }) => ({
    color: theme.palette.common.white,
    minWidth: "282px",
    minHeight: "42px",
    borderRadius: "50px",
    backgroundImage:
      props.color === undefined
        ? `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(296.45deg, ${hColorful[100]}, ${hColorful[200]})`
        : "none",
  })
);
