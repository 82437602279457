import React from "react";
import { styled } from "@mui/system";
import { Button, ButtonProps, Typography } from "@mui/material";
import { hSocialColor } from "../../../theme";

interface ISyncButtonProps {
  social: string;
  children?: React.ReactNode;
}

const ButtonSync = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.common.white,
  borderRadius: "50px",
  width: "300px",
  minHeight: "42px",
}));

function SyncButton(props: ISyncButtonProps) {
  const { social, children } = props;

  const socialColor = (color: string) => {
    switch (color) {
      case "facebook":
        return hSocialColor.facebook;
      case "instagram":
        return hSocialColor.instagram;
      case "youtube":
        return hSocialColor.youtube;
      case "twitter":
        return hSocialColor.twitter;
      case "tiktok":
        return hSocialColor.tiktok;
      default:
        break;
    }
  };

  return (
    <ButtonSync
      variant="contained"
      sx={{
        border: "none",
        background: socialColor(social),
        "&:hover": {
          border: "none",
          background: socialColor(social),
        },
      }}
      disabled={social === "disabled" && true}
    >
      <Typography variant="body1" fontWeight="inherit">
        {children}
      </Typography>
    </ButtonSync>
  );
}

export default SyncButton;
