import React from "react";

// Styles
import { useInfluencerMyPortfolioStyle as classes } from "./InfluencerMyPortfolio.styles";
// Types
import { IInfluencerMyPortfolioProps } from "./InfluencerMyPortfolio.types";
// Material
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import Divider from "@mui/material/Divider";
// Icons
import {
  EditSolidIcon,
  AttachFile,
  AddIcon,
  OpenLink,
} from "../../assets/icons/social/social";
// Globall
import nl2br from "react-nl2br";

export default function InfluencerMyPortfolio(
  props: IInfluencerMyPortfolioProps
) {
  const { profileInfluencer, openEditMyPortfolioDialog, myPortfolioIndex } =
    props;

  return (
    <Card sx={classes.portfolioCard} elevation={4}>
      <CardHeader
        sx={classes.portfolioCardHeader}
        title={
          <Typography variant="body1" color="common.white" fontWeight="inherit">
            My Portfolio
          </Typography>
        }
      />

      <CardContent sx={classes.cardContent}>
        <Box>
          <Box pt={4} pb={5}>
            <Box display="flex" justifyContent="space-between">
              <Box sx={classes.portfolioIconBox}>
                <AttachFile color="primary" sx={{ fontSize: "18px" }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  My Portfolio
                </Typography>
              </Box>
              {profileInfluencer &&
              (profileInfluencer.portfolio?.length as number) <= 4 ? (
                <Box>
                  <IconButton
                    aria-label="save"
                    color="primary"
                    size="small"
                    sx={classes.portfolioCardEditIcon}
                    onClick={() => myPortfolioIndex(10)}
                  >
                    <AddIcon
                      onClick={openEditMyPortfolioDialog}
                      sx={{ fontSize: "15px" }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
          {profileInfluencer !== null &&
            profileInfluencer.portfolio &&
            profileInfluencer.portfolio.map((portfolio, index) => {
              return (
                <Box key={index} my={2}>
                  <Box display="flex" justifyContent="space-between">
                    <a
                      href={portfolio.url}
                      target="_blank"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ "&:hover": { opacity: "0.8" } }}
                        >
                          {portfolio.title}
                        </Typography>
                        <OpenLink color="primary" sx={{ fontSize: "15px" }} />
                      </Stack>
                    </a>
                    <Box>
                      <IconButton
                        color="primary"
                        size="small"
                        sx={classes.portfolioCardEditIcon}
                        onClick={() => myPortfolioIndex(index)}
                      >
                        <EditSolidIcon
                          onClick={openEditMyPortfolioDialog}
                          sx={{ fontSize: "15px" }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <a
                    href={portfolio.url}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      color="rgba(0, 0, 0, 0.54)"
                      sx={{
                        width: "85%",
                        overflow: "hidden",
                        "&:hover": {
                          color: "rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    >
                      {portfolio.url}
                    </Typography>
                  </a>
                  <Typography
                    mb={2}
                    sx={{
                      width: "85%",
                    }}
                  >
                    {nl2br(portfolio.description)}
                  </Typography>
                  {index ===
                  (profileInfluencer.portfolio?.length as number) - 1 ? null : (
                    <Divider />
                  )}
                </Box>
              );
            })}
          {/* {!profileInfluencer?.portfolio && (
            <Box>
              <Typography variant="body1" color={"text.secondary"}>
                + Add Bank Account
              </Typography>
            </Box>
          )} */}
        </Box>
      </CardContent>
    </Card>
  );
}
