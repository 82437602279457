import React from "react";
import SyncButton from "../../global/sync-button";
import { useYoutubeTabStyle as classes } from "./Youtube.style";
import { Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import youtubeImg from "./../../../assets/images/social/youtube.png";

export default function DefaultTab() {
  return (
    <Stack sx={classes.stackContainer}>
      <div>
        <img
          src={youtubeImg}
          alt="tiktok_sync"
          style={{
            height: "80px",
          }}
        />
      </div>
      <Box textAlign="center" width="40%" pt={4} color={grey[700]}>
        <Typography variant="h5" color="inherit">
          Coming soon...
        </Typography>
      </Box>
      <Box textAlign="center" py={5}>
        <Typography
          variant="body1"
          sx={{
            color: grey[700],
          }}
        >
          Simply add more of your social channels below
        </Typography>
      </Box>
      <Box textAlign="center" py={3}>
        <SyncButton social="disabled">Sync Data Youtube</SyncButton>
      </Box>
    </Stack>
  );
}
