import React, { useState } from "react";
import { readFileDataTo64 } from "../../../utils/helpers/readFileDataTo64";
import "./imageInput.css";
import imageIcon from "../../../assets/images/other/photo.svg";
import { Box, IconButton, Typography } from "@mui/material";
import { AddIcon } from "../../../assets/icons/social/social";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
// Api
import { uploadImage } from "../../../api/appInformation/index";

interface IImageInput {
  register: Function;
  keyName: string;
  errors: any;
  [any: string]: any;
}

function ImageInput(props: IImageInput) {
  const { register, keyName, errors, icon, setValue } = props;
  const [imageData, setImageData] = useState<string | null>("");

  const onInputImage = async (e: any) => {
    const file = await e.target.files[0];
    const data = {
      file: file,
      destination: "test",
      permission: "public",
    };
    const image = await uploadImage(data);

    setImageData(image.public_url);
    setValue(keyName, image.public_url as string);
  };

  const onHandleRemove = () => {
    setImageData("");
    setValue(keyName, "");
  };

  return (
    <div>
      <Box position="relative" width="100px" height="100px" mb={3}>
        {imageData ? (
          <>
            <img src={imageData} alt="img" className="image" />
            <IconButton className="button" onClick={onHandleRemove}>
              {/* <CancelIcon style={{ color: "black", fontSize: "30px" }} /> */}
              <i
                className="ico-hu-cancel-circle-solid"
                style={{
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
          </>
        ) : (
          <div>
            <label
              className="label-upload"
              style={{
                borderColor: errors ? "red" : "",
                width: "100px",
                height: "100px",
              }}
            >
              <div
                style={{
                  marginRight: "5px",
                  width: "46px",
                }}
              >
                <img src={imageIcon} alt="img" style={{ color: "white" }} />
              </div>
              {/* <div>+ Image</div> */}
              <input
                className=""
                type="file"
                accept="image/*"
                id="input-file"
                {...register(keyName, {
                  onChange: onInputImage,
                })}
              />
            </label>
          </div>
        )}
        {icon ? (
          <IconButton
            className={imageData ? "inputButtonData" : "inputButton"}
            style={{
              backgroundColor:
                icon && icon === "camera" ? "#8C499C" : "#ED1968",
            }}
          >
            <label style={{ display: "flex", cursor: "pointer" }}>
              {icon && icon === "camera" ? (
                <CameraAltRoundedIcon
                  style={{ color: "white", fontSize: "16px" }}
                />
              ) : icon && icon === "plus" ? (
                <AddIcon style={{ color: "white", fontSize: "16px" }} />
              ) : (
                ""
              )}
              <input
                className=""
                type="file"
                accept="image/*"
                id="input-file"
                {...register(keyName, {
                  onChange: onInputImage,
                })}
              />
            </label>
          </IconButton>
        ) : (
          ""
        )}
      </Box>

      {errors ? (
        <Typography variant="caption" color="error.main" fontWeight={400}>
          {errors.message}
        </Typography>
      ) : (
        ""
      )}
    </div>
  );
}

export default ImageInput;
