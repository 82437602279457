import { hGrey, hPurple } from "../../theme";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const useInfluencerProfileStyle = {
  profileCard: {
    width: "100%",
    borderRadius: "20px",
    marginBottom: "20px",
  },
  profileCardHeader: {
    backgroundColor: "primary.main",
    textAlign: "center",
    height: "42px",
    padding: "0",
    fontWeight: "bold",
  },
  cardContent: {
    padding: "20px 20px 0",
  },
  imageBox: {
    overflow: "hidden",
    width: "inherit",
    aspectRatio: "1 / 1",
    bgcolor: "grey.100",
    borderRadius: "20px 20px 0 0",
    "& .first-layer": {
      marginTop: "-5px",
    },
  },
  imageListBox: {
    bgcolor: "grey.100",
    borderRadius: "0 0 20px 20px",
  },
  imageList: {
    borderRadius: "0 0 20px 20px",
    height: "inherit",
    margin: "0",
  },
  imageListItem: {
    aspectRatio: "1 / 1",
    alignItems: "center",
  },
  contactListBox: {
    display: "flex",
    py: 2,
  },
  contactIconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "56px",
    fontSize: "24px",
  },
  cardActionBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    pb: 4,
  },
  contactLine: {
    width: "100%",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: `1px solid ${hGrey[100]}`,
    margin: 0,
  },
  descriptionBox: {
    textAlign: "center",
    py: 4,
    px: 5,
    backgroundColor: hPurple[100],
    borderRadius: "5px",
  },
};

export const useEditProfileDialogStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  dialogContent: {
    paddingTop: 3,
    paddingBottom: 0,
  },
  profileContainerBox: {
    width: "160px",
    height: "160px",
  },
  profileBox: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      objectFit: "cover",
      borderRadius: "10px",
    },
  },
  profileButton: {
    position: "absolute",
    bottom: "-6px",
    right: "-12px",
    height: "30px",
    maxWidth: "30px",
    minWidth: "30px",
    bgcolor: "primary.main",
    border: "none",
    p: 0,
    fontSize: "20px",
    "&:hover": {
      bgcolor: "primary.main",
      border: "none",
    },
  },
  imagesContainerBox: {
    width: "120px",
    height: "120px",
    marginBottom: 1,
  },
  imagesBox: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      objectFit: "cover",
      borderRadius: "10px",
    },
  },
  imagesButton: {
    position: "absolute",
    bottom: "-6px",
    right: "-12px",
    height: "26px",
    maxWidth: "26px",
    minWidth: "26px",
    bgcolor: "primary.main",
    border: "none",
    p: 0,
    fontSize: "16px",
    "&:hover": {
      bgcolor: "primary.main",
      border: "none",
    },
  },
  addCategoryButton: {
    "& .MuiSvgIcon-root": {
      color: "common.white",
    },
  },
  categoryListBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1.5,
    marginBlock: "0px",
    paddingTop: "0px",
  },
  categoryChip: {
    height: "27px",
    "& .MuiSvgIcon-root.MuiChip-deleteIcon": {
      fontSize: "13px",
    },
  },
  visibilityOffIcon: { color: "grey.500" },
};

export const InfluencerProfileBox = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    "& .MuiCard-root": {
      borderRadius: "0 0 20px 20px",
    },
  },
  [theme.breakpoints.up("md")]: {
    borderRadius: "20px",
  },
}));
