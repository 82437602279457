export const useCampaignOverviewStyle = {
  cardContainer: {
    margin: "24px",
    borderRadius: "12px",
    padding: "8px",
  },
  cardHeader: {
    "& p:first-of-type": {
      fontSize: "20px",
      color: "#231F20",
      fontWeight: "500",
    },
    "& p:last-of-type": {
      fontSize: "14px",
      color: "#5C5C5C",
    },
  },
  divider: {
    borderBottom: "2px solid #EBDFFF",
    mx: "-10px",
  },
  orgLogo: {
    width: "100px",
    height: "100px",
    borderRadius: "8px",
  },
  companyLogo: {
    "& .label-upload": {
      border: "1.5px solid #E0E0E0",
      bgcolor: "#fff",
      borderRadius: "8px",
    },
  },
  optionRadio: {
    "& .css-m5f4gq-MuiTypography-root": {
      fontSize: "14px",
    },
  },
  optionLabelRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
};

export const useCampaignBriefDetailStyle = {
  cardContainer: {
    margin: "24px",
    borderRadius: "12px",
    padding: "8px 8px 0 8px",
  },
  cardHeader: {
    ...useCampaignOverviewStyle.cardHeader,
  },
  divider: {
    ...useCampaignOverviewStyle.divider,
  },
  doOrEmphasisTextBox: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    mt: "12px",
    border: "1px solid #F5F6F8",
    position: "relative",
    "& p:first-of-type": {
      padding: "12px 0 0 12px",
      fontWeight: "500",
    },
    "& ul": {
      fontSize: "14px",
    },
  },
  copyIcon: {
    position: "absolute",
    top: "168px",
    right: "10px",
    width: "32px",
    height: "32px",
    bgcolor: "#fff",
  },
};

export const useCampaignTargetAudience = {
  cardContainer: {
    ...useCampaignBriefDetailStyle.cardContainer,
  },
  cardHeader: {
    ...useCampaignOverviewStyle.cardHeader,
  },
  divider: {
    ...useCampaignOverviewStyle.divider,
  },
  ageTitle: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "6px",
      ml: "10px",
    },
    "& p": {
      ml: "8px",
      fontSize: "14px",
    },
  },
};

export const useCampaignBudgetAndRewardStyle = {
  cardContainer: {
    ...useCampaignBriefDetailStyle.cardContainer,
  },
  cardHeader: {
    ...useCampaignOverviewStyle.cardHeader,
  },
  divider: {
    ...useCampaignOverviewStyle.divider,
  },
  formControlLabel: {
    "& .css-m5f4gq-MuiTypography-root": { fontSize: "14px" },
    "& .MuiSvgIcon-root": { fontSize: "18px" },
  },
  compensation: {
    mt: "9px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  },
  productAndService: {
    border: "1px solid #8C499C",
    borderRadius: "8px",
    width: "296px",
    height: "88px",
    alignItems: "center",
    position: "relative",
    "& div:first-of-type": {
      borderRadius: "4px",
      width: "64px",
      height: "64px",
      ml: "12px",
    },
  },
  productAndServiceheader: {
    ml: "8px",
    "& p:first-of-type": {
      fontSize: "16px",
      mb: "8px",
    },
    "& p:last-of-type": {
      fontSize: "12px",
      color: "#5C5C5C",
    },
  },
  productAndServiceIcon: {
    top: 0,
    right: "5px",
    position: "absolute",
    svg: {
      width: "24px",
      height: "24px",
    },
  },
  addProductAndServiceButton: {
    margin: "20px 0 -10px -10px",
    border: "none",
    backgroundColor: "#EBDFFF",
    color: "#8C499C",
    fontWeight: "500",
    "&:hover": {
      border: "none",
      color: "#ffff",
    },
  },
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    fontSize: "16px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: "8px",
      right: "8px",
    },
  },
  specifyingProductDetails: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    height: "140px",
    "& p:first-of-type": {
      pt: "8px",
      pl: "12px",
      color: "#8C499C",
    },
    "& ul": {
      fontSize: "14px",
    },
  },
  dialogAction: {
    display: "flex",
    justifyContent: "center",
    mt: "-20px",
    "& button": {
      border: "none",
      "&:hover": { border: "none" },
      width: "212px",
    },
  },
};

export const useCampaignBriefStyle = {
  boxContent: {
    width: "920px",
    backgroundColor: "#EBDFFF",
    borderRadius: "20px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: "25px",
    border: "1px solid #EBDFFF",
  },
  cardContainer: {
    m: 2,
  },
  saveBtn: {
    position: "fixed",
    bottom: "15%",
    right: 0,
    width: 100,
    height: 100,
    borderRadius: "20px",
  },
};

export const useTermsAndConditionStyle = {
  cardContainer: {
    ...useCampaignBriefDetailStyle.cardContainer,
  },
  cardHeader: {
    ...useCampaignOverviewStyle.cardHeader,
  },
  divider: {
    ...useCampaignOverviewStyle.divider,
  },
  IconButton: {
    width: "25px",
    height: "25px",
  },
  textfield: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  },
  textExample: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    mt: "12px",
    border: "1px solid #F5F6F8",
    position: "relative",
    "& p:first-of-type": {
      color: "#8C499C",
      padding: "12px 0 0 12px",
      fontWeight: "500",
    },
    "& ul": {
      fontSize: "14px",
    },
  },
  coppyIcon: {
    position: "absolute",
    top: "150px",
    right: "10px",
    width: "32px",
    height: "32px",
    bgcolor: "#fff",
  },
  addIcon: {
    width: "23px",
    height: "23px",
    borderRadius: "50%",
    bgcolor: "#4CAF50",
    color: "#fff",
  },
  addFieldBtn: {
    width: "100%",
    borderRadius: "25px",
    backgroundColor: "primary.main",
    "&:hover": {
      backgroundColor: "primary.300",
    },
  },
};

export const useTimelineStyle = {
  cardContainer: {
    ...useCampaignBriefDetailStyle.cardContainer,
  },
  cardHeader: {
    ...useCampaignOverviewStyle.cardHeader,
  },
  divider: {
    ...useCampaignOverviewStyle.divider,
  },
  timelineLeft: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    border: "3px solid #EBDFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#fff",
  },
  timelineRigthBox: {
    border: "1.5px solid #EBDFFF",
    borderRadius: "8px",
    height: "112px",
    padding: "16px",
  },
  timelineDevider: {
    position: "absolute",
    top: 0,
    left: "20px",
    height: "1050px",
    zIndex: -1,
    mt: 1,
  },
  announceDate: {
    alignItems: "center",
    "& svg:first-of-type": {
      fontSize: "6px",
      ml: "10px",
      mr: "2px",
    },
    "p:first-of-type": {
      fontSize: "14px",
    },
  },
  selectCampParticipants: {
    alignItems: "center",
    "& svg": {
      fontSize: "6px",
      ml: "10px",
      mr: "2px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  closeDate: {
    width: "48%",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  },
  text: {
    fontSize: "14px",
    ml: "10px",
    "& ul li:first-of-type": {
      marginLeft: "-22px",
    },
  },
};
