import React, { useEffect, useState } from "react";
// Component
import InfluencerProfile from "../../components/influencer-profile/InfluencerProfile";
import InfluencerAddress from "../../components/influencer-address/InfluencerAddress";
import InfluencerBankAccount from "../../components/influencer-bank-account/InfluencerBankAccount";
import InfluencerMyPortfolio from "../influencer-myPortfolio/InfluencerMyPortfolio";
import EditProfileDialog from "../../components/influencer-profile/edit-profile-dialog/EditProfileDialog";
import EditAddressDialog from "../../components/influencer-address/edit-address-dialog/EditAddressDialog";
import EditAddressBillingDialog from "../../components/influencer-address/edit-address-dialog/EditAddressBillingDialog";
import EditBankAccountDialog from "../../components/influencer-bank-account/edit-bank-account-dialog/EditBankAccountDialog";
import EditMyPortfolioDialog from "../influencer-myPortfolio/edit-my-portfolio/EditMyPortfolioDialog";
// Types
import {
  IInfluencerProfileRes,
  IInfluencerProfileApi,
  IBank,
  IInfluencerMyPortfolioApi,
} from "../../views/my-profile/MyProfile.types";
import {
  IBankAccountRes,
  IBankLists,
} from "../influencer-myEarning/InfluencerMyEarning.types";
import {
  IAddressDetailRes,
  IPersonalUpdateInformationRes,
} from "../../views/my-earning/MyEarning.types";
import { IMyPortfolio } from "./../influencer-myPortfolio/InfluencerMyPortfolio.types";
// Material
import { Grid, Stack } from "@mui/material";
// Mock Data
import { generalData } from "../../views/my-profile/influencerGeneral.data";
// API
import { getInfluencer } from "./../../api/influencer/InfluencerAPI";
import { getBankLists } from "../../api/appInformation";

function MyProfile() {
  const [profiles, setProfiles] = useState<IInfluencerProfileRes | null>(null);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [isEditAddressOpen, setIsEditAddressOpen] = useState(false);
  const [isEditBillingOpen, setIsEditBillingOpen] = useState(false);
  const [isEditBankAccountOpen, setIsEditBankAccountOpen] = useState(false);
  const [isEditMyPortfolioOpen, setIsEditMyPortfolioOpen] = useState(false);
  const [editMyPortfolioIndex, setEditMyPortfolioIndex] = useState<number>();
  const [profileInfluencer, setProfileInfluencer] =
    useState<IInfluencerProfileApi | null>(null);
  const [bankList, setBankList] = useState<IBankLists[] | null>(null);

  // useEffect(() => {
  //   async function getProfile() {
  //     setProfiles(generalData.result.data);
  //   }
  //   getProfile();
  // }, []);

  useEffect(() => {
    async function getBank() {
      const result = await getBankLists();
      const bankList = result.map((bank: IBank) => ({
        id: bank.id,
        value: bank.value,
        name_en: bank.name_en,
        name_th: bank.name_th,
        logo_image_url: bank.logo_image_url,
      }));
      setBankList(bankList);
    }
    getBank();
  }, []);

  useEffect(() => {
    async function getResult() {
      const results = await getInfluencer();
      setProfileInfluencer(results);
    }
    getResult();
  }, []);

  const openEditProfileDialog = () => {
    setIsEditProfileOpen(true);
  };
  const closeEditProfileDialog = () => {
    setIsEditProfileOpen(false);
  };
  const openEditBilling = () => {
    setIsEditBillingOpen(true);
  };
  const closeEditBilling = () => {
    setIsEditBillingOpen(false);
  };

  const onSubmitUpdateProfile = (data: IPersonalUpdateInformationRes) => {
    if (profileInfluencer !== null) {
      setProfileInfluencer({
        ...profileInfluencer,
        fullname: data.fullname,
        birth_date: data.birth_date,
        description: data.description,
        gender: data.gender,
        line_id: data.line_id,
        media: {
          images: data.media.images,
          videos: [],
        },
        nationality: data.nationality,
        phone: data.phone,
      });
    }
  };

  const onSubmitChangePassword = (data: any) => {
    console.log(data);
  };

  const openEditAddressDialog = () => {
    setIsEditAddressOpen(true);
  };
  const closeAddressDialog = () => {
    setIsEditAddressOpen(false);
  };

  const onSubmitShippingAddress = (data: IAddressDetailRes) => {
    if (profileInfluencer !== null) {
      setProfileInfluencer({
        ...profileInfluencer,
        address: {
          shipping_address: data,
          billing_address: profileInfluencer.address.billing_address,
        },
      });
    }
  };
  const onSubmitBillingAddress = (data: IAddressDetailRes) => {
    if (profileInfluencer !== null) {
      setProfileInfluencer({
        ...profileInfluencer,
        address: {
          shipping_address: profileInfluencer.address.shipping_address,
          billing_address: data,
        },
      });
    }
  };

  const openEditBankAccountDialog = () => {
    setIsEditBankAccountOpen(true);
  };
  const closeBankAccountDialog = () => {
    setIsEditBankAccountOpen(false);
  };
  const onSubmitBankAccount = (data: { bank_account: IBankAccountRes }) => {
    if (profileInfluencer !== null && bankList !== null) {
      const bankId = bankList.find(
        (bank: { id: number }) => bank.id === data.bank_account.bank_id
      );
      const bankAccount = data.bank_account;
      const bookBankImage = data.bank_account.book_bank_image;
      const idCardImage = data.bank_account.id_card_image;
      const bankData = {
        ...profileInfluencer.bank_account,
        account_name: bankAccount.account_name,
        account_number: bankAccount.account_number,
        account_type: bankAccount.account_type,
        book_bank_image_url: {
          ...bookBankImage,
          file_id: bookBankImage.file_id as string,
          file_name: bookBankImage.file_name as string,
          description: bookBankImage.description as string,
        },
        branch: bankAccount.branch,
        id_card_image_url: {
          ...idCardImage,
          file_id: idCardImage.file_id as string,
          file_name: idCardImage.file_name as string,
          description: idCardImage.description as string,
        },
        tax_id: bankAccount.tax_id,
        bank: {
          id: bankId?.id as number,
          logo_image_url: bankId?.logo_image_url as string,
          name_en: bankId?.name_en as string,
          name_th: bankId?.name_th as string,
          value: bankId?.value as string,
        },
      };
      setProfileInfluencer({
        ...profileInfluencer,
        bank_account: { ...bankData },
      });
    }
  };

  const openEditMyProfileDialog = () => {
    setIsEditMyPortfolioOpen(true);
  };

  const closeEditMyPortfolioDialog = () => {
    setIsEditMyPortfolioOpen(false);
  };

  const changeMyPortfolioIndexHandler = async (index: number) => {
    setEditMyPortfolioIndex(index);
  };

  const onSubmitMyPortfolio = (data: { portfolio: IMyPortfolio[] | null }) => {
    if (profileInfluencer !== null && profileInfluencer.portfolio !== null) {
      const portfolioData = data.portfolio;
      setProfileInfluencer({
        ...profileInfluencer,
        portfolio: portfolioData,
      });
      console.log("On submit my portfolio", portfolioData);
    }
  };

  return (
    <React.Fragment>
      <Stack>
        <InfluencerProfile
          profileInfluencer={profileInfluencer}
          openEditProfileDialog={openEditProfileDialog}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={12} lg={12}>
            <InfluencerAddress
              address={
                profileInfluencer !== null ? profileInfluencer.address : null
              }
              openEditAddressDialog={openEditAddressDialog}
              openEditBilling={openEditBilling}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={12}>
            <InfluencerBankAccount
              bankAccount={
                profileInfluencer !== null
                  ? profileInfluencer.bank_account
                  : null
              }
              openEditBankAccountDialog={openEditBankAccountDialog}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={12}>
            <InfluencerMyPortfolio
              profileInfluencer={profileInfluencer}
              openEditMyPortfolioDialog={openEditMyProfileDialog}
              myPortfolioIndex={changeMyPortfolioIndexHandler}
            />
          </Grid>
        </Grid>
      </Stack>
      <EditProfileDialog
        data={profileInfluencer}
        isOpened={isEditProfileOpen}
        onClose={closeEditProfileDialog}
        onSubmitUpdateProfile={onSubmitUpdateProfile}
        onSubmitChangePassword={onSubmitChangePassword}
      />
      <EditAddressDialog
        data={
          profileInfluencer !== null
            ? profileInfluencer.address.shipping_address
            : null
        }
        isOpened={isEditAddressOpen}
        onClose={closeAddressDialog}
        onSubmitShippingAddress={onSubmitShippingAddress}
        onSubmitBillingAddress={onSubmitBillingAddress}
      />
      <EditAddressBillingDialog
        data={
          profileInfluencer !== null
            ? profileInfluencer.address.billing_address
            : null
        }
        isOpened={isEditBillingOpen}
        onClose={closeEditBilling}
        onSubmitShippingAddress={onSubmitShippingAddress}
        onSubmitBillingAddress={onSubmitBillingAddress}
      />
      <EditBankAccountDialog
        data={profileInfluencer}
        isOpened={isEditBankAccountOpen}
        onClose={closeBankAccountDialog}
        onSubmitBankAccount={onSubmitBankAccount}
      />
      <EditMyPortfolioDialog
        data={profileInfluencer}
        isOpened={isEditMyPortfolioOpen}
        onClose={closeEditMyPortfolioDialog}
        onSubmitMyPortfolio={onSubmitMyPortfolio}
        myPortfolioIndex={editMyPortfolioIndex}
        openEditMyProfileDialog={openEditMyProfileDialog}
      />
    </React.Fragment>
  );
}

export default MyProfile;
