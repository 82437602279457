import React from "react";
// Mui
import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
// Style
import { useCampaignDetailOverviewStyle as classes } from "./CampaignDetails.style";
// others
import { Link } from "react-scroll";

interface ICampaignDetailOverviewProps {
  handleOpenCampaignBrief: () => void;
}

export default function CampaignDetailOverview() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={3}>
        <Typography>Category</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Fashion, Lifestyle</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>Review</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Product (สินค้า)</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>Product Details</Typography>
      </Grid>
      <Grid item xs={9} sx={classes.productDetail}>
        <Avatar />
        <Avatar />
      </Grid>
      <Grid item xs={3}>
        <Typography sx={classes.ProdDesc}>
          <CircleIcon />
          Product Name
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>WITAL B+Sage (Dietary Supplement Product)</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={classes.ProdDesc}>
          <CircleIcon />
          Description
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          ยี่ห้อ : WITAL
          <br /> บรรจุภัณฑ์ : 1 ขวด (บรรจุ 30 เม็ด)
          <br /> ข้อมูลสำหรับผู้แพ้อาหาร : มีผลิตภัณฑ์จากถั่วเหลือง อาจะมีนม
          กลูเตน ซัลไฟต์ ปลาและกุ้ง ราคา 890 บาท
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>Review Product</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>การส่งสินค้าไป ไม่ต้องส่งสินค้ากลับ</Typography>
      </Grid>
    </Grid>
  );
}
