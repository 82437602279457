import React from "react";
import {
  CardContent,
  Card,
  Box,
  Avatar,
  Typography,
  Grid,
  Stack,
  CardHeader,
  Button,
  IconButton,
  Collapse,
  MenuItem,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import InfluencerDnD from "../global/drag-and-drop/InfluencerDnD";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface IInfluencerSelected {
  influencerSelected: any;
  setInfluencerSelected: any;
}

function InfluencerSelected(props: IInfluencerSelected) {
  const { influencerSelected, setInfluencerSelected } = props;

  return (
    <Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={3}
        mb={3}
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="body1"
            color="common.black"
            fontWeight="500"
            mr={3}
          >
            Influencer List
          </Typography>
          <Typography variant="body1" color="common.black" fontWeight="400">
            (Primary Influencer)
          </Typography>

          <Tooltip
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.white",
                  "& .MuiTooltip-arrow": {
                    color: "common.white",
                  },
                  color: "common.black",
                  width: 500,
                  p: 3,
                },
              },
            }}
            title={
              <React.Fragment>
                <div>
                  <Typography variant="h6" color="inherit" fontWeight="500">
                    Example of Term and Condition
                  </Typography>
                  <Typography color="rgba(0, 0, 0, 0.54)">
                    <ul>
                      <li>
                        โปรดทบทวนนโยบายและความเป็นส่วนตัวของบริษัท
                        เพื่อปฎิบัติตามอย่าง เคร่งครัด
                      </li>
                      <li>
                        ให้สิทธิ์บริษัทในการทำซ้ำ ดัดแปลง แก้ไข
                        หรือประชาสัมพันธ์ บทความการรีวิว ของคุณ
                        ผ่านช่องทางออนไลน์ต่างๆของบริษัทได้
                        โดยคุณไม่สามารถเรียกร้อง
                        ค่าตอบแทนใดๆกับทางบริษัทเพิ่มเติมได้
                      </li>
                      <li>
                        เปิดบัญชีสาธารณะ และไม่ทำการลบโพสต์ จนกว่าจะครบกำหนดเวลา
                        หากทำการลบโพสต์ก่อนเวลา ที่กำหนด
                        คุณจะไม่ได้รับค่าตอบแทนและบริษัท
                        สามารถเรียกร้องค่าเสียหายจากคุณได้
                      </li>
                      <li>
                        โปรดอ่านและทำความเข้าใจ รายละเอียด
                        รวมถึงสิ่งที่ต้องทำและข้อห้ามใน การรีวิวสินค้าหรือบริการ
                        ก่อนยอมรับข้อตกลงและเงื่อนไขในการรีวิว
                      </li>
                    </ul>
                  </Typography>
                </div>
              </React.Fragment>
            }
          >
            <HelpOutlineIcon sx={{ fontSize: "18px", marginLeft: "5px" }} />
          </Tooltip>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body1" color="secondary.main" fontWeight="500">
            {influencerSelected.slice(0, 20).length}
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            fontWeight="500"
            mr={5}
          >
            /20
          </Typography>
          <Typography variant="body1" color="common.black" fontWeight="400">
            Influencers
          </Typography>
        </Box>
      </Box>

      <InfluencerDnD
        influencerSelected={influencerSelected}
        setInfluencerSelected={setInfluencerSelected}
      />
    </Stack>
  );
}

export default InfluencerSelected;
