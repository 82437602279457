import React from "react";
// Utils
import { useInfluencerBankAccountStyle as classes } from "./InfluencerBankAccount.styles";
// Types
import { IInfluencerBankAccountProps } from "./InfluencerBankAccount.types";
// Material
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
} from "@mui/material";
// Icons
import { BankIcon, EditSolidIcon } from "../../assets/icons/social/social";
// Globall
import { formatPhoneNumber, formatTaxId } from "../global/format-number";

function InfluencerBankAccount(props: IInfluencerBankAccountProps) {
  const { bankAccount, openEditBankAccountDialog } = props;

  const mapAccountType = (type: string) => {
    switch (type) {
      case "saving":
        return "Savings Account";
      case "current":
        return "Current Account";
      default:
        break;
    }
  };

  return (
    <Card sx={classes.bankAccountCard} elevation={4}>
      <CardHeader
        sx={classes.bankAccountCardHeader}
        title={
          <Typography variant="body1" color="common.white" fontWeight="inherit">
            Bank Account
          </Typography>
        }
      />

      <CardContent sx={classes.cardContent}>
        <Box>
          <Box pt={4} pb={5}>
            <Box display="flex" justifyContent="space-between">
              <Box sx={classes.bankAccountIconBox}>
                <BankIcon color="primary" sx={{ fontSize: "18px" }} />
                <Typography
                  variant="body1"
                  ml={1}
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Bank Account
                </Typography>
              </Box>
              <Box>
                <IconButton
                  aria-label="save"
                  color="primary"
                  size="small"
                  sx={classes.bankAccountCardEditIcon}
                  onClick={openEditBankAccountDialog}
                >
                  <EditSolidIcon sx={{ fontSize: "15px" }} />
                </IconButton>
              </Box>
            </Box>
            {bankAccount !== null ? (
              <Box sx={classes.bankAccountDetailBox}>
                <Typography variant="body1" color={"common.black"}>
                  {bankAccount.account_name && `${bankAccount.account_name} , `}
                  {bankAccount.account_number &&
                    `${bankAccount.account_number} ,`}
                </Typography>
                <Typography variant="body1" color={"common.black"}>
                  {bankAccount.bank.name_en && `${bankAccount.bank.name_en} ,`}
                  {bankAccount.account_type !== null &&
                    mapAccountType(bankAccount.account_type)}
                </Typography>
                <Typography
                  variant="body1"
                  color={"common.black"}
                  sx={{ pt: 2 }}
                >
                  {bankAccount.tax_id &&
                    `Tax ID : ${formatTaxId(bankAccount.tax_id)}`}
                </Typography>
              </Box>
            ) : (
              <Box sx={classes.bankAccountDetailBox}>
                <Typography variant="body1" color={"text.secondary"}>
                  + Add Bank Account
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default InfluencerBankAccount;
