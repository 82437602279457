import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactSortable } from "react-sortablejs";
import CardInfluencerSm from "../card-influencer/CardInfluencerSm";
import { Typography, Box, Collapse, Tooltip } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { log } from "console";

function InfluencerDnD({ influencerSelected, setInfluencerSelected }: any) {
  // const [list1, setList1] = useState<any>(influencerSelected.slice(0, 20));
  // const [list2, setList2] = useState<any>(influencerSelected.slice(20, 24));
  const [list1, setList1] = useState<any>([]);
  const [list2, setList2] = useState<any>([]);
  const [openCollapse, setOpenCollapse] = useState(false);

  useEffect(() => {
    setList1(influencerSelected.slice(0, 20));
    setList2(influencerSelected.slice(20, 24));
  }, [influencerSelected]);

  const onHandleChange = () => {
    const list = [...list1, ...list2];

    if (list1.length > 20 && list.length === influencerSelected.length) {
      setList1(list.slice(0, 20));
      setList2(list.slice(20, 24));
    } else if (list1.length < 20 && list.length === influencerSelected.length) {
      setList1(list.slice(0, 20));
      setList2(list.slice(20, 24));
    }
  };

  return (
    <div>
      <Container>
        <StyledReactSortable
          onEnd={onHandleChange}
          list={list1}
          setList={setList1}
          animation={150}
          // className=''
          group="shared-group-name"
        >
          {list1.map((item: any) => (
            <Item key={item.influencer_id}>
              <CardInfluencerSm
                profile={item}
                influencerSelected={influencerSelected}
                setInfluencerSelected={setInfluencerSelected}
              />
            </Item>
          ))}
        </StyledReactSortable>
        <hr />
        <Collapse in={openCollapse}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={3}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="body1"
                color="common.black"
                fontWeight="500"
                mr={5}
              >
                Influencer List
              </Typography>
              <Typography variant="body1" color="common.black" fontWeight="400">
                (Secondary Influencer){" "}
              </Typography>
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.white",
                      },
                      color: "common.black",
                      width: 500,
                      p: 3,
                    },
                  },
                }}
                title={
                  <React.Fragment>
                    <div>
                      <Typography variant="h6" color="inherit" fontWeight="500">
                        Example of Term and Condition
                      </Typography>
                      <Typography color="rgba(0, 0, 0, 0.54)">
                        <ul>
                          <li>
                            โปรดทบทวนนโยบายและความเป็นส่วนตัวของบริษัท
                            เพื่อปฎิบัติตามอย่าง เคร่งครัด
                          </li>
                          <li>
                            ให้สิทธิ์บริษัทในการทำซ้ำ ดัดแปลง แก้ไข
                            หรือประชาสัมพันธ์ บทความการรีวิว ของคุณ
                            ผ่านช่องทางออนไลน์ต่างๆของบริษัทได้
                            โดยคุณไม่สามารถเรียกร้อง
                            ค่าตอบแทนใดๆกับทางบริษัทเพิ่มเติมได้
                          </li>
                          <li>
                            เปิดบัญชีสาธารณะ และไม่ทำการลบโพสต์
                            จนกว่าจะครบกำหนดเวลา หากทำการลบโพสต์ก่อนเวลา
                            ที่กำหนด คุณจะไม่ได้รับค่าตอบแทนและบริษัท
                            สามารถเรียกร้องค่าเสียหายจากคุณได้
                          </li>
                          <li>
                            โปรดอ่านและทำความเข้าใจ รายละเอียด
                            รวมถึงสิ่งที่ต้องทำและข้อห้ามใน
                            การรีวิวสินค้าหรือบริการ
                            ก่อนยอมรับข้อตกลงและเงื่อนไขในการรีวิว
                          </li>
                        </ul>
                      </Typography>
                    </div>
                  </React.Fragment>
                }
              >
                <HelpOutlineIcon sx={{ fontSize: "18px", marginLeft: "5px" }} />
              </Tooltip>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography
                variant="body1"
                color="secondary.main"
                fontWeight="500"
              >
                {influencerSelected?.slice(20, 24).length}
              </Typography>
              <Typography
                variant="body1"
                color="primary.main"
                fontWeight="500"
                mr={5}
              >
                /4
              </Typography>
              <Typography variant="body1" color="common.black" fontWeight="400">
                Influencers
              </Typography>
            </Box>
          </Box>
          <StyledReactSortable
            onEnd={onHandleChange}
            list={list2}
            setList={setList2}
            animation={150}
            group="shared-group-name"
          >
            {list2.map((item: any) => (
              <Item key={item.influencer_id}>
                <CardInfluencerSm
                  profile={item}
                  influencerSelected={influencerSelected}
                  setInfluencerSelected={setInfluencerSelected}
                />
              </Item>
            ))}
          </StyledReactSortable>
        </Collapse>

        <Box
          onClick={() => setOpenCollapse(!openCollapse)}
          role="button"
          sx={{ cursor: "pointer" }}
        >
          {openCollapse ? (
            <Typography
              variant="body1"
              color="primary.300"
              fontWeight="400"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Show Less <ExpandLessIcon />
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="primary.300"
              fontWeight="400"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Show more <ExpandMoreIcon />
            </Typography>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default InfluencerDnD;

const StyledReactSortable = styled(ReactSortable)`
  display: grid;
  width: 100%;

  grid-template-columns: repeat(2, 50%);

  @media only screen and (min-width: 400px) and (max-width: 999px) {
    grid-template-columns: repeat(4, 20%);
  }

  @media only screen and (min-width: 1000px) and (max-width: 1099px) {
    grid-template-columns: repeat(6, 16.6%);
  }

  @media only screen and (min-width: 1100px) and (max-width: 1199px) {
    grid-template-columns: repeat(8, 12.5%);
  }

  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    grid-template-columns: repeat(9, 11%);
  }
  @media only screen and (min-width: 1400px) {
    grid-template-columns: repeat(10, 10%);
  }
`;

const Item = styled.div`
  display: flex;
  padding: 0.3rem;
  border-radius: 0.2rem;
  background-color: #fff6;
  margin-bottom: 0.3rem;

  &.sortable-ghost {
    background-color: #c8ebfb;
  }
  &:hover {
    cursor: pointer;
  }
`;

const GridItem = styled(Item)`
  width: 80px;
  & > * {
    margin: auto;
  }
  &:hover {
    cursor: pointer;
  }
`;

/** Wraps internal components for styling. */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  & > * {
    width: 100%;
    margin-left: 0.3rem;
    :first-child() {
      margin-left: 0rem;
    }
  }
`;
