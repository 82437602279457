export const socialPosts = [
  {
    id: 1,
    name: "John Smith",
    textDetail:
      "ต้องเข้าแอปไปช้อป ‘Lazada 12.12 เซลใหญ่ ส่งท้ายปี’แล้วมั้ยล่ะ !! เค้าลดหนักจริงไม่มีแกง ลดสูงถึง90% ส่งท้ายปีกันไปเลยจุกๆ ปีนี้อยากได้อะไรแล้วยังไม่ได้ซื้อ รีบ เข้าไปซื้อกันด่วนๆๆเลยจ้า วันนี้ - 14 ธ.ค. 64 เท่านั้น 📦 <br/>💙ลดแรงสุด 90% <br/>💙ส่งฟรีทั่วไทย <br/>💙Lazada Bonus ช้อปทุกๆ 500.- ลดอีก 50.- <br/>💙มีดีลพิเศษจากแบรนด์ดังใน LazMall ให้ช้อปทุกชั่วโมงแถมยังใช้ส่วนลดพร้อมกันได้ถึง 6 ต่อ ลดแล้วลดไปอี๊ก ใครไม่ช้อปพลาดมาก อย่าหาว่าพีไม่เตือนนะ  <br/>💙  #Lazada1212TH  #Lazada1212GrandYearEndSaleTH",

    type: 1,
    video: "/assets/videos/too-cute.mp4",
    images: [
      {
        img: "/assets/images/laz-1.png",
        title: "John",
      },
    ],
    rating: {
      likes: 5,
      comments: 5,
      shares: 5,
    },
    status: {
      statusCode: 1,
      statusText: "Send To Approve ",
    },
  },

  {
    id: 2,
    name: "Putthipong",
    textDetail:
      "ต้องเข้าแอปไปช้อป ‘Lazada 12.12 เซลใหญ่ ส่งท้ายปี’แล้วมั้ยล่ะ !! เค้าลดหนักจริงไม่มีแกง ลดสูงถึง90% ส่งท้ายปีกันไปเลยจุกๆ ปีนี้อยากได้อะไรแล้วยังไม่ได้ซื้อ รีบ เข้าไปซื้อกันด่วนๆๆเลยจ้า วันนี้ - 14 ธ.ค. 64 เท่านั้น 📦 <br/>💙ลดแรงสุด 90% <br/>💙ส่งฟรีทั่วไทย <br/>💙Lazada Bonus ช้อปทุกๆ 500.- ลดอีก 50.- <br/>💙มีดีลพิเศษจากแบรนด์ดังใน LazMall ให้ช้อปทุกชั่วโมงแถมยังใช้ส่วนลดพร้อมกันได้ถึง 6 ต่อ ลดแล้วลดไปอี๊ก ใครไม่ช้อปพลาดมาก อย่าหาว่าพีไม่เตือนนะ  <br/>💙  #Lazada1212TH  #Lazada1212GrandYearEndSaleTH",

    type: 1,
    video: "/assets/videos/too-cute.mp4",
    images: [
      {
        img: "/assets/images/laz-1.png",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-2.jpeg",
        title: "Putthipong",
      },
    ],
    rating: {
      likes: 10,
      comments: 10,
      shares: 10,
    },
    status: {
      statusCode: 2,
      statusText: "Approved",
    },
  },
  {
    id: 3,
    name: "Putthipong",
    textDetail:
      "ต้องเข้าแอปไปช้อป ‘Lazada 12.12 เซลใหญ่ ส่งท้ายปี’แล้วมั้ยล่ะ !! เค้าลดหนักจริงไม่มีแกง ลดสูงถึง90% ส่งท้ายปีกันไปเลยจุกๆ ปีนี้อยากได้อะไรแล้วยังไม่ได้ซื้อ รีบ เข้าไปซื้อกันด่วนๆๆเลยจ้า วันนี้ - 14 ธ.ค. 64 เท่านั้น 📦 <br/>💙ลดแรงสุด 90% <br/>💙ส่งฟรีทั่วไทย <br/>💙Lazada Bonus ช้อปทุกๆ 500.- ลดอีก 50.- <br/>💙มีดีลพิเศษจากแบรนด์ดังใน LazMall ให้ช้อปทุกชั่วโมงแถมยังใช้ส่วนลดพร้อมกันได้ถึง 6 ต่อ ลดแล้วลดไปอี๊ก ใครไม่ช้อปพลาดมาก อย่าหาว่าพีไม่เตือนนะ  <br/>💙  #Lazada1212TH #Lazada1212GrandYearEndSaleTH",

    type: 1,
    video: "/assets/videos/too-cute.mp4",
    images: [
      {
        img: "/assets/images/laz-1.png",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-2.jpeg",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-3.jpeg",
        title: "Putthipong",
      },
    ],
    rating: {
      likes: 10,
      comments: 10,
      shares: 10,
    },
    status: {
      statusCode: 1,
      statusText: "Send To Approve ",
    },
  },
  {
    id: 4,
    name: "Putthipong",
    textDetail:
      "ต้องเข้าแอปไปช้อป ‘Lazada 12.12 เซลใหญ่ ส่งท้ายปี’แล้วมั้ยล่ะ !! เค้าลดหนักจริงไม่มีแกง ลดสูงถึง90% ส่งท้ายปีกันไปเลยจุกๆ ปีนี้อยากได้อะไรแล้วยังไม่ได้ซื้อ รีบ เข้าไปซื้อกันด่วนๆๆเลยจ้า วันนี้ - 14 ธ.ค. 64 เท่านั้น 📦 <br/>💙ลดแรงสุด 90% <br/>💙ส่งฟรีทั่วไทย <br/>💙Lazada Bonus ช้อปทุกๆ 500.- ลดอีก 50.- <br/>💙มีดีลพิเศษจากแบรนด์ดังใน LazMall ให้ช้อปทุกชั่วโมงแถมยังใช้ส่วนลดพร้อมกันได้ถึง 6 ต่อ ลดแล้วลดไปอี๊ก ใครไม่ช้อปพลาดมาก อย่าหาว่าพีไม่เตือนนะ  <br/>💙  #Lazada1212TH #Lazada1212GrandYearEndSaleTH",

    type: 1,
    video: "/assets/videos/too-cute.mp4",
    images: [
      {
        img: "/assets/images/laz-1.png",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-2.jpeg",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-3.jpeg",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-4.jpeg",
        title: "Putthipong",
      },
    ],
    rating: {
      likes: 10,
      comments: 10,
      shares: 10,
    },
    status: {
      statusCode: 2,
      statusText: "Approved",
    },
  },
  {
    id: 5,
    name: "Putthipong",
    textDetail:
      "ต้องเข้าแอปไปช้อป ‘Lazada 12.12 เซลใหญ่ ส่งท้ายปี’แล้วมั้ยล่ะ !! เค้าลดหนักจริงไม่มีแกง ลดสูงถึง90% ส่งท้ายปีกันไปเลยจุกๆ ปีนี้อยากได้อะไรแล้วยังไม่ได้ซื้อ รีบ เข้าไปซื้อกันด่วนๆๆเลยจ้า วันนี้ - 14 ธ.ค. 64 เท่านั้น 📦 <br/>💙ลดแรงสุด 90% <br/>💙ส่งฟรีทั่วไทย <br/>💙Lazada Bonus ช้อปทุกๆ 500.- ลดอีก 50.- <br/>💙มีดีลพิเศษจากแบรนด์ดังใน LazMall ให้ช้อปทุกชั่วโมงแถมยังใช้ส่วนลดพร้อมกันได้ถึง 6 ต่อ ลดแล้วลดไปอี๊ก ใครไม่ช้อปพลาดมาก อย่าหาว่าพีไม่เตือนนะ  <br/>💙  #Lazada1212TH #Lazada1212GrandYearEndSaleTH",

    type: 1,
    video: "/assets/videos/too-cute.mp4",
    images: [
      {
        img: "/assets/images/laz-1.png",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-2.jpeg",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-3.jpeg",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-4.jpeg",
        title: "Putthipong",
      },
      {
        img: "/assets/images/laz-1.png",
        title: "Putthipong",
      },
    ],
    rating: {
      likes: 10,
      comments: 10,
      shares: 10,
    },
    status: {
      statusCode: 2,
      statusText: "Approved",
    },
  },

  {
    id: 6,
    name: "John Smith",
    textDetail:
      "ต้องเข้าแอปไปช้อป ‘Lazada 12.12 เซลใหญ่ ส่งท้ายปี’แล้วมั้ยล่ะ !! เค้าลดหนักจริงไม่มีแกง ลดสูงถึง90% ส่งท้ายปีกันไปเลยจุกๆ ปีนี้อยากได้อะไรแล้วยังไม่ได้ซื้อ รีบ เข้าไปซื้อกันด่วนๆๆเลยจ้า วันนี้ - 14 ธ.ค. 64 เท่านั้น 📦 <br/>💙ลดแรงสุด 90% <br/>💙ส่งฟรีทั่วไทย <br/>💙Lazada Bonus ช้อปทุกๆ 500.- ลดอีก 50.- <br/>💙มีดีลพิเศษจากแบรนด์ดังใน LazMall ให้ช้อปทุกชั่วโมงแถมยังใช้ส่วนลดพร้อมกันได้ถึง 6 ต่อ ลดแล้วลดไปอี๊ก ใครไม่ช้อปพลาดมาก อย่าหาว่าพีไม่เตือนนะ  <br/>💙  #Lazada1212TH  #Lazada1212GrandYearEndSaleTH",

    type: 2,
    video: "/assets/videos/lazada66.mp4",
    images: [{ img: "/assets/images/laz-3.png", title: "John" }],
    rating: {
      likes: 5,
      comments: 5,
      shares: 5,
    },
    status: {
      statusCode: 1,
      statusText: "Send To Approve ",
    },
  },
];
