import React, { createContext, useContext } from "react";
import { IAuthContext, Role } from "./Types";
import { getTokenFromStorage } from "../utils/helpers/getTokenFromStorage";

const user = getUserFromLocalStorage();

const InititalAuth: IAuthContext = {
  isAuthenticated: user.isAuthenticated,
  role: user.role,
  signIn(token, cb) {
    localStorage.setItem("token", token);
    localStorage.setItem("status", JSON.stringify({ isAuthenticated: true }));
    InititalAuth.isAuthenticated = true;
    const { session_token } = getTokenFromStorage();
    if (session_token) {
      // const roles = session_token.role;
      // const role = getUserRole(roles);
      // if (role) {
      //   InititalAuth.role = role;
      // }
    }
    cb();
  },
  signOut(cb) {
    localStorage.clear();
    InititalAuth.isAuthenticated = false;
    cb();
  },
};

const authContext = createContext<IAuthContext>(InititalAuth);

function getUserFromLocalStorage() {
  let result = { isAuthenticated: false, role: "guest" as Role };
  const status = localStorage.getItem("status");
  if (status) {
    // const { isAuthenticated } = JSON.parse(status);
    const { session_token } = getTokenFromStorage();
    if (session_token) {
      // const roles = session_token.role;
      // const role = getUserRole(roles);
      // if (role) {
      //   result = { isAuthenticated, role };
      // }
    }
  }
  return result;
}

// function getUserRole(roles: Role[]) {
//   return roles.find(
//     (item) => item.includes("influencer") || item.includes("employer")
//   );
// }

function useAuth() {
  return useContext(authContext);
}

function ProvideAuth({ children }: { children: React.ReactNode }) {
  <authContext.Provider value={InititalAuth}>{children}</authContext.Provider>;
}

export { useAuth, ProvideAuth };
