import React, { ChangeEvent, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// Component
import FacebookTab from "./facebook-tab/FacebookTab";
import InstagramTab from "./instagram-tab/InstagramTab";
import YoutubeTab from "./youtube-tab/YoutubeTab";
import TwitterTab from "./twitter-tab/TwitterTab";
import TiktokTab from "./tiktok-tab/TiktokTab";
import MyprofileTab from "./myprofile-tab/MyprofileTab";
// Types
import {
  IInfluencerSocialProps,
  ITabPanelProps,
} from "./InfluencerSocial.types";
// Styles
import { useInfluencerSocialStyle as classes } from "./InfluencerSocial.styles";
import { SocialTab } from "./InfluencerSocial.styles";

// Icons
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import TikTokIcon from "@mui/icons-material/MusicNoteOutlined";
import {
  Instagram,
  Facebook,
  Youtube,
  Twitter,
  Tiktok,
} from "../../assets/icons/social/social";
// Material
import { Box, Tabs, Typography } from "@mui/material";

const InfluencerSocial = React.memo((props: any) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { facebook, instagram, youtube, twitter, tiktokApi } = props;

  const [tabSelected, setTabSelected] = useState<Number>(0);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: Number) => {
    setTabSelected(newValue);
  };

  const handleTabActive = (index: unknown) => {
    console.log("index", index);

    return {
      id: `social-tab-${index}`,
      "aria-controls": `social-tabpanel-${index}`,
    };
  };

  const tabs = matches
    ? [
        { key: "myprofile", text: "My Profile" },
        {
          key: "tiktok",
          text: "TikTok",
          icon: <Tiktok sx={{ fontSize: "18px" }} />,
        },
        {
          key: "facebook",
          text: "Facebook",
          icon: <Facebook />,
        },
        {
          key: "instagram",
          text: "Instagram",
          icon: <Instagram />,
        },
        {
          key: "youtube",
          text: "YouTube",
          icon: <Youtube />,
        },
        {
          key: "twitter",
          text: "Twitter",
          icon: <Twitter />,
        },
      ]
    : [
        {
          key: "tiktok",
          text: "TikTok",
          icon: <Tiktok />,
        },
        {
          key: "facebook",
          text: "Facebook",
          icon: <Facebook />,
        },
        {
          key: "instagram",
          text: "Instagram",
          icon: <Instagram />,
        },
        {
          key: "youtube",
          text: "YouTube",
          icon: <Youtube />,
        },
        {
          key: "twitter",
          text: "Twitter",
          icon: <Twitter />,
        },
      ];

  const TabPanel = (props: ITabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`social-tabpanel-${index}`}
        aria-labelledby={`social-tab-${index}`}
        // id={`scrollable-auto-tabpanel-${index}`}
        // aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  return (
    <Box>
      <Tabs
        value={tabSelected}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={classes.tabs}
      >
        {tabs.map((tab, index) => (
          <SocialTab
            key={index}
            icon={tab.icon}
            iconPosition="start"
            label={
              <Typography variant="body1" fontWeight="inherit">
                {tab.text}
              </Typography>
            }
            {...handleTabActive(tab.key)}
          />
        ))}
      </Tabs>
      <FacebookTab
        TabPanel={TabPanel}
        tabSelected={tabSelected}
        facebook={facebook}
        matches={matches}
      />
      <InstagramTab
        TabPanel={TabPanel}
        tabSelected={tabSelected}
        instagram={instagram}
        matches={matches}
      />
      <YoutubeTab
        TabPanel={TabPanel}
        tabSelected={tabSelected}
        youtube={youtube}
        matches={matches}
      />
      <TwitterTab
        TabPanel={TabPanel}
        tabSelected={tabSelected}
        twitter={twitter}
        matches={matches}
      />
      <TiktokTab
        TabPanel={TabPanel}
        tabSelected={tabSelected}
        tiktokApi={tiktokApi}
        matches={matches}
      />
      <MyprofileTab TabPanel={TabPanel} tabSelected={tabSelected} />
    </Box>
  );
});

export default InfluencerSocial;
