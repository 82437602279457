import React, { useEffect, useState } from "react";
// Material UI
import {
  Grid,
  Box,
  Typography,
  IconButton,
  SvgIcon,
  Icon,
  Stack,
} from "@mui/material";
// Styles
import { InfluencerMyEarningStyles as classes } from "./InfluencerMyEarning.styles";
// ICons
import {
  BankIcon,
  EditSolidIcon,
  PaymentIcon,
  StatementBrandsIcon,
  StatementJobsIcon,
} from "../../assets/icons/social/social";
// ICons from material UI
// import BankIcon from "@mui/icons-material/AccountBalanceOutlined";
// import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
// Components
import EditBankAccountDialog from "../influencer-bank-account/edit-bank-account-dialog/EditBankAccountDialog";
// Types
import { IInfluencerRes } from "../../api/influencer/Types";
import { IBank } from "../../views/my-earning/MyEarning.types";
import {
  IEditBankAccountDialogProps,
  IBankAccountRes,
  IBankLists,
} from "./InfluencerMyEarning.types";
// API
import { getBankLists } from "../../api/appInformation/index";
// Globall
import { formatTaxId } from "../global/format-number";

export default function InfluencerMyEarning(data: IEditBankAccountDialogProps) {
  const [influencerProfile, setInfluencerProfile] =
    useState<IInfluencerRes | null>(null);
  const [isEditBankAccountOpen, setIsEditBankAccountOpen] =
    useState<boolean>(false);
  const [bankLists, setBankLists] = useState<IBankLists[] | null>(null);

  useEffect(() => {
    setInfluencerProfile(data.data);
    async function getBank() {
      const result = await getBankLists();
      const bankList = result.map((bank: IBank) => ({
        id: bank.id,
        value: bank.value,
        name_en: bank.name_en,
        name_th: bank.name_th,
        logo_image_url: bank.logo_image_url,
      }));
      setBankLists(bankList);
      console.log("Bank Lists", result);
    }
    getBank();
  }, [data]);

  const openEditBankAccountDialog = () => {
    setIsEditBankAccountOpen(true);
  };

  const closeBankAccountDialog = () => {
    setIsEditBankAccountOpen(false);
  };

  const onSubmitBankAccount = (data: { bank_account: IBankAccountRes }) => {
    if (influencerProfile !== null && bankLists !== null) {
      const bankId = bankLists.find(
        (bankId: { id: number }) => bankId.id === data.bank_account.bank_id
      );
      const bankAccount = data.bank_account;
      const IdCardImage = data.bank_account.id_card_image;
      const bookBankImage = data.bank_account.book_bank_image;
      const bank_account = {
        ...influencerProfile.bank_account,
        account_name: bankAccount.account_name,
        account_number: bankAccount.account_number,
        account_type: bankAccount.account_type,
        book_bank_image_url: {
          ...bookBankImage,
          file_id: bookBankImage.file_id as string,
          file_name: bookBankImage.file_name as string,
          description: bookBankImage.description as string,
        },
        branch: bankAccount.branch,
        id_card_image_url: {
          ...IdCardImage,
          file_id: IdCardImage.file_id as string,
          file_name: IdCardImage.file_name as string,
          description: IdCardImage.description as string,
        },
        tax_id: bankAccount.tax_id,
        bank: {
          id: bankId?.id as number,
          logo_image_url: bankId?.logo_image_url as string,
          name_en: bankId?.name_en as string,
          name_th: bankId?.name_th as string,
          value: bankId?.value as string,
        },
      };
      setInfluencerProfile({
        ...influencerProfile,
        bank_account: {
          ...bank_account,
        },
      });
    }
  };

  const mapAccountType = (type: string) => {
    switch (type) {
      case "saving":
        return "Savings Account";
      case "current":
        return "Current Account";
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h5" mb={4}>
        My Earning
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={classes.totalEarningBox}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <PaymentIcon />
              <Typography variant="body1" ml={2}>
                Total Earning
              </Typography>
            </Box>
            <Box sx={classes.totalEearning}>
              <Typography>
                {new Intl.NumberFormat("jp-JP", {
                  minimumFractionDigits: 2,
                }).format(123456)}
              </Typography>
              <span>THB</span>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Stack direction="row" sx={classes.brandsBox}>
            <Box sx={classes.brands}>
              <Box>
                <StatementBrandsIcon />
                <Typography ml={2}>Brands</Typography>
              </Box>
              <Typography className="totalBrands">50</Typography>
            </Box>
            <Divider orientation="vertical" flexItem sx={classes.brandsLine} />
            <Box sx={classes.brands}>
              <Box>
                <StatementJobsIcon />
                <Typography ml={2}>Jobs</Typography>
              </Box>
              <Typography className="totalBrands">50</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box sx={classes.bankAccountBox} px={5}>
            <Box display="flex" justifyContent="space-between">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={3}
              >
                <BankIcon color="primary" fontSize="inherit" />
                <Typography
                  variant="body1"
                  ml={2}
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Bank Account
                </Typography>
              </Box>
              <Box>
                {influencerProfile !== null &&
                influencerProfile.bank_account ? (
                  <IconButton
                    aria-label="save"
                    color="primary"
                    size="small"
                    sx={classes.bankAccountCardEditIcon}
                    onClick={openEditBankAccountDialog}
                  >
                    <EditSolidIcon sx={{ fontSize: "15px" }} />
                  </IconButton>
                ) : (
                  <Box></Box>
                )}
              </Box>
            </Box>
            {influencerProfile !== null && influencerProfile.bank_account ? (
              <Box sx={{ paddingTop: ["2px", "4px"] }}>
                <Typography
                  variant="body1"
                  color={"common.black"}
                  sx={classes.textSize}
                >
                  {influencerProfile.bank_account.account_name &&
                    `${influencerProfile.bank_account.account_name}, ${influencerProfile.bank_account.account_number}`}
                </Typography>
                <Typography color={"common.black"} sx={classes.textSize}>
                  {influencerProfile.bank_account.bank.name_en &&
                    `${influencerProfile.bank_account.bank.name_en} ,`}
                  {influencerProfile.bank_account.account_type !== null &&
                    mapAccountType(influencerProfile.bank_account.account_type)}
                </Typography>
                <Typography color={"common.black"} sx={classes.textSize}>
                  {influencerProfile.bank_account.tax_id &&
                    `Tax ID: ${formatTaxId(
                      influencerProfile.bank_account.tax_id
                    )}`}
                </Typography>
                {/* EditBankAccountDialog */}
                <EditBankAccountDialog
                  data={influencerProfile}
                  isOpened={isEditBankAccountOpen}
                  onClose={closeBankAccountDialog}
                  onSubmitBankAccount={onSubmitBankAccount}
                />
              </Box>
            ) : (
              <Box pt={4}>
                <Typography variant="body1" color={"text.secondary"}>
                  + Add Bank Account
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
