import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
} from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";

function ProductReview() {
  const styles = {
    boxContent: {
      padding: "8px",
      backgroundColor: "primary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
    cardContent: {
      padding: "8px",
      backgroundColor: "secondary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
  };

  return (
    <Card sx={styles.cardContent}>
      <CardHeader
        title={
          <Typography
            variant="h6"
            color="common.black"
            fontWeight="500"
            display="flex"
            alignItems="center"
          >
            <img src="/assets/images/box.png" style={{ marginRight: "20px" }} />
            Product Review
          </Typography>
        }
      />
      <CardContent sx={{ paddingInline: "30px" }}>
        <Stack>
          <WrapInputWithLabel title="Shipping Type " childrenFontWeight="500">
            Thailand Post
          </WrapInputWithLabel>
          <WrapInputWithLabel title="Tracking Code" childrenFontWeight="500">
            <a href="#">#EL126568989892</a>
          </WrapInputWithLabel>
          <WrapInputWithLabel
            title="Note"
            labelWidth="80px"
            childrenFontWeight="500"
          >
            ให้ส่งสินค้ากลับมาคืนภายในวันที่ 11 มกราคมนี้
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ProductReview;
