import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
} from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";
import TextFieldCustom from "../global/text-field";
import { Controller } from "react-hook-form";
import { numberWithCommas } from "../../utils/helpers/numberWithCommas";

function Budget({ form }: any) {
  const {
    formState: { errors },
    setValue,
    watch,
    control,
  } = form;

  const budgetTotal = watch("budget.total");
  const budgetInfluencerAmout = watch("budget.influencerAmount");

  const styles = {
    cardContainer: {
      margin: "8px",
    },
  };

  return (
    <Card sx={styles.cardContainer}>
      <CardHeader
        title={
          <Typography variant="h6" color="common.black" fontWeight="500">
            Budget
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Total Campaign"
            required
          >
            <Controller
              name="budget.total"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldCustom
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  error={!!errors?.budget?.total}
                  helperText={errors?.budget?.total?.message}
                  value={value}
                  onChange={(ev) => {
                    const newNum = numberWithCommas(ev.target.value);
                    onChange(newNum);
                  }}
                  name={name}
                  ref={ref}
                  onBlur={() => {
                    if (budgetTotal && budgetInfluencerAmout) {
                      const pBudgetTotal = +budgetTotal.replace(/\,/g, "");
                      const pBudgetInfluAmout = +budgetInfluencerAmout.replace(
                        /\,/g,
                        ""
                      );

                      const each = pBudgetTotal / pBudgetInfluAmout;
                      const eachWithComma = numberWithCommas(each.toString());

                      setValue("budget.eachInfluencer", eachWithComma);
                    } else {
                      setValue("budget.eachInfluencer", "");
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">THB</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Influencer amount"
            required
          >
            <Controller
              name="budget.influencerAmount"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldCustom
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  error={!!errors?.budget?.influencerAmount}
                  helperText={errors?.budget?.influencerAmount?.message}
                  value={value}
                  onChange={(ev) => {
                    const newNum = numberWithCommas(ev.target.value);
                    onChange(newNum);
                  }}
                  name={name}
                  ref={ref}
                  onBlur={() => {
                    if (budgetTotal && budgetInfluencerAmout) {
                      const pBudgetTotal = +budgetTotal.replace(/\,/g, "");
                      const pBudgetInfluAmout = +budgetInfluencerAmout.replace(
                        /\,/g,
                        ""
                      );

                      const each = pBudgetTotal / pBudgetInfluAmout;
                      const eachWithComma = numberWithCommas(each.toString());

                      setValue("budget.eachInfluencer", eachWithComma);
                    } else {
                      setValue("budget.eachInfluencer", "");
                    }
                  }}
                />
              )}
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel labelWidth="200px" title="Each influencer">
            <Controller
              name="budget.eachInfluencer"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextFieldCustom
                  variant="outlined"
                  placeholder="Please enter text..."
                  fullWidth
                  disabled
                  error={!!errors?.budget?.eachInfluencer}
                  helperText={errors?.budget?.eachInfluencer?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">THB</InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Budget;
