import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { getEmployerList } from "./../../api/Employer/employerAPI";
import { IEmployerListParams } from "./../../api/Employer/Types";
import { IEmployerState } from "./EmployerType";

const initialState: IEmployerState = {
  employerList: [],
  employerCreate: null,
  total_item: 0,
  item_per_page: 0,
  page: 0,
  loading: false,
  error: "",
};

export const getEmployerListAsync = createAsyncThunk(
  "employers",
  async (params: IEmployerListParams) => {
    try {
      const response = await getEmployerList(params);
      return response;
    } catch (err) {
      console.log("catch", err);
      throw err;
    }
  }
);

export const employerSlice = createSlice({
  name: "employers",
  initialState,
  reducers: {
    employerList: (state, { payload }) => {
      state.employerList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployerListAsync.pending, (state) => {
        state.employerList = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getEmployerListAsync.fulfilled, (state, action) => {
        const data = action.payload;
        state.employerList = data.item.map((items) => ({
          user_id: items.user_id,
          employer_id: items.employer_id,
          employer_full_id: items.employer_full_id,
          fullname: items.fullname,
          email: items.email,
          phone: items.phone,
          organisation: {
            id: items.organisation.id,
            name: items.organisation.name,
            size: items.organisation.size,
            type: items.organisation.type,
            attachment: {
              file_id: items.organisation.attachment.file_id,
              file_name: items.organisation.attachment.file_name,
              file_type: items.organisation.attachment.file_type,
              url: items.organisation.attachment.url,
              purpose: items.organisation.attachment.purpose,
              description: items.organisation.attachment.description,
            },
            category: items.organisation.category,
          },
        }));
        state.total_item = data.total_item;
        state.item_per_page = data.item_per_page;
        state.page = data.page;
        state.loading = false;
        state.error = "";
      })
      .addCase(getEmployerListAsync.rejected, (state, action) => {
        state.employerList = [];
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const selectEmployer = (state: RootState) => state.employers;

export const selectPagination = (state: RootState) => {
  return {
    page: state.employers.page,
    perPage: state.employers.item_per_page,
    totalItems: state.employers.total_item,
  };
};
export const selectOnload = (state: RootState) => state.employers.loading;

export default employerSlice.reducer;
