import React from "react";
// Mui
import {
  Box,
  Divider,
  Stack,
  Typography,
  CardMedia,
  CardContent,
  Card,
} from "@mui/material";
// Mui icons
import CircleIcon from "@mui/icons-material/Circle";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AllInboxOutlinedIcon from "@mui/icons-material/AllInboxOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import productImage from "../../../assets/images/other/product.jpg";
// style
import { useCampaignDetailCardStyle as classes } from "./CampaignDetails.style";

export default function CampaignDetailCard() {
  return (
    <Card sx={{ mx: "20px" }}>
      <CardMedia
        component="img"
        height="210px"
        image={productImage}
        alt="green iguana"
      />
      <CardContent>
        <Box sx={classes.cardTitle}>
          <Typography>ประกาศรับสมัคร</Typography>
        </Box>
        <Stack
          direction="row"
          spacing={3}
          sx={classes.categoryBox}
          divider={<CircleIcon sx={classes.divider} />}
        >
          {["Fashion", "Lifestyle", "Beauty"].map((item, index) => (
            <Typography key={index}>{item}</Typography>
          ))}
        </Stack>
        <Typography sx={classes.campName}>
          Lazada Shop Marathon 12.12
        </Typography>
        <Stack direction="row" spacing={7} sx={classes.campNameSubtitle}>
          {[
            {
              icon: <FacebookOutlinedIcon />,
              label: "Photo Album",
            },
            {
              icon: <MonetizationOnOutlinedIcon />,
              label: "Photo Album",
            },
          ].map((item, index) => (
            <Box key={index}>
              {item.icon}
              <Typography ml={2}>{item.label}</Typography>
            </Box>
          ))}
        </Stack>
        <Box sx={classes.product}>
          <AllInboxOutlinedIcon />
          <Box ml={2}>
            <Typography fontSize="12px">Product</Typography>
            <Typography fontSize="14px" color="#5C5C5C">
              WITAL B+Sage (Dietary Supplement...
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ m: "16px" }} />
        <Box display="flex" justifyContent="space-between" mx="16px">
          <Box display="flex">
            <AccountCircleIcon />
            <Typography ml="5px">0/20</Typography>
          </Box>
          <Typography>Now</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
