import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { Box, Typography, Button, Icon, CircularProgress } from "@mui/material";
// Styles
import { useVertifyEmail as classes } from "./vertifyEmail.styles";
import { IVerifiedEmail } from "./../../components/vertify-email/LoadingVerified";
import { resendVerifyEmailWithAPI } from "../../api/authentication/Authentication";

export default function VertifyEmail() {
  const { email }: any = useParams();

  const [loading, setLoading] = useState(false);

  const resendVerifyEmail = async (params: IVerifiedEmail) => {
    setLoading(true);
    const body = {
      email: email,
      grant_type: "resend",
    };
    try {
      const response = await resendVerifyEmailWithAPI(body);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  return (
    <div>
      <Box sx={classes.backgroundContainer}>
        <Box sx={classes.backgroundLiner}></Box>
        <Box sx={classes.boxDialog}>
          <Box sx={classes.boxIcon}>
            <Icon className="ico-hu-email-solid" sx={classes.mailIcon} />
          </Box>
          <Box sx={classes.boxContent}>
            <Typography variant="h4">Please vertify your email</Typography>
            <Typography variant="body1" sx={{ marginTop: "24px" }}>
              You're almost there! We sent an email to
            </Typography>
            <Typography variant="body1" sx={classes.textEmail}>
              {email}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "16px" }}>
              Just click on the link in that email to complete your sign up.
            </Typography>
            <Typography variant="body1">
              If you don't see it, please check again in your spam folder.
            </Typography>
            <Typography variant="body1" sx={classes.textDanger}>
              ***Please verify email within 15 minutes.***
            </Typography>
          </Box>
          <Box sx={classes.boxButton}>
            <Typography variant="caption">
              Still can't find the email?
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={() => {
                  resendVerifyEmail(email);
                }}
                sx={classes.resendButton}
              >
                Resend Email
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
