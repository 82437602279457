import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { SaveButton } from "../../../../views/my-profile/MyProfile.styles";
import DialogScrollingCustom from "../../../global/dialog-scrolling-custom";
import TextFieldCustom from "../../../global/text-field";
import { useEditProfileDialogStyle as classes } from "../../../influencer-social/myprofile-tab/MyProfileTab.style";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import DefaultImage from "../../../../assets/images/other/image-empty.jpg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "../../../../store/hook";
import { selectEmployerDetail } from "../../../../views/employer/detail/EmployerDetailSlice";
import BasicSelectCustom from "../../../global/basic-select";
// import MultipleSelectCustom from "../../../global/multiple-select";
import { ICategory, IEditEmployerFormSchema } from "../../Types";
import {
  getCategories,
  uploadImage,
} from "../../../../api/influencer/InfluencerAPI";
import { IEditEmployerForm, IEditImageUrl } from "./EditEmployerForm";
import { updateEmployer } from "../../../../api/Employer/employerAPI";
import { EditEmployerFormSchema } from "./EditEmployerForm.Schema";
import { addDefaultImageSrc } from "../../../../utils/helpers/addDefaultImageSrc.helpers";

interface IEditEmployerDialogProps {
  isOpened: boolean;
  onClose: () => void;
}

const initImage: IEditImageUrl = {
  file_id: "",
  file_name: "",
  file_type: "",
  url: "",
  purpose: "",
  description: "",
};

const initEditEmployerForm: IEditEmployerForm = {
  fullname: "",
  email: "",
  phone: "",
  line_id: "",
  name: "",
  size: "",
  type: "",
  category: [],
  attachment: initImage,
};

export default function EditInformation(props: IEditEmployerDialogProps) {
  const { isOpened, onClose } = props;
  const employerDetail = useAppSelector(selectEmployerDetail);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optionCategory, setOptionCategory] = useState<ICategory[]>([]);
  const [employerId, setEmployerId] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categorySelected, setCategorySelected] = useState<any>([]);
  const [imageOrgLogo, setImageOrgLogo] = useState<IEditImageUrl>(initImage);
  const [imageName, setImageName] = useState<string>("");

  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
    register,
  } = useForm<IEditEmployerForm>({
    resolver: yupResolver(EditEmployerFormSchema),
    defaultValues: initEditEmployerForm,
  });

  const optionBusinessSize = [
    {
      id: 1,
      value: "small",
      label: "Small Business",
    },
    {
      id: 2,
      value: "medium",
      label: "Medium Business",
    },
    {
      id: 3,
      value: "large",
      label: "Large Business",
    },
  ];

  const optionType = [
    {
      id: 1,
      value: "brand or company",
      label: "Brand or Company",
    },
    {
      id: 2,
      value: "agency",
      label: "Agency",
    },
    {
      id: 3,
      value: "individual",
      label: "Individual",
    },
    {
      id: 4,
      value: "enterpise",
      label: "Enterprise",
    },
    {
      id: 5,
      value: "other",
      label: "Other",
    },
  ];

  useEffect(() => {
    async function getResult() {
      const results = await getCategories();
      setOptionCategory(results);
    }
    getResult();
  }, []);

  const handleChange = (e: any, nameChange: keyof IEditEmployerFormSchema) => {
    setValue(nameChange, e.target.value);
    clearErrors(nameChange);
  };

  // useEffect(() => {
  //   setActionEditEmployer(!actionEditEmployer);
  // }, [actionEditEmployer]);

  useEffect(() => {
    if (employerDetail) {
      if (
        (employerDetail.employer_full_id && employerId === null) ||
        employerDetail.employer_full_id !== employerId
      ) {
        setEmployerId(employerDetail.employer_full_id);
      }

      const form = {
        name: employerDetail?.organisation.name,
        size: employerDetail?.organisation.size,
        type: employerDetail?.organisation.type,
        category: employerDetail?.organisation.category,
        attachment: employerDetail?.organisation.attachment,
        email: employerDetail?.email,
        fullname: employerDetail?.fullname,
        phone: employerDetail?.phone,
        line_id: employerDetail?.line_id,
      };
      reset(form);
      setImageOrgLogo(employerDetail.organisation.attachment);
    }
    // eslint-disable-next-line
  }, [employerDetail, reset]);

  const handleChangeUploadImage = async (e: any) => {
    let data = {
      file: e.target.files[0],
      destination: "organisation",
      permission: "public",
    };
    const result = await uploadImage(data);
    console.log("result image", result);

    if (result) {
      const imageData = {
        file_name: result.file_name as string,
        file_type: result.file_type as string,
        url: (result.public_url as string) || (result.private_url as string),
      };
      if (imageName === "imageOrgLogo") {
        setImageOrgLogo({
          ...imageOrgLogo,
          file_id: imageOrgLogo.file_id === "" ? "" : imageOrgLogo.file_id,
          purpose:
            imageOrgLogo.purpose === ""
              ? "organization_logo_image"
              : imageOrgLogo.purpose,
          ...imageData,
        });
      }
    }
  };

  const onSubmit = async (data: IEditEmployerForm) => {
    try {
      const reqBody = {
        fullname: data.fullname,
        phone: data.phone,
        line_id: data.line_id,
        organisation: {
          name: data.name,
          size: data.size,
          type: data.type,
          attachment: imageOrgLogo,
          category: data.category,
        },
      };
      const response = updateEmployer(employerId, reqBody);
      console.log("edit", response);
      onClose();
    } catch (error: any) {
      console.log("error", error);
    }
  };

  return (
    <DialogScrollingCustom
      open={isOpened}
      onClose={onClose}
      title="Edit Employer"
      sx={classes.dialog}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={classes.dialogContent}>
          <Box px={2} pt={1}>
            <Box display="flex" flexDirection="row">
              <Icon
                className="ico-hu-s-acc-business"
                sx={{ marginRight: "8px" }}
              />
              <Typography variant="body1" fontWeight="600">
                Personal Information
              </Typography>
            </Box>
            <Stack px={5} pt={4}>
              <Grid container pb={5}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body2">Organization Logo :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box sx={classes.profileContainerBox}>
                    <Box sx={classes.profileBox}>
                      {imageOrgLogo.url !== "" ? (
                        <img
                          src={imageOrgLogo.url}
                          alt="images"
                          onError={addDefaultImageSrc}
                        />
                      ) : (
                        <img
                          src={DefaultImage}
                          alt="images"
                          onError={addDefaultImageSrc}
                        />
                      )}
                      <Button
                        variant="contained"
                        component="label"
                        disableElevation
                        onChange={handleChangeUploadImage}
                        onClick={() => setImageName("imageOrgLogo")}
                        sx={classes.profileButton}
                      >
                        <input
                          hidden
                          name="imageName"
                          accept="image/*"
                          multiple
                          type="file"
                        />
                        <CameraIcon fontSize="inherit" />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Organization Name :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("name")}
                    onChange={(e) => handleChange(e, "name")}
                    type="text"
                    placeholder="Organization Name"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Full Name :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("fullname")}
                    onChange={(e) => handleChange(e, "fullname")}
                    type="text"
                    placeholder="Full Name"
                    fullWidth
                    error={!!errors.fullname}
                    helperText={errors.fullname?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Size :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <BasicSelectCustom
                    id="size-select"
                    value={getValues("size")}
                    {...register("size")}
                    onChange={(e) => handleChange(e, "size")}
                    data={optionBusinessSize}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select"
                    fullWidth
                    error={!!errors.size}
                    helperText={errors.size?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Type :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <BasicSelectCustom
                    id="type-select"
                    value={getValues("type")}
                    {...register("type")}
                    onChange={(e) => handleChange(e, "type")}
                    data={optionType}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select Type"
                    fullWidth
                    error={!!errors.type}
                    helperText={errors.type?.message}
                  />
                </Grid>
              </Grid>
              {/* <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">Categories :</Typography>
                    <Typography variant="caption" color="#5C5C5C">
                      (เลือกได้สูงสุด 3 ประเภท)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <MultipleSelectCustom
                    id="category-select"
                    value={getValues("category")}
                    setValue={categorySelected}
                    onChange={(e) => handleChange(e, "category")}
                    placeholder={"Select"}
                    data={optionCategory}
                    fullWidth
                    valueKey="id"
                    labelKey="name_en"
                    error={!!errors.category}
                  />
                </Grid>
              </Grid> */}
            </Stack>
            <Divider />
            <Box display="flex" flexDirection="row" pt={4}>
              <Icon
                className="ico-hu-people-top-card"
                sx={{ marginRight: "8px" }}
              />
              <Typography variant="body1" fontWeight="600">
                Contact
              </Typography>
            </Box>
            <Stack px={5} pt={4}>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Email :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("email")}
                    // onChange={(e) => handleChange(e, "email")}
                    type="text"
                    placeholder="Email"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="ico-hu-email-solid"
                            // sx={classes.iconInput}
                          />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Phone :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("phone")}
                    onChange={(e) => handleChange(e, "phone")}
                    type="text"
                    placeholder="Phone"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Line ID :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("line_id")}
                    onChange={(e) => handleChange(e, "line_id")}
                    type="text"
                    placeholder="Line ID"
                    fullWidth
                    error={!!errors.line_id}
                    helperText={errors.line_id?.message}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            marginTop: 3,
            marginBottom: 3,
          }}
        >
          <SaveButton
            variant="contained"
            type="submit"
            startIcon={<Icon className="ico-hu-save" />}
          >
            <Typography variant="button">Save</Typography>
          </SaveButton>
        </DialogActions>
      </form>
      {/* </Box> */}
    </DialogScrollingCustom>
  );
}
