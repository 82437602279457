import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEmployerDetail } from "../../../api/Employer/employerAPI";
import { IEmployerDetailState } from "./employerDetailType";
import { RootState } from "../../../store/store";

const initialState: IEmployerDetailState = {
  employerDetail: null,
  loading: false,
  error: "",
};

export const getEmployerDetailAsync = createAsyncThunk(
  "employers/getEmployerDetail",
  async (employerId: string) => {
    try {
      const response = await getEmployerDetail(employerId);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const employerDetailSlice = createSlice({
  name: "employerDetail",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployerDetailAsync.pending, (state) => {
        state.employerDetail = null;
        state.loading = true;
        state.error = "";
      })
      .addCase(getEmployerDetailAsync.fulfilled, (state, action) => {
        state.employerDetail = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getEmployerDetailAsync.rejected, (state, action) => {
        state.employerDetail = null;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const { setLoading } = employerDetailSlice.actions;
export const selectEmployerDetail = (state: RootState) =>
  state.employerDetail.employerDetail;
export const selectOnload = (state: RootState) => state.employerDetail.loading;

export default employerDetailSlice.reducer;
