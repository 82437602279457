import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// MUI
import { Box } from "@mui/material";
// Styles
import { useVertifyEmail as classes } from "./vertifyEmail.styles";
// API
import { verifyEmailWithAPI } from "./../../api/authentication/Authentication";
// Components
import LoadingVerified from "../../components/vertify-email/LoadingVerified";
import VerifyEmailSuccess from "./../../components/vertify-email/VerifyEmailSuccess";
import VerifyEmailFailed from "./../../components/vertify-email/VerifyEmailFailed";

interface IVerifiedEmail {
  email: string;
  grant_type: string;
}

export default function VerifiedEmail() {
  let { email }: any = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [verifiedSuccess, setVerifiedSuccess] = useState<boolean>(true);

  useEffect(() => {
    const verifiedEmail = async (params: IVerifiedEmail) => {
      const body = {
        email: email,
        grant_type: "verify",
      };
      try {
        console.log(params);
        const response = await verifyEmailWithAPI(body);
        setLoading(false);
        setVerifiedSuccess(true);
        return response;
      } catch (error) {
        setLoading(false);
        setVerifiedSuccess(false);
        console.log("error", error);
      }
    };
    verifiedEmail({ email: email, grant_type: "verify" });
  }, [email]);

  return (
    <Box sx={classes.backgroundContainer}>
      <Box sx={classes.backgroundLiner}></Box>
      <Box sx={classes.boxDialog}>
        {loading ? (
          <LoadingVerified />
        ) : verifiedSuccess ? (
          <VerifyEmailSuccess />
        ) : (
          <VerifyEmailFailed />
        )}
      </Box>
    </Box>
  );
}
