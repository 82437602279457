import React from "react";
import { styled } from "@mui/system";
import {
  SxProps,
  Theme,
  Pagination,
  PaginationProps,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
  useMediaQuery,
  Grid,
  MenuItem,
  darken,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface IPaginationCustomProps extends PaginationProps {
  count: number;
  page: number;
  onPaginationChange: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void;
  rowsPerPageData: number[];
  rowsPerPage: number;
  onRowPerPageChange: (event: SelectChangeEvent<any>) => void;
  sxPagination?: SxProps<Theme>;
  sxRowPerPage?: SxProps<Theme>;
}

const PaginationCustomStyle = styled(Pagination)<PaginationProps>(
  ({ theme }) => ({
    "& .MuiPagination-ul .MuiPaginationItem-root": {
      height: "40px",
      width: "40px",
      borderRadius: "20px",
      fontSize: "16px",
      "&.MuiPaginationItem-previousNext": {
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.common.white,
        border: "2px solid",
        margin: "0px 8px",
        "&:hover": {
          backgroundColor: darken(theme.palette.common.white, 0.05),
        },
        "&.Mui-disabled": {
          backgroundColor: "transparent",
        },
        "& .MuiSvgIcon-root.MuiPaginationItem-icon": {
          fontSize: "22px",
        },
      },
    },
  })
);

const SelectRowPerPage = styled(Select)<SelectProps>(({ theme }) => ({
  height: "40px",
  minWidth: "120px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: `2px solid ${theme.palette.grey[500]}`,
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[500],
    },
  },
}));

const style = {
  paginationGrid: {
    display: "flex",
    justifyContent: "center",
  },
  rowPerPageGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  showText: {
    paddingRight: 3,
  },
  menu: {
    color: "common.black",
    border: "2px solid",
    borderColor: "secondary.main",
    borderRadius: "20px !important",
  },
  menuItem: {
    alignItems: "center",
  },
} as const;

function PaginationCustom({
  count,
  page,
  onPaginationChange,
  rowsPerPageData,
  rowsPerPage,
  onRowPerPageChange,
  sxPagination,
  sxRowPerPage,
}: IPaginationCustomProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const lg = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <React.Fragment>
      <Grid container columnSpacing={{ xs: 0, md: 5, lg: 8 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={"auto"}
          lg={"auto"}
          sx={style.paginationGrid}
        >
          <PaginationCustomStyle
            count={count}
            page={page}
            onChange={onPaginationChange}
            color="secondary"
            variant="text"
            siblingCount={lg ? undefined : md ? 0 : 0}
            boundaryCount={lg ? undefined : md ? 1 : 0}
            sx={sxPagination && (sxPagination as object)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={"auto"}
          lg={"auto"}
          sx={style.rowPerPageGrid}
        >
          <Typography variant="body1" sx={style.showText}>
            Show :
          </Typography>
          <SelectRowPerPage
            value={rowsPerPage}
            variant="outlined"
            color="secondary"
            onChange={onRowPerPageChange}
            MenuProps={{
              PaperProps: {
                sx: style.menu,
              },
            }}
            sx={sxRowPerPage && (sxRowPerPage as object)}
          >
            {rowsPerPageData.map((data, index) => (
              <MenuItem
                key={index}
                value={data}
                sx={{
                  ...style.menuItem,
                  color: rowsPerPage === data ? "secondary.main" : "inherit",
                }}
              >
                {data} item
              </MenuItem>
            ))}
          </SelectRowPerPage>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PaginationCustom;
