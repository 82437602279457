import React from "react";
import { styled } from "@mui/system";
import {
  SxProps,
  Theme,
  DialogProps,
  Dialog,
  Box,
  Button,
  Paper,
  DialogTitle,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

interface IDialogCustomProps extends DialogProps {
  title?: string;
  onClose: () => void;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  sx?: SxProps<Theme>;
}

const DialogCustomStyle = styled(Dialog)<DialogProps>(({ theme }) => ({
  "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const style = {
  absoluteBox: {
    position: "absolute",
    top: "5px",
    right: "6px",
    m: 0,
    p: 0,
  },
  closeButton: {
    position: "relative",
    height: "30px",
    maxWidth: "30px",
    minWidth: "30px",
    p: 0,
    bgcolor: "common.black",
    border: "none",
    "&:hover": {
      bgcolor: "common.black",
      border: "none",
    },
  },
  closeIcon: { fontSize: "20px" },
  paperBox: {
    backgroundColor: "transparent",
    margin: 3,
  },
  paper: {
    backgroundColor: "common.white",
    padding: 0,
    margin: 0,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "42px",
    m: 0,
    p: 0,
    bgcolor: "primary.main",
    width: "100%",
  },
};

function DialogScrollingCustom({
  title,
  onClose,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  sx,
  children,
  ...props
}: IDialogCustomProps) {
  return (
    <DialogCustomStyle
      onClose={(event, reason) => {
        if (!!disableEscapeKeyDown && !disableBackdropClick) {
          if (reason !== "backdropClick") {
            onClose();
          }
        } else if (!disableEscapeKeyDown && !!disableBackdropClick) {
          if (reason !== "escapeKeyDown") {
            onClose();
          }
        } else if (!disableEscapeKeyDown && !disableBackdropClick) {
          onClose();
        }
      }}
      scroll="body"
      {...props}
      sx={sx && (sx as object)}
    >
      <Box sx={style.absoluteBox}>
        <Button variant="contained" onClick={onClose} sx={style.closeButton}>
          <CloseIcon sx={style.closeIcon} />
        </Button>
      </Box>
      <Box sx={style.paperBox}>
        <Paper elevation={3} sx={style.paper}>
          <DialogTitle sx={style.dialogTitle}>
            <Typography variant="body1" fontWeight="bold" color="common.white">
              {title}
            </Typography>
          </DialogTitle>
          {children}
        </Paper>
      </Box>
    </DialogCustomStyle>
  );
}

export default DialogScrollingCustom;
