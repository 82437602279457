import React from "react";
import { styled } from "@mui/system";
import { Button, ButtonProps } from "@mui/material";

interface IEditProfileButtonProps {
  onClick?: () => void;
  startIcon?: React.ReactNode;
  children?: React.ReactNode;
}

const ButtonProfile = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  borderRadius: "50px",
  width: "290px",
}));

function EditProfileButton(props: IEditProfileButtonProps) {
  const { startIcon, children, onClick } = props;

  return (
    <ButtonProfile
      variant="outlined"
      color="primary"
      startIcon={startIcon}
      onClick={onClick}
    >
      {children}
    </ButtonProfile>
  );
}

export default EditProfileButton;
