import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import TextFieldCustom from "../text-field";
import { numberWithCommas } from "../../../utils/helpers/numberWithCommas";

interface IRangeInput {
  startKeyName: string;
  endKeyName: string;
  Controller: any;
  control: any;
  errors: any;
  watch: any;
  setError?: any;
  clearErrors?: any;
  [any: string]: any;
}

function RangeInput(props: IRangeInput) {
  const {
    startKeyName,
    endKeyName,
    Controller,
    control,
    errors,
    watch,
    setError,
    clearErrors,
    unit,
  } = props;

  const [firstError, setFirstError] = useState<any>("");
  const [secondError, setSecondError] = useState<any>("");

  const firstKey = startKeyName.split(".");
  const secondKey = endKeyName.split(".");

  const firstValue = +watch(startKeyName)?.replaceAll(",", "");
  const secondValue = +watch(endKeyName)?.replaceAll(",", "");

  const onCheckRange = () => {
    if (firstValue > secondValue) {
      setError(startKeyName, {
        type: "custom",
        message: `please input data in range`,
      });

      setError(endKeyName, {
        type: "custom",
        message: `please input data in range`,
      });
    } else {
      clearErrors(startKeyName);
      clearErrors(endKeyName);
      setFirstError("");
      setSecondError("");
    }
  };

  const getErrorPath = () => {
    if (errors) {
      let firstErr = errors;
      let secondErr = errors;

      for (let index = 0; index < firstKey.length; index++) {
        firstErr = firstErr[firstKey[index]];
      }

      for (let index = 0; index < secondKey.length; index++) {
        secondErr = secondErr[secondKey[index]];
      }

      setFirstError(firstErr);
      setSecondError(secondErr);
    }
  };

  return (
    <Grid container>
      <Grid item xs={5} md={5.7}>
        <Controller
          name={startKeyName}
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value, name, ref } }: any) => (
            <TextFieldCustom
              variant="outlined"
              placeholder=""
              fullWidth
              error={firstError ? true : false}
              helperText={firstError ? firstError?.message : ""}
              value={value ? `${value} ${unit ? unit : ""}` : ""}
              onChange={(ev) => {
                const newNum = numberWithCommas(ev.target.value);

                onChange(newNum);
              }}
              name={name}
              ref={ref}
              onBlur={() => {
                onCheckRange();
                getErrorPath();
              }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "50px",
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={2}
        md={0.6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={getErrorPath}
      >
        -
      </Grid>

      <Grid item xs={5} md={5.7}>
        <Controller
          name={endKeyName}
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value, name, ref } }: any) => (
            <TextFieldCustom
              variant="outlined"
              placeholder=""
              fullWidth
              error={secondError ? true : false}
              helperText={secondError ? secondError?.message : ""}
              value={value ? `${value} ${unit ? unit : ""}` : ""}
              onChange={(ev) => {
                const newNum = numberWithCommas(ev.target.value);
                onChange(newNum);
              }}
              name={name}
              ref={ref}
              onBlur={() => {
                onCheckRange();
                getErrorPath();
              }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "50px",
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default RangeInput;
