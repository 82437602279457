// import React, { useEffect, useState } from "react";
import React from "react";
import { AudienceTab } from "../InfluencerSocial.styles";
import { useTiktokTabStyle as classes } from "./TiktokTab.styles";
import "./../InfluencerSocial.styles.css";

import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ArcElement, Tooltip, Legend } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

// import { IAudienceDemographicProps } from "../InfluencerSocial.types";
// import { ChartData, ChartOptions } from "chart.js";
// import { Doughnut } from "react-chartjs-2";
// import { calculateNumberSocialUnit } from "../../../utils/helpers/calculateNumberSocialUnit.helpers";
// import { capitalizeFirstLetter } from "../../../utils/helpers/capitalizeFirstLetter.helpers";
// import { instagramChartColor } from "./../../../utils/constants/chartColors.constants";

import { Box, Grid, Stack, Tabs, Typography } from "@mui/material";

Chart.register(ChartDataLabels, ArcElement, Tooltip, Legend, annotationPlugin);

const tabs = [
  { key: "gender", text: "Gender" },
  { key: "age", text: "Age" },
  { key: "location", text: "Location" },
];

// function AudienceDemographic(props: IAudienceDemographicProps) {
function AudienceDemographic() {
  // const { audienceData } = props;

  // const [tabSelected, setTabSelected] = useState<Number>(0);
  // const [audience, setAudience] = useState<any[]>([]);

  // const createAudienceData = (data: any): any[] => {
  //   let arr = [];
  //   let i = 0;
  //   for (const [key, value] of Object.entries(data)) {
  //     arr.push({
  //       label: key,
  //       value: value,
  //       color: instagramChartColor[i],
  //     });
  //     i++;
  //   }
  //   return arr;
  // };

  // useEffect(() => {
  //   if (audienceData) {
  //     setAudience(() => createAudienceData(audienceData.gender));
  //   }
  // }, [audienceData]);

  // const summaryChartValue = () => {
  //   const sum = audience.reduce((a, b) => a + b.value, 0);
  //   return calculateNumberSocialUnit(sum, 0);
  // };

  // const chartData: ChartData<"doughnut"> = React.useMemo(
  //   () => ({
  //     labels: audience.map(({ label }) => capitalizeFirstLetter(label)),
  //     datasets: [
  //       {
  //         data: audience.map(({ value }) => value),
  //         backgroundColor: audience.map(({ color }) => color),
  //         borderWidth: 0,
  //       },
  //     ],
  //   }),
  //   [audience]
  // );

  // const doughnutChartOptions: ChartOptions = React.useMemo(
  //   () => ({
  //     plugins: {
  //       legend: { display: false },
  //       tooltip: {
  //         displayColors: false,
  //         bodyFont: { family: "Kanit", size: 12 },
  //         padding: 10,
  //       },
  //       datalabels: { display: false },
  //       annotation: {
  //         annotations: [
  //           {
  //             type: "label",
  //             xValue: 0,
  //             yValue: 0,
  //             content: summaryChartValue(),
  //             font: {
  //               size: 20,
  //               family: "Kanit",
  //               weight: "bold",
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   }),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [audience]
  // );

  const handleTabActive = (index: unknown) => {
    return {
      id: `audience-tab-${index}`,
      "aria-controls": `audience-tabpanel-${index}`,
    };
  };

  // const handleTabChange = (event: React.ChangeEvent<{}>, newValue: Number) => {
  //   if (newValue === 0) {
  //     setAudience(() => createAudienceData(audienceData.gender));
  //   } else if (newValue === 1) {
  //     setAudience(() => createAudienceData(audienceData.age));
  //   } else if (newValue === 2) {
  //     setAudience(() => createAudienceData(audienceData.location));
  //   }
  //   setTabSelected(newValue);
  // };

  return (
    <Box sx={classes.audienceBox}>
      <Tabs
        variant="fullWidth"
        // value={tabSelected}
        // onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        sx={classes.audienceTabs}
      >
        {tabs.map((tab, index) => (
          <AudienceTab
            key={index}
            label={
              <Typography variant="caption" fontWeight="inherit">
                {tab.text}
              </Typography>
            }
            {...handleTabActive(tab.key)}
          />
        ))}
      </Tabs>
      <Box>
        <Grid container>
          <Grid item xs={7} px={2} py={4}>
            {/* <Doughnut data={chartData} options={doughnutChartOptions} /> */}
            Waiting data....
          </Grid>
          <Grid item xs={5}>
            <Stack justifyContent="center" height="100%" pl={7}>
              {/* {audience.map((item, index) => (
                <Box key={index} display="flex" alignItems="center" pb={2}>
                  <Box
                    sx={{
                      minHeight: "15px",
                      maxHeight: "15px",
                      minWidth: "15px",
                      maxWidth: "15px",
                      bgcolor: item.color,
                      borderRadius: "2px",
                      mr: 2,
                    }}
                  ></Box>
                  <Typography variant="caption">
                    {capitalizeFirstLetter(item.label)}
                  </Typography>
                </Box>
              ))} */}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default React.memo(AudienceDemographic);
