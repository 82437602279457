import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Link, Typography, Tabs } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  getEmployerDetailAsync,
  selectEmployerDetail,
} from "./EmployerDetailSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import HeaderDetail from "../../../components/employer/detail/HeaderDetail";
import { EmployerDetailTab } from "./EmployerDetail.style";
import ProfileTab from "../../../components/employer/detail/profile-tab/ProfileTab";
import { ITabPanelProps } from "./employerDetailType";
import { useEmployersStyle as classes } from "../employers.style";

export default function EmployerDetail() {
  const { id } = useParams<{ id: string }>();
  const employerDetail = useAppSelector(selectEmployerDetail);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(getEmployerDetailAsync(id));
  }, [dispatch, id]);

  const [tabSelected, setTabSelected] = useState<Number>(0);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: Number) => {
    setTabSelected(newValue);
  };

  const handleTabActive = (index: unknown) => {
    console.log("index", index);
    return {
      id: `social-tab-${index}`,
      "aria-controls": `social-tabpanel-${index}`,
    };
  };

  const TabPanel = (props: ITabPanelProps) => {
    const { children, value, index } = props;
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const tabs = [
    { key: "profile", text: "Profile" },
    { key: "campaign", text: "Campaign" },
    { key: "subscription", text: "Subscription" },
  ];

  return (
    <Box sx={classes.containnerMain}>
      <Box>
        <Link
          onClick={() => {
            goBack();
          }}
          sx={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          <KeyboardArrowLeftIcon />
          <Typography variant="subtitle2">Back</Typography>
        </Link>
      </Box>
      <Box>
        <Typography variant="h5">Employer Information</Typography>
        <Typography variant="body1">รายละเอียดผู้จ้างงาน</Typography>
      </Box>
      <Box>
        <HeaderDetail employerDetail={employerDetail} />
      </Box>

      <Box sx={{ marginTop: "32px" }}>
        <Tabs
          value={tabSelected}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabs.map((tab, index) => (
            <EmployerDetailTab
              key={index}
              label={<Typography>{tab.text}</Typography>}
              {...handleTabActive(tab.key)}
            />
          ))}
        </Tabs>
        <ProfileTab
          TabPanel={TabPanel}
          tabSelected={tabSelected}
          employerDetail={employerDetail}
        />
      </Box>
    </Box>
  );
}
