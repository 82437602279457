import React from "react";
// Component
import DialogAuthentication from "../global/dialog-authentication";
import TextFieldCustom from "../global/text-field";
// Types
import { IResetPasswordDialogProps } from "./AuthenDialog.types";
// Styles
import { useSignInDialogStyle as classes } from "./authentication.styles";
// Material
import {
  DialogContent,
  Stack,
  InputAdornment,
  Icon,
  Button,
  Typography,
  IconButton,
} from "@mui/material";

function ResetPasswordDialog(props: IResetPasswordDialogProps) {
  const { isOpened, setIsOpenResetPassword } = props;

  const handleClose = () => {
    setIsOpenResetPassword(false);
  };
  return (
    <DialogAuthentication
      title="Reset Password?"
      open={isOpened}
      onClose={handleClose}
      sx={classes.authenDialog}
    >
      <DialogContent sx={classes.dialogContent}>
        <Stack
          component="form"
          //   onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          gap={5}
          py={1}
          px={5}
        >
          <TextFieldCustom
            // value={getValues("email")}
            // onChange={(e) => handleChange(e, "email")}
            variant="outlined"
            placeholder="Recover Code"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="ico-hu-code"
                    sx={{ ...classes.textFieldIcon, paddingRight: "2px" }}
                  />
                </InputAdornment>
              ),
            }}
            // error={!!errors.email}
            // helperText={errors.email?.message}
          />
          <TextFieldCustom
            //   value={getValues("password")}
            //   onChange={(e) => handleChange(e, "password")}
            //   type={!!showPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Password"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    //   onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon
                      className="ico-hu-visability-off-solid"
                      sx={classes.textFieldIcon}
                    />
                    {/* {!!showPassword ? (
                    <Icon
                      className="ico-hu-visability-off-solid"
                      sx={classes.textFieldIcon}
                    />
                  ) : (
                    <Icon
                      className="ico-hu-visability-on-solid"
                      sx={classes.textFieldIcon}
                    />
                  )} */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            //   error={!!errors.password}
            //   helperText={errors.password?.message}
          />
          <TextFieldCustom
            //   value={getValues("password")}
            //   onChange={(e) => handleChange(e, "password")}
            //   type={!!showPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Password"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    //   onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon
                      className="ico-hu-visability-on-solid"
                      sx={classes.textFieldIcon}
                    />
                    {/* {!!showPassword ? (
                    <Icon
                      className="ico-hu-visability-off-solid"
                      sx={classes.textFieldIcon}
                    />
                  ) : (
                    <Icon
                      className="ico-hu-visability-on-solid"
                      sx={classes.textFieldIcon}
                    />
                  )} */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            //   error={!!errors.password}
            //   helperText={errors.password?.message}
          />
        </Stack>
      </DialogContent>
      <DialogContent>
        <Stack
          component="form"
          //   onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          gap={5}
          py={1}
          px={5}
        >
          <Button type="submit" fullWidth>
            <Typography variant="body1" fontWeight="bold">
              Change Password
            </Typography>
          </Button>
          <Typography
            color="grey.800"
            variant="body1"
            align="center"
            sx={classes.forgetPasswordLink}
          >
            Request Recover Code
          </Typography>
        </Stack>
      </DialogContent>
    </DialogAuthentication>
  );
}

export default ResetPasswordDialog;
