import React, { useEffect, useState } from "react";
import { hPurple } from "../../../theme";
import { styled } from "@mui/system";
import {
  Box,
  MenuItem,
  Select,
  SelectProps,
  Theme,
  SxProps,
  FormControl,
  FormHelperText,
  Avatar,
} from "@mui/material";

interface IBasicSelectProps extends SelectProps {
  id: string;
  value: any;
  onChange: (event: any) => void;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  data: any[];
  valueKey: string;
  labelKey: string;
  iconKey?: string;
  iconType?: "icon" | "imageUrl";
  sx?: SxProps<Theme>;
}

const SelectBasic = styled(Select)<SelectProps>(({ theme, ...props }) => {
  const initialStyle = {
    transition: "border-radius 0.1s",
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      color: "#8C499C",
      "& > svg.MuiSvgIcon-root": {
        fontSize: "20px",
        marginRight: "4px",
        paddingBottom: "2px",
      },
      "& > .MuiAvatar-root > svg.MuiSvgIcon-root": {
        fontSize: "20px",
      },
    },
  };
  if (props.disabled) {
    return {
      ...initialStyle,
      backgroundColor: theme.palette.grey[100],
      "& .MuiSelect-select": {
        ...initialStyle["& .MuiSelect-select"],
        WebkitTextFillColor:
          props.value && props.value !== ""
            ? theme.palette.common.black
            : theme.palette.grey[300],
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[100]} !important`,
      },
    };
  } else if (props.error) {
    return {
      ...initialStyle,
    };
  } else {
    return {
      ...initialStyle,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
        },
      },
    };
  }
});

const style = {
  menu: {
    color: "common.black",
    border: "2px solid",
    borderColor: "primary.main",
  },
  menuItem: {
    alignItems: "center",
    "& > svg.MuiSvgIcon-root": {
      fontSize: "20px",
      mr: 1,
    },
  },
  imageIcon: {
    width: 20,
    height: 20,
    marginRight: "10px",
    fontSize: "14px",
  },
  textLabel: {
    marginLeft: "10px",
  },
};

const Placeholder = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box component="div" color="grey.300">
      {children}
    </Box>
  );
};

function BasicSelectCustom({
  id,
  value,
  onChange,
  placeholder,
  error,
  helperText,
  fullWidth = false,
  data = [],
  valueKey,
  labelKey,
  iconKey,
  iconType = "icon",
  sx,
  ...props
}: IBasicSelectProps) {
  const [width, setWidth] = useState<number | string>(0);

  const target = document.getElementById(id) as HTMLElement;

  useEffect(() => {
    setWidth(target ? (target as HTMLElement).offsetWidth : "auto");
  }, [target]);

  const onSelectOpen = (method: string) => {
    if (method === "open") {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px 20px 0px 0px"
      );
    } else {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px"
      );
    }
  };

  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      // sx={{ minWidth: 120 }}
    >
      <SelectBasic
        id={id}
        value={value !== null ? value : ""}
        onChange={onChange}
        size="small"
        color="primary"
        variant="outlined"
        // autoWidth
        displayEmpty
        onOpen={(e) => onSelectOpen("open")}
        onClose={(e) => onSelectOpen("close")}
        sx={sx && (sx as object)}
        {...props}
        renderValue={
          value ? undefined : () => <Placeholder>{placeholder}</Placeholder>
        }
        MenuProps={{
          PaperProps: {
            sx: {
              ...style.menu,
              width: typeof width === "number" ? width - 4 : width,
            },
          },
        }}
      >
        {data.length > 0 ? (
          data.map((d, index) => {
            return (
              <MenuItem
                key={index}
                value={d[valueKey]}
                sx={{
                  ...style.menuItem,
                  color: value === d[valueKey] ? "primary.main" : "inherit",
                  "&.Mui-selected": {
                    bgcolor: value === d[valueKey] ? hPurple[100] : "inherit",
                  },
                }}
              >
                {iconKey ? (
                  iconType === "icon" ? (
                    d[iconKey]
                  ) : (
                    <Avatar
                      variant="rounded"
                      alt={d[labelKey]}
                      src={d[iconKey]}
                      sx={style.imageIcon}
                    />
                  )
                ) : undefined}
                <span style={style.textLabel}>{d[labelKey]}</span>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem
            disabled
            sx={{
              ...style.menuItem,
            }}
          >
            <span>No data</span>
          </MenuItem>
        )}
      </SelectBasic>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default BasicSelectCustom;
