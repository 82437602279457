import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Grid, InputAdornment, Typography } from "@mui/material";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { TextField } from "@mui/material";
import TextFieldCustom from "../text-field";
import CalendarIcon from "@mui/icons-material/CalendarMonth";

interface IDateRangeInput {
  startKeyName: string;
  endKeyName: string;
  register: any;
  setValue: any;
  watch: any;
  errors: any;
  clearErrors: any;
  placeholderFirst?: string;
  placeholderSecond?: string;
}

function DateRangeInput(props: IDateRangeInput) {
  const {
    startKeyName,
    endKeyName,
    setValue,
    register,
    errors,
    clearErrors,
    placeholderFirst,
    placeholderSecond,
  } = props;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [startError, setStartError] = useState<any>();
  const [endError, setEndError] = useState<any>();

  const firstRef = useRef<any>(null);
  const secondRef = useRef<any>(null);

  useEffect(() => {
    register(startKeyName, { required: true });
    register(endKeyName, { required: true });
  }, []);

  useEffect(() => {
    const skeys = startKeyName.split(".");
    const ekeys = endKeyName.split(".");

    if (
      errors &&
      errors[skeys[0]] &&
      errors[skeys[0]][skeys[1]] &&
      errors[skeys[0]][skeys[1]][skeys[2]]
    ) {
      const sErr = errors[skeys[0]][skeys[1]][skeys[2]];

      setStartError(sErr);
    }

    if (
      errors &&
      errors[ekeys[0]] &&
      errors[ekeys[0]][ekeys[1]] &&
      errors[ekeys[0]][ekeys[1]][ekeys[2]]
    ) {
      const eErr = errors[ekeys[0]][ekeys[1]][ekeys[2]];
      setEndError(eErr);
    }
  }, [errors]);

  return (
    <div>
      <Grid container>
        <Grid item xs={5.7}>
          <DatePicker
            ref={firstRef}
            name={startKeyName}
            dateFormat="dd/MM/yyyy"
            calendarClassName="datepicker-calendar-container "
            selected={startDate}
            onChange={(date: any) => {
              setStartDate(date);
              setValue(startKeyName, date);
              clearErrors(startKeyName);
              setStartError("");
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            monthsShown={2}
            placeholderText={placeholderFirst}
            customInput={
              <TextFieldCustom
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon sx={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "50px",
                  },
                }}
              />
            }
          />
          {startError ? (
            <Typography variant="caption" color="error.main" fontWeight={400}>
              {startError?.message}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          xs={0.6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          -
        </Grid>
        <Grid item xs={5.7}>
          <DatePicker
            ref={secondRef}
            name={endKeyName}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            calendarClassName="datepicker-calendar-container"
            onChange={(date: any) => {
              setEndDate(date);
              setValue(endKeyName, date);
              clearErrors(endKeyName);
              setEndError("");
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            monthsShown={2}
            placeholderText={placeholderSecond}
            customInput={
              <TextFieldCustom
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon sx={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "50px",
                  },
                }}
              />
            }
          />
          {endError ? (
            <Typography variant="caption" color="error.main" fontWeight={400}>
              {endError?.message}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DateRangeInput;
