import {
  CampaignOutlined,
  ArticleOutlined,
  WifiOutlined,
  CheckOutlined,
} from "@mui/icons-material";

export const timelineData = [
  {
    label: "ประกาศผลผู้เข้าร่วมแคมเปญ",
    subLabel: "03/10/2022",
    icon: <CampaignOutlined />,
  },
  {
    label: "เริ่มส่งงาน",
    subLabel: "15/10/2022",
    icon: <ArticleOutlined />,
  },
  {
    label: "โพสต์และแนบลิงค์ส่งงาน",
    subLabel: "20/10/2022 - 30/10/2022",
    icon: <WifiOutlined />,
  },
  {
    label: "สิ้นสุดแคมเปญ",
    subLabel: "30/11/2022",
    icon: <CheckOutlined />,
  },
];

export const termsAndConditionsDetailData = [
  {
    id: 1,
    label:
      "1. โปรดทบทวน นโยบายความเป็นส่วนตัวของบริษัทเพื่อท่านจะได้เข้าแนวใจปฏิบัติ เกี่ยวกับความเป็นส่วนตัว",
  },
  {
    id: 2,
    label:
      "2. บริษัทไม่รับผิดชอบต่อเนื้อหาของเพจใดๆ ที่อยู่นอกเว็บไซต์หรือเว็บไซต์อื่นใดที่ถูกโยง ไปถึง หรือจากเว็บไซต์(ของบริษัท) การเชื่อมโยงที่ปรากฎบนเว็บไซต์เป็นไปเพื่อความสะดวกเท่านั้น",
  },
  {
    id: 3,
    label:
      "3. ในการเข้าใช้เว็บไซต์ ให้ท่านปฏิบัติตามข้อกำหนดและเงื่อนไขรวมทั้งคำเตือนหรือคำสั่ง พิเศษสำหรับการเข้าชมหรือการใช้ที่ขึ้นไว้ที่เว็บไซต์",
  },
  {
    id: 4,
    label:
      "4. เมื่อท่านส่งต่อ อัพโหลด โพสต์ ส่งอีเมล์ หรือทำให้มีซึ่งข้อมูล ข้อความ ซอฟต์แวร์ ดนตรี เสียง ภาพถ่าย กราฟฟิก ภาพ วีดีโอ ข้อความ หรืองานอื่นๆ (“เนื้อหาของผู้ใช้”) บนเว็ปไซต์",
  },
];

export const selectInfluAndAnnounce = [
  {
    title:
      "กรณีที่ “อินฟลูเอนเซอร์” สมัครเข้าร่วมแคมเปญ “น้อยกว่า” ที่กำหนด หรือน้อยกว่า xx คน",
    text: [
      "ผู้จ้างงาน สามารถ “ยกเลิก” แคมเปญได้โดยเงินมัดจำจะคืนกลับไปยัง Wallet ของผู้จ้างงาน",
      "ผู้จ้างงาน สามารถ “เลือก” อินฟลูเอนเซอร์เท่าที่มีเพื่อเริ่มแคมเปญต่อไปได้ โดย Budget จะคำนวณตามจำนวนอินฟลูเอนเซอร์ที่เข้าร่วมแคมเปญ",
    ],
  },
  {
    title: "การรีวิว",
    text: [
      "กรณีที่ผู้จ้างงานต้อง “ส่งสินค้า” ให้อินฟลูเอนเซอร์โปรดส่งสินค้าภายใน 3 วันหลังจากที่ประกาศผล” อินฟลูเอนเซอร์เท่าที่มีเพื่อเริ่มแคมเปญต่อไปได้ โดย Budget จะคำนวณตามจำนวนอินฟลูเอนเซอร์ที่เข้าร่วมแคมเปญ",
      "กรณีที่รีวิว “สถานที่”โปรดรอการติดต่อจากอินฟลูเอนเซอร์เพื่อทำการนัดหมายกับผู้จ้างงาน",
    ],
  },
];

export const submitDraftData = [
  {
    title: "การตรวจงาน",
    text: [
      "ผู้จ้างงานสามารถให้อินฟลูเอนเซอร์แก้ไขงานได้ “2 ครั้ง” เท่านั้น",
      "การอนุมัติ Draft ครั้งสุดท้าย คือก่อนวันสุดท้ายที่ต้องโพสต์แคมเปญ “1 วัน”",
    ],
  },
  {
    title: "กรณีที่อินฟลูเอนเซอร์ไม่ส่งภายในเวลาที่กำหนด",
    text: [
      "อินฟลูเอนเซอรคนนั้นจะลงโทษและทำการยกเลิกงานกับอินฟลูเอนเซอร์คนนั้นโดยทันที",
      "หากผู้จ้างงานได้จัดส่งสินค้าหรือให้บริการด้านสถานที่กับอินฟลูเอนเซอร์แล้วอินฟลูเอนเซอร์จะต้องชดเชยค่าสินค้า และบริการทั้งหมด",
    ],
  },
];
