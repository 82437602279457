import React from "react";
import { useHistory } from "react-router-dom";
import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
// Components
import OurService from "./OurService";
import { CarouselContent } from "../global/carousel-content";
// Types
import { IBreakpoint, IDevice } from "../../views/global/global.types";
// Utils
import { setValueByBreakpoint } from "../../utils/helpers/setValueByBreakpoint.helpers";
// Styles
import "./HomeMarketer.styles.css";
import { ButtonPricingAndPlan } from "./HomeMarketer.styles";
import { useHomeMarketerStyle as classes } from "./HomeMarketer.styles";
import { ButtonSignUpColorful } from "./../home-main/HomeMain.styles";
// Material
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
// Images
import InfographicDesk from "../../assets/images/home/3_Marketer page/2 - Infographic/D-marketer infographic.svg";
import InfographicTablet from "../../assets/images/home/3_Marketer page/2 - Infographic/T-marketer infographic.svg";
import InfographicMobile from "../../assets/images/home/3_Marketer page/2 - Infographic/P-marketer infographic.svg";

// Mock Logo Icon
import Absorba from "./../../assets/images/home/1_Homepage/5 - Trusted by/Absorba.png";
import Bottoms from "./../../assets/images/home/1_Homepage/5 - Trusted by/Bottoms up.png";
import Britania from "./../../assets/images/home/1_Homepage/5 - Trusted by/Britania.png";
import CP from "./../../assets/images/home/1_Homepage/5 - Trusted by/CP.png";
import Fitpub from "./../../assets/images/home/1_Homepage/5 - Trusted by/Fitpub.png";
import Goodlooking from "./../../assets/images/home/1_Homepage/5 - Trusted by/Goodlooking.png";
import Guardforce from "./../../assets/images/home/1_Homepage/5 - Trusted by/Guardforce.png";
import IKEA from "./../../assets/images/home/1_Homepage/5 - Trusted by/IKEA.png";
import Ikigai from "./../../assets/images/home/1_Homepage/5 - Trusted by/Ikigai.png";
import Masterplanmedia from "./../../assets/images/home/1_Homepage/5 - Trusted by/Masterplanmedia.png";
import MeatZero from "./../../assets/images/home/1_Homepage/5 - Trusted by/MeatZero.png";
import Signature from "./../../assets/images/home/1_Homepage/5 - Trusted by/Signature.png";
import WeCleanVR from "./../../assets/images/home/1_Homepage/5 - Trusted by/We clean VR.png";
import Bantapea from "./../../assets/images/home/1_Homepage/5 - Trusted by/บ้านท่าแพ.png";
import Mhotean from "./../../assets/images/home/1_Homepage/5 - Trusted by/หมอเถียร.png";

// Mock Our Performance
import MobileImg from "../../assets/images/home/3_Marketer page/5 - Our performance/Jole_s gem - 343x600.png";
import IgImg from "./../../assets/images/home/icon/IG.png";
import ReachImg from "../../assets/images/home/3_Marketer page/3 - Our service/Reach.png";
import EngagementImg from "../../assets/images/home/3_Marketer page/3 - Our service/Engagement.png";

function HomeMarketer({
  device,
  breakpoint,
  setIsOpenCreateAccount,
}: {
  device: IDevice;
  breakpoint: IBreakpoint;
  setIsOpenCreateAccount: (isOpen: boolean) => void;
}) {
  const history = useHistory();
  let nf = new Intl.NumberFormat();

  const logoList = [
    Absorba,
    Bottoms,
    Britania,
    CP,
    Fitpub,
    Goodlooking,
    Guardforce,
    IKEA,
    Ikigai,
    Masterplanmedia,
    MeatZero,
    Signature,
    WeCleanVR,
    Bantapea,
    Mhotean,
  ];
  const engagement = [
    {
      type: "campaigns",
      point: 100,
    },
    {
      type: "reach",
      point: 1200000,
    },
    {
      type: "impression",
      point: 946,
    },
    {
      type: "engagement",
      point: 1200000,
    },
  ];
  const ourPerformanceList = [
    {
      header: "Our Performance",
      subHeader: "Jolie’s Gems",
      content: `Jolie's gems แบรนด์เครื่องประดับเพชรแท้ พลอยแท้
      ต้องการโปรโมทแบรนด์ให้เป็นที่รู้จักในกลุ่มเป้าหมายลูกค้าที่มีกําลังซื้อ และชื่นชอบเครื่องประดับ
      โดยโปรโมทสินค้าภายในร้านตั้งแต่ต่างหู แหวน 
      สร้อยคอ และอื่นๆอีกมากมาย โดยนําเสนอผ่านช่องทาง Instagram 
      ให้เข้าถึงกับลูกค้า และกลุ่มเป้าหมายมากขึ้น
      พร้อมโปรโมชั่น ราคาพิเศษ และบริการนที่น่าประทับใจ`,
      image: MobileImg,
      state: [
        {
          image: IgImg,
          value: 8,
          pronoun: "Influencers",
        },
        {
          image: ReachImg,
          value: 16404,
          pronoun: "Reach",
        },
        {
          image: EngagementImg,
          value: 2805,
          pronoun: "Engagement",
        },
      ],
    },
  ];

  return (
    <Stack>
      <Box id="back-to-top-anchor" sx={classes.containerBox}>
        <Box sx={classes.coverBox}>
          <Box>
            <Box
              pt={[null, 30, 30, 18]}
              pb={[4, 2, 2, 4]}
              sx={classes.yellowColor}
            >
              <Typography
                color="inherit"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "h4",
                  null,
                  null,
                  "h2"
                )}
                fontWeight={setValueByBreakpoint(breakpoint, "bold", "normal")}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                Marketer
              </Typography>
            </Box>
            <Box pb={[6, 4, 5, 10]}>
              <Typography
                color="common.white"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "body1",
                  "body1",
                  "body1",
                  "h5"
                )}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                ไม่ว่าธุรกิจคุณจะเล็กหรือใหญ่ {device === "mobile" && <br />}
                Start up หรือ Enterprise
                <br />
                คุณสามารถดีไซน์แคมเปญด้วย{device === "mobile" && <br />}
                แพลตฟอร์ม <span style={classes.yellowColor}>HashU</span>{" "}
                {device !== "mobile" && <br />}
                ที่ใช้งานง่าย {device === "mobile" && <br />}
                จนประสบความสำเร็จด้านการตลาดออนไลน์
              </Typography>
            </Box>
            <Box pb={[7, 9, 10, 12]} sx={classes.yellowColor}>
              <Typography
                color="inherit"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "h6",
                  null,
                  null,
                  "h4"
                )}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                “เพื่อธุรกิจของคุณ
                <br />
                โดยเฉพาะด้วยตัวคุณเอง”
              </Typography>
            </Box>
            <Box textAlign={device === "mobile" ? "center" : undefined} pb={18}>
              <ButtonSignUpColorful
                onClick={() => setIsOpenCreateAccount(true)}
              >
                <Typography variant="body1">สมัครเป็น Marketer</Typography>
              </ButtonSignUpColorful>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box bgcolor="common.white" py={[3, 5, 6, 7]} px={[4, 8, 10, 20]}>
        <Carousel
          plugins={[
            "infinite",
            "centered",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: setValueByBreakpoint(breakpoint, 5, 7, 7, 9),
              },
            },
            {
              resolve: autoplayPlugin,
              options: {
                interval: 3000,
              },
            },
          ]}
          animationSpeed={1000}
        >
          {logoList.map((logo, index) => (
            <Box
              key={"carousel" + index}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: setValueByBreakpoint(
                    breakpoint,
                    "52px",
                    "78px",
                    "80px",
                    "100px"
                  ),
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Carousel>
      </Box>

      <Box sx={classes.infographicBox}>
        <Stack
          alignItems="center"
          height="100%"
          py={[3, 8, 10, 20]}
          px={[4, 0]}
        >
          <Typography
            color="common.black"
            variant={setValueByBreakpoint(breakpoint, "h5", null, null, "h3")}
            fontWeight={setValueByBreakpoint(
              breakpoint,
              "bold",
              null,
              null,
              "normal"
            )}
            align="center"
            mb={[5, 4, 4, 5]}
          >
            3 ขั้นตอน <span style={classes.navyBlueColor}>สร้างแคมเปญ</span>{" "}
            {device === "mobile" && <br />}กับ HashU ได้ง่ายๆ
          </Typography>
          <Typography
            color="common.black"
            variant={setValueByBreakpoint(
              breakpoint,
              "body1",
              null,
              null,
              "h5"
            )}
            align="center"
            mb={[10, 10, 10, 13]}
          >
            3 ขั้นตอนง่ายๆ เริ่มทำการตลาดออนไลน์{device === "mobile" && <br />}
            กับเรา ด้วยแคมเปญของคุณ
            <br />
            <span style={classes.navyBlueColor}>‘ปัง’</span> และ{" "}
            <span style={classes.navyBlueColor}>‘ดัง’</span>{" "}
            ด้วยพลังแห่งการบอกต่อ
          </Typography>
          <img
            src={
              device === "desktop"
                ? InfographicDesk
                : device === "tablet"
                ? InfographicTablet
                : InfographicMobile
            }
            alt="step infographic"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "90%",
                "90%",
                "90%",
                "80%"
              ),
              height: "auto",
            }}
          />
        </Stack>
      </Box>

      <Box bgcolor="common.white">
        <OurService
          device={device}
          breakpoint={breakpoint}
          engagement={engagement}
        />
      </Box>

      <Box sx={classes.engagementBox}>
        <Stack sx={classes.engagementStack}>
          <Typography
            color="common.white"
            variant={setValueByBreakpoint(breakpoint, "h6", "h5", null, "h4")}
            fontWeight={setValueByBreakpoint(
              breakpoint,
              "normal",
              "normal",
              "bold"
            )}
            align={setValueByBreakpoint(breakpoint, "center", "right")}
            pb={[5, 4, 4, 8]}
          >
            HashU {device !== "mobile" && <br />}
            จัดการแคมเปญของคุณ{device === "mobile" && <br />}ในที่เดียว
          </Typography>
          <Typography
            color="common.white"
            variant={setValueByBreakpoint(
              breakpoint,
              "body1",
              null,
              null,
              "h6"
            )}
            align={setValueByBreakpoint(breakpoint, "center", "right")}
            pb={[6, 8, 8, 14]}
          >
            เลือกนักรีวิวที่ใช่ ผ่านระบบคัดกรอง
            <br />
            สร้างคอนเทนต์ และผลงานที่น่าเชื่อถือ
            <br />
            พร้อมติดตามการทํางานของคุณในแต่ละขั้นตอน
            <br />
            เพื่อวัดผลแคมเปญด้วยระบบที่แม่นยํา
            <br />
            และได้รับผลลัพธ์ที่ถูกต้องจาก User ยิ่งขึ้น
          </Typography>
          <Box
            textAlign={device === "mobile" ? "center" : "right"}
            mb={[11, 0]}
          >
            <ButtonPricingAndPlan
              variant="contained"
              onClick={() => {
                history.push("/home/pricing");
              }}
            >
              <Typography variant="body1">Pricing and Plan</Typography>
            </ButtonPricingAndPlan>
          </Box>
        </Stack>
      </Box>

      <Box sx={classes.carouselBox}>
        <CarouselContent device={device} maxLength={ourPerformanceList.length}>
          {ourPerformanceList.map((d, index) => (
            <Box
              key={"data-" + index}
              display="flex"
              mx="auto"
              sx={classes.contentBox}
            >
              <Grid
                container
                direction={device === "mobile" ? "column-reverse" : "row"}
                justifyContent="center"
                alignItems="center"
                spacing={setValueByBreakpoint(breakpoint, 2, 0, 4, 8)}
              >
                <Grid
                  item
                  xs={true}
                  sm={true}
                  md={true}
                  lg={true}
                  xl={true}
                  px={[0, 2, 2, 0]}
                  py={[4, 2, 2, 0]}
                >
                  <Paper sx={classes.paper}>
                    <Box px={[5, 7, 7, 12]} py={[6, 7, 7, 10]}>
                      <Typography
                        variant={setValueByBreakpoint(
                          breakpoint,
                          "body2",
                          null,
                          null,
                          "h6"
                        )}
                        color="secondary"
                        fontWeight="bold"
                        textAlign={device === "mobile" ? "center" : "start"}
                        mb={2}
                      >
                        {d.header}
                      </Typography>
                      <Typography
                        variant={setValueByBreakpoint(
                          breakpoint,
                          "h5",
                          null,
                          null,
                          "h4"
                        )}
                        fontWeight="bold"
                        textAlign={device === "mobile" ? "center" : "start"}
                        mb={6}
                        sx={classes.headerText}
                      >
                        {d.subHeader}
                      </Typography>
                      <Typography
                        variant={setValueByBreakpoint(
                          breakpoint,
                          "body1",
                          null,
                          null,
                          "h6"
                        )}
                        color="common.black"
                        textAlign="start"
                        mb={[3, 3, 3, 10]}
                      >
                        {d.content}
                      </Typography>
                      <Grid
                        container
                        justifyContent="center"
                        pb={[2, 4, 4, 12]}
                      >
                        {d.state.map((eng, index) => (
                          <Grid
                            key={index}
                            item
                            xs={6}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                          >
                            <Stack alignItems="center">
                              <Box sx={classes.engagementCarouselBox}>
                                <img
                                  src={eng.image}
                                  alt="ig"
                                  style={{
                                    height: setValueByBreakpoint(
                                      breakpoint,
                                      "38px",
                                      "40px",
                                      null,
                                      "58px"
                                    ),
                                    objectFit: "contain",
                                  }}
                                />
                              </Box>
                              <Typography
                                variant={setValueByBreakpoint(
                                  breakpoint,
                                  "h5",
                                  "h6",
                                  "h5",
                                  "h4"
                                )}
                                fontWeight="bold"
                              >
                                {nf.format(eng.value)}
                              </Typography>
                              <Typography
                                variant={setValueByBreakpoint(
                                  breakpoint,
                                  "body2",
                                  null,
                                  null,
                                  "h6"
                                )}
                                color="grey.500"
                              >
                                {eng.pronoun}
                              </Typography>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  sm="auto"
                  md="auto"
                  lg="auto"
                  xl="auto"
                  px={[0, 0, 0, 3]}
                >
                  <img
                    src={d.image}
                    alt="mobile"
                    style={{
                      width: setValueByBreakpoint(
                        breakpoint,
                        "260px",
                        "250px",
                        "280px",
                        "390px"
                      ),
                      objectFit: "contain",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </CarouselContent>
      </Box>
    </Stack>
  );
}

export default HomeMarketer;
