import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// Icons
import WifiIcon from "@mui/icons-material/Wifi";
import GroupIcon from "@mui/icons-material/Group";
import PolylineRoundedIcon from "@mui/icons-material/PolylineRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CircleIcon from "@mui/icons-material/Circle";
// Styles
import { useTimelineDialogStyle as classes } from "./CampaignDetails.style";
// data
import { selectInfluAndAnnounce, submitDraftData } from "./CampaignDetailsData";

interface IOpenTimelineDetailDialogProps {
  openTimelineDialog: boolean;
  setOpenTimelineDialog: (value: boolean) => void;
}

export default function TimelineDetailDialog(
  props: IOpenTimelineDetailDialogProps
) {
  const { openTimelineDialog, setOpenTimelineDialog } = props;
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openTimelineDialog}
        onClose={() => setOpenTimelineDialog(false)}
      >
        <DialogTitle sx={classes.dialogTitle}>
          <Typography>Timeline</Typography>
          <Typography>
            โปรดอ่านและทำความเข้าใจ เรื่อง ระยะเวลาของแคมเปญ
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={classes.dialogContent}>
            <Grid container>
              <Grid item xs={1} mt={2.5} position="relative" sx={{ zIndex: 1 }}>
                <Box sx={classes.timelineLeft}>
                  <WifiIcon color="primary" />
                </Box>
                <Divider sx={classes.divider} orientation="vertical" />
              </Grid>

              <Grid item xs={11} mb={3}>
                <Box sx={{ ...classes.timelineRigthBox, height: "104px" }}>
                  <Typography
                    color="primary"
                    fontWeight="500"
                    mb="22px"
                    mt="16px"
                  >
                    เปิดรับสมัคร
                  </Typography>
                  <Box display="flex">
                    <Grid item xs={4}>
                      <Typography>ช่วงที่เปิดรับสมัคร</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack direction="row" spacing={4} sx={classes.openDate}>
                        <Typography>20/09/2022</Typography>
                        <Typography>-</Typography>
                        <Typography>20/09/2022</Typography>
                      </Stack>
                    </Grid>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
                <Box sx={classes.timelineLeft}>
                  <GroupIcon color="primary" />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Box sx={{ ...classes.timelineRigthBox, height: "87%" }}>
                  <Typography color="primary" fontWeight="500" mb="26px">
                    คัดเลือกและประกาศผลผู้เข้าร่วมแคมเปญ
                  </Typography>
                  <Box display="flex">
                    <Grid item xs={4} sx={classes.selectInfluDate}>
                      <CircleIcon />
                      <Typography>วันคัดเลือกอินฟลูเอนเซอร์</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack direction="row" spacing={4} sx={classes.openDate}>
                        <Typography>20/09/2022</Typography>
                        <Typography>-</Typography>
                        <Typography>20/09/2022</Typography>
                      </Stack>
                    </Grid>
                  </Box>

                  <Box display="flex" mt={4}>
                    <Grid item xs={4} sx={classes.selectInfluDate}>
                      <CircleIcon />
                      <Typography>วันประกาศผล</Typography>
                    </Grid>
                    <Grid item xs={8} sx={classes.openDate}>
                      <Typography>20/09/2022</Typography>
                    </Grid>
                  </Box>

                  <Box sx={classes.selectInfluAndAnnounce}>
                    {selectInfluAndAnnounce.map((item, index) => (
                      <ul key={index}>
                        <li>{item.title}</li>
                        {item.text.map((txtItem, txtIndex) => (
                          <li key={txtIndex}>{txtItem}</li>
                        ))}
                      </ul>
                    ))}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
                <Box sx={classes.timelineLeft}>
                  <PolylineRoundedIcon color="primary" />
                </Box>
              </Grid>
              <Grid item xs={11} mb={2}>
                <Box sx={{ ...classes.timelineRigthBox, height: "87%" }}>
                  <Typography color="primary" fontWeight="500" mb="26px">
                    ส่งงานรีวิว
                  </Typography>

                  <Box display="flex" mt={4}>
                    <Grid item xs={4} sx={classes.selectInfluDate}>
                      <CircleIcon />
                      <Typography>วันประกาศผล</Typography>
                    </Grid>
                    <Grid item xs={8} sx={classes.openDate}>
                      <Typography>20/09/2022</Typography>
                    </Grid>
                  </Box>

                  <Box sx={classes.selectInfluAndAnnounce}>
                    {submitDraftData.map((item, index) => (
                      <ul key={index}>
                        <li>{item.title}</li>
                        {item.text.map((txtItem, txtIndex) => (
                          <li key={index}>{txtItem}</li>
                        ))}
                      </ul>
                    ))}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
                <Box sx={classes.timelineLeft}>
                  <WifiIcon color="primary" />
                </Box>
              </Grid>
              <Grid item xs={11} mb={2}>
                <Box sx={{ ...classes.timelineRigthBox, height: "80%" }}>
                  <Typography color="primary" fontWeight="500" mb="26px">
                    ช่วงโพสต์และแนบลิงค์ส่งงาน
                  </Typography>
                  <Box display="flex">
                    <Grid item xs={4} sx={classes.selectInfluDate}>
                      <CircleIcon />
                      <Typography>วันคัดเลือกอินฟลูเอนเซอร์</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack direction="row" spacing={4} sx={classes.openDate}>
                        <Typography>20/09/2022</Typography>
                        <Typography>-</Typography>
                        <Typography>20/09/2022</Typography>
                      </Stack>
                    </Grid>
                  </Box>
                  <Box display="flex" mt={4}>
                    <Grid item xs={4} sx={classes.selectInfluDate}>
                      <CircleIcon />
                      <Typography>วันประกาศผล</Typography>
                    </Grid>
                    <Grid item xs={8} sx={classes.openDate}>
                      <Typography>30 วัน</Typography>
                    </Grid>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
                <Box sx={classes.timelineLeft}>
                  <CheckRoundedIcon color="primary" />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Box sx={{ ...classes.timelineRigthBox, height: "104px" }}>
                  <Typography color="primary" fontWeight="500" mb="26px">
                    สิ้นสุดแคมเปญ
                  </Typography>
                  <Box display="flex" mt={4}>
                    <Grid item xs={4} sx={classes.selectInfluDate}>
                      <CircleIcon />
                      <Typography>วันประกาศผล</Typography>
                    </Grid>
                    <Grid item xs={8} sx={classes.openDate}>
                      <Typography>30 วัน</Typography>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={classes.acceptCondition} mt={10}>
              <Button
                onClick={() => setOpenTimelineDialog(false)}
                variant="contained"
              >
                อ่านและทำความเข้าใจเรียบร้อยแล้ว
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpenTimelineDialog(false)}
              >
                ยกเลิก
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
