import React from "react";
import { HeaderProfile } from "./HeaderProfile";

// import { IInfluencerSocialRes } from "../../../views/my-profile/MyProfile.types";

import { Stack } from "@mui/material";

function DetailTab({ data }: { data: any }) {
  return (
    <React.Fragment>
      <Stack width={"100%"}>
        <HeaderProfile data={data} />
      </Stack>
    </React.Fragment>
  );
}

export default DetailTab;
