import { hPurple } from "../../../theme";
import { darken } from "@mui/material/styles";
import { hGrey } from "../../../theme";
import { styled } from "@mui/material/styles";

export const useTwitterTabStyle = {
  stackContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    width: "100%",
  },
  audienceTabs: {
    minHeight: "fit-content",
    backgroundColor: hPurple[100],
    borderRadius: "45px",
  },
};
