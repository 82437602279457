import React from "react";
import CategoryChip from "../../global/cetagory-chip";

import {
  HeaderProfileMobile,
  useTiktokTabStyle as classes,
} from "./TiktokTab.styles";
import { calculateNumberSocialUnit } from "../../../utils/helpers/calculateNumberSocialUnit.helpers";

import { SmallAvatar } from "../InfluencerSocial.styles";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import FollowerIcon from "@mui/icons-material/AccountCircle";
import SyncIcon from "@mui/icons-material/Cached";
import tiktokLogo from "./../../../assets/images/social/tiktok-only.png";
import dayjs from "dayjs";
// import { IInfluencerSocialRes } from "../../../views/my-profile/MyProfile.types";

export function HeaderProfile({ data }: { data: any }) {
  const tiktokAccount = data[0].social_account[0];

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm="auto">
          <HeaderProfileMobile>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={
                <SmallAvatar alt="instagram icon" src={tiktokLogo} />
              }
              sx={classes.tiktokBadge}
            >
              <Avatar
                alt="Travis Howard"
                src={tiktokAccount.profile_image}
                variant="rounded"
                sx={classes.tiktokProfileAvatar}
              />
            </Badge>
          </HeaderProfileMobile>
        </Grid>
        <Grid item xs={12} sm>
          <Stack sx={classes.InformationHeaderBox}>
            <HeaderProfileMobile>
              <Box sx={classes.NameHeaderProfile}>
                <Typography variant="h6">{tiktokAccount.name}</Typography>
                {tiktokAccount.recommended && (
                  <>
                    <Typography variant="h6">{data.social_name}</Typography>
                    {data.recommended && (
                      <StarIcon
                        fontSize="inherit"
                        htmlColor="#FFD54F"
                        sx={{
                          ml: 1,
                        }}
                      />
                    )}
                  </>
                )}
              </Box>
            </HeaderProfileMobile>
            <HeaderProfileMobile>
              <Box display="flex" gap={2}>
                {tiktokAccount.category.map((cate: any) => (
                  <CategoryChip label={cate.name_en} key={cate.id} />
                ))}
              </Box>
            </HeaderProfileMobile>
            <HeaderProfileMobile>
              <Box display="inline-flex">
                <FollowerIcon
                  sx={{
                    pr: 2,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    pr: 1,
                  }}
                >
                  {calculateNumberSocialUnit(tiktokAccount.follower, -1)}
                </Typography>
                <Typography variant="body1" color="grey.700">
                  Followers
                </Typography>
              </Box>
            </HeaderProfileMobile>
          </Stack>
        </Grid>

        <Box sx={classes.line}></Box>

        <Grid item xs={12} sm="auto" justifyContent="center">
          <Stack alignItems={"center"}>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<SyncIcon />}
              sx={classes.syncButton}
            >
              <Typography variant="body1">Sync Data</Typography>
            </Button>
            <Typography variant="caption" color="grey.700">
              Updated:{" "}
              {dayjs(data.latest_update_datetime).format("DD/MM/YYYY HH:mm A")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
