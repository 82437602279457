import React from "react";
// MUI
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
  Divider,
} from "@mui/material";
// Global
import WrapInputWithLabel from "../../global/wrap-input-label";
import TextFieldCustom from "../../global/text-field";
import BasicSelectCustom from "../../global/basic-select";
// import { numberWithCommas } from "../../../utils/helpers/numberWidthCommas";
import RangeInput from "../../global/range-input";
// react hook
import { Controller } from "react-hook-form";
// Icon
import CircleIcon from "@mui/icons-material/Circle";
import CreatableSelectCustom from "../../global/creatable-select-custom";
// styles
import { useCampaignTargetAudience as classes } from "./CampaignBrief.style";
// data
import {
  gender,
  location,
  targetGenderLocation,
  targetAudInfluData,
} from "./CampaignBriefData";

export default function TargetAudience({ form }: any) {
  const {
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    control,
    setError,
    getValues,
  } = form;

  const onHandleChange = (e: any, keyName: string, arr: Array<any>) => {
    const { value } = e.target;
    setValue(keyName, value);
  };
  return (
    <Card id="targetAudience" sx={classes.cardContainer}>
      <CardHeader
        title={
          <Box sx={classes.cardHeader}>
            <Typography>Target Audience</Typography>
            <Typography>กลุ่มเป้าหมาย</Typography>
          </Box>
        }
      />
      <Divider sx={classes.divider} />
      <CardHeader
        title={
          <Typography variant="body1" color="primary" fontWeight="500">
            กลุ่มลูกค้าของแบรนด์
          </Typography>
        }
      />
      <CardContent sx={{ mt: "-16px" }}>
        <Stack spacing={2.5}>
          <WrapInputWithLabel
            title={
              <Box sx={classes.ageTitle}>
                <CircleIcon />
                <Typography>Age</Typography>
              </Box>
            }
          >
            <RangeInput
              setError={setError}
              clearErrors={clearErrors}
              startKeyName="targetAudience.userAge.startAge"
              endKeyName="targetAudience.userAge.endAga"
              Controller={Controller}
              control={control}
              errors={errors}
              watch={watch}
            />
          </WrapInputWithLabel>

          {targetAudInfluData.targetGenderLocation.map((item, index) => (
            <WrapInputWithLabel
              key={index}
              title={
                <Box sx={classes.ageTitle}>
                  <CircleIcon />
                  <Typography>{item.title}</Typography>
                </Box>
              }
            >
              <Controller
                name={`targetAudience.${item.id}`}
                control={control}
                rules={{
                  required: false,
                }}
                defaultValue=""
                render={({ field }) => {
                  return (
                    <CreatableSelectCustom
                      options={item.id === "location" ? location : gender}
                      {...field}
                    />
                  );
                }}
              />
            </WrapInputWithLabel>
          ))}
        </Stack>
      </CardContent>
      <CardHeader
        title={
          <Typography variant="body1" color="primary" fontWeight="500">
            คุณสมบัตอินฟลูเอนเซอร์ที่คุณต้องการ
          </Typography>
        }
      />
      <CardContent sx={{ mt: "-16px" }}>
        <Stack spacing={2.5}>
          {targetAudInfluData.followerAge.map((item, index) => (
            <WrapInputWithLabel
              key={index}
              title={
                <Box sx={classes.ageTitle}>
                  <CircleIcon />
                  <Typography>{item.title}</Typography>
                </Box>
              }
            >
              <RangeInput
                setError={setError}
                clearErrors={clearErrors}
                startKeyName={`targetAudience.${item.idStart}`}
                endKeyName={`targetAudience.${item.idEnd}`}
                Controller={Controller}
                control={control}
                errors={errors}
                watch={watch}
              />
            </WrapInputWithLabel>
          ))}

          {targetAudInfluData.genderLocation.map((item, index) => (
            <WrapInputWithLabel
              title={
                <Box sx={classes.ageTitle}>
                  <CircleIcon />
                  <Typography>{item.title}</Typography>
                </Box>
              }
            >
              <Controller
                name={`targetAudience.${item.id}`}
                control={control}
                rules={{
                  required: false,
                }}
                defaultValue=""
                render={({ field }) => {
                  return (
                    <CreatableSelectCustom
                      options={item.id === "influGender" ? gender : location}
                      {...field}
                    />
                  );
                }}
              />
            </WrapInputWithLabel>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
