import React, { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Avatar,
} from "@mui/material";
// react hook
import { Controller, useFieldArray } from "react-hook-form";
// Global
import WrapInputWithLabel from "../../global/wrap-input-label";
import TextFieldCustom from "../../global/text-field";
import ImageInput from "../../global/image-input";
// Helper
import { numberWithCommas } from "../../../utils/helpers/numberWithCommas";
// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// style
import { useCampaignBudgetAndRewardStyle as classes } from "./CampaignBrief.style";

import { specifyingProductDetails } from "./CampaignBriefData";

type FormValues = {
  firstName: string;
  fieldArray: { name: string }[];
};

export default function BudgetAndReward({ form }: any) {
  const [productService, setProductService] = useState<boolean>(false);
  const [openAddProduct, setOpenAddProduct] = useState<boolean>(false);

  const {
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    register,
    watch,
    control,
  } = form;

  const { append, remove } = useFieldArray({
    control,
    name: "budgetAndReward.productAndService",
  });

  const budgetTotal = watch("budgetAndReward.campaignBudget");
  const budgetInfluencerAmout = watch("budgetAndReward.influencerAmount");

  const handleProductService = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setProductService(value);
  };
  return (
    <Card id="budgetAndReward" sx={classes.cardContainer}>
      <CardHeader
        title={
          <Box sx={classes.cardHeader}>
            <Typography>Budget and Reward</Typography>
            <Typography>สิ่งที่อินฟลูเอนเซอร์จะได้รับ</Typography>
          </Box>
        }
      />
      <Divider sx={classes.divider} />
      <CardContent>
        <Typography color="primary" fontWeight="500" mb={3}>
          Budget (งบประมาณ)
        </Typography>
        <Stack spacing={2}>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Campaign Budget"
            required
          >
            <Controller
              name="budgetAndReward.campaignBudget"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field: { onChange, value, name, ref } }) => (
                <>
                  <TextFieldCustom
                    variant="outlined"
                    placeholder=""
                    fullWidth
                    error={!!errors?.budget?.total}
                    helperText={errors?.budget?.total?.message}
                    value={value}
                    onChange={(ev) => {
                      const newNum = numberWithCommas(ev.target.value);
                      onChange(newNum);
                    }}
                    name={name}
                    ref={ref}
                    onBlur={() => {
                      if (budgetTotal && budgetInfluencerAmout) {
                        const pBudgetTotal = +budgetTotal.replace(/\,/g, "");
                        const pBudgetInfluAmout =
                          +budgetInfluencerAmout.replace(/\,/g, "");

                        const each = pBudgetTotal / pBudgetInfluAmout;
                        const eachWithComma = numberWithCommas(
                          each.toFixed(0).toString()
                        );

                        setValue(
                          "budgetAndReward.eachInfluencer",
                          eachWithComma
                        );
                      } else {
                        setValue("budgetAndReward.eachInfluencer", "");
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">บาท</InputAdornment>
                      ),
                    }}
                  />
                  <Typography fontSize="12px" color="#5C5C5C" ml={5} mt={2.5}>
                    ค่าตอบแทนขั้นต่ำในการรีวิว คือ 200 บาทต่อคน
                  </Typography>
                </>
              )}
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Influencer amount"
            required
          >
            <Controller
              name="budgetAndReward.influencerAmount"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldCustom
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  error={!!errors?.budget?.influencerAmount}
                  helperText={errors?.budget?.influencerAmount?.message}
                  value={value}
                  onChange={(ev) => {
                    const newNum = numberWithCommas(ev.target.value);
                    onChange(newNum);
                  }}
                  name={name}
                  ref={ref}
                  onBlur={() => {
                    if (budgetTotal && budgetInfluencerAmout) {
                      const pBudgetTotal = +budgetTotal.replace(/\,/g, "");
                      const pBudgetInfluAmout = +budgetInfluencerAmout.replace(
                        /\,/g,
                        ""
                      );

                      const each = pBudgetTotal / pBudgetInfluAmout;
                      const eachWithComma = numberWithCommas(
                        each.toFixed(0).toString()
                      );
                      setValue("budgetAndReward.eachInfluencer", eachWithComma);
                    } else {
                      setValue("budgetAndReward.eachInfluencer", "");
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">คน</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel
            labelWidth="200px"
            title={
              <Typography fontSize="14px" mt={2.5}>
                Each influencer
              </Typography>
            }
          >
            <Controller
              name="budgetAndReward.eachInfluencer"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextFieldCustom
                  variant="outlined"
                  placeholder="xxx"
                  fullWidth
                  disabled
                  error={!!errors?.budget?.eachInfluencer}
                  helperText={errors?.budget?.eachInfluencer?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">บาทต่อคน</InputAdornment>
                    ),
                  }}
                  {...field}
                  sx={{ mt: "9px" }}
                />
              )}
            />
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
      <CardContent>
        <Typography color="primary" fontWeight="500" mb={3}>
          Reward (สิ่งที่อินฟลูเอนเซอร์จะได้รับ)
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WrapInputWithLabel
              labelWidth="200px"
              title={
                <FormGroup>
                  <FormControlLabel
                    sx={classes.formControlLabel}
                    control={<Checkbox defaultChecked disabled />}
                    label="ค่าตอบแทนในการรีวิว"
                  />
                </FormGroup>
              }
            >
              <Controller
                name="budgetAndReward.compensation"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue=""
                render={({ field: { onChange, value, name, ref } }) => (
                  <TextFieldCustom
                    variant="outlined"
                    placeholder="xxx"
                    fullWidth
                    error={!!errors?.budget?.total}
                    helperText={errors?.budget?.total?.message}
                    value={value}
                    onChange={(ev) => {
                      const newNum = numberWithCommas(ev.target.value);
                      onChange(newNum);
                    }}
                    name={name}
                    ref={ref}
                    onBlur={() => {
                      if (budgetTotal && budgetInfluencerAmout) {
                        const pBudgetTotal = +budgetTotal.replace(/\,/g, "");
                        const pBudgetInfluAmout =
                          +budgetInfluencerAmout.replace(/\,/g, "");

                        const each = pBudgetTotal / pBudgetInfluAmout;
                        const eachWithComma = numberWithCommas(
                          each.toFixed(0).toString()
                        );
                        setValue("budget.eachInfluencer", eachWithComma);
                      } else {
                        setValue("budget.eachInfluencer", "");
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">บาท</InputAdornment>
                      ),
                    }}
                    sx={classes.compensation}
                  />
                )}
              />
            </WrapInputWithLabel>
          </Grid>
          <Grid item xs={3}>
            <FormGroup>
              <FormControlLabel
                sx={classes.formControlLabel}
                control={<Checkbox onChange={(e) => handleProductService(e)} />}
                label="สินค้าหรือบริการ"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={9} display={productService === true ? "flex" : "none"}>
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={4}>
                {getValues("budgetAndReward.productAndService").map(
                  (item: any, index: number) => (
                    <Grid item xs={6}>
                      <Box display="flex" sx={classes.productAndService}>
                        <Avatar src={item.productImage} />
                        <Box sx={classes.productAndServiceheader}>
                          <Typography>{item.productTitle}</Typography>
                          <Typography>{item.description}</Typography>
                        </Box>
                        <IconButton
                          onClick={() => remove(index)}
                          sx={classes.productAndServiceIcon}
                        >
                          <RemoveCircleIcon color="secondary" />
                        </IconButton>
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>

              <Button
                sx={classes.addProductAndServiceButton}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => setOpenAddProduct(true)}
              >
                เพิ่มสินค้าหรือบริการ
              </Button>
            </Box>
            <Dialog
              open={openAddProduct}
              onClose={() => setOpenAddProduct(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
                <Typography>
                  สินค้าหรือบริการที่อินฟลูเอนเซอร์จะได้รับ
                </Typography>
                <IconButton onClick={() => setOpenAddProduct(false)}>
                  <CancelIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ my: "24px" }}>
                <Box display="flex">
                  <AllInboxIcon />
                  <Typography fontSize="16px" fontWeight="500" ml={2}>
                    Product or service detail
                  </Typography>
                </Box>
                <Grid container spacing={3} mt={3}>
                  <Grid item xs={3.5}>
                    <Typography>
                      Product Image<span className="requir">*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8.5}>
                    <ImageInput
                      errors={errors?.campaign?.logoOrganization}
                      register={register}
                      setValue={setValue}
                      keyName="budgetAndReward.productAndService.productImage"
                      icon="camera"
                    />
                  </Grid>
                  <Grid item xs={3.5}>
                    <Typography>
                      Product Title<span className="requir">*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8.5}>
                    <TextFieldCustom
                      variant="outlined"
                      placeholder="ชื่อสินค้าหรือบริการ"
                      fullWidth
                      error={!!errors.campaign?.companyName}
                      helperText={errors.campaign?.companyName?.message}
                      inputProps={{
                        ...register(
                          "budgetAndReward.productAndService.productTitle",
                          {
                            required: true,
                          }
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3.5}>
                    <Typography>
                      Description<span className="requir">*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8.5}>
                    <TextFieldCustom
                      variant="outlined"
                      placeholder="Text"
                      fullWidth
                      error={!!errors.campaign?.companyName}
                      helperText={errors.campaign?.companyName?.message}
                      inputProps={{
                        ...register(
                          "budgetAndReward.productAndService.description",
                          {
                            required: true,
                          }
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3.5}></Grid>
                  <Grid item xs={8.5}>
                    <Box sx={classes.specifyingProductDetails}>
                      <Typography>ตัวอย่าง การระบุรายละเอียดสินค้า</Typography>
                      <ul>
                        {specifyingProductDetails.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={classes.dialogAction}>
                <Button
                  onClick={() => {
                    append({
                      productImage: getValues(
                        "budgetAndReward.productAndService.productImage"
                      ),
                      productTitle: getValues(
                        "budgetAndReward.productAndService.productTitle"
                      ),
                      description: getValues(
                        "budgetAndReward.productAndService.description"
                      ),
                    });
                    setOpenAddProduct(false);
                  }}
                  // disabled={
                  //   !getValues(
                  //     "budgetAndReward.productAndService.productTitle"
                  //   ) ||
                  //   !getValues(
                  //     "budgetAndReward.productAndService.description"
                  //   ) ||
                  //   (!getValues(
                  //     "budgetAndReward.productAndService.productImage"
                  //   ) &&
                  //     true)
                  // }
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
