import * as yup from "yup";

export const BankAccountFormSchema = yup.object().shape({
  account_name: yup.string().required("Please enter account name.").matches(/^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/, "Account name is not valid"),
  account_number: yup.string().required("Please enter account number."),
  bank: yup
    .object()
    .shape({
      id: yup.number(),
      value: yup.string(),
      name_en: yup.string(),
      name_th: yup.string(),
      logo_image_url: yup.string(),
    })
    .required("Please select data.")
    .nullable(),
  branch: yup.string(),
  account_type: yup.string().nullable(),
  tax_id: yup.string().required("Please enter tax id.").matches(/^\(?([0-9]{1})\)?[-]?([0-9]{4})[-]?([0-9]{5})[-]?([0-9]{2})[-]?([0-9]{1})$/,"Tax ID should be 13 digits"),
  id_card_image_url: yup.object().shape({
    file_id: yup.string(),
    file_name: yup.string(),
    file_type: yup.string(),
    url: yup.string(),
    purpose: yup.string(),
    description: yup.string()
  }).required("Plese upload image Copy of thai national ID"),
  book_bank_image_url: yup.object().shape({
    file_id: yup.string(),
    file_name: yup.string(),
    file_type: yup.string(),
    url: yup.string(),
    purpose: yup.string(),
    description: yup.string()
  }).required("Please upload image Book bank")
});
