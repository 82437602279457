import { CardContent, Card, Box, Avatar, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

function Information() {
  const styles = {
    boxInfoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cardInfoContainer: {
      marginBlock: 3,
      borderWidth: "3px",
      borderStyle: "solid",
      borderColor: "primary.main",
    },
  };

  return (
    <Card sx={styles.cardInfoContainer}>
      <CardContent>
        <Box sx={styles.boxInfoContainer}>
          <Box display="flex" alignItems="center">
            <Avatar
              src="/assets/images/lazada.png"
              variant="square"
              sx={{ width: 50, height: 50, borderRadius: "5px", mr: 1 }}
            />
            <span>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  color="common.black"
                  fontWeight="500"
                  display="flex"
                  alignItems="center"
                  marginRight={5}
                >
                  LAZADA Shop Marathon 12.12
                </Typography>
                <Typography
                  variant="h6"
                  color="common.black"
                  fontWeight="400"
                  display="flex"
                  alignItems="center"
                >
                  (LAZADA)
                </Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <Typography
                  marginRight={5}
                  variant="body1"
                  color="common.black"
                  fontWeight="inherit"
                  display="flex"
                  alignItems="center"
                >
                  Campaign ID : XXXXX
                </Typography>
                <Typography
                  marginRight={5}
                  variant="body1"
                  color="common.black"
                  fontWeight="inherit"
                  display="flex"
                  alignItems="center"
                >
                  |
                  <i
                    className="ico-hu-calendar"
                    style={{ fontSize: "18px", marginInline: "5px" }}
                  />
                  10/12/2021
                </Typography>

                <Typography
                  marginRight={5}
                  variant="body1"
                  color="common.black"
                  fontWeight="inherit"
                  display="flex"
                  alignItems="center"
                >
                  |
                  <i
                    className="ico-hu-posts"
                    style={{ fontSize: "18px", marginInline: "5px" }}
                  />
                  Public
                </Typography>
                <Typography
                  marginRight={5}
                  variant="body1"
                  color="common.black"
                  fontWeight="inherit"
                  display="flex"
                  alignItems="center"
                >
                  |
                  <i
                    className="ico-hu-facebook-solid"
                    style={{ fontSize: "22px", marginInline: "5px" }}
                  />
                  Photo Album 4-5 Pic
                </Typography>
              </Box>
            </span>
          </Box>
          <Box sx={{ textAlign: "end" }}>
            <Typography
              variant="body1"
              color="secondary.main"
              fontWeight="inherit"
              display="flex"
              alignItems="center"
            >
              <CircleIcon sx={{ fontSize: "14px", marginInline: "2px" }} />
              Influencer Listing
            </Typography>

            <Typography variant="body1" color="grey.500" fontWeight="inherit">
              Cancel
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Information;
