import React, { useEffect, useState } from "react";
import CreateAccountDialog from "../../components/authentication-dialog/CreateAccountDialog";
import HomeMarketer from "../../components/home-marketer/HomeMarketer";
import useBreakpoint from "../../utils/hooks/useBreakpoint";
import { IDevice } from "../global/global.types";

function HomeMarketerPage() {
  const bp = useBreakpoint();
  const [device, setDevice] = useState<IDevice>("mobile");
  const [isOpenCreateAccount, setIsOpenCreateAccount] =
    useState<boolean>(false);

  useEffect(() => {
    if (bp === "xs") {
      setDevice("mobile");
    } else if (bp === "sm" || bp === "md") {
      setDevice("tablet");
    } else {
      setDevice("desktop");
    }
  }, [bp]);

  return (
    <div>
      <HomeMarketer
        device={device}
        breakpoint={bp}
        setIsOpenCreateAccount={setIsOpenCreateAccount}
      />

      {/* -- UAT comment here -- */}
      <CreateAccountDialog
        isOpened={isOpenCreateAccount}
        setOpen={setIsOpenCreateAccount}
        accountSelected={"marketer"}
      />
      {/* -- End comment -- */}
    </div>
  );
}

export default HomeMarketerPage;
