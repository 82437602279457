import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Library
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Component
import DialogAuthentication from "../global/dialog-authentication";
import TextFieldCustom from "../global/text-field";
import LoadingVerified from "../vertify-email/LoadingVerified";
// Types
import { ISignInDialogProps, ISignInFormSchema } from "./AuthenDialog.types";
import { ISignInForm } from "./AuthenticationForm";
// Schema
import { SignInFormSchema } from "./AuthenticationForm.schema";
// Styles
import { useSignInDialogStyle as classes } from "./authentication.styles";
// Material
import {
  Button,
  DialogContent,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
// API
import { signInWithAPI } from "../../api/authentication/Authentication";
// Helper
import { decodeJWT } from "./../../utils/helpers/decodeJWT";
// Context
import { useAuth } from "../../contexts/Auth";
import { getTokenFromStorage } from "./../../utils/helpers/getTokenFromStorage";
//Sweet Alert
import SwalCustom from "./../global/sweet-alert-custom/SwalCustom";
// Theme
import { hAlertColor } from "../../theme";
import Loading from "../global/loading/Loading";

const initSignInForm: ISignInForm = {
  email: "",
  password: "",
};

function SignInDialog({
  isOpened,
  setIsOpenSignIn,
  openSelectAccountType,
  openForgetPassword,
}: ISignInDialogProps) {
  const history = useHistory();
  const { signIn } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<ISignInForm>({
    resolver: yupResolver(SignInFormSchema),
    defaultValues: initSignInForm,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpenSignIn(false);
  };

  const handleChange = (e: any, name: keyof ISignInFormSchema) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onSubmit = async (data: ISignInForm) => {
    setLoading(true);
    signInWithAPI(data)
      .then((response) => {
        setLoading(false);
        const { access_token, refresh_token, session_token } = response;
        const decoded = decodeJWT(session_token);
        const token = JSON.stringify({
          access_token,
          refresh_token,
          session_token: decoded ?? null,
        });
        signIn(token, () => {
          const { session_token, access_token } = getTokenFromStorage();
          if (access_token === "") {
            const resendEmail = session_token?.email;
            history.push(`/verify-email/${resendEmail}`);
          } else {
            history.push("/my-profile");
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        let data = error.response.data;
        if (data.status_code === 401) {
          if (/(Invalid Sign In Credentials)/.test(data.error.message)) {
            data.error.message = "Invalid Sign In Credentials";
          }
          switch (data.error.message) {
            case "error, email format is invalid (example@hasumail.com).":
              SwalCustom.fire({
                icon: "error",
                title: "Opps !",
                text: "Error, Full Name is invalid !",
                confirmButtonColor: hAlertColor.error,
                confirmButtonText: "Close",
              });
              break;
            case "Invalid Sign In Credentials":
              SwalCustom.fire({
                icon: "error",
                title: "Opps !",
                text: "Error, Email or Password is invalid !",
                confirmButtonColor: hAlertColor.error,
                confirmButtonText: "Close",
              });
              break;
            case "email is not verify":
              SwalCustom.fire({
                icon: "error",
                title: "Opps !",
                text: "Please verify your email !",
                confirmButtonColor: hAlertColor.error,
                confirmButtonText: "Close",
              });
              break;
          }
        } else {
          SwalCustom.fire({
            icon: "error",
            text: "Something when wrong !",
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#0277BD",
            customClass: {
              confirmButton: "prompt-font",
            },
          });
        }
      });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <DialogAuthentication
          title="Sign in"
          open={isOpened}
          onClose={handleClose}
          sx={classes.authenDialog}
        >
          <DialogContent sx={classes.dialogContent}>
            <Stack
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              gap={5}
              py={1}
              px={5}
            >
              <TextFieldCustom
                value={getValues("email")}
                onChange={(e) => handleChange(e, "email")}
                variant="outlined"
                placeholder="Email"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "grey.500",
                  },
                  "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                    color: "grey.500",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        className="ico-hu-email-solid"
                        sx={{ ...classes.textFieldIcon, paddingRight: "2px" }}
                      />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <TextFieldCustom
                value={getValues("password")}
                onChange={(e) => handleChange(e, "password")}
                type={!!showPassword ? "text" : "password"}
                variant="outlined"
                placeholder="Password"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "grey.500",
                  },
                  "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                    color: "grey.500",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!!showPassword ? (
                          <Icon
                            className="ico-hu-visability-off-solid"
                            sx={classes.textFieldIcon}
                          />
                        ) : (
                          <Icon
                            className="ico-hu-visability-on-solid"
                            sx={classes.textFieldIcon}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
              <Button type="submit" fullWidth>
                <Typography variant="body1" fontWeight="bold">
                  Sign in
                </Typography>
              </Button>
            </Stack>
            <Typography
              color="grey.800"
              variant="body1"
              align="center"
              onClick={() => openForgetPassword()}
              sx={classes.forgetPasswordLink}
            >
              Forget your password?
            </Typography>
          </DialogContent>
          <Divider sx={classes.divider} />
          <DialogContent sx={classes.dialogContent}>
            <Stack>
              <Typography variant="body1" align="center">
                Don't have an account?
              </Typography>
              <Typography
                color="primary"
                variant="body1"
                fontWeight="bold"
                align="center"
                onClick={() => openSelectAccountType()}
                sx={classes.createAccountLink}
              >
                Create an Account
              </Typography>
            </Stack>
          </DialogContent>
        </DialogAuthentication>
      )}
    </>
  );
}

export default SignInDialog;
