import * as React from "react";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  Stack,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Pagination,
  Card,
  CardActions,
  CardContent,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Icons Material UI
import Download from "@mui/icons-material/FileDownloadOutlined";
// Styles
import {
  InfluencerHistoryStyle as classes,
  DocumentButton,
  TableRowCustom,
  TableCellCustom,
  // status,
} from "./InfluencerMyEarning.styles";
// Mock Data
import { InfluencerMyEarningMockData as earningData } from "./InfluencerMyEarningMockData";

export default function InfluencerHistory() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box mt={3}>
      <Typography variant="h6" mt={10} mb={5}>
        Earnings History
      </Typography>
      <TableContainer
        sx={{ display: { xs: "none", md: "block" } }}
        component={Paper}
      >
        <Table sx={{ minWidth: [980, 1100] }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCellCustom>Campaign</TableCellCustom>
              <TableCellCustom align="center">Recipient</TableCellCustom>
              <TableCellCustom align="left">Completed Date</TableCellCustom>
              <TableCellCustom align="left">Receipt Date</TableCellCustom>
              <TableCellCustom align="center">Amount</TableCellCustom>
              <TableCellCustom align="center">Status</TableCellCustom>
              <TableCellCustom align="center">Decoment</TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            {earningData.map((row, index) => {
              return (
                <TableRowCustom key={index}>
                  <TableCellCustom align="left">
                    <Stack direction="row" alignItems="center">
                      <Avatar sx={classes.campaignIcon} alt="Campaign" src="" />
                      <Box ml={2.5}>
                        <Typography color="#8C499C">
                          {row.campaign.name}
                        </Typography>
                        <Typography fontSize={["12px", "14px"]}>
                          {row.campaign.transaction}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Avatar
                        sx={{ width: "20px", height: "20px" }}
                        alt="Recipient"
                        src=""
                      />
                      <Typography ml={2}>{row.recipient.name}</Typography>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="left">
                    {row.completeData}
                  </TableCellCustom>
                  <TableCellCustom align="left">
                    {row.receiptData}
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    {`${new Intl.NumberFormat("jp-JP", {
                      minimumFractionDigits: 2,
                    }).format(row.amount)} THB`}
                  </TableCellCustom>
                  <TableCellCustom align="center" sx={{ padding: "auto" }}>
                    <Box
                      sx={
                        row.status === "paid"
                          ? classes.statusPaid
                          : classes.statusPending
                      }
                    >
                      {row.status}
                    </Box>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <DocumentButton
                      disabled={
                        row.document.status === "display" ? false : true
                      }
                      startIcon={<Download />}
                    >
                      {row.document.name}
                    </DocumentButton>
                  </TableCellCustom>
                </TableRowCustom>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container display={{ md: "none" }} spacing={5}>
        {earningData.map((row, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              display="flex"
              justifyContent="center"
            >
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <CardContent sx={classes.cardContent}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={1}
                    mb={2.5}
                  >
                    <span>{`ID : ${row.campaign.transaction}`}</span>
                    <Box
                      sx={
                        row.status === "paid"
                          ? classes.statusPaid
                          : classes.statusPending
                      }
                    >
                      {row.status}
                    </Box>
                  </Box>
                  <Divider sx={{ my: "10px" }} />
                  <Box display="flex" alignItems="center">
                    <Avatar sx={classes.campaignIcon} />
                    <Box ml={3}>
                      <Typography
                        color="primary"
                        sx={{ fontSize: "16px", fontWeigth: "500" }}
                      >
                        {row.campaign.name}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Avatar sx={classes.recipientIcon} />
                        <Typography>{row.recipient.name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={classes.cardTable}>
                    <Box className="cardTable">
                      <Typography>Completed Data</Typography>
                      <Typography>{row.completeData}</Typography>
                    </Box>
                    <Box className="cardTable">
                      <Typography>Receipted Data</Typography>
                      <Typography>{row.receiptData}</Typography>
                    </Box>
                    <Box className="cardTable">
                      <Typography>Amount Data</Typography>
                      <Typography>{`${new Intl.NumberFormat("jp-JP", {
                        minimumFractionDigits: 2,
                      }).format(row.amount)} THB`}</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography ml={4}>Document</Typography>
                    <Button
                      sx={{ border: "none", "&:hover": { border: "none" } }}
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<Download />}
                      disabled={
                        row.document.status === "display" ? false : true
                      }
                    >
                      หัก ณ ที่จ่าย
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Stack
        sx={classes.pagination}
        spacing={5}
        direction={["column", "row"]}
        justifyContent="center"
        alignItems="center"
        my={4}
      >
        <Pagination count={10} color="secondary" />
        <TablePagination
          component="div"
          labelRowsPerPage={<span>Shows : </span>}
          // rowsPerPageOptions={[10, 25, 100]}
          // count={data.length}
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Box>
  );
}
