import React from "react";
// Mui
import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
// Styles
import { useBudgetAndRewardDetailStyle as classes } from "./CampaignDetails.style";

export default function BudgetAndRewardDetail() {
  return (
    <Grid container spacing={4} my="-10px">
      <Grid item xs={3}>
        <Typography fontWeight="500">ค่าตอบแทนในการรีวิว</Typography>
      </Grid>
      <Grid item xs={9}>
        <Button variant="contained" sx={classes.compensation}>
          1,000 THB
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Typography fontWeight="500">สินค้าและบริการ</Typography>
      </Grid>
      <Grid item xs={9}>
        <Box sx={classes.productAndServiceCon}>
          <Avatar />
          <Box sx={classes.productAndService}>
            <Typography>อาหารเช้าสำหรับ 2 ท่าน </Typography>
            <Typography>มูลค่ารวม 800 บาท</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
