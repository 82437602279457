import { hNavyBlue, hPink, hPurple, hWhite, hYellow } from "../../theme";
import BannerMainImg from "./../../assets/images/home/2_Influencer page/1 - Cover/D - influ page cover.png";
import BannerMainImgMobile from "./../../assets/images/home/2_Influencer page/1 - Cover/P - influ page cover.png";
import BecomeDesk from "./../../assets/images/home/2_Influencer page/5 - commonity influencer/D - become influ.png";
import BecomeMobile from "./../../assets/images/home/2_Influencer page/5 - commonity influencer/P - become influ.png";

export const useHomeInfluencerStyle = {
  containerStack: {
    height: ["calc(100vh - 30px)", "480px", "480px", "620px", "100vh"],
    backgroundImage: [
      `url("${BannerMainImgMobile}")`,
      `url("${BannerMainImg}")`,
    ],
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  coverBox: {
    display: "flex",
    justifyContent: ["center", "start"],
    alignItems: ["flex-end", "center"],
    height: "inherit",
    paddingInline: [6, 6, 12, 14, 36],
    paddingTop: 4,
  },
  yellowColor: {
    color: hYellow[100],
  },
  navyBlueColor: {
    color: hNavyBlue[100],
  },
  pinkColor: {
    color: hPink[300],
  },
  infographicBox: {
    height: ["auto", null, null, null, "100vh"],
    bgcolor: "#f6e8ee",
    paddingBlock: [10, 10, 10, 0],
  },
  influencerCommunityBox: {
    borderRight: `3px solid ${hPurple[100]}`,
  },
  influencerHashUBox: {
    height: ["640px", "480px", "480px", "620px", "100vh"],
    backgroundImage: [`url("${BecomeMobile}")`, `url("${BecomeDesk}")`],
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  influencerHashUStack: {
    display: "flex",
    justifyContent: ["flex-end", "center"],
    alignItems: ["center", "flex-end"],
    height: "inherit",
    paddingInline: [6, 6, 12, 14, 36],
  },
  influencerImageBox: {
    height: ["220px", "260px", "260px", "280px"],
    width: "inherit",
    borderRadius: "20px",
  },
  influencerProfileMain: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  influencerProfileImage: {
    position: "relative" as any,
    left: 0,
    width: "inherit",
    height: "inherit",
    objectFit: "cover" as any,
  },
  influencerProfileGradient: {
    position: "absolute" as any,
    bottom: 0,
    left: 0,
    width: "inherit",
    height: "120px",
    borderRadius: ["10px", "10px", "20px", "20px"],
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.9) 100%)",
  },
  influencerProfileText: {
    position: "absolute" as any,
    bottom: 0,
    left: 0,
    width: "inherit",
  },
  carouselBox: {
    height: ["auto", null, null, null, "100vh"],
    py: [10, 10, 10, 30, 0],
    bgcolor: hWhite[100],
  },
  contentBox: {
    display: "flex",
    height: "inherit",
    width: "inherit",
    paddingBottom: ["35px", "50px", "50px", "50px"],
  },
  influencerCard: {
    bgcolor: "#C8347A",
    paddingInline: 8,
    paddingTop: 3,
    paddingBottom: 2,
    height: "100%",
  },
  influencerCardHeader: {
    paddingInline: 0,
  },
  influencerAvatar: {
    width: [58, 68, 72, 84],
    height: [58, 68, 72, 84],
    border: "2px solid white",
  },
  divider: {
    borderBottomWidth: "1px",
    borderColor: "common.white",
    marginBlock: 1,
  },
  influencerCardContent: { paddingInline: 0 },
  headerText: {
    color: hNavyBlue[100],
  },
  engagementBox: {
    display: "flex",
    jusjustifyContent: "center",
    alignItems: "center",
    height: 68,
  },
};
