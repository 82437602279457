import { YouTube, Twitter } from "@mui/icons-material";

export const campaignOverviewRadioData = [
  {
    id: "campaignOverview.objective",
    label: "Objective",
    value: "objective",
    options: [
      "Awareness (การทำให้สินค้าหรือบริการของคุณเป็นที่รู้จัก",
      "Conversion (การตัดสินใจหรือการตอบรับจากผู้บริโภค)",
    ],
  },
  {
    id: "campaignOverview.campaignType",
    label: "Campaign Type",
    value: "campaignType",
    options: [
      "Public (การสร้างแคมเปญ และประกาศรับสมัคร Influencer)",
      "Private (การสร้างแคมเปญ และยื่นข้อเสนอให้ Influencer เพื่อเข้าร่วมแคมเปญโดยตรง)",
    ],
  },
  {
    id: "campaignOverview.review",
    label: "Review",
    value: "review",
    options: [
      "Product (การรีวิวสินค้า)",
      "Place (การรีวิวสถานที่ หรือสินค้านอกสถานที่",
    ],
  },
];

export const campaignOverviewOptionsData = [
  { id: 1, name_en: "Thai", name_th: "Thai", value: "Thai", label: "Thai" },
  { id: 2, name_en: "Eng", name_th: "Eng", value: "Eng", label: "Eng" },
  { id: 3, name_en: "Chi", name_th: "Chi", value: "Chi", label: "Chi" },
  { id: 4, name_en: "Jpn", name_th: "Jpn", value: "Jpn", label: "Jpn" },
];

export const emphasisOrProhibition = [
  {
    id: "doOrEmphasis",
    label: "Do or Emphasis",
    titleText: "ตัวอย่าง “สิ่งที่ต้องทำ”",
    textItems: [
      "ถ่ายสินค้าให้สวยงาม และเห็นชื่อแบรนด์อย่างชัดเจน",
      "ถ่ายรูปตัวเองกับสินค้าหรือบริกา",
      "ห้ามคัดลอกบทความของบุคคลอื่นมาเป็นของตัวเอง",
      "อัพโหลดรูปภาพประกอบการรีวิว 4-5 รูป",
      "บอกปัญหาที่พบก่อนการใช้สินค้าหรือบริการ",
      "บอกความประทับใจขณะทดลองใช้งานสินค้าและบริการ",
      "แนะนำข้อมูล จุดเด่น และช่องทางการจัดจำหน่าย",
    ],
  },
  {
    id: "dontOrProhibition",
    label: "Don't or Prohibition",
    titleText: "ตัวอย่าง “สิ่งที่ห้ามทำ”",
    textItems: [
      "ไม่แสดงพฤติกรรมและทัศนคติที่ไม่เหมาะสมต่อบริบทของสังคม เช่น การใช้คำหยาบ การใช้เนื้อหาที่รุนแรง ลามกอนาจาร ดูหมิ่นและสร้างความเสียหายให้ผู้อื่น",
      "ไม่พูดเชิงลบหรือเปรียบเทียบสินค้าและบริการ กับบริษัทคู่แข่งทางการค้า",
      "ห้ามคัดลอกบทความของบุคคลอื่นมาเป็นของตัวเอง",
      "ไม่ทำการบิดเบือนข้อมูลหรือการพูดเกินความจริง อันเป็นเหตุให้บริษัทเกิดความเสียหาย",
    ],
  },
];

export const optionPlatform = [
  {
    id: 1,
    value: "facebook",
    label: "Facebook",
    icon: (
      <i className="ico-hu-facebook-solid" style={{ marginRight: "5px" }} />
    ),
  },
  {
    id: 2,
    value: "instagram",
    label: "Instagram",
    icon: <i className="ico-hu-ig-solid" style={{ marginRight: "5px" }} />,
  },
  {
    id: 3,
    value: "youtube",
    label: "Youtube",
    icon: <YouTube style={{ marginRight: "5px" }} />,
  },
  {
    id: 4,
    value: "twiter",
    label: "Twiter",
    icon: <Twitter style={{ marginRight: "5px" }} />,
  },
  {
    id: 5,
    value: "tiktok",
    label: "TikTok",
    icon: <i className="ico-hu-tiktok" style={{ marginRight: "5px" }} />,
  },
];

export const descriptionAndMoodTone = [
  {
    id: "description",
    title: "Description",
  },
  {
    id: "moodAndTone",
    title: "Mood and Tone",
  },
];

export const contentTypeData = {
  status: "OK",
  status_code: 200,
  result: {
    data: [
      {
        id: 1,
        value: "video_post_30s",
        name_en: "Video 30 second post",
        name_th: "วิดีโอไม่เกิน 30 วินาที",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 2,
        value: "video_post_60s",
        name_en: "Video 60 second post",
        name_th: "วิดีโอไม่เกิน 60 วินาที",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 3,
        value: "photo_album",
        name_en: "Photo album",
        name_th: "อัลบั้มรูป",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 4,
        value: "video_photo_album",
        name_en: "Video and photo album post",
        name_th: "วิดีโอและอัลบั้มรูป",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 5,
        value: "video_post_30s",
        name_en: "Video 30 second post",
        name_th: "วิดีโอไม่เกิน 30 วินาที",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 6,
        value: "video_post_60s",
        name_en: "Video 60 second post",
        name_th: "วิดีโอไม่เกิน 60 วินาที",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 7,
        value: "photo_album",
        name_en: "Photo album",
        name_th: "อัลบั้มรูป",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 8,
        value: "video_photo_album",
        name_en: "Video and photo album post",
        name_th: "วิดีโอและอัลบั้มรูป",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 9,
        value: "video_post_20m",
        name_en: "Video 20 minuite clip",
        name_th: "วิดีโอ 20 นาทีขึ้นไป",
        platform: "youtube",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 10,
        value: "video_post_30s",
        name_en: "Video 30 second post",
        name_th: "วิดีโอไม่เกิน 30 วินาที",
        platform: "tiktok",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 11,
        value: "video_post_60s",
        name_en: "Video 60 second post",
        name_th: "วิดีโอไม่เกิน 60 วินาที",
        platform: "tiktok",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
    ],
  },
};

export const categoryData = {
  status: "OK",
  status_code: 200,
  result: {
    data: [
      {
        id: 1,
        value: "real-estate",
        name_en: "Real Estate",
        name_th: "อสังหาริมทรัพย์",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 2,
        value: "retail",
        name_en: "Retail",
        name_th: "ค้าปลีก",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 3,
        value: "health_and_fitness",
        name_en: "Health & Fitness",
        name_th: "สุขภาพ",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 4,
        value: "sport",
        name_en: "Sport",
        name_th: "กีฬา",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 5,
        value: "game",
        name_en: "Game",
        name_th: "เกมส์",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 6,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 7,
        value: "telecom",
        name_en: "Telecom",
        name_th: "การสื่อสาร",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 8,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 9,
        value: "food_and_beverage",
        name_en: "Food & Beverage",
        name_th: "อาหารและเครื่องดื่ม",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 10,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 11,
        value: "fashion",
        name_en: "Fashion",
        name_th: "แฟชั่น",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 12,
        value: "lifestyle",
        name_en: "Life Style",
        name_th: "ไลฟ์สไตล์",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 13,
        value: "parenting",
        name_en: "Parenting",
        name_th: "พ่อแม่",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 14,
        value: "luxury",
        name_en: "Luxury",
        name_th: "ความหรูหรา",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 15,
        value: "pet_and_animal",
        name_en: "Pet & Animal",
        name_th: "สัตว์เลี้ยง",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 16,
        value: "luxury",
        name_en: "Luxury",
        name_th: "ความหรูหรา",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 17,
        value: "music",
        name_en: "Music",
        name_th: "ดนตรี",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 18,
        value: "photography",
        name_en: "Photography",
        name_th: "การถ่ายภาพ",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 19,
        value: "other",
        name_en: "Other",
        name_th: "อื่น ๆ",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
    ],
  },
};

export const hashtagData = {
  status: "OK",
  status_code: 200,
  result: {
    data: [
      {
        id: 28,
        value: "travel",
        name_en: "Travel",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 29,
        value: "samsung",
        name_en: "Samsung",
        name_th: "",
        type: "brand",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 30,
        value: "beauty",
        name_en: "Beauty",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 31,
        value: "food",
        name_en: "Food",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 32,
        value: "study",
        name_en: "Study",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 33,
        value: "pet",
        name_en: "Pet",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 34,
        value: "อร่อย",
        name_en: "Delicious",
        name_th: "อร่อย",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 35,
        value: "brand-name",
        name_en: "BrandName",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 36,
        value: "ทะเล",
        name_en: "Sea",
        name_th: "ทะเล",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
    ],
  },
};

export const gender = [
  { id: 1, label: "All", value: "all" },
  { id: 2, label: "Man", value: "man" },
  { id: 3, label: "Women", value: "women" },
  { id: 4, label: "LGBT+", value: "LGBTQ+" },
];

export const location = [
  { id: 1, label: "Bangkok", value: "bangkok" },
  { id: 2, label: "Korat", value: "korat" },
  { id: 3, label: "Chiangmai", value: "chiangmai" },
];

export const targetGenderLocation = [
  {
    id: "userGender",
    title: "Gender",
  },
  {
    id: "location",
    title: "Location",
  },
];

export const targetAudInfluData = {
  targetGenderLocation: [
    {
      id: "userGender",
      title: "Gender",
    },
    {
      id: "location",
      title: "Location",
    },
  ],
  followerAge: [
    {
      title: "Follwer",
      idStart: "influFollower.startFollower",
      idEnd: "influFollower.endFollower",
    },
    {
      title: "Age",
      idStart: "influAge.startAge",
      idEnd: "influAge.endAge",
    },
  ],
  genderLocation: [
    {
      id: "influGender",
      title: "Gender",
    },
    {
      id: "influLocation",
      title: "Location",
    },
  ],
};

export const specifyingProductDetails = [
  "คูปองรับประทานอาหาร สำหรับ 2 ท่าน มูลค่า 800 บาท",
  "เสื้อยืด ตรา HashU รุ่น A284 Size S มูลค่า 400 บาท",
  "ห้องพักประเภท วิวทะเล จำนวน 1 คืน (เข้าพักได้ 2 ท่าน)",
  "Gift Voucher Central มูบค่า 500 บาท",
];

export const termAndConditionData = [
  "ให้สิทธิ์บริษัทในการทำซ้ำ ดัดแปลง แก้ไข หรือประชาสัมพันธ์บทความการรีวิวของคุณ ผ่านช่องทางออนไลน์ต่างๆของบริษัทได้โดยคุณไม่สามารถเรียกร้องค่าตอบแทนเพิ่มเติมได้",
  "เปิดบัญชีสาธารณะ และไม่ทำการลบโพสต์ จนกว่าจะครบกำหนดเวลาหากทำการลบโพสต์ก่อน เวลาที่กำหนดคุณจะไม่ได้รับค่าตอบแทนและบริษัทสามารถเรียกร้องค่าเสียหายจากคุณได้",
  "โปรดอ่านและทำความเข้าใจ รายละเอียด รวมถึงสิ่งที่ต้องทำและข้อห้ามในการรีวิวสินค้าหรือ บริการก่อนยอมรับข้อตกลงและเงื่อนไขในการรีวิว",
];

export const timelineData = {
  selectCampParticipantsData: [
    {
      label:
        "กรณีที่ “อินฟลูเอนเซอร์” สมัครเข้าร่วมแคมเปญ “น้อยกว่า” ที่กำหนด หรือน้อยกว่า xx คน",
      text: [
        "ผู้จ้างงาน สามารถ “ยกเลิก” แคมเปญได้ โดยเงินมัดจำจะคืนกลับไปยัง Wallet ของผู้จ้างงาน",
        "ผู้จ้างงาน สามารถ “เลือก” อินฟลูเอนเซอร์เท่าที่มีเพื่อเริ่มแคมเปญต่อไปได้ โดย Budget จะคำนวณตามจำนวนอินฟลูเอนเซอร์ที่เข้าร่วมแคมเปญ",
      ],
    },
    {
      label: "การรีวิว",
      text: [
        "กรณีที่ผู้จ้างงานต้อง “ส่งสินค้า” ให้อินฟลูเอนเซอร์โปรดส่งสินค้าภายใน 3 วันหลังจากที่ประกาศผล",
        "กรณีที่รีวิว “สถานที่”โปรดรอการติดต่อจากอินฟลูเอนเซอร์เพื่อทำการนัดหมายกับผู้จ้างงาน",
      ],
    },
  ],
  submitDraft: [
    {
      label: "การตรวจงาน",
      text: [
        "ผู้จ้างงานสามารถให้อินฟลูเอนเซอร์แก้ไขงานได้ “2 ครั้ง” เท่านั้น",
        "การอนุมัติ Draft ครั้งสุดท้าย คือก่อนวันสุดท้ายที่ต้องโพสต์แคมเปญ “1 วัน”",
      ],
    },
    {
      label: "กรณีที่อินฟลูเอนเซอร์ไม่ส่งภายในเวลาที่กำหนด",
      text: [
        "อินฟลูเอนเซอรคนนั้นจะลงโทษและทำการยกเลิกงานกับอินฟลูเอนเซอร์คนนั้นโดยทันที",
        "หากผู้จ้างงานได้จัดส่งสินค้าหรือให้บริการด้านสถานที่กับอินฟลูเอนเซอร์แล้วอินฟลูเอนเซอร์จะต้องชดเชยค่าสินค้า และบริการทั้งหมด",
      ],
    },
  ],
};
