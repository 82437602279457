import React from "react";
// Typea
import { IBreakpoint } from "../../views/global/global.types";
// Utils
import { setValueByBreakpoint } from "../../utils/helpers/setValueByBreakpoint.helpers";
import { calculateNumberSocialUnit } from "../../utils/helpers/calculateNumberSocialUnit.helpers";
import { addDefaultImageSrc } from "./../../utils/helpers/addDefaultImageSrc.helpers";
// Material
import { Box, Stack, Typography } from "@mui/material";
// Styles
import { useHomeInfluencerStyle as classes } from "./HomeInfluencer.style";

export function InfulencerImage({
  breakpoint,
  influencer,
}: {
  breakpoint: IBreakpoint;
  influencer: any;
}) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin={0}
      padding={0}
      sx={classes.influencerImageBox}
    >
      <Box sx={classes.influencerProfileMain}>
        <img
          src={influencer.image}
          alt="influencer"
          onError={addDefaultImageSrc}
          style={{
            ...classes.influencerProfileImage,
            borderRadius: setValueByBreakpoint(
              breakpoint,
              "10px",
              "10px",
              "20px",
              "20px"
            ),
          }}
        />
        <Box sx={classes.influencerProfileGradient} />
        <Box sx={classes.influencerProfileText}>
          <Stack width="100%" alignItems="center" pb={4}>
            <Typography
              variant={setValueByBreakpoint(
                breakpoint,
                "body1",
                null,
                null,
                "h6"
              )}
              fontWeight={setValueByBreakpoint(
                breakpoint,
                "bold",
                null,
                null,
                "normal"
              )}
              color="common.white"
              pb={1}
            >
              {influencer.name}
            </Typography>
            <Typography
              variant={setValueByBreakpoint(
                breakpoint,
                "caption",
                null,
                null,
                "body1"
              )}
              color="common.white"
            >
              {calculateNumberSocialUnit(influencer.followers, -1)} followers
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
