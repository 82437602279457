import React from "react";
import {
  CardContent,
  Card,
  Box,
  Typography,
  Grid,
  CardHeader,
  Button,
} from "@mui/material";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import InfluencerSelected from "./InfluencerSelected";
import { calculateNumberSocialUnit } from "../../utils/helpers/calculateNumberSocialUnit.helpers";
import { numberWithCommas } from "../../utils/helpers/numberWithCommas";
import StatsTab from "../global/stats-tab";

interface ISummary {
  influencerSelected: Array<Object>;
  setInfluencerSelected: Function;
}

function Summary(props: ISummary) {
  const { influencerSelected, setInfluencerSelected } = props;

  const styles = {
    btnSecondary: {
      backgroundColor: "secondary.main",
      paddingInline: "20px",
      borderColor: "secondary.main",
    },
    tabStyle: {
      borderRightWidth: "1px",
      borderRightStyle: "solid",
      borderRightColor: "grey.500",
      padding: 4,
    },
  };

  const getSum = (arr: Array<Object>, key: string) => {
    try {
      if (arr.length) {
        const sum = arr.reduce((acc: any, item: any) => {
          return acc + +item[key];
        }, 0);

        return sum;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  };

  const displayStats = (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item xl={2} sx={styles.tabStyle}>
        <StatsTab
          stats={
            calculateNumberSocialUnit(
              +getSum(influencerSelected, "followers")
            ) || "0"
          }
          label="Followers"
          icon="ico-hu-user-circle-solid-in"
        />
      </Grid>
      <Grid item sx={styles.tabStyle} xl={2}>
        <StatsTab stats="2.2M" label="Like" icon="ico-hu-like" />
      </Grid>
      <Grid item sx={styles.tabStyle} xl={2}>
        <StatsTab
          stats="856K"
          label="Comment"
          icon="ico-hu-comment-line-line"
        />
      </Grid>
      <Grid item sx={styles.tabStyle} xl={2}>
        <StatsTab stats="125K" label="Share" icon="ico-hu-share" />
      </Grid>
      <Grid item p={4} xl={4}>
        <StatsTab
          stats={numberWithCommas(
            +influencerSelected.reduce((acc: any, item: any) => {
              return acc + +item.rate_card[0].price.value;
            }, 0)
          )}
          total="700,000"
          label="Budget"
          icon="ico-hu-payment"
        />
      </Grid>
    </Grid>
  );

  return (
    <Card>
      <CardHeader
        title={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h6"
              color="common.black"
              fontWeight="500"
              display="flex"
              alignItems="center"
            >
              Estimation Summary
            </Typography>
            <Button
              variant="contained"
              endIcon={<ArrowCircleRightRoundedIcon />}
              sx={styles.btnSecondary}
            >
              Complete
            </Button>
          </Box>
        }
      />
      <CardContent>
        {displayStats}
        <hr />

        <InfluencerSelected
          influencerSelected={influencerSelected}
          setInfluencerSelected={setInfluencerSelected}
        />
      </CardContent>
    </Card>
  );
}

export default Summary;
