import React from "react";
import { Box, Icon, Typography, Button, Link } from "@mui/material";
import { useVertifyEmail as classes } from "./verifyEmail.styles";
import { useHistory } from "react-router-dom";

export default function VerifyEmailSuccess() {
  const history = useHistory();

  const backToHome = () => history.push("/");

  return (
    <div>
      <Box sx={classes.boxDialog}>
        <Box sx={classes.boxIconSuccess}>
          <Icon className="ico-hu-email-solid" sx={classes.mailIconFailed} />
        </Box>
        <Box sx={classes.boxContent}>
          <Typography variant="h4">Email Verification Success</Typography>
          <Typography
            variant="body1"
            sx={{ marginTop: "16px", fontWeight: "400" }}
          >
            You have successfully create a HashU account
          </Typography>
        </Box>
        <Box sx={classes.boxButton}>
          <Button
            onClick={() => {
              backToHome();
            }}
            sx={classes.resendButton}
          >
            Sign in
          </Button>
          <Link href={"/"} sx={classes.backHompage}>
            Back to Homepage
          </Link>
        </Box>
      </Box>
    </div>
  );
}
