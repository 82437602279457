import axios from "axios";
// Helper
import { getTokenFromStorage } from "./../../utils/helpers/getTokenFromStorage";
// Constans
import {
  API_URL,
  SOCIAL_API_KEY,
  API_SOCIAL_URL,
} from "../../utils/constants/api.constants";
// Types 
import { IuploadImageAPI, IBankListsAPI, IAddressAPI, IUploadImageAPI, IUploadImageRes, IBankAccountAPI } from './appInformation.types'

export function getBankLists(){
  return new Promise((resolve:(value:IBankListsAPI[]) => void , rejects) => {
    const { access_token } = getTokenFromStorage()
    const header = { Authorization: `Bearer ${access_token}`}
    axios
      .get(`${API_URL}/info/banks`, {headers:header})
      .then((response) => {
        if(response.data.status_code === 200){
          resolve(response.data.result.data)
          console.log('get bank lists success')
        }else{
          console.log('There are somthing wrong')
        }
      })
      .catch((error) => {
        console.log('There are somthing wrong')
      })
  })
}

export function uploadImage(data:IUploadImageAPI){
  return new Promise((resolve: (value: IUploadImageRes) => void, reject) => {
    const { access_token } = getTokenFromStorage()
    const config = {
      headers:{
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data"
      }
    }
    let formData = new FormData()
    formData.append("file", data.file)
    formData.append("destination", data.destination)
    formData.append("permission", data.permission)
    axios
      .post(`${API_URL}/files/upload`, formData, config)
      .then((response) => {
        if(response.data.status_code === 201){
          resolve(response.data.result.data)
          console.log("Data",response.data)
        }
      })
      .catch((error) => {
        reject(error.response.data)
        console.log(error.response.data)
      })
  })
}

export function editBankAccountAPI(data:{bank_account: IBankAccountAPI}){
  return new Promise((resolve:(value: unknown) => void,reject) => {
    const { access_token, session_token } = getTokenFromStorage()
    const config = {
      headers:{
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    }
    axios
      .patch(`${API_URL}/influencers/${session_token?.influencer_id}`, data, config)
      .then((response) => {
        if(response.data.status_code === 200){
          resolve(response.data.result.data)
          console.log('Edit Bank success');
        }else{
          console.log('Edit Bank Error');
        }
      })
      .catch((error) => {
        reject(error.response.data)
        console.log('Edit Bank Error');
      })
  })
}