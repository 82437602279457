import { green, lightBlue, orange, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const hWhite = { 100: "#FFFFFF", 200: "#f5f6f8" };
export const hBlack = { 100: "#000000", 200: "#231F20" };
export const hPurple = {
  100: "#EBDFFF",
  200: "#8C499C",
  300: "#542485",
  400: "#893DBD",
};
export const hPink = {
  100: "#FFD4ED",
  200: "#FB79C3",
  300: "#ED1968",
  400: "#E8008A",
};
export const hNavyBlue = { 100: "#4A489D" };
export const hYellow = { 100: "#FBDD4F", 300: "#FFD54F", 400: "#FFD932" };
export const hColorful = { 100: hPink[300], 200: hPurple[200] };
export const hAlertColor = {
  success: green[500],
  error: red[500],
  warning: orange[600],
  info: lightBlue[800],
  question: hPurple[200],
};
export const hGrey = {
  100: "#E0E0E0",
  200: "#D8D8D8",
  300: "#a2a2a2",
  400: "#848484",
  500: "#9E9E9E",
  600: "#E5E5E5",
  700: "#757575",
  800: "#616161",
};
export const hSocialColor = {
  facebook: "#1A77F1",
  instagram: hPink[300],
  youtube: "#FF0000",
  twitter: "#1AA1F1 ",
  tiktok: hBlack[100],
  line: green["A700"],
};

const theme = createTheme({
  palette: {
    primary: {
      main: hPurple[200],
      "100": hPurple[100],
      "300": hPurple[300],
      contrastText: hWhite[100],
    },
    secondary: {
      main: hPink[300],
      "100": hPink[100],
      contrastText: hWhite[100],
    },
    success: {
      main: hAlertColor.success,
    },
    error: {
      main: hAlertColor.error,
    },
    warning: {
      main: hAlertColor.warning,
    },
    info: {
      main: lightBlue[800],
      A100: hNavyBlue[100],
    },
    background: {
      default: "#F5F6F8",
    },
    grey: {
      100: hGrey[100],
      200: hGrey[200],
      300: hGrey[300],
      400: hGrey[400],
      500: hGrey[500],
      600: hGrey[600],
      700: hGrey[700],
      800: hGrey[800],
    },
    common: {
      black: hBlack[100],
      white: hWhite[100],
    },
  },
  typography: {
    fontFamily: "Kanit",
    button: {
      textTransform: "none",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  shape: {
    borderRadius: 20,
  },
  spacing: 4,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "50px",
          border: "2px solid",
          "&:hover": {
            border: "2px solid",
          },
          "&.Mui-disabled": {
            color: hWhite[100],
            backgroundColor: hGrey[500],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "40px",
          fontSize: "0.875rem",
          backgroundColor: hWhite[100],
        },
        input: {
          "&::-webkit-input-placeholder": {
            color: hGrey[300],
            opacity: 1,
          },
          "&:-ms-input-placeholder": {
            color: hGrey[300],
            opacity: 1,
          },
          "&::placeholder": {
            color: hGrey[300],
            opacity: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          root: {
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          top: "-2px",
          "& .MuiPaper-root": {
            borderRadius: "0 0 20px 20px",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          height: "34px",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          "&.MuiAvatar-rounded": {
            borderRadius: "4px",
          },
        },
      },
    },
  },
});

export default theme;
