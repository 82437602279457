import { styled } from "@mui/material/styles";
import { hGrey } from "../../theme";
import { Box } from "@mui/material";

export const useSidebarStyle = {
  sideBar: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "block",
    width: "90px",
    minHeight: "560px",
    height: "100%",
    backgroundColor: "common.white",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px 50px 50px 0px;",
    zIndex: 1111,
  },
  logoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    height: "104px",
    borderBottom: "2px solid",
    borderColor: "grey.100",
  },
  menuBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "112px",
    borderBottom: "2px solid",
    borderColor: "blue",
  },
  iconSideBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "10px",
    marginBottom: "4px",
    marginTop: "4px",
    fontSize: "24px",
    color: "common.white",
    cursor: "pointer",
  },
  line: {
    width: "100%",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: `1px solid ${hGrey[100]}`,
    margin: "8px 0",
  },
};

export const MenusBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "24px 0px 12px 24px",
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "112px",
    borderBottom: "2px solid",
    borderColor: "#e0e0e0",
  },
}));

export const IconsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    borderRadius: "10px",
    fontSize: "24px",
    color: "white",
    cursor: "pointer",
    marginRight: "12px",
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "10px",
    marginBottom: "4px",
    marginTop: "4px",
    fontSize: "24px",
    color: "white",
    cursor: "pointer",
  },
}));
