import React from "react";
// Library
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Component
import DialogAuthentication from "../global/dialog-authentication";
import TextFieldCustom from "../global/text-field";
// Types
import {
  IForgetPasswordDialogProps,
  IForgetPasswordSchema,
} from "./AuthenDialog.types";
// Schema
import { ForgetPasswordSchema } from "./AuthenticationForm.schema";
// Styles
import { useSignInDialogStyle as classes } from "./authentication.styles";
// Material
import {
  DialogContent,
  Stack,
  InputAdornment,
  Icon,
  Button,
  Typography,
  Divider,
} from "@mui/material";

const initForgetPassword: IForgetPasswordSchema = {
  email: "",
};

function ForgetPasswordDialog(props: IForgetPasswordDialogProps) {
  const { isOpened, setIsOpenForgetPassword, openResetPassword, openSignIn } =
    props;
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<IForgetPasswordSchema>({
    resolver: yupResolver(ForgetPasswordSchema),
    defaultValues: initForgetPassword,
  });

  const handleClose = () => {
    setIsOpenForgetPassword(false);
  };
  const onSubmit = async (value: IForgetPasswordSchema) => {
    console.log("submit: ", value);
  };
  const handleChange = (e: any, email: keyof IForgetPasswordSchema) => {
    setValue(email, e.target.value);
    clearErrors(email);
  };

  return (
    <DialogAuthentication
      title="Forgot Password?"
      open={isOpened}
      onClose={handleClose}
      sx={classes.authenDialog}
    >
      <DialogContent sx={classes.dialogContent}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          gap={5}
          py={1}
          px={5}
        >
          <Typography variant="body1" color="grey.700" align="center">
            Please enter your email address below <br />
            The system will send you a new password <br />
            through your email.
          </Typography>
          <TextFieldCustom
            value={getValues("email")}
            onChange={(e) => handleChange(e, "email")}
            variant="outlined"
            placeholder="Email"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="ico-hu-email-solid"
                    sx={{ ...classes.textFieldIcon, paddingRight: "2px" }}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Button onClick={() => openResetPassword()} type="submit" fullWidth>
            <Typography variant="body1" fontWeight="bold">
              Reset Password
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
      <Divider sx={classes.divider} />
      <DialogContent sx={classes.dialogContent}>
        <Stack>
          <Typography variant="body1" align="center">
            Already have an account?
          </Typography>
          <Typography
            color="primary"
            variant="body1"
            fontWeight="bold"
            align="center"
            onClick={() => openSignIn()}
            sx={classes.createAccountLink}
          >
            Sign In
          </Typography>
        </Stack>
      </DialogContent>
    </DialogAuthentication>
  );
}

export default ForgetPasswordDialog;
