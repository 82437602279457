import React from "react";
import Routes from "./routes";
import { ThemeProvider } from "@mui/material";
import Theme from "./theme";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
