import React, { useEffect, useState } from "react";
// Library
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Component
import TextFieldCustom from "../../global/text-field/index";
import BasicSelectCustom from "../../global/basic-select";
import { SaveButton } from "../../../views/my-profile/MyProfile.styles";
// Types
import {
  IProvince,
  IDistrict,
  IBillingAddressFormSchema,
  ISubdistrict,
  IBillingAddressSectionProps,
  IBillingAddressForm,
} from "../InfluencerAddress.types";
// Utils
import { useEditAddressDialogStyle as classes } from "../InfluencerAddress.styles";
import { BillingAddressFormSchema } from "../InfluencerAddressForm.schema";
// API
import {
  getAddressLists,
  editInfluencerShippingAPI,
  editInfluencerBillingAPI,
} from "../../../api/influencer/InfluencerAPI";
// Material
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// Icons
import {
  SaveIcon,
  AddressBillingIcon,
} from "../../../assets/icons/social/social";
// Globall
import SwalCustom from "../../global/sweet-alert-custom/SwalCustom";
import { formatTaxId } from "../../global/format-number";

const initBillingAddressForm: IBillingAddressForm = {
  fullname: "",
  tax_id: "",
  phone: "",
  address: "",
  postcode: "",
  province: null,
  district: null,
  subdistrict: null,
};

export function BillingAddressSection({
  address,
  billingAddressSubmitHandler,
  closeDialog,
}: IBillingAddressSectionProps) {
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<IBillingAddressFormSchema>({
    resolver: yupResolver(BillingAddressFormSchema),
    defaultValues: initBillingAddressForm,
  });

  const [provincesList, setProvincesList] = useState<IProvince[]>([]);
  const [districtsList, setDistrictsList] = useState<IDistrict[]>([]);
  const [subdistrictsList, setSubdistrictsList] = useState<ISubdistrict[]>([]);

  async function getAddress() {
    try {
      const localAddress = localStorage.getItem("address");
      if (localAddress === null) {
        const result = await getAddressLists();
        if (result) {
          const addressList = JSON.stringify(result);
          localStorage.setItem("address", addressList);
          console.log("Get Address Success", result);
        }
      } else {
        return undefined;
      }
    } catch (error) {
      console.log("Get Address Error");
    }
  }

  useEffect(() => {
    async function addressLists() {
      await getAddress();
      const localAddress = localStorage.getItem("address");
      if (localAddress !== null) {
        const address = JSON.parse(localStorage.address);
        const provinceList = address.province.map((province: IProvince) => ({
          id: province.id,
          name_en: province.name_en,
          name_th: province.name_th,
          abbreviation_en: province.abbreviation_en,
          abbreviation_th: province.abbreviation_th,
        }));
        setProvincesList(provinceList);
      }
    }
    addressLists();
  }, []);

  useEffect(() => {
    if (address) {
      const form = {
        fullname: address.fullname,
        tax_id: address.tax_id,
        phone: address.phone,
        address: address.address,
        postcode: address.postcode,
        province: address.province,
        district: address.district,
        subdistrict: address.subdistrict,
      };
      reset(form);
      if (address.district !== null) {
        setDistrictsList([{ ...address.district }]);
      }
      if (address.subdistrict !== null) {
        setSubdistrictsList([{ ...address.subdistrict }]);
      }
    }
  }, [address]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IBillingAddressForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const provinceHandleChange = async (e: { target: { value: number } }) => {
    const provinceId = e.target.value;
    const provinceSelect = provincesList.find(
      (province) => province.id === provinceId
    );
    setValue("province", provinceSelect || null);
    setValue("district", null);
    setValue("subdistrict", null);
    setValue("postcode", "");
    clearErrors("province");
    clearErrors("district");
    clearErrors("subdistrict");
    clearErrors("postcode");

    const address = JSON.parse(localStorage.address);
    const districts = address.district.filter(
      (district: { province_id: number }) => district.province_id === provinceId
    );

    const districtList = districts.map((district: IDistrict) => ({
      id: district.id,
      province_id: district.province_id,
      name_en: district.name_en,
      name_th: district.name_th,
    }));
    setDistrictsList(districtList);
  };

  const districtHandleChange = async (e: { target: { value: number } }) => {
    const districtId = e.target.value;
    const districtSelect = districtsList.find(
      (district) => district.id === districtId
    );
    setValue("district", districtSelect || null);
    setValue("subdistrict", null);
    setValue("postcode", "");
    clearErrors("district");
    clearErrors("subdistrict");
    clearErrors("postcode");

    const address = JSON.parse(localStorage.address);
    const subdistrict = address.subdistrict.filter(
      (subdistrict: { district_id: number }) =>
        subdistrict.district_id === districtId
    );

    const subdistrictList = subdistrict.map((subdistrict: ISubdistrict) => ({
      id: subdistrict.id,
      province_id: subdistrict.province_id,
      district_id: subdistrict.district_id,
      postcode: subdistrict.postcode,
      name_en: subdistrict.name_en,
      name_th: subdistrict.name_th,
    }));
    setSubdistrictsList(subdistrictList);
  };

  const subdistrictHandleChange = async (e: { target: { value: number } }) => {
    const subdistrictId = e.target.value;
    const address = JSON.parse(localStorage.address);
    const subdistrictSelect = address.subdistrict.find(
      (subdistrict: { id: number }) => subdistrict.id === subdistrictId
    );
    setValue("subdistrict", subdistrictSelect || null);
    setValue("postcode", subdistrictSelect.postcode);
    clearErrors("subdistrict");
    clearErrors("postcode");
  };

  setValue("tax_id", getValues("tax_id").split("-").join(""));

  const onSubmit = async (value: IBillingAddressForm) => {
    const data = {
      address: {
        billing_address: {
          fullname: value.fullname,
          phone: address?.phone as string,
          address: value.address,
          postcode: value.postcode,
          province_id: value.province?.id as number,
          district_id: value.district?.id as number,
          subdistrict_id: value.subdistrict?.id as number,
          tax_id: value.tax_id,
        },
      },
    };

    try {
      const result = await editInfluencerBillingAPI(data);
      await SwalCustom.fire({
        icon: "success",
        title: "Updated billing address success",
        showConfirmButton: false,
        timer: 1600,
      });
      closeDialog();
    } catch (error: unknown) {
      await SwalCustom.fire({
        icon: "error",
        title: "Opps !",
        text: "Error, There are something when wrong",
        showConfirmButton: true,
        timer: 1600,
      });
    }
    billingAddressSubmitHandler(value);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      m={0}
      p={0}
    >
      <DialogContent sx={classes.dialogContent}>
        <Box px={[0, 2]} py={0}>
          <Box display="flex" alignItems="center" gap={1}>
            <AddressBillingIcon color="primary" fontSize="small" />
            <Typography variant="body1" fontWeight="bold">
              Billing Address
            </Typography>
          </Box>
          <Stack px={[1.5, 5]} pt={3}>
            {/* Full Name */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Full Name<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("fullname")}
                  onChange={(e) => handleChange(e, "fullname")}
                  variant="outlined"
                  placeholder="Full Name"
                  fullWidth
                  error={!!errors.fullname}
                  helperText={errors.fullname?.message}
                />
              </Grid>
            </Grid>
            {/* Tax ID */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} xl={2}>
                <Typography variant="body2">
                  Tax ID / Citizen ID<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={formatTaxId(getValues("tax_id"))}
                  onChange={(e) => handleChange(e, "tax_id")}
                  variant="outlined"
                  placeholder="Tax ID / Citizen ID"
                  fullWidth
                  error={!!errors.tax_id}
                  helperText={errors.tax_id?.message}
                />
              </Grid>
            </Grid>
            {/* Province */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Province<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <BasicSelectCustom
                  id="address-province-select-1"
                  value={getValues("province.id")}
                  onChange={provinceHandleChange}
                  data={provincesList}
                  valueKey="id"
                  labelKey="name_th"
                  placeholder="Province"
                  fullWidth
                  error={!!errors.province}
                  helperText={(errors.province as any)?.message}
                />
              </Grid>
            </Grid>
            {/* District */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  District<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <BasicSelectCustom
                  id="address-district-select-1"
                  value={getValues("district.id")}
                  onChange={districtHandleChange}
                  data={districtsList}
                  valueKey="id"
                  labelKey="name_th"
                  placeholder="District"
                  fullWidth
                  error={!!errors.district}
                  helperText={(errors.district as any)?.message}
                  disabled={
                    getValues("province") === null ||
                    getValues("district.id") !== null
                  }
                />
              </Grid>
            </Grid>
            {/* Sub District */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Subdistrict<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <BasicSelectCustom
                  id="address-sub-district-select-1"
                  value={getValues("subdistrict.id")}
                  onChange={subdistrictHandleChange}
                  data={subdistrictsList}
                  valueKey="id"
                  labelKey="name_th"
                  placeholder="Subdistrict"
                  fullWidth
                  error={!!errors.subdistrict}
                  helperText={(errors.subdistrict as any)?.message}
                  disabled={
                    getValues("district") === null ||
                    getValues("subdistrict.id") !== null
                  }
                />
              </Grid>
            </Grid>
            {/* Zipcode */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Zipcode<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("postcode")}
                  onChange={(e) => handleChange(e, "postcode")}
                  variant="outlined"
                  placeholder="Zipcode"
                  fullWidth
                  error={!!errors.postcode}
                  helperText={errors.postcode?.message}
                  disabled
                />
              </Grid>
            </Grid>
            {/* Address */}
            <Grid container pb={0}>
              <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Address<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("address")}
                  onChange={(e) => handleChange(e, "address")}
                  variant="outlined"
                  multiline
                  rows={2}
                  placeholder="Address"
                  fullWidth
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginTop: 2,
          paddingBottom: 5,
        }}
      >
        <SaveButton
          type="submit"
          variant="contained"
          startIcon={<SaveIcon sx={{ width: "14.4px", height: "14.4px" }} />}
          disabled={
            !getValues("fullname") ||
            !getValues("tax_id") ||
            !getValues("province.id") ||
            !getValues("district.id") ||
            !getValues("subdistrict.id") ||
            !getValues("postcode") ||
            !getValues("address")
              ? true
              : false
          }
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
      </DialogActions>
    </Box>
  );
}
