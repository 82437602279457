import axios from "axios";
// ENV
import {
  API_URL,
  API_KEY,
  CLIENT_ID,
  CLIENT_SECRET,
} from "../../utils/constants/api.constants";
// Types
import {
  ICreateInfluencerBody,
  ICreateInfluencerRes,
  ISignInBody,
  ISignInRes,
  IVerifyEmailParams,
  IRefreshTokenBody,
} from "./Types";

import qs from "qs";
import { getTokenFromStorage } from "./../../utils/helpers/getTokenFromStorage";

export function createInfluencer(data: ICreateInfluencerBody) {
  return new Promise(
    (resolve: (value: ICreateInfluencerRes) => void, reject) => {
      const header = {
        "X-Api-Key": API_KEY,
      };
      axios
        .post(`${API_URL}/influencers`, data, { headers: header })
        .then((response) => {
          const data = response.data;
          if (data.status_code === 201) {
            resolve(data.result.data);
          } else {
            reject("something when wrong");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
}

export function signInWithAPI(body: ISignInBody) {
  return new Promise((resolve: (value: ISignInRes) => void, reject) => {
    const header = { "X-Api-Key": API_KEY };
    const data = {
      grant_type: "password",
      username: body.email,
      password: body.password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };
    axios
      .post(`${API_URL}/oauth/token`, data, {
        headers: header,
      })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signOutWithAPI() {
  return new Promise((resolve: (value: null) => void, reject) => {
    const { access_token, refresh_token } = getTokenFromStorage();
    const header = { Authorization: `Bearer ${access_token}` };
    const data = {
      token: refresh_token,
      token_type_hint: "access_token",
    };
    axios
      .post(`${API_URL}/oauth/revoke`, data, {
        headers: header,
      })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch(async (error) => {
        reject(error);
      });
  });
}

export function refreshTokenWithAPI(body: IRefreshTokenBody) {
  return new Promise((resolve: (value: ISignInRes) => void, reject) => {
    const header = { "X-Api-Key": API_KEY };
    const data = {
      grant_type: "refresh_token",
      refresh_token: body.refresh_token,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };
    axios
      .post(`${API_URL}/oauth/token`, data, { headers: header })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function verifyEmailWithAPI(params: IVerifyEmailParams) {
  return new Promise((resolve: (value: string) => void, reject) => {
    const header = {
      "X-Api-Key": API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    var data = qs.stringify({
      email: params.email,
      grant_type: "verify",
    });

    axios
      .post(`${API_URL}/oauth/email_verify`, data, {
        headers: header,
      })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resendVerifyEmailWithAPI(params: IVerifyEmailParams) {
  return new Promise((resolve: (value: string) => void, reject) => {
    const header = {
      "X-Api-Key": API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    var data = qs.stringify({
      email: params.email,
      grant_type: "resend",
    });
    axios
      .post(`${API_URL}/oauth/email_verify`, data, {
        headers: header,
      })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
