import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getAddressLists } from "../../../../api/influencer/InfluencerAPI";
import {
  AddressShippingIcon,
  SaveIcon,
} from "../../../../assets/icons/social/social";
import { SaveButton } from "../../../../views/my-profile/MyProfile.styles";
import BasicSelectCustom from "../../../global/basic-select";
import DialogScrollingCustom from "../../../global/dialog-scrolling-custom";
import { formatTaxId } from "../../../global/format-number";
import TextFieldCustom from "../../../global/text-field";
import { useEditProfileDialogStyle as classes } from "../../../influencer-social/myprofile-tab/MyProfileTab.style";
import {
  IDistrict,
  IProvince,
  IShippingAddressForm,
  ISubdistrict,
  IShippingAddressFormSchema,
  IBillingAddressForm,
} from "./EditEmployerForm";

const initBillingAddressForm: IBillingAddressForm = {
  fullname: "",
  phone: "",
  province: null,
  district: null,
  subdistrict: null,
  postcode: "",
  address: "",
  tax_id: "",
};

export default function EditBilling(props: any) {
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<IShippingAddressFormSchema>({
    // resolver: yupResolver(BillingAddressFormSchema),
    defaultValues: initBillingAddressForm,
  });
  const { data, isOpened, onClose, onSubmit } = props;

  const [provincesList, setProvincesList] = useState<IProvince[]>([]);
  const [districtsList, setDistrictsList] = useState<IDistrict[]>([]);
  const [subdistrictsList, setSubdistrictsList] = useState<ISubdistrict[]>([]);

  async function getAddress() {
    try {
      const localAddress = localStorage.getItem("address");
      if (localAddress === null) {
        const result = await getAddressLists();
        if (result) {
          const addressList = JSON.stringify(result);
          localStorage.setItem("address", addressList);
          console.log("Get Address success");
        }
      } else {
        return undefined;
      }
    } catch (error) {
      console.log("Get Address Error");
    }
  }

  useEffect(() => {
    async function addressLists() {
      await getAddress();
      const localAddress = localStorage.getItem("address");
      if (localAddress !== null) {
        const address = JSON.parse(localStorage.address);
        const provinceList = address.province.map((province: IProvince) => ({
          id: province.id,
          name_en: province.name_en,
          name_th: province.name_th,
          abbreviation_en: province.abbreviation_en,
          abbreviation_th: province.abbreviation_th,
        }));
        setProvincesList(provinceList);
      }
    }
    addressLists();
  }, []);

  useEffect(() => {
    if (data) {
      const form = {
        fullname: data.fullname,
        tax_id: data.tax_id,
        phone: data.phone,
        address: data.address,
        postcode: data.postcode,
        province: data.province,
        district: data.district,
        subdistrict: data.subdistrict,
      };
      reset(form);
      if (data.district !== null) {
        setDistrictsList([{ ...data.district }]);
      }
      if (data.subdistrict !== null) {
        setSubdistrictsList([{ ...data.subdistrict }]);
      }
    }
  }, [data, reset]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IBillingAddressForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const provinceHandleChange = async (e: { target: { value: number } }) => {
    const provinceId = e.target.value;
    const provinceSelect = provincesList.find(
      (province) => province.id === provinceId
    );
    setValue("province", provinceSelect || null);
    setValue("district", null);
    setValue("subdistrict", null);
    setValue("postcode", "");
    clearErrors("province");
    clearErrors("district");
    clearErrors("subdistrict");
    clearErrors("postcode");

    const address = JSON.parse(localStorage.address);
    const districts = address.district.filter(
      (district: { province_id: number }) => district.province_id === provinceId
    );

    const districtList = districts.map((district: IDistrict) => ({
      id: district.id,
      province_id: district.province_id,
      name_en: district.name_en,
      name_th: district.name_th,
    }));
    setDistrictsList(districtList);
  };

  const districtHandleChange = async (e: { target: { value: number } }) => {
    const districtId = e.target.value;
    const districtSelect = districtsList.find(
      (district) => district.id === districtId
    );
    setValue("district", districtSelect || null);
    setValue("subdistrict", null);
    setValue("postcode", "");
    clearErrors("district");
    clearErrors("subdistrict");
    clearErrors("postcode");

    const address = JSON.parse(localStorage.address);
    const subdistrict = address.subdistrict.filter(
      (subdistrict: { district_id: number }) =>
        subdistrict.district_id === districtId
    );

    const subdistrictList = subdistrict.map((subdistrict: ISubdistrict) => ({
      id: subdistrict.id,
      province_id: subdistrict.province_id,
      district_id: subdistrict.district_id,
      postcode: subdistrict.postcode,
      name_en: subdistrict.name_en,
      name_th: subdistrict.name_th,
    }));
    setSubdistrictsList(subdistrictList);
  };

  const subdistrictHandleChange = async (e: { target: { value: number } }) => {
    const subdistrictId = e.target.value;
    const address = JSON.parse(localStorage.address);
    const subdistrictSelect = address.subdistrict.find(
      (subdistrict: { id: number }) => subdistrict.id === subdistrictId
    );
    setValue("subdistrict", subdistrictSelect || null);
    setValue("postcode", subdistrictSelect.postcode);
    clearErrors("subdistrict");
    clearErrors("postcode");
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <DialogScrollingCustom
      title="Address"
      open={isOpened}
      onClose={handleClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={false}
      sx={classes.dialog}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={classes.dialogContent}>
          <Box px={2} py={0}>
            <Box display="flex" alignItems="center" gap={1}>
              <AddressShippingIcon color="primary" fontSize="small" />
              <Typography variant="body1" fontWeight="bold">
                Shipping Address
              </Typography>
            </Box>
            <Stack px={5} pt={3}>
              {/* Full Name */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Full Name<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={getValues("fullname")}
                    onChange={(e) => handleChange(e, "fullname")}
                    variant="outlined"
                    placeholder="Full Name"
                    fullWidth
                    //   error={!!errors.fullname}
                    //   helperText={errors.fullname?.message}
                  />
                </Grid>
              </Grid>
              {/* Phone */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Tax ID<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={formatTaxId(getValues("tax_id"))}
                    onChange={(e) => handleChange(e, "tax_id")}
                    variant="outlined"
                    placeholder="Phone"
                    fullWidth
                    //   error={!!errors.phone}
                    //   helperText={errors.phone?.message}
                  />
                </Grid>
              </Grid>
              {/* Province */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Province<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <BasicSelectCustom
                    id="address-province-select-1"
                    value={getValues("province.id")}
                    onChange={provinceHandleChange}
                    onClick={getAddressLists}
                    data={provincesList}
                    valueKey="id"
                    labelKey="name_th"
                    placeholder="Province"
                    fullWidth
                    error={!!errors.province}
                    helperText={(errors.province as any)?.message}
                  />
                </Grid>
              </Grid>
              {/* District */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    District<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <BasicSelectCustom
                    id="address-district-select-1"
                    value={getValues("district.id")}
                    onChange={districtHandleChange}
                    data={districtsList}
                    valueKey="id"
                    labelKey="name_th"
                    placeholder="District"
                    fullWidth
                    error={!!errors.district}
                    helperText={(errors.district as any)?.message}
                    disabled={
                      getValues("province") === null ||
                      getValues("district.id") !== null
                    }
                  />
                </Grid>
              </Grid>
              {/* Sub District */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Subdistrict<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <BasicSelectCustom
                    id="address-sub-district-select-1"
                    value={getValues("subdistrict.id")}
                    onChange={subdistrictHandleChange}
                    data={subdistrictsList}
                    valueKey="id"
                    labelKey="name_th"
                    placeholder="Subdistrict"
                    fullWidth
                    error={!!errors.subdistrict}
                    helperText={(errors.subdistrict as any)?.message}
                    disabled={
                      getValues("district") === null ||
                      getValues("subdistrict.id") !== null
                    }
                  />
                </Grid>
              </Grid>
              {/* Zipcode */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Zipcode<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={getValues("postcode")}
                    onChange={(e) => handleChange(e, "postcode")}
                    variant="outlined"
                    placeholder="Zipcode"
                    fullWidth
                    //   error={!!errors.postcode}
                    //   helperText={errors.postcode?.message}
                    disabled
                  />
                </Grid>
              </Grid>
              {/* Address */}
              <Grid container pb={0}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Address<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={getValues("address")}
                    onChange={(e) => handleChange(e, "address")}
                    variant="outlined"
                    multiline
                    rows={2}
                    placeholder="Address"
                    fullWidth
                    //   error={!!errors.address}
                    //   helperText={errors.address?.message}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
            marginTop: 2,
            paddingBottom: 5,
          }}
        >
          <SaveButton
            type="submit"
            variant="contained"
            startIcon={<SaveIcon sx={{ width: "14.4px", height: "14.4px" }} />}
          >
            <Typography variant="button">Save</Typography>
          </SaveButton>
        </DialogActions>
      </form>
    </DialogScrollingCustom>
  );
}
