import { hColorful } from "../../theme";

export const HomePricingStyle = {
  contentMain: {
    display: "flex",
    justifyContent: "center",
    padding: "50px",
    background: `linear-gradient(296.45deg, #f3eefe -3.27%, #f5ebef 102.18%)`,
  },
  FAQImage: {
    aspectRatio: "1 / 1" as any,
    objectFit: "cover" as any,
  },
  contactUsButton: {
    width: "200px",
    border: "unset",
    background: `linear-gradient(296.45deg, ${hColorful[100]} -3.27%, ${hColorful[200]} 102.18%)`,
    "&:hover": {
      border: "unset",
    },
  },
};
