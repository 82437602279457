export const useCampaignDetailOverviewStyle = {
  productDetail: {
    display: "flex",
    "& .css-urvyfo-MuiAvatar-root": {
      width: "150px",
      height: "150px",
    },
    "& .css-urvyfo-MuiAvatar-root:first-of-type": {
      borderRadius: "8px 0 0 8px",
    },
    "& .css-urvyfo-MuiAvatar-root:last-of-type": {
      borderRadius: "0 8px 8px 0",
    },
  },
  ProdDesc: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "7px",
      mx: "10px",
    },
  },
};

export const useTimelineDeatailStyle = {
  leftCon: {
    borderLeft: "4px solid #F5F6F8",
    position: "absolute",
    height: "400px",
    top: "40px",
    left: "63px",
    zIndex: 1,
  },
  leftConButton: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    bgcolor: "#8C499C",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 10,
    "& svg": {
      fontSize: "20px",
      color: "#fff",
      zIndex: 9,
    },
  },
  leftConButton2: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    bgcolor: " #F5F6F8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    "& svg": {
      fontSize: "20px",
      color: "#5C5C5C",
    },
  },
};

export const useCampaignDetailBriefStyle = {
  contentType: {
    display: "flex",
    "& .css-urvyfo-MuiAvatar-root": {
      width: "150px",
      height: "150px",
      borderRadius: 0,
    },
    "& .css-urvyfo-MuiAvatar-root:first-of-type": {
      borderRadius: "8px 0 0 8px",
    },
    "& .css-urvyfo-MuiAvatar-root:last-of-type": {
      borderRadius: "0 8px 8px 0",
    },
  },
  moodAndtoneImg: {
    width: "150px",
    height: "150px",
    borderRadius: "8px",
    mt: "5px",
  },
  hashtag: {
    ml: "-10px",
    cursor: "auto",
    maxHeight: "32px",
  },
  do: {
    bgcolor: "rgba(119, 221, 119, 0.1)",
    padding: "16px",
    borderRadius: "12px",
    "& p:first-of-type": {
      fontWeight: "500",
      color: "#4CAF50",
    },
  },
  dont: {
    bgcolor: "rgba(255, 132, 132, 0.1)",
    padding: "16px",
    borderRadius: "12px",
    "& p:first-of-type": {
      fontWeight: "500",
      color: "#F44336",
    },
  },
};

export const useBudgetAndRewardDetailStyle = {
  compensation: {
    border: "none",
    cursor: "auto",
    "&:hover": { border: "none" },
    ml: "-10px",
  },
  productAndServiceCon: {
    border: "1px solid #8C499C",
    borderRadius: "8px",
    height: "88px",
    width: "296px",
    display: "flex",
    alignItems: "center",
    "& .css-urvyfo-MuiAvatar-root": {
      borderRadius: "4px",
      width: "64px",
      height: "64px",
      ml: "12px",
    },
  },
  productAndService: {
    ml: "8px",
    "& p:first-of-type": {
      mb: "5px",
    },
    "& p:last-of-type": {
      color: "#5C5C5C",
      fontSize: "12px",
    },
  },
};

export const useTermsAndConditionsDetailStyle = {
  formGroup: {
    "& .css-167pxul-MuiButtonBase-root-MuiCheckbox-root": {
      padding: "0 10px 0 10px",
      my: "20px",
    },
  },
};

export const useTermsAndConditionsDialogStyle = {
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "96px",
    borderBottom: "1px solid #EBDFFF",
    "& p:first-of-type": {
      fontSize: "20px",
      fontWeight: "500",
      color: "#8C499C",
      mb: "4px",
    },
    "& p:last-of-type": {
      color: "#5C5C5C",
      fontSize: "14px",
    },
  },
  dialogContent: {
    mt: "15px",
    borderRadius: "12px",
    padding: "8px 8px 0 8px",
  },
  divider: {
    position: "absolute",
    top: 0,
    left: "20px",
    height: "1000px",
    zIndex: -1,
    mt: 1,
  },
  cardContainer: {
    margin: "24px",
    borderRadius: "12px",
    padding: "8px 8px 0 8px",
  },
  cardHeader: {
    "& p:first-of-type": {
      fontSize: "20px",
      color: "#231F20",
      fontWeight: "500",
    },
    "& p:last-of-type": {
      fontSize: "14px",
      color: "#5C5C5C",
    },
  },
  timelineLeft: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    border: "3px solid #EBDFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#fff",
  },
  timelineRigthBox: {
    border: "1.5px solid #EBDFFF",
    borderRadius: "8px",
    height: "112px",
    padding: "16px",
  },
  openDate: {
    "& p:not(:nth-of-type(2))": {
      bgcolor: "#F5F6F8",
      width: "200px",
      height: "32px",
      borderRadius: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  selectInfluDate: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "6px",
      ml: "10px",
      mr: "10px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  selectInfluAndAnnounce: {
    fontSize: "14px",
    ml: "10px",
    "& ul li:first-of-type": {
      marginLeft: "-22px",
    },
  },
  timelineDevider: {
    position: "absolute",
    top: 0,
    left: "20px",
    height: "1050px",
    zIndex: -1,
    mt: 1,
  },
  announceDate: {
    alignItems: "center",
    "& svg:first-of-type": {
      fontSize: "6px",
      ml: "10px",
      mr: "2px",
    },
    "p:first-of-type": {
      fontSize: "14px",
    },
  },
  selectCampParticipants: {
    alignItems: "center",
    "& svg": {
      fontSize: "6px",
      ml: "10px",
      mr: "2px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  closeDate: {
    width: "48%",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  },
  text: {
    fontSize: "14px",
    ml: "10px",
    "& ul li:first-of-type": {
      marginLeft: "-22px",
    },
  },
  acceptCondition: {
    display: "flex",
    justifyContent: "center",
    "& button:first-of-type": {
      border: "none",
      "&:hover": { border: "none" },
      width: "277px",
      maxHeight: "40px",
    },
    "& button:last-of-type": {
      width: "277px",
      maxHeight: "40px",
      ml: "15px",
    },
  },
};

export const useTimelineDialogStyle = {
  dialogTitle: {
    ...useTermsAndConditionsDialogStyle.dialogTitle,
  },
  dialogContent: {
    ...useTermsAndConditionsDialogStyle.dialogContent,
  },
  divider: {
    ...useTermsAndConditionsDialogStyle.divider,
  },
  timelineLeft: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    border: "3px solid #EBDFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#fff",
  },
  timelineRigthBox: {
    border: "1.5px solid #EBDFFF",
    borderRadius: "8px",
    height: "112px",
    padding: "16px",
  },
  openDate: {
    ...useTermsAndConditionsDialogStyle.openDate,
  },
  selectInfluDate: {
    ...useTermsAndConditionsDialogStyle.selectInfluDate,
  },
  selectInfluAndAnnounce: {
    ...useTermsAndConditionsDialogStyle.selectInfluAndAnnounce,
  },
  timelineDevider: {
    position: "absolute",
    top: 0,
    left: "20px",
    height: "1050px",
    zIndex: -1,
    mt: 1,
  },
  announceDate: {
    alignItems: "center",
    "& svg:first-of-type": {
      fontSize: "6px",
      ml: "10px",
      mr: "2px",
    },
    "p:first-of-type": {
      fontSize: "14px",
    },
  },
  selectCampParticipants: {
    alignItems: "center",
    "& svg": {
      fontSize: "6px",
      ml: "10px",
      mr: "2px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  closeDate: {
    width: "48%",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  },
  text: {
    fontSize: "14px",
    ml: "10px",
    "& ul li:first-of-type": {
      marginLeft: "-22px",
    },
  },
  acceptCondition: {
    ...useTermsAndConditionsDialogStyle.acceptCondition,
  },
};

export const useCampaignDetailCardStyle = {
  cardTitle: {
    bgcolor: "#E5F6FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    m: "-16px -16px 10px -16px",
    "& p": {
      color: "#0077E4",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  categoryBox: {
    alignItems: "center",
    padding: "16px",
    "& p": {
      fontSize: "12px",
      color: "#9E9E9E",
    },
  },
  divider: {
    fontSize: "4px",
    color: "#9E9E9E",
  },
  campName: {
    fontSize: "18px",
    fontWeight: "500",
    m: "0 16px 10px 16px",
  },
  campNameSubtitle: {
    "& div": {
      display: "flex",
      alignItems: "center",
      mx: "16px",
      my: "10px",
      "&:first-of-type svg": {
        fontSize: "20px",
        color: "#1A77F1",
      },
      "&:last-of-type svg": {
        fontSize: "20px",
      },
    },
  },
  product: {
    color: "#9E9E9E",
    display: "flex",
    ml: "16px",
    alignItems: "center",
    "& svg": {
      fontSize: "18px",
    },
  },
};
