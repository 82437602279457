import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import PackageCard from "../../components/home-pricing/PackageCard";
import { IPackage } from "./HomePricing.types";
import { HomePricingStyle as classes } from "./HomePricing.style";
import { setValueByBreakpoint } from "../../utils/helpers/setValueByBreakpoint.helpers";
import useBreakpoint from "../../utils/hooks/useBreakpoint";
import Faq from "../../components/home-pricing/Faq";
import { useHistory } from "react-router-dom";

// images
import FAQImage from "../../assets/images/home/4_Pricing and Plan page/FAQ.png";

function HomePricing() {
  const bp = useBreakpoint();
  const history = useHistory();
  const items: IPackage[] = [
    {
      package: "Lite",
      price: "50,000",
      currency: "THB",
      details: ["80K Reach", "40 Posts", "240K Followers", "8K Engagement"],
      button: {
        text: "Select Package",
        onClick: () => {
          console.log("Lite");
        },
      },
    },
    {
      package: "Pro",
      price: "100,000",
      currency: "THB",
      recommend: true,
      details: ["100K Reach", "60 Posts", "750K Followers", "12K Engagement"],
      button: {
        text: "Select Package",
        onClick: () => {
          console.log("Pro");
        },
      },
    },
    {
      package: "Premium",
      price: "300,000",
      currency: "THB",
      details: ["80K Reach", "40 Posts", "240K Followers", "8K Engagement"],
      button: {
        text: "Select Package",
        onClick: () => {
          console.log("Premium");
        },
      },
    },
    {
      package: "Custom",
      price: "Custom",
      currency: "",
      details: ["All Custom"],
      button: {
        text: "Contact Us",
        onClick: () => {
          history.push("/home/contact");
        },
      },
    },
  ];

  return (
    <Box sx={classes.contentMain}>
      <Box>
        <Box pt={[15, 20, 20, 30]}>
          <Box id="back-to-top-anchor" textAlign="center">
            <Typography
              variant={setValueByBreakpoint(bp, "h4", "h3", "h3", "h2")}
            >
              Pricing & Plan
            </Typography>
            <br />
            <Typography
              variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
              fontWeight="regular"
              color={"text.secondary"}
            >
              Choose a plan that’s right for you
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" my={10}>
            <Grid
              container
              rowSpacing={10}
              width={["100%", "95%", "70%", "90%", "85%"]}
            >
              {items.map((item, idx) => {
                return (
                  <Grid
                    key={idx}
                    item
                    display="flex"
                    justifyContent="center"
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                  >
                    <PackageCard data={item} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box my={15} px={[5, 10, 15, 30]}>
            <Faq />
          </Box>
          <Box px={15}>
            <Grid
              container
              columns={8}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                xs={8}
                sm={4}
                md={4}
                lg={4}
              >
                <Box textAlign={bp === "xs" ? "center" : "start"}>
                  <Typography
                    variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
                  >
                    Question ?
                  </Typography>
                  <br />
                  <Typography
                    variant={setValueByBreakpoint(
                      bp,
                      "body1",
                      "body2",
                      "body2",
                      "body1"
                    )}
                    color={"text.secondary"}
                  >
                    Feel free to contact us if you have any further question.
                  </Typography>
                  <br />
                  <Button
                    variant="contained"
                    sx={classes.contactUsButton}
                    onClick={() => {
                      history.push("/home/contact");
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                xs={8}
                sm={4}
                md={4}
                lg={4}
              >
                <img
                  src={FAQImage}
                  alt="faq"
                  style={{
                    width: setValueByBreakpoint(
                      bp,
                      "280px",
                      "296px",
                      "296px",
                      "445px"
                    ),
                    objectFit: "contain",
                  }}
                ></img>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HomePricing;
