import React, { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
// Icons
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Link } from "react-scroll";
// Styles
import { useCampaignBriefTabStyle as classes } from "./CampaignBriefTabs.style";
// mock data
import { steps } from "../../../views/create-campaign/CreateCampaignData";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "#F5F6F8",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "#F5F6F8",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: "40px",
  height: "40px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <StorefrontRoundedIcon sx={{ fontSize: "17px" }} />,
    2: <ListRoundedIcon sx={{ fontSize: "17px" }} />,
    3: <PeopleAltIcon sx={{ fontSize: "17px" }} />,
    4: <MonetizationOnIcon sx={{ fontSize: "17px" }} />,
    5: <EditIcon sx={{ fontSize: "17px" }} />,
    6: <AccessTimeFilledIcon sx={{ fontSize: "17px" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function CampaignBriefTab(props: any) {
  const {
    form: { getValues, watch },
  } = props;

  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <Box sx={classes.tabsCon}>
      <Stack
        sx={{
          ...classes.tabButton,
          "& .css-n7tliy-MuiStep-root:first-of-type": {
            "& .css-bq6l67": {
              bgcolor:
                (watch("campaignOverview.campaignName") &&
                  watch("campaignOverview.organization") &&
                  watch("campaignOverview.companyName") &&
                  watch("campaignOverview.companyLogo") &&
                  watch("campaignOverview.organizationLogo") !== "") ||
                null
                  ? "#8C499C"
                  : "#ccc",
            },
          },
        }}
        spacing={4}
      >
        <Stepper
          alternativeLabel
          // activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label.id} className="nav-item">
              <Link
                to={`${label.id}`}
                spy={true}
                smooth={true}
                offset={label.offset}
                duration={50}
              >
                <StepLabel
                  onClick={() => {
                    setActiveStep(index);
                  }}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {label.title}
                </StepLabel>
              </Link>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
}
