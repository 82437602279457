import * as yup from "yup";

export const ShippingAddressFormSchema = yup.object().shape({
  fullname: yup.string().required("Please enter Full Name.").matches(/^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/, "Full name is not valid"),
  phone: yup.string().required("Please enter Phone Number.").matches(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/, "Phone number should be 10 digits"),
  province: yup
    .object()
    .shape({
      id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
      abbreviation_en: yup.string(),
      abbreviation_th: yup.string(),
    })
    .required("Please select Province.")
    .nullable(),
  district: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select District.")
    .nullable(),
  subdistrict: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      district_id: yup.number(),
      postcode: yup.string(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select Subdistrict.")
    .nullable(),
  postcode: yup.string().required("Please enter Zipcode."),
  address: yup.string().required("Please enter Address."),
});

export const BillingAddressFormSchema = yup.object().shape({
  fullname: yup.string().required("Please enter Full Name.").matches(/^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/, "Full name is not valid"),
  tax_id: yup.string().required("Please enter Tax Id.").matches(/^\(?([0-9]{1})\)?[-]?([0-9]{4})[-]?([0-9]{5})[-]?([0-9]{2})[-]?([0-9]{1})$/, "Tax ID should be 13 digits"),
  province: yup
    .object()
    .shape({
      id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
      abbreviation_en: yup.string(),
      abbreviation_th: yup.string(),
    })
    .required("Please select Province.")
    .nullable(),
  district: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select District.")
    .nullable(),
  subdistrict: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      district_id: yup.number(),
      postcode: yup.string(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select Subdistrict.")
    .nullable(),
  postcode: yup.string().required("Please enter Zipcode."),
  address: yup.string().required("Please enter Address."),
});
