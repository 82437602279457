import React from "react";

// Component
import { PersonalInformationSection } from "./PersonalInformationSection";
import { ChangePasswordSection } from "./ChangePasswordSection";
import DialogScrollingCustom from "../../global/dialog-scrolling-custom";
// Types
import {
  IEditProfileDialogProps,
  IPersonalInformationForm,
} from "../InfluencerProfile.types";
// Utils
import { useEditProfileDialogStyle as classes } from "../InfluencerProfile.styles";

// Material
import { Divider } from "@mui/material";

//  DayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

const EditProfileDialog = React.memo((props: IEditProfileDialogProps) => {
  const {
    data,
    isOpened,
    onClose,
    onSubmitUpdateProfile,
    onSubmitChangePassword,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const personalSubmitHandler = async (data: IPersonalInformationForm) => {
    onSubmitUpdateProfile(data);
    console.log("personalSubmitHandler", data);
  };

  const emailSubmitHandler = async () => {
    onSubmitChangePassword("test");
  };

  return (
    <React.Fragment>
      <DialogScrollingCustom
        title="My Profile"
        open={isOpened}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={false}
        sx={classes.dialog}
      >
        <PersonalInformationSection
          data={data}
          personalSubmitHandler={personalSubmitHandler}
          closeDialog={handleClose}
        ></PersonalInformationSection>
        <Divider variant="middle" />
        {/* <ChangePasswordSection
          email={data ? data.email : ""}
          changePasswordHandlerSubmit={emailSubmitHandler}
        /> */}
      </DialogScrollingCustom>
    </React.Fragment>
  );
});

export default EditProfileDialog;
