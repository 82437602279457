import React from "react";
import { IBreakpoint } from "../../../views/global/global.types";
import { setValueByBreakpoint } from "../../../utils/helpers/setValueByBreakpoint.helpers";
import { Grid } from "@mui/material";

export function GridMobileReverse({
  breakpoint,
  isMobileRevers = false,
  children,
}: {
  breakpoint: IBreakpoint;
  isMobileRevers?: boolean;
  children?: any;
}) {
  return (
    <Grid
      component="div"
      container
      direction={
        isMobileRevers && breakpoint === "xs" ? "column-reverse" : "row"
      }
      rowSpacing={5}
      columnSpacing={setValueByBreakpoint(breakpoint, 0, 3, 3, 3)}
      px={[10, 14, 14, 40]}
      mb={[20, 24, 18, 28]}
    >
      {children}
    </Grid>
  );
}
