import React from "react";


// Components
import CampaignOverview from "../../components/campaign/Campaign";


import { Grid, Stack, Box, Avatar, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICampaign } from "./campaign.types";
import { CampaignFormSchema } from "./campaign.schema";
import CampaignComp from "../../components/campaign/CampaignComp";
import Timeline from "../../components/campaign/Timeline";
import Budget from "../../components/campaign/Budget";
import Target from "../../components/campaign/Target";
import Content from "../../components/campaign/Content";
import TermAndCondition from "../../components/campaign/TermAndCondition";

const initCampaignForm: ICampaign = {
  audience: {
    gender: "",
    location: "",
    age: {
      min: "",
      max: "",
    },
  },
  influencer: {
    gender: "",
    location: "",
    age: {
      min: "",
      max: "",
    },
    followers: {
      min: "",
      max: "",
    },
  },

  timeline: {
    recruiting: {
      startDate: "",
      endDate: "",
    },
    campaign: {
      startDate: "",
      endDate: "",
    },

    annouceDate: "",
    postingPeriod: "",
  },

  campaign: {
    campaignType: "",
    logoCompany: null,
    companyName: "",
    organization: "",
    logoOrganization: null,
    campaignName: "",

    selectBy: "",
    method: "",
    objective: "",
    review: "",
  },

  budget: {
    total: "",
    influencerAmount: "",
    eachInfluencer: "",
  },

  target: {
    totalFollowers: "",
    reach: "",
  },

  content: {
    briefImage: {
      images: null,
      video: null,
    },
    platform: "",
    contentType: "",
    category: [],
    hashtag: "",
    moodAndTone: "",
    moodAndToneImage: {
      images: null,
      video: null,
    },
    description: "",
    keyMessage: "",
    emphasis: "",
    prohibition: "",
  },
  termAndCondition: [],
};

function Campaign() {
  const form = useForm<ICampaign>({
    defaultValues: initCampaignForm,
    resolver: yupResolver(CampaignFormSchema),
  });

  const { handleSubmit, setValue, clearErrors } = form;

  const styles = {
    boxContent: {
      padding: "8px",
      backgroundColor: "primary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
    cardContainer: {
      margin: "8px",
    },
    saveBtn: {
      position: "fixed",
      bottom: "15%",
      right: 0,
      width: "100px",
      height: "100px",
      borderRadius: "20px",
    },
  };

  const handleChange = (e: any, name: keyof ICampaign) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  return (
    <React.Fragment>
      <div className="container-main" style={{ position: "relative" }}>
        <form>
          <Box sx={styles.boxContent}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack>
                  <CampaignComp form={form} />
                  <Timeline form={form} />
                  <Budget form={form} />
                  <Target form={form} />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Content form={form} />
                  <TermAndCondition form={form} />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <IconButton onClick={handleSubmit((ev) => console.log(ev))}>
            <Avatar
              src="/assets/images/save-btn.png"
              variant="square"
              sx={styles.saveBtn}
            />
          </IconButton>
        </form>
      </div>
    </React.Fragment>
  );
}

export default Campaign;
