import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
  Box,
  Button,
  Grid,
} from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";
import TextFieldCustom from "../global/text-field";
import SaveIcon from "@mui/icons-material/Save";
import BasicSelectCustom from "../global/basic-select";

function PublishedPostLink({ hooksForm }: any) {
  const {
    formState: { errors },
    setValue,
    watch,
  } = hooksForm;

  const styles = {
    boxContent: {
      padding: "8px",
      backgroundColor: "primary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
  };

  const onHandleChange = (e: any, keyName: string, arr: Array<any>) => {
    const { value } = e.target;

    setValue(keyName, value);
  };
  return (
    <React.Fragment>
      <Box sx={styles.boxContent}>
        <Card sx={{ m: 2 }}>
          <CardHeader
            title={
              <Typography variant="h6" color="common.black" fontWeight="500">
                Published Post Link
              </Typography>
            }
          />
          <CardContent sx={{ paddingInline: "30px" }}>
            <WrapInputWithLabel
              title={
                <Box>
                  <i
                    className="ico-hu-facebook-solid"
                    style={{
                      color: "#8C499C",
                      fontSize: "20px",
                      marginRight: "5px",
                    }}
                  />
                  Facebook
                </Box>
              }
              labelWidth="120px"
            >
              https://www.facebook.com/hashtag/lazada1212supershow?source=feed_text&ep
            </WrapInputWithLabel>
          </CardContent>
        </Card>

        <Card sx={{ m: 2 }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                color="common.black"
                fontWeight="inherit"
              >
                Return Product Review
              </Typography>
            }
          />
          <CardContent sx={{ paddingInline: "30px" }}>
            <WrapInputWithLabel title="Shipping Type" labelWidth="150px">
              Thailand Post
            </WrapInputWithLabel>
            <WrapInputWithLabel title="Tracking Code" labelWidth="150px">
              <a href="#">#EL126568989892</a>
            </WrapInputWithLabel>
            <WrapInputWithLabel title="Note" labelWidth="80px">
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </WrapInputWithLabel>
          </CardContent>
        </Card>
      </Box>
      <Box sx={styles.boxContent}>
        <Card sx={{ m: 2 }}>
          <CardHeader
            title={
              <Typography variant="h6" color="common.black" fontWeight="500">
                Published Post Link
              </Typography>
            }
          />
          <CardContent sx={{ paddingInline: "30px" }}>
            <WrapInputWithLabel
              title={
                <Box display="flex" alignItems="center">
                  <i
                    className="ico-hu-facebook-solid"
                    style={{
                      color: "#8C499C",
                      fontSize: "20px",
                      marginRight: "5px",
                    }}
                  />
                  Facebook
                </Box>
              }
            >
              <TextFieldCustom
                variant="outlined"
                placeholder="Link"
                fullWidth
              />
            </WrapInputWithLabel>
          </CardContent>
        </Card>

        <Card sx={{ m: 2 }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                color="common.black"
                fontWeight="inherit"
              >
                Return Product Review
              </Typography>
            }
          />
          <CardContent sx={{ paddingInline: "30px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <WrapInputWithLabel title="Shipping Type">
                  <BasicSelectCustom
                    id="shippingType-select"
                    value={watch("shippingType")}
                    onChange={(
                      e: React.MouseEventHandler<HTMLButtonElement>
                    ) => {
                      onHandleChange(e, "shippingType", []);
                    }}
                    data={[]}
                    valueKey="id"
                    labelKey="name_en"
                    placeholder="Select"
                    fullWidth
                    error={!!errors.shippingType}
                    helperText={(errors.shippingType as any)?.message}
                  />
                </WrapInputWithLabel>
              </Grid>
              <Grid item xs={6}>
                <WrapInputWithLabel title="Tracking Code">
                  <TextFieldCustom
                    variant="outlined"
                    placeholder="#EL01234567890"
                    fullWidth
                  />
                </WrapInputWithLabel>
              </Grid>

              <Grid item xs={12}>
                <WrapInputWithLabel title="Note" labelWidth="80px">
                  <TextFieldCustom
                    variant="outlined"
                    placeholder="Please enter text..."
                    fullWidth
                  />
                </WrapInputWithLabel>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "primary.main",
              width: "300px",
              border: "none !important",
            }}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export default PublishedPostLink;
