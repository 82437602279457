import { Box, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import { readFileDataTo64 } from "../../../utils/helpers/readFileDataTo64";
import CancelIcon from "@mui/icons-material/Cancel";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import AddIcon from "@mui/icons-material/Add";
import "./imageInput.css";

interface IVideoInput {
  register: Function;
  errors: any;
  keyName: string;
  [any: string]: any;
}

function VideoInput(props: IVideoInput) {
  const VideoImageThumbnail = require("react-video-thumbnail-image");
  const { register, keyName, errors } = props;
  const [imageData, setImageData] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const styles = {
    button: {
      position: "absolute",
      top: "-15px",
      right: "-15px",
    },
    image: {
      height: "150px",
      width: "150px",
      objectFit: "cover" as "cover",
      border: "1px solid #E0E0E0",
      borderRadius: "10px",
    },
  };

  const onInputImage = async (ev: any) => {
    const file = await ev.target.files[0];
    const url = URL.createObjectURL(file);

    if (file) {
      setImageData(file);
    }
    setImgUrl(url);
  };

  const onHandleRemove = () => {
    setImageData("");
    setImgUrl("");
  };

  return (
    <div>
      <Box position="relative" width="150px">
        <div>
          {imgUrl ? (
            <VideoImageThumbnail
              videoUrl={imgUrl}
              thumbnailHandler={(thumbnail: any) => console.log(thumbnail)}
              width={150}
              height={150}
              alt="video thumbnail"
            />
          ) : (
            <label
              className="label-upload"
              style={{ borderColor: errors ? "red" : "" }}
            >
              <div>
                <img src="/assets/images/vdo-upload.png" alt="vdo" />
              </div>

              <div>+ Video</div>

              <input
                className=""
                type="file"
                accept="video/*"
                id="input-file"
                {...register(keyName, {
                  onChange: onInputImage,
                })}
              />
            </label>
          )}
        </div>
        {imageData ? (
          <IconButton sx={styles.button} onClick={onHandleRemove}>
            <CancelIcon style={{ color: "black", fontSize: "30px" }} />
          </IconButton>
        ) : (
          ""
        )}
      </Box>

      {errors ? (
        <Typography variant="caption" color="error.main" fontWeight={400}>
          {errors.message}
        </Typography>
      ) : (
        ""
      )}
    </div>
  );
}

export default VideoInput;
