import React, { useEffect, useState } from "react";
// Library
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Component
import TextFieldCustom from "../../global/text-field/index";
// Types
import {
  IMyPortfolioSectionProps,
  IMyPortfolio,
  IMyPortfolioFormSchema,
} from "../InfluencerMyPortfolio.types";
// Material
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// Schema
import { MyPortfolioFormSchema } from "../InfluencerMyPortfolioForm.schema";
// Styles
import { useEditMyPortfolioDialogStyle as classes } from "../InfluencerMyPortfolio.styles";
// Icons
import { SaveButton } from "../../../views/my-profile/MyProfile.styles";
import { SaveIcon } from "../../../assets/icons/social/social";
import { AttachFile } from "../../../assets/icons/social/social";
// API
import { editInfluencerMyPortfolio } from "../../../api/influencer/InfluencerAPI";
// Others
import SwalCustom from "../../global/sweet-alert-custom/SwalCustom";

const initMyPortfolio: IMyPortfolio = {
  title: "",
  url: "",
  description: "",
};

export default function MyPortfolioSection(props: IMyPortfolioSectionProps) {
  const {
    data,
    myPortfolioSubmitHandler,
    closeDialog,
    myPortfolioIndex,
    openEditMyProfileDialog,
  } = props;
  const [myPortfolio, setMyPortfolio] = useState<IMyPortfolio[]>([]);

  useEffect(() => {
    if (myPortfolioIndex !== undefined) {
      setMyPortfolio(data);
      reset(data[myPortfolioIndex]);
    }
  }, []);

  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<IMyPortfolioFormSchema>({
    resolver: yupResolver(MyPortfolioFormSchema),
    defaultValues: initMyPortfolio,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IMyPortfolio
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onDeleteMyPortfolio = async () => {
    const portfolioData = [...myPortfolio];
    portfolioData.splice(myPortfolioIndex as number, 1);
    const portfolio = {
      portfolio: portfolioData,
    };
    closeDialog();
    SwalCustom.fire({
      title: "Are you sure?",
      text: "You won't to delete this portfolio!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4CAF50",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await editInfluencerMyPortfolio(portfolio);
          if (result) {
            await SwalCustom.fire({
              icon: "success",
              title: "Delete portfolio success",
              showConfirmButton: false,
              timer: 1600,
            });
            myPortfolioSubmitHandler(portfolio);
            closeDialog();
          }
        } catch (error: unknown) {
          await SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, There are something when wrong",
            showConfirmButton: true,
            timer: 1600,
          });
        }
      } else {
        openEditMyProfileDialog();
      }
    });
  };

  const onSubmit = async (data: IMyPortfolio) => {
    let portfolioData = [...myPortfolio];
    const portfolioDatas = {
      title: data.title as string,
      url: data.url as string,
      description: data.description as string,
    };
    console.log("aiyaa", portfolioData);
    if (myPortfolioIndex === 10) {
      portfolioData.unshift(portfolioDatas);
    } else if (data && myPortfolioIndex !== undefined) {
      portfolioData[myPortfolioIndex].title = data.title;
      portfolioData[myPortfolioIndex].url = data.url;
      portfolioData[myPortfolioIndex].description = data.description;
    }
    const portfolio = {
      portfolio: portfolioData,
    };

    try {
      const result = await editInfluencerMyPortfolio(portfolio);
      if (result) {
        await SwalCustom.fire({
          icon: "success",
          title: "Updated portfolio success",
          showConfirmButton: false,
          timer: 1600,
        });
        myPortfolioSubmitHandler(portfolio);
        closeDialog();
        console.log("submit portfolio: ", portfolio);
      }
    } catch (error: unknown) {
      await SwalCustom.fire({
        icon: "error",
        title: "Opps !",
        text: "Error, There are something when wrong",
        showConfirmButton: true,
        timer: 1600,
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      m={0}
      p={0}
    >
      <DialogContent sx={classes.dialogContent}>
        <Box px={[0, 2]} py={0}>
          <Box display="flex" alignItems="center" gap={1}>
            <AttachFile color="primary" fontSize="small" />
            <Typography variant="body1" fontWeight="bold">
              My Portfolio
            </Typography>
          </Box>
          <Stack px={2} pt={3}>
            {/* Title */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Title<span className="requir">*</span>:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("title")}
                  onChange={(e) => handleChange(e, "title")}
                  variant="outlined"
                  placeholder="Please enter title..."
                  fullWidth
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              </Grid>
            </Grid>
            {/* Url */}
            <Grid container pb={3}>
              <Grid item xs={12} md={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Paste or type a link<span className="requir">*</span>:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("url")}
                  onChange={(e) => handleChange(e, "url")}
                  variant="outlined"
                  placeholder="Please enter Url..."
                  fullWidth
                  error={!!errors.url}
                  helperText={errors.url?.message}
                />
              </Grid>
            </Grid>
            {/* Description */}
            <Grid container pb={5}>
              <Grid item xs={12} md={3} mt={[0, 0, 2]}>
                <Typography variant="body2">Description:</Typography>
              </Grid>
              <Grid item xs={12} md={9} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("description")}
                  onChange={(e) => handleChange(e, "description")}
                  variant="outlined"
                  placeholder="Please enter Description..."
                  fullWidth
                  multiline
                  rows={3}
                  inputProps={{
                    maxLength: "60",
                  }}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
                <Typography display="flex" justifyContent="end">
                  {getValues("description") !== undefined &&
                    `${getValues("description").length}/60`}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogAction}>
        <SaveButton
          type="submit"
          variant="contained"
          startIcon={<SaveIcon sx={classes.saveButton} />}
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
        {myPortfolioIndex !== 10 && (
          <Typography
            sx={classes.saveTitel}
            color="primary"
            mt={6}
            mb={4}
            onClick={onDeleteMyPortfolio}
          >
            Delete Portfolio
          </Typography>
        )}
      </DialogActions>
    </Box>
  );
}
