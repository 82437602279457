import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  TextField,
} from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";
import TextFieldCustom from "../global/text-field";
import ImageInput from "../global/image-input";
import BasicSelectCustom from "../global/basic-select";

interface ICampaignProps {
  form: any;
}

function CampaignComp(props: ICampaignProps) {
  const {
    form: {
      formState: { errors },
      setValue,
      watch,
      register,
      getValues,
      clearErrors,
    },
  } = props;

  const styles = {
    cardContainer: {
      margin: "8px",
    },
  };

  const onHandleChange = (e: React.BaseSyntheticEvent, keyName: string) => {
    const { value } = e.target;
    setValue(keyName, value);
    clearErrors(keyName);
  };

  console.log("watch", watch("campaign"));

  return (
    <Card sx={styles.cardContainer}>
      <CardHeader
        title={
          <Typography variant="h6" color="common.black" fontWeight="500">
            Campaign
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <WrapInputWithLabel labelWidth="200px" title="Organization" required>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              value={getValues("campaign.organization")}
              onChange={(e) => onHandleChange(e, "campaign.organization")}
              error={!!errors.campaign?.organization}
              helperText={errors.campaign?.organization?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel
            labelWidth="200px"
            title="Logo Organization"
            required
            alignItems="start"
          >
            <ImageInput
              errors={errors?.campaign?.logoOrganization}
              register={register}
              keyName="campaign.logoOrganization"
              icon="camera"
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel labelWidth="200px" title="Company Name" required>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              value={getValues("campaign.companyName")}
              onChange={(e) => onHandleChange(e, "campaign.companyName")}
              error={!!errors.campaign?.companyName}
              helperText={errors.campaign?.companyName?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel
            labelWidth="200px"
            title="Logo Company"
            alignItems="start"
            required
          >
            <ImageInput
              errors={errors?.campaign?.logoCompany}
              register={register}
              keyName="campaign.logoCompany"
              icon="plus"
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel labelWidth="200px" title="Campaign Name" required>
            <TextFieldCustom
              value={getValues("campaign.campaignName")}
              onChange={(e) => onHandleChange(e, "campaign.campaignName")}
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              error={!!errors.campaign?.campaignName}
              helperText={errors.campaign?.campaignName?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel labelWidth="200px" title="Campaign Type" required>
            <BasicSelectCustom
              id="campaignType-select"
              value={watch("campaign.campaignType")}
              onChange={(e: any) => {
                onHandleChange(e, "campaign.campaignType");
              }}
              placeholder="Please select"
              data={[]}
              valueKey="id"
              labelKey="name_en"
              fullWidth
              required
              error={!!errors.campaign?.campaignType}
              helperText={(errors.campaign?.campaignType as any)?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel labelWidth="200px" title="Selection by" required>
            <TextFieldCustom
              variant="outlined"
              disabled
              fullWidth
              error={!!errors.campaign?.selectBy}
              helperText={errors.campaign?.selectBy?.message}
              value={getValues("campaign.selectBy")}
              onChange={(e) => onHandleChange(e, "campaign.selectBy")}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel labelWidth="200px" title="Method" required>
            <BasicSelectCustom
              id="method-select"
              value={watch("campaign.method")}
              onChange={(e: any) => {
                onHandleChange(e, "method");
              }}
              data={[]}
              valueKey="id"
              labelKey="name_en"
              placeholder="Please select"
              required
              fullWidth
              error={!!errors.campaign?.method}
              helperText={(errors.campaign?.method as any)?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel labelWidth="200px" title="Objective" required>
            <BasicSelectCustom
              id="objective-select"
              value={watch("campaign.objective")}
              onChange={(e: any) => {
                onHandleChange(e, "objective");
              }}
              data={[]}
              valueKey="id"
              labelKey="name_en"
              required
              placeholder="Please select"
              fullWidth
              error={!!errors.campaign?.objective}
              helperText={(errors.campaign?.objective as any)?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel
            labelWidth="200px"
            title="Review Product"
            required
          >
            <BasicSelectCustom
              id="review-select"
              value={watch("campaign.review")}
              onChange={(e: any) => {
                onHandleChange(e, "review");
              }}
              data={[]}
              valueKey="id"
              required
              labelKey="name_en"
              placeholder="Please select"
              fullWidth
              error={!!errors.campaign?.review}
              helperText={(errors.campaign?.review as any)?.message}
            />
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default CampaignComp;
