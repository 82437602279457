export const useCampaignBriefTabStyle = {
  tabsCon: {
    width: "920px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    borderRadius: "20px",
    backgroundColor: "#fff",
    mb: "24px",
    position: "sticky",
    top: "70px",
    zIndex: 10,
  },
  tabButton: {
    width: "100%",
    "& .css-15qycc7-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      mt: "10px",
    },
  },
};
