import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  Grid,
} from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";
import TextFieldCustom from "../global/text-field";
import { Controller } from "react-hook-form";
import BasicSelectCustom from "../global/basic-select";
import {
  IcategoryData,
  IcontentTypeData,
} from "../../views/campaign/campaign.types";
import { contentTypeData } from "../../data/content_type";
import MultiImageInput from "../global/multi-image-input";
import VideoInput from "../global/video-input";
import MultipleSelectCustom from "../global/multiple-select";
import { categoryData } from "../../data/category";
import CreatableSelectCustom from "../global/creatable-select-custom";
import { hashtagData } from "../../data/hashtag";

interface IoptionHashtag {
  label: string;
  value: string;
  id: string | number;
}

function Content({ form }: any) {
  const [optionCategory, setOptionCategory] = useState<IcategoryData[]>([]);
  const [optionHashtag, setOptionHashtag] = useState<IoptionHashtag[]>([]);
  const [optionContentType, setOptionContentType] = useState<
    IcontentTypeData[]
  >([]);

  const {
    formState: { errors },
    setValue,
    watch,
    control,
    register,
    clearErrors,
    getValues,
  } = form;

  const watchPlatForm = watch("content.platform");

  const styles = {
    cardContainer: {
      margin: 2,
      overflow: "initial",
    },
  };

  const optionPlatform = [
    {
      id: 1,
      value: "facebook",
      label: "Facebook",
      icon: <i className="ico-hu-facebook-solid" />,
    },
    {
      id: 2,
      value: "instagram",
      label: "Instagram",
      icon: <i className="ico-hu-ig-solid" />,
    },
    {
      id: 3,
      value: "tiktok",
      label: "TikTok",
      icon: <i className="ico-hu-tiktok" />,
    },
  ];

  const onHandleChange = (e: React.BaseSyntheticEvent, keyName: string) => {
    const { value } = e.target;
    setValue(keyName, value);
    clearErrors(keyName);
  };

  useEffect(() => {
    if (categoryData && categoryData.result) {
      setOptionCategory(categoryData.result.data);
    }
  }, [categoryData]);

  useEffect(() => {
    setValue("content.contentType", "");
    if (contentTypeData && contentTypeData.result && watchPlatForm) {
      const filterList = contentTypeData.result.data.filter(
        (item) => item.platform === watchPlatForm
      );

      setOptionContentType(filterList);
    } else {
      setOptionContentType([]);
    }
  }, [contentTypeData, watchPlatForm]);

  useEffect(() => {
    if (hashtagData && hashtagData.result) {
      const list = hashtagData.result.data.map((item) => {
        return {
          label: item.name_en,
          value: item.name_en,
          id: item.id,
        };
      });

      setOptionHashtag(list);
    }
  }, [hashtagData]);

  return (
    <Card sx={styles.cardContainer}>
      <CardHeader
        title={
          <Typography variant="h6" color="common.black" fontWeight="500">
            Content
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <WrapInputWithLabel title="Platform" required>
            <BasicSelectCustom
              id="platform-select"
              value={getValues("content.platform")}
              onChange={(e: any) => {
                onHandleChange(e, "content.platform");
              }}
              data={optionPlatform}
              valueKey="value"
              labelKey="label"
              iconKey="icon"
              placeholder="Please select"
              fullWidth
              error={!!errors?.content?.platform}
              helperText={(errors?.content?.platform as any)?.message}
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel title="Content Type" required>
            <BasicSelectCustom
              id="contentType-select"
              value={getValues("content.contentType")}
              onChange={(e: any) => {
                onHandleChange(e, "content.contentType");
              }}
              data={optionContentType}
              valueKey="id"
              labelKey="name_en"
              placeholder="Please select"
              fullWidth
              error={!!errors?.content?.contentType}
              helperText={(errors?.content?.contentType as any)?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Brief image" required alignItems="start">
            <Grid container minHeight={150}>
              <Grid item>
                <MultiImageInput
                  register={register}
                  errors={errors?.content?.briefImage?.images}
                  keyName="content.briefImage.images"
                />
              </Grid>
              <Grid item>
                <VideoInput
                  errors={errors?.content?.briefImage?.video}
                  register={register}
                  keyName="content.briefImage.video"
                />
              </Grid>
            </Grid>
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Category" required>
            <MultipleSelectCustom
              id="category-select"
              value={watch("content.category")}
              setValue={(e: any) => {
                setValue("content.category", e);
              }}
              placeholder={"Placeholder"}
              data={optionCategory}
              fullWidth
              valueKey="id"
              labelKey="name_en"
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel title="Description" alignItems="start" required>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              multiline
              minRows={3}
              error={!!errors?.content?.description}
              helperText={errors?.content?.description?.message}
              value={getValues("content.description")}
              onChange={(e) => onHandleChange(e, "content.description")}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Mood and Tone" required alignItems="start">
            <TextFieldCustom
              multiline
              minRows={3}
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              error={!!errors?.content?.moodAndTone}
              helperText={errors?.content?.moodAndTone?.message}
              value={getValues("content.moodAndTone")}
              onChange={(e) => onHandleChange(e, "content.moodAndTone")}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title=" Mood and Tone image" alignItems="start">
            <Grid container>
              <Grid item>
                <MultiImageInput
                  register={register}
                  keyName="content.moodAndToneImage.images"
                  errors={errors?.content?.moodAndToneImage?.images}
                />
              </Grid>
              <Grid item>
                <VideoInput
                  errors={errors?.content?.moodAndToneImage?.video}
                  register={register}
                  keyName="content.moodAndToneImage.video"
                />
              </Grid>
            </Grid>
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Key Message" required alignItems="start">
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              multiline
              minRows={3}
              error={!!errors?.content?.keyMessage}
              helperText={errors?.content?.keyMessage?.message}
              value={getValues("content.keyMessage")}
              onChange={(e) => onHandleChange(e, "content.keyMessage")}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Hashtag" alignItems="start">
            <Controller
              name="content.hashtag"
              control={control}
              rules={{
                required: false,
              }}
              defaultValue=""
              render={({ field }) => {
                return (
                  <CreatableSelectCustom
                    options={optionHashtag}
                    {...field}
                    prefix="#"
                  />
                );
              }}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel
            alignItems="start"
            title={
              <>
                Do or <br /> Emphasis
              </>
            }
            required
            tooltips={
              <React.Fragment>
                <div>
                  <Typography variant="h6" color="inherit" fontWeight="500">
                    Example of term and Condition
                  </Typography>
                  <Typography color="rgba(0, 0, 0, 0.54)">
                    <ul>
                      <li>
                        โปรดทบทวนนโยบายและความเป็นส่วนตัวของบริษัท
                        เพื่อปฎิบัติตามอย่าง เคร่งครัด
                      </li>
                      <li>
                        ให้สิทธิ์บริษัทในการทำซ้ำ ดัดแปลง แก้ไข
                        หรือประชาสัมพันธ์ บทความการรีวิว ของคุณ
                        ผ่านช่องทางออนไลน์ต่างๆของบริษัทได้
                        โดยคุณไม่สามารถเรียกร้อง
                        ค่าตอบแทนใดๆกับทางบริษัทเพิ่มเติมได้
                      </li>
                      <li>
                        เปิดบัญชีสาธารณะ และไม่ทำการลบโพสต์ จนกว่าจะครบกำหนดเวลา
                        หากทำการลบโพสต์ก่อนเวลา ที่กำหนด
                        คุณจะไม่ได้รับค่าตอบแทนและบริษัท
                        สามารถเรียกร้องค่าเสียหายจากคุณได้
                      </li>
                      <li>
                        โปรดอ่านและทำความเข้าใจ รายละเอียด
                        รวมถึงสิ่งที่ต้องทำและข้อห้ามใน การรีวิวสินค้าหรือบริการ
                        ก่อนยอมรับข้อตกลงและเงื่อนไขในการรีวิว
                      </li>
                    </ul>
                  </Typography>
                </div>
              </React.Fragment>
            }
          >
            {/* <Controller
              name="content.emphasis"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field }) => (
                <TextFieldCustom
                  variant="outlined"
                  multiline
                  minRows={3}
                  placeholder="Please enter text..."
                  fullWidth
                  error={!!errors?.content?.emphasis}
                  helperText={errors?.content?.emphasis?.message}
                  {...field}
                />
              )}
            /> */}

            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              multiline
              minRows={3}
              value={getValues("content.emphasis")}
              onChange={(e) => onHandleChange(e, "content.emphasis")}
              error={!!errors.content?.emphasis}
              helperText={errors.content?.emphasis?.message}
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel
            alignItems="start"
            title={
              <>
                Don't or <br /> Prohibition
              </>
            }
            required
            tooltips={
              <React.Fragment>
                <div>
                  <Typography variant="h6" color="inherit" fontWeight="500">
                    Example of
                    <span style={{ color: "#8C499C" }}>
                      {" "}
                      term and Condition
                    </span>
                  </Typography>
                  <Typography color="rgba(0, 0, 0, 0.54)">
                    <ul>
                      <li>
                        โปรดทบทวนนโยบายและความเป็นส่วนตัวของบริษัท
                        เพื่อปฎิบัติตามอย่าง เคร่งครัด
                      </li>
                      <li>
                        ให้สิทธิ์บริษัทในการทำซ้ำ ดัดแปลง แก้ไข
                        หรือประชาสัมพันธ์ บทความการรีวิว ของคุณ
                        ผ่านช่องทางออนไลน์ต่างๆของบริษัทได้
                        โดยคุณไม่สามารถเรียกร้อง
                        ค่าตอบแทนใดๆกับทางบริษัทเพิ่มเติมได้
                      </li>
                      <li>
                        เปิดบัญชีสาธารณะ และไม่ทำการลบโพสต์ จนกว่าจะครบกำหนดเวลา
                        หากทำการลบโพสต์ก่อนเวลา ที่กำหนด
                        คุณจะไม่ได้รับค่าตอบแทนและบริษัท
                        สามารถเรียกร้องค่าเสียหายจากคุณได้
                      </li>
                      <li>
                        โปรดอ่านและทำความเข้าใจ รายละเอียด
                        รวมถึงสิ่งที่ต้องทำและข้อห้ามใน การรีวิวสินค้าหรือบริการ
                        ก่อนยอมรับข้อตกลงและเงื่อนไขในการรีวิว
                      </li>
                    </ul>
                  </Typography>
                </div>
              </React.Fragment>
            }
          >
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              multiline
              minRows={3}
              value={getValues("content.prohibition")}
              onChange={(e) => onHandleChange(e, "content.prohibition")}
              error={!!errors.content?.prohibition}
              helperText={errors.content?.prohibition?.message}
            />

            {/* <Controller
              name="content.prohibition"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field }) => (
                <TextFieldCustom
                  variant="outlined"
                  multiline
                  minRows={3}
                  placeholder="Please enter text..."
                  fullWidth
                  error={!!errors?.content?.prohibition}
                  helperText={errors?.content?.prohibition?.message}
                  {...field}
                />
              )}
            /> */}
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Content;
