export const useCreateCampaignStyle = {
  createCampaignTitle: {
    "& p:first-of-type": {
      fontSize: "24px",
      fontWeight: "500",
    },
    "& p:last-of-type": {
      fontSize: "16px",
      color: "#5C5C5C",
    },
  },
  createCampaignCon: {
    mt: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  boxContent: {
    width: "920px",
    backgroundColor: "#EBDFFF",
    borderRadius: "20px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: "25px",
    border: "1px solid #EBDFFF",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      border: "none",
      "&:hover": { border: "none" },
    },
    mb: "20px",
  },
  back: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  campCardCon: {
    height: "104px",
    my: "24px",
    display: "flex",
    alignItems: "center",
    bgcolor: "#fff",
    borderRadius: "12px",
    justifyContent: "space-between",
  },
  campCardImg: {
    borderRadius: "8px",
    width: "64px",
    height: "64px",
    ml: "20px",
  },
  campCardTimeline: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "18px",
      mr: "4px",
    },
  },
  campDraftCon: {
    display: "flex",
    alignSelf: "start",
    mt: "20px",
    mr: "20px",
    "& p": {
      color: "#FB8C00",
      "& svg": {
        fontSize: "8px",
        mr: "4px",
      },
    },
  },
  campDetailCon: {
    borderRadius: "20px",
    bgcolor: "#fff",
    padding: "24px",
  },
  campDetailTitle: {
    "& p:first-of-type": {
      fontSize: "24px",
      fontWeight: "500",
    },
    "& p:last-of-type": {
      fontSize: "16px",
      color: "#5C5C5C",
    },
  },
  campCard: {
    border: "1px solid #EBDFFF",
    borderRadius: "12px",
    padding: "24px",
  },
  campDetailCardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& div:first-of-type": {
      "& p:first-of-type": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& p:last-of-type": {
        fontSize: "14px",
        color: "#5C5C5C",
      },
    },
  },
  campDetailAccept: {
    ml: "20px",
    display: "flex",
    alignItems: "center",
  },
  accnouceDateButton: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      fontSize: "18px",
      "&:hover": { border: "none" },
      maxHeight: "48px",
      minHeight: "48px",
      width: "365px",
    },
  },
};
