import React from "react";
import { Grid } from "@mui/material";

import { socialPosts } from "./mockup-socialposts";
import { IJobForm } from "./job.types";
import { useForm } from "react-hook-form";
import Images from "../../components/job/Images";
import Feedback from "../../components/job/Feedback";
import Approved from "../../components/job/Approved";
import PublishedPostLink from "../../components/job/PublishedPostLink";
import ProductReview from "../../components/job/ProductReview";
import FeedbackReview from "../../components/job/FeedbackReview";
import PostReview from "../../components/job/PostReview";
// import DisplayInfo from "../../components/job/DisplayInfo";

const initReviewForm: IJobForm = {
  shippingType: "",
  images: [],
  caption: "",
};

function Job() {
  const hooksForm = useForm<IJobForm>({ defaultValues: initReviewForm });

  return (
    <React.Fragment>
      <div className="container-main">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <DisplayInfo /> */}
          </Grid>
          <Grid item xs={9}>
            <Images hooksForm={hooksForm} />
            <Feedback hooksForm={hooksForm} />
            <Approved />
            <PublishedPostLink hooksForm={hooksForm} />
            <ProductReview />
            <FeedbackReview />
          </Grid>
          <Grid item xs={3}>
            <PostReview posts={socialPosts} />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Job;
