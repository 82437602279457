import React, { useEffect, useState } from "react";
import CreateAccountDialog from "../../components/authentication-dialog/CreateAccountDialog";
import HomeInfluencer from "../../components/home-influencer/HomeInfluencer";
import useBreakpoint from "../../utils/hooks/useBreakpoint";
import { IDevice } from "../global/global.types";

function HomeInfluencerPage() {
  const bp = useBreakpoint();
  const [device, setDevice] = useState<IDevice>("mobile");
  const [isOpenCreateAccount, setIsOpenCreateAccount] =
    useState<boolean>(false);

  useEffect(() => {
    if (bp === "xs") {
      setDevice("mobile");
    } else if (bp === "sm" || bp === "md") {
      setDevice("tablet");
    } else {
      setDevice("desktop");
    }
  }, [bp]);

  return (
    <div>
      <HomeInfluencer
        device={device}
        breakpoint={bp}
        setIsOpenCreateAccount={setIsOpenCreateAccount}
      />

      <CreateAccountDialog
        isOpened={isOpenCreateAccount}
        setOpen={setIsOpenCreateAccount}
        accountSelected={"influencer"}
      />
    </div>
  );
}

export default HomeInfluencerPage;
