import { hGrey } from "../../theme";

export const useInfluencerBankAccountStyle = {
  bankAccountCard: {
    width: "100%",
    borderRadius: "20px",
    marginBottom: "20px",
  },
  bankAccountCardHeader: {
    backgroundColor: "primary.main",
    textAlign: "center",
    height: "42px",
    padding: "0",
    fontWeight: "bold",
  },
  cardContent: {
    padding: "0 20px 0",
  },
  cardActionBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    pb: 4,
    pt: 3,
  },
  line: {
    width: "100%",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: `1px solid ${hGrey[100]}`,
    margin: 0,
  },
  bankAccountIconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
  bankAccountDetailBox: {
    paddingTop: "8px",
  },
  bankAccountCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
};

export const useEditBankAccountDialogStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  dialogContent: {
    paddingTop: 3,
    paddingBottom: 0,
  },

  uploadImageBox: {
    borderRadius: "4px",
    width: ["150px", "170px"],
    height: ["150px", "170px"],
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
  },

  uploadImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px dashed gray",
    width: "150px",
    height: "150px",
    borderRadius: "4px",
    "& img": {
      opacity: 0.5,
      width: "70px",
    },
  },
};
