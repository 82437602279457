import React, { useState } from "react";
import { useForm } from "react-hook-form";
// classes
import { useTiktokTabStyle as classes } from "./TiktokTab.styles";
// MUI
import {
  Stack,
  Box,
  Typography,
  Button,
  Avatar,
  Alert,
  Collapse,
  CircularProgress,
} from "@mui/material";
// Global Components
import TextFieldCustom from "./../../global/text-field/index";
// API
import {
  getVertifyCode,
  verifyAccount,
} from "../../../api/influencer/InfluencerAPI";
// Types
import { IVertifyCodeForm } from "./Types";
// Helper
import { getTokenFromStorage } from "./../../../utils/helpers/getTokenFromStorage";
// Assets
import tiktokLogo from "../../../assets/images/social/tiktok-only.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TiktokVerify from "../../../assets/images/other/tiktokVerify.png";
//Sweet Alert
import SwalCustom from "./../../global/sweet-alert-custom/SwalCustom";
// Theme
import { hAlertColor } from "../../../theme";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default function DefaultTab() {
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<IVertifyCodeForm>();

  const [verifyCode, setVerifyCode] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const clipboard = () => {
    navigator.clipboard.writeText(verifyCode);
  };

  const { session_token } = getTokenFromStorage();

  const handleChange = (e: any, name: keyof IVertifyCodeForm) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onSubmitVertifyCode = async (data: IVertifyCodeForm) => {
    setLoading(true);
    try {
      setLoading(false);
      const reqBody = {
        influencer_id: session_token ? session_token.influencer_id : "",
        url: data.url,
      };
      const response = await getVertifyCode(reqBody);
      const verifyCode = response.verify_code;
      setVerifyCode(verifyCode);
      setUsername(response.username);
    } catch (error: any) {
      setLoading(false);
      const data = error.response.data;
      if (data.status_code === 400) {
        switch (data.error.message) {
          case "URL domain must be tiktok.com":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "URL นี้ไม่ใช่ของ TikTok",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "Account has amount of follower lower than 10":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "บัญชี TikTok มีจำนวน Follower ไม่ถึง 10 คน",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "Account has amount of post lower than 3":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "บัญชี TikTok มีจำนวนไม่ถึง 3 Posts",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "Account has amount of follower lower than 500":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "บัญชี TikTok มีจำนวน Follower ไม่ถึง 500 คน",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "Account has amount of post lower than 180":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "บัญชี TikTok มีจำนวนไม่ถึง 180 Posts",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "This TikTok has already existed":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "มี User HashU อื่นที่ Sync TikTok Account นี้ไปแล้ว",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "TikTok username has not existed":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "ไม่พบ Username นี้บน TikTok",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "TikTok profile has not existed":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "ไม่พบ หรือไม่สามารถเข้าถึง Profile TikTok ได้",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "Can not check posts on account":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "ไม่สามารถเข้าถึง Post บน TikTok ได้",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
        }
      } else {
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Something when wrong !",
          confirmButtonColor: hAlertColor.error,
          confirmButtonText: "Close",
        });
      }
    }
  };

  const syncDataTiktok = async (data: any) => {
    try {
      setLoading(true);
      const reqBody = {
        influencer_id: session_token ? session_token.influencer_id : "",
        username: data,
      };
      const response = await verifyAccount(reqBody);
      console.log("response verify account api", response);
      await sleep(8000);
      setLoading(false);
      window.location.reload();
    } catch (error: any) {
      setLoading(false);
      const data = error.response.data;
      if (data.status_code === 500) {
        switch (data.error.message) {
          case "verify code has not existed":
            window.scrollTo(0, 0);
            setAlertMessage("ไม่พบ Verify Code ของ Account นี้");
            setShowAlert(true);
            break;
          case "verify code has expired":
            window.scrollTo(0, 0);
            setAlertMessage("Verify Code หมดอายุ");
            setShowAlert(true);
            break;
        }
      } else {
        window.scrollTo(0, 0);
        setAlertMessage("การยืนยันตัวตนไม่สำเร็จ กรุณายืนยันตัวตนใหม่อีกครั้ง");
        setShowAlert(true);
      }
    }
  };

  return (
    <Stack sx={classes.stackContainer}>
      <Collapse sx={{ width: "100%" }} in={showAlert}>
        <Alert
          variant="outlined"
          severity="error"
          sx={{ marginBottom: "24px", color: "red" }}
        >
          {alertMessage}
        </Alert>
      </Collapse>

      <Box sx={classes.tiktokAccount}>
        <Avatar alt="Tiktok Logo" src={tiktokLogo} />
        <Typography>ขั้นตอนการเชื่อมต่อบัญชี Tiktok</Typography>
      </Box>

      <Box sx={{ marginBottom: "24px" }}>
        <video
          width="700px"
          height="auto"
          controls
          src="https://storage.googleapis.com/hashu-bucket-uat/media/videos/Connect%20Tiktok%20Desktop%20v.2%2Bmusic.mp4"
        />
      </Box>

      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmitVertifyCode)}
      >
        <Box sx={classes.fillURLTiktokBox}>
          <Typography className="fillUrlTiktokTitle">
            1. กรอก URL บัญชี TikTok ของคุณ
          </Typography>
          <Box sx={classes.fillURLTiktok}>
            <Typography>
              TikTok URL<span>*</span> :
            </Typography>
            <TextFieldCustom
              value={getValues("url")}
              onChange={(e) => handleChange(e, "url")}
              variant="outlined"
              placeholder="url"
              fullWidth
              error={!!errors.url}
              helperText={errors.url?.message}
            />
          </Box>
        </Box>
        <Box sx={classes.verifyCodeToTiktokBioBox} mt={5}>
          <Typography className="verifyCodeToTiktokBioTitle">
            2. นำรหัสการยืนยันตัวตนไประบุในบรรทัดสุดท้ายบน TikTok Bio ของคุณ
          </Typography>
          <Box sx={classes.verifyCodeToTiktokBio}>
            <Typography>ตำแหน่งที่ต้องระบุรหัสสำหรับการยืนยันตัวตน</Typography>
            <img src={TiktokVerify} />
          </Box>
        </Box>
        <Box sx={classes.verifyCodeBox}>
          <Typography className="verifyCodeTitle">
            3. กดปุ่มเพื่อขอรหัสการยืนยันตัวตน
          </Typography>
          <Box sx={classes.verifyCode}>
            <Typography className="verifyCode">
              รหัสสำหรับการยืนยันตัวตน
            </Typography>
            <Box>
              <Button type="submit" variant="contained" color="secondary">
                กดที่นี้ เพื่อขอรหัสการยืนยันตัวตน
              </Button>
              {!verifyCode ? (
                <Box></Box>
              ) : (
                <Box sx={classes.buttonVerifyCode}>
                  <Typography>{verifyCode}</Typography>
                  <ContentCopyIcon
                    onClick={clipboard}
                    sx={classes.coppyVerifyCode}
                  />
                </Box>
              )}

              <Typography>
                โปรดใช้รหัสภายใน 30 นาที หากเกินเวลาที่กำหนด
                คุณต้องทำการขอรหัสใหม่อีกครั้ง
              </Typography>
            </Box>
          </Box>
        </Box>
      </form>

      <Box sx={classes.verifyTiktokAccount}>
        <Typography>
          4. หลังจากนำรหัสไประบุบน TitTok Bio แล้ว ให้กดปุ่ม "Verify TikTok
          Account" ด้านล่างเพื่อทำการดึงข้อมูล
        </Typography>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "8px 0" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              syncDataTiktok(username);
            }}
          >
            Verify TikTok Account
          </Button>
        )}
      </Box>
    </Stack>
  );
}
