export const influencerData = [
  {
    influencer_id: "INF-100001",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000453",
    social_account_type: "user",
    social_name: "Karin Chaiyasan",
    recommended: false,
    category: [
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "i9uIhSlOWYc",
          file_name:
            "If you are lucky, you grow old and become a village elder. For the Karen people, they do not keep record of their ages. Instead, they recall a certain past event that occurred when they were born.",
          url: "https://images.unsplash.com/photo-1488820098099-8d4a4723a490?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man holding brown stick smiling",
        },
        {
          file_id: "RQAf_3Iagqw",
          file_name:
            "Omar is my main training partner at the Gym this was after his 13th fight, and 13th victory and 3rd National Belt. He has since won 4 more fights and collected another belt. He is one of the top amateur Nak Muays in America and I’m honored to get beat up by him regularly.",
          url: "https://images.unsplash.com/photo-1525871685609-48be2dbd54f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "grayscale photo of boxer",
        },
        {
          file_id: "1nRWSg1ipPs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1545252682-2d32e48111da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "white van on mountain",
        },
        {
          file_id: "UoLWIlRg-8A",
          file_name: null,
          url: "https://images.unsplash.com/photo-1528956357149-92a27fd6349e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown jacket wearing sunglasses facing the wall",
        },
        {
          file_id: "XK2H0My1ZL8",
          file_name: "Thai Street Vendor",
          url: "https://images.unsplash.com/photo-1524912158767-3e3c6e103529?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white top sitting in front of food stall",
        },
        {
          file_id: "jAke8NofTtE",
          file_name:
            "My friend Mo was fighting so he invited me to come along.  I took this picture of his coach and him in-between rounds.  Mo ended up winning the fight in a unanimous decision by the referee.",
          url: "https://images.unsplash.com/photo-1529165980561-f19d4acc4f3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black talking to boxer inside ring",
        },
        {
          file_id: "J1hMrwSKfE4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1615477777908-f0a892b84067?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "person holding clear glass ball",
        },
        {
          file_id: "lkv16-AfGgA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1522124719135-96ddb42c76d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman facing body of water at daytime",
        },
      ],
    },
    followers: 46170,
    engagement_rate: 4.2,
    avg_like: 1440,
    avg_comment: 645,
    avg_share: 304,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 13500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 62000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100002",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000046",
    social_account_type: "user",
    social_name: "Siddhi Vilailuck",
    recommended: false,
    category: [
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
    ],
    media: {
      images: [
        {
          file_id: "MVT64DA8dqQ",
          file_name: "We Bad and We Know It",
          url: "https://images.unsplash.com/photo-1533596145255-9591c8ba0b7e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white dress beside man",
        },
        {
          file_id: "CCQvy4C_WX4",
          file_name: "Together at sunset",
          url: "https://images.unsplash.com/photo-1496523720220-b62e33cf3161?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman holding hands white facing sunset",
        },
        {
          file_id: "vLENm-coX5Y",
          file_name: null,
          url: "https://images.unsplash.com/photo-1541089404510-5c9a779841fc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman hugging each other",
        },
        {
          file_id: "dqa2LjshmWY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1512141633501-8eb74114f568?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman hugging man selective focus photography",
        },
        {
          file_id: "5LZBFMe7C3c",
          file_name:
            "My soon to be wife and I on one of our first shoots.\n\nIf you find my photos useful, please consider subscribing to me on YouTube for the occasional photography tutorial and much more - https://bit.ly/3smVlKp ",
          url: "https://images.unsplash.com/photo-1504730513966-dfcd6e53fdc8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man hugging woman on top of hill during nighttime",
        },
      ],
    },
    followers: 47997,
    engagement_rate: 2.43,
    avg_like: 1684,
    avg_comment: 1218,
    avg_share: 229,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 1500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 42000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100003",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000955",
    social_account_type: "user",
    social_name: "Chaisai Tansoongnen",
    recommended: true,
    category: [
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
    ],
    media: {
      images: [
        {
          file_id: "i9uIhSlOWYc",
          file_name:
            "If you are lucky, you grow old and become a village elder. For the Karen people, they do not keep record of their ages. Instead, they recall a certain past event that occurred when they were born.",
          url: "https://images.unsplash.com/photo-1488820098099-8d4a4723a490?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man holding brown stick smiling",
        },
        {
          file_id: "RQAf_3Iagqw",
          file_name:
            "Omar is my main training partner at the Gym this was after his 13th fight, and 13th victory and 3rd National Belt. He has since won 4 more fights and collected another belt. He is one of the top amateur Nak Muays in America and I’m honored to get beat up by him regularly.",
          url: "https://images.unsplash.com/photo-1525871685609-48be2dbd54f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "grayscale photo of boxer",
        },
        {
          file_id: "1nRWSg1ipPs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1545252682-2d32e48111da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "white van on mountain",
        },
        {
          file_id: "UoLWIlRg-8A",
          file_name: null,
          url: "https://images.unsplash.com/photo-1528956357149-92a27fd6349e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown jacket wearing sunglasses facing the wall",
        },
        {
          file_id: "XK2H0My1ZL8",
          file_name: "Thai Street Vendor",
          url: "https://images.unsplash.com/photo-1524912158767-3e3c6e103529?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white top sitting in front of food stall",
        },
        {
          file_id: "jAke8NofTtE",
          file_name:
            "My friend Mo was fighting so he invited me to come along.  I took this picture of his coach and him in-between rounds.  Mo ended up winning the fight in a unanimous decision by the referee.",
          url: "https://images.unsplash.com/photo-1529165980561-f19d4acc4f3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black talking to boxer inside ring",
        },
        {
          file_id: "J1hMrwSKfE4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1615477777908-f0a892b84067?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "person holding clear glass ball",
        },
        {
          file_id: "lkv16-AfGgA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1522124719135-96ddb42c76d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman facing body of water at daytime",
        },
      ],
    },
    followers: 20286,
    engagement_rate: 1.87,
    avg_like: 1398,
    avg_comment: 334,
    avg_share: 97,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 31000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 73500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100004",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000604",
    social_account_type: "user",
    social_name: "Chuan Kriangsak",
    recommended: true,
    category: [],
    media: {
      images: [
        {
          file_id: "sySclyGGJv4",
          file_name: "Boy counting for hide and seek",
          url: "https://images.unsplash.com/photo-1529330622332-87c22f3114d7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "boy covering his face while standing",
        },
        {
          file_id: "y3CDFIbCo4I",
          file_name: "dope vintage look with cool sunglasses on a rooftop",
          url: "https://images.unsplash.com/photo-1596333104666-1e56f179269a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in black framed eyeglasses and black crew neck shirt",
        },
        {
          file_id: "2LdvigX5xkc",
          file_name: null,
          url: "https://images.unsplash.com/photo-1578832842586-514eb4695010?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man standing on the horse",
        },
        {
          file_id: "gi3hspD9F-4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1541980161-965b576fe443?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "topless man standing near wall",
        },
        {
          file_id: "oYQ7MpUeP44",
          file_name: null,
          url: "https://images.unsplash.com/photo-1596389662344-80f794f1ca99?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in black jacket and black pants sitting on brown concrete bench during daytime",
        },
        {
          file_id: "pU4J5VFnqCQ",
          file_name: null,
          url: "https://images.unsplash.com/photo-1563993297290-609c9406efcd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "women's white camisole top and black skirt",
        },
        {
          file_id: "RyinJoJNCS8",
          file_name: null,
          url: "https://images.unsplash.com/photo-1580551881592-a5c0f309e91f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in grey and white floral button up t-shirt and blue cap holding a black",
        },
        {
          file_id: "GZ7pOiR2VX4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1605042493231-d975adf96740?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black jacket standing on road during daytime",
        },
      ],
    },
    followers: 4515,
    engagement_rate: 3.73,
    avg_like: 1081,
    avg_comment: 208,
    avg_share: 87,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 39000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 42500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100005",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000648",
    social_account_type: "user",
    social_name: "Srimuang Praves",
    recommended: false,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "2Ts5HnA67k8",
          file_name: "Pug in a blanket",
          url: "https://images.unsplash.com/photo-1453227588063-bb302b62f50b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "pug covered with blanket on bedspread",
        },
        {
          file_id: "TwuPHbcQ57w",
          file_name: "City Scape",
          url: "https://images.unsplash.com/photo-1484515991647-c5760fcecfc7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man taking selfie",
        },
        {
          file_id: "_iIB6g19mq4",
          file_name: "Jandre Shoot 1",
          url: "https://images.unsplash.com/photo-1551860863-d98db3dbbee3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man wearing gray pullover hoodie",
        },
        {
          file_id: "uTZ9UIxUKVY",
          file_name: "Vintage model",
          url: "https://images.unsplash.com/photo-1576800774081-68c65e87becc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "RCfi7vgJjUY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1560114928-40f1f1eb26a0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "orange tabby kitten in grasses",
        },
        {
          file_id: "DMVD9RkZIwQ",
          file_name: "dark tones",
          url: "https://images.unsplash.com/photo-1507081323647-4d250478b919?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray crew-neck t-shirt",
        },
        {
          file_id: "9QW52RyBLao",
          file_name: null,
          url: "https://images.unsplash.com/photo-1593757147298-e064ed1419e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white polo shirt",
        },
      ],
    },
    followers: 71225,
    engagement_rate: 2.7199999999999998,
    avg_like: 1127,
    avg_comment: 1146,
    avg_share: 104,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 45500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 60000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100006",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000203",
    social_account_type: "user",
    social_name: "Manitho Darawan",
    recommended: false,
    category: [
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "MVT64DA8dqQ",
          file_name: "We Bad and We Know It",
          url: "https://images.unsplash.com/photo-1533596145255-9591c8ba0b7e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white dress beside man",
        },
        {
          file_id: "CCQvy4C_WX4",
          file_name: "Together at sunset",
          url: "https://images.unsplash.com/photo-1496523720220-b62e33cf3161?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman holding hands white facing sunset",
        },
        {
          file_id: "vLENm-coX5Y",
          file_name: null,
          url: "https://images.unsplash.com/photo-1541089404510-5c9a779841fc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman hugging each other",
        },
        {
          file_id: "dqa2LjshmWY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1512141633501-8eb74114f568?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman hugging man selective focus photography",
        },
        {
          file_id: "5LZBFMe7C3c",
          file_name:
            "My soon to be wife and I on one of our first shoots.\n\nIf you find my photos useful, please consider subscribing to me on YouTube for the occasional photography tutorial and much more - https://bit.ly/3smVlKp ",
          url: "https://images.unsplash.com/photo-1504730513966-dfcd6e53fdc8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man hugging woman on top of hill during nighttime",
        },
      ],
    },
    followers: 49749,
    engagement_rate: 2.16,
    avg_like: 1450,
    avg_comment: 473,
    avg_share: 590,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 11000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 37000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100007",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000990",
    social_account_type: "user",
    social_name: "Phassakorn Bunyasarn",
    recommended: true,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
    ],
    media: {
      images: [
        {
          file_id: "2Ts5HnA67k8",
          file_name: "Pug in a blanket",
          url: "https://images.unsplash.com/photo-1453227588063-bb302b62f50b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "pug covered with blanket on bedspread",
        },
        {
          file_id: "TwuPHbcQ57w",
          file_name: "City Scape",
          url: "https://images.unsplash.com/photo-1484515991647-c5760fcecfc7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man taking selfie",
        },
        {
          file_id: "_iIB6g19mq4",
          file_name: "Jandre Shoot 1",
          url: "https://images.unsplash.com/photo-1551860863-d98db3dbbee3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man wearing gray pullover hoodie",
        },
        {
          file_id: "uTZ9UIxUKVY",
          file_name: "Vintage model",
          url: "https://images.unsplash.com/photo-1576800774081-68c65e87becc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "RCfi7vgJjUY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1560114928-40f1f1eb26a0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "orange tabby kitten in grasses",
        },
        {
          file_id: "DMVD9RkZIwQ",
          file_name: "dark tones",
          url: "https://images.unsplash.com/photo-1507081323647-4d250478b919?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray crew-neck t-shirt",
        },
      ],
    },
    followers: 25323,
    engagement_rate: 1.17,
    avg_like: 868,
    avg_comment: 99,
    avg_share: 563,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 4500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 63000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100008",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000741",
    social_account_type: "user",
    social_name: "Theeranai Preecha",
    recommended: true,
    category: [],
    media: {
      images: [
        {
          file_id: "i9uIhSlOWYc",
          file_name:
            "If you are lucky, you grow old and become a village elder. For the Karen people, they do not keep record of their ages. Instead, they recall a certain past event that occurred when they were born.",
          url: "https://images.unsplash.com/photo-1488820098099-8d4a4723a490?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man holding brown stick smiling",
        },
        {
          file_id: "RQAf_3Iagqw",
          file_name:
            "Omar is my main training partner at the Gym this was after his 13th fight, and 13th victory and 3rd National Belt. He has since won 4 more fights and collected another belt. He is one of the top amateur Nak Muays in America and I’m honored to get beat up by him regularly.",
          url: "https://images.unsplash.com/photo-1525871685609-48be2dbd54f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "grayscale photo of boxer",
        },
        {
          file_id: "1nRWSg1ipPs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1545252682-2d32e48111da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "white van on mountain",
        },
        {
          file_id: "UoLWIlRg-8A",
          file_name: null,
          url: "https://images.unsplash.com/photo-1528956357149-92a27fd6349e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown jacket wearing sunglasses facing the wall",
        },
        {
          file_id: "XK2H0My1ZL8",
          file_name: "Thai Street Vendor",
          url: "https://images.unsplash.com/photo-1524912158767-3e3c6e103529?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white top sitting in front of food stall",
        },
        {
          file_id: "jAke8NofTtE",
          file_name:
            "My friend Mo was fighting so he invited me to come along.  I took this picture of his coach and him in-between rounds.  Mo ended up winning the fight in a unanimous decision by the referee.",
          url: "https://images.unsplash.com/photo-1529165980561-f19d4acc4f3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black talking to boxer inside ring",
        },
        {
          file_id: "J1hMrwSKfE4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1615477777908-f0a892b84067?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "person holding clear glass ball",
        },
      ],
    },
    followers: 1262,
    engagement_rate: 1.65,
    avg_like: 737,
    avg_comment: 1249,
    avg_share: 520,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 22500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 61500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100009",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000520",
    social_account_type: "user",
    social_name: "Nuengnimman Chamlong",
    recommended: true,
    category: [
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "EZ4TYgXPNWk",
          file_name:
            "Portrait of a smiling man in clean cloths on a white background.\nSelf portrait taken to test a brand new EOS RP.",
          url: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray button up shirt",
        },
        {
          file_id: "7rIE9DPdo80",
          file_name: null,
          url: "https://images.unsplash.com/photo-1592023031338-ee30ef94abbc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white button up shirt wearing black sunglasses",
        },
        {
          file_id: "v2aKnjMbP_k",
          file_name: "Tajik boy...",
          url: "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "men's gray crew-neck shirt",
        },
        {
          file_id: "BI91NrppE38",
          file_name:
            "There is nothing like a summer day in the heart of the Romanian mountains. Photograph of my brother, taken by one of his friends and edited by me.",
          url: "https://images.unsplash.com/photo-1508341591423-4347099e1f19?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man standing near to tree",
        },
        {
          file_id: "OZkKls0Rirc",
          file_name: "Instagram: www.instagram.com/davidboca/",
          url: "https://images.unsplash.com/photo-1535124406821-d2848dfbb25c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man climbing ciff",
        },
        {
          file_id: "orYdcTza06E",
          file_name:
            "My instagram - aiony\r\n \r\n Location: Almaty, Kazakhstan,ArtHouse\r\n Model: Dana Shomak\r\n Style: Bekzat Sultangali\r\n \r\n Please tag me if you post my photo!",
          url: "https://images.unsplash.com/photo-1542996966-2e31c00bae31?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing in front of wall",
        },
      ],
    },
    followers: 19492,
    engagement_rate: 1.63,
    avg_like: 2438,
    avg_comment: 1184,
    avg_share: 221,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 48000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 51000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-100010",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000403",
    social_account_type: "user",
    social_name: "Pathit Yodsuwan",
    recommended: true,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "EZ4TYgXPNWk",
          file_name:
            "Portrait of a smiling man in clean cloths on a white background.\nSelf portrait taken to test a brand new EOS RP.",
          url: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray button up shirt",
        },
        {
          file_id: "XtmpHM7uKCs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1542847970-584270eb111b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man wearing tank top",
        },
        {
          file_id: "E9PFbdhZmus",
          file_name: "The man froze in the photo but not thoughts",
          url: "https://images.unsplash.com/photo-1579038773867-044c48829161?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man sitting on chair",
        },
        {
          file_id: "oYQ7MpUeP44",
          file_name: null,
          url: "https://images.unsplash.com/photo-1596389662344-80f794f1ca99?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in black jacket and black pants sitting on brown concrete bench during daytime",
        },
        {
          file_id: "r0l0Es5fbbs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1544179559-032b931c661e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown zip-up jacket",
        },
      ],
    },
    followers: 19721,
    engagement_rate: 1.27,
    avg_like: 397,
    avg_comment: 341,
    avg_share: 54,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 16000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 32000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000011",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000777",
    social_account_type: "user",
    social_name: "Manya-phathon Srisai",
    recommended: false,
    category: [
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "emlKHDEydhg",
          file_name: null,
          url: "https://images.unsplash.com/photo-1485811661309-ab85183a729c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing blue denim jacket",
        },
        {
          file_id: "8a95EVm0ovQ",
          file_name: null,
          url: "https://images.unsplash.com/photo-1484399172022-72a90b12e3c1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing black knitted cap",
        },
        {
          file_id: "UH9ax_FS-bw",
          file_name: "Alone In The Woods",
          url: "https://images.unsplash.com/photo-1494290050113-90f628448a21?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "selective color of woman stepping downstairs",
        },
        {
          file_id: "JG3Vns8bNRI",
          file_name: "Save me.",
          url: "https://images.unsplash.com/photo-1509891970056-05b5353d4357?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in gray long-sleeved shirt seating under tree",
        },
        {
          file_id: "iHW-JTEkr2c",
          file_name: null,
          url: "https://images.unsplash.com/photo-1483840128890-2e61c0d5516d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in sleeveless top on plants field",
        },
        {
          file_id: "6Xai7XxOgBc",
          file_name: null,
          url: "https://images.unsplash.com/photo-1550928431-ee0ec6db30d3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing red dress kneeling on sand",
        },
      ],
    },
    followers: 56257,
    engagement_rate: 5.51,
    avg_like: 605,
    avg_comment: 734,
    avg_share: 203,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 17000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 25500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000012",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000672",
    social_account_type: "user",
    social_name: "Ma-dee Sawasdipon",
    recommended: false,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "rDEOVtE7vOs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "shallow focus photography of woman outdoor during day",
        },
        {
          file_id: "LyeduBb2Auk",
          file_name: "Soft Curls",
          url: "https://images.unsplash.com/photo-1440589473619-3cde28941638?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman blonde hair and red lipstick",
        },
        {
          file_id: "va0YmkIFtPA",
          file_name:
            "I decided to print my own towel design for this shot. The model was someone I met through Instagram and she was great to work with and keen to sign the model release form.",
          url: "https://images.unsplash.com/photo-1482555670981-4de159d8553b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing monokini and standing on seashore",
        },
        {
          file_id: "6PROVhY2Yq4",
          file_name: "understand what you want in life",
          url: "https://images.unsplash.com/photo-1512310604669-443f26c35f52?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman sitting on gray concrete platform during daytime",
        },
        {
          file_id: "BGz8vO3pK8k",
          file_name: "Woman behind cherry blossoms",
          url: "https://images.unsplash.com/photo-1464863979621-258859e62245?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing near white petaled flower",
        },
        {
          file_id: "3hoAon9Mc88",
          file_name: "Brunette woman portrait",
          url: "https://images.unsplash.com/photo-1445404590072-16ef9c18bd83?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing spaghetti strap top in front of bush",
        },
        {
          file_id: "gG70fyu3qsg",
          file_name: null,
          url: "https://images.unsplash.com/photo-1514315384763-ba401779410f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman sitting on rock during daytime",
        },
        {
          file_id: "8HqPXTToMn0",
          file_name: "Stretching Her Arms",
          url: "https://images.unsplash.com/photo-1503342394128-c104d54dba01?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman stretching her arm upward",
        },
      ],
    },
    followers: 64245,
    engagement_rate: 1.8199999999999998,
    avg_like: 354,
    avg_comment: 40,
    avg_share: 416,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 20500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 58500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000013",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000247",
    social_account_type: "user",
    social_name: "Punngarm Srisati",
    recommended: false,
    category: [],
    media: {
      images: [
        {
          file_id: "-2vD8lIhdnw",
          file_name: null,
          url: "https://images.unsplash.com/photo-1516534775068-ba3e7458af70?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman biting pencil while sitting on chair in front of computer during daytime",
        },
        {
          file_id: "sLwkS2P-Ryw",
          file_name:
            "Two young women stopped to hoist their signs over their heads during the women’s march as onlookers with cameras took pictures, myself included. It was one of many moments that felt joyous during the march.",
          url: "https://images.unsplash.com/photo-1512404190248-ad677758134a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women raising banners",
        },
        {
          file_id: "wVYnSXIil6U",
          file_name:
            "The bee is a representation of our loved ones. They’re there to remind us that we are flowers and worthy of caring for. Never forget that you a so worthy a beautiful!",
          url: "https://images.unsplash.com/photo-1543366749-ad19659450ef?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman's face",
        },
        {
          file_id: "vLwH8bWoi_8",
          file_name: null,
          url: "https://images.unsplash.com/photo-1573164574001-518958d9baa2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sits on padded chair while using MacBook during daytime",
        },
        {
          file_id: "rfyXoYrcYoA",
          file_name: "Holding On",
          url: "https://images.unsplash.com/photo-1487611739259-6ccdf9a9daf3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing holding a brown leather tote bag",
        },
        {
          file_id: "R-8DsF0Dcwk",
          file_name: "So take a deep breath and let it go",
          url: "https://images.unsplash.com/photo-1484240950164-450be1af341f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman covering her mouth with green leaf",
        },
      ],
    },
    followers: 53908,
    engagement_rate: 3.54,
    avg_like: 1308,
    avg_comment: 823,
    avg_share: 212,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 31000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 74500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000014",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000078",
    social_account_type: "user",
    social_name: "Chawiwan Chao",
    recommended: false,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "lNm2Px2UYB4",
          file_name: "Strangers in Sync",
          url: "https://images.unsplash.com/photo-1521422529055-2d9adb679ed0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women standing in front of yellow wall",
        },
        {
          file_id: "dcZsxUAAJXs",
          file_name: "2016.10.03",
          url: "https://images.unsplash.com/photo-1475823678248-624fc6f85785?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing near body of water",
        },
        {
          file_id: "aXDj_NiS05o",
          file_name: "Girl.",
          url: "https://images.unsplash.com/photo-1515734674582-29010bb37906?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sitting on white metal stair railing during daytime",
        },
        {
          file_id: "y81Rtrrbdzw",
          file_name: "Tea ceremony",
          url: "https://images.unsplash.com/photo-1538722909817-e4243928a610?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman making coffee",
        },
        {
          file_id: "p-a7rnHtk1s",
          file_name: null,
          url: "https://images.unsplash.com/photo-1543458083-d625ee4ef9c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman doing yoga pose",
        },
      ],
    },
    followers: 8584,
    engagement_rate: 1.56,
    avg_like: 698,
    avg_comment: 454,
    avg_share: 42,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 41000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 48500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000015",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000679",
    social_account_type: "user",
    social_name: "Butri Taksin",
    recommended: false,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "4bmtMXGuVqo",
          file_name: null,
          url: "https://images.unsplash.com/photo-1460904577954-8fadb262612c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "3N2vAlrTWos",
          file_name: "Woman on the rocks",
          url: "https://images.unsplash.com/photo-1466314760659-7a0975a51304?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman holding clear glass bottle during daytime",
        },
        {
          file_id: "oWDRVgk04EA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1497340525489-441e8427c980?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "selective focus photography of girl sitting near tree",
        },
        {
          file_id: "vTru22pWbGM",
          file_name: "Woman standing in swimsuit",
          url: "https://images.unsplash.com/photo-1444913220552-fe31fed9c5bd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing on the shore",
        },
        {
          file_id: "qYanN54gIrI",
          file_name: "Contemplating woman",
          url: "https://images.unsplash.com/photo-1475938476802-32a7e851dad1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman praying",
        },
      ],
    },
    followers: 9549,
    engagement_rate: 5.67,
    avg_like: 1731,
    avg_comment: 225,
    avg_share: 328,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 16000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 56000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000016",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000535",
    social_account_type: "user",
    social_name: "Noklek Chalerm",
    recommended: false,
    category: [],
    media: {
      images: [
        {
          file_id: "k_MRo-VOWOg",
          file_name: "Red Dress & Jean Jacket",
          url: "https://images.unsplash.com/photo-1541647249291-71c1d98ce84f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing blue denim jacket",
        },
        {
          file_id: "5zsSJ5U3us4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1582164838301-3454c11a7523?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in red long sleeve shirt and blue denim jeans leaning on wall",
        },
        {
          file_id: "g0DdUOYHx5o",
          file_name: null,
          url: "https://images.unsplash.com/photo-1587762046729-c5fe0cd88abc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing on brown grass field during daytime",
        },
        {
          file_id: "-_tUHvw09jY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1595868321403-bac51fbdeb97?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing beside green plant",
        },
        {
          file_id: "epBh_ogRXiU",
          file_name: null,
          url: "https://images.unsplash.com/photo-1566942974683-0a1aa5d212f1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white long-sleeved dress",
        },
        {
          file_id: "0UECcInuCR4",
          file_name:
            "Model - Ciera Nicole: http://www.instagram.com/imcieranicole2\nPhotographer - Judeus Samson: https://www.instagram.com/shotbyjudeus/",
          url: "https://images.unsplash.com/photo-1545291730-faff8ca1d4b0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing in the middle of the road",
        },
      ],
    },
    followers: 19814,
    engagement_rate: 1.37,
    avg_like: 1211,
    avg_comment: 950,
    avg_share: 358,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 14500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 65000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000017",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000390",
    social_account_type: "user",
    social_name: "Choi Kasamsun",
    recommended: true,
    category: [
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
    ],
    media: {
      images: [
        {
          file_id: "k_MRo-VOWOg",
          file_name: "Red Dress & Jean Jacket",
          url: "https://images.unsplash.com/photo-1541647249291-71c1d98ce84f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing blue denim jacket",
        },
        {
          file_id: "5zsSJ5U3us4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1582164838301-3454c11a7523?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in red long sleeve shirt and blue denim jeans leaning on wall",
        },
        {
          file_id: "g0DdUOYHx5o",
          file_name: null,
          url: "https://images.unsplash.com/photo-1587762046729-c5fe0cd88abc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing on brown grass field during daytime",
        },
        {
          file_id: "-_tUHvw09jY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1595868321403-bac51fbdeb97?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing beside green plant",
        },
        {
          file_id: "epBh_ogRXiU",
          file_name: null,
          url: "https://images.unsplash.com/photo-1566942974683-0a1aa5d212f1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white long-sleeved dress",
        },
        {
          file_id: "0UECcInuCR4",
          file_name:
            "Model - Ciera Nicole: http://www.instagram.com/imcieranicole2\nPhotographer - Judeus Samson: https://www.instagram.com/shotbyjudeus/",
          url: "https://images.unsplash.com/photo-1545291730-faff8ca1d4b0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing in the middle of the road",
        },
        {
          file_id: "_cvwXhGqG-o",
          file_name:
            "This series shows the beauty of each model. The point was to make them look super cute.- jekafe\nModel's IG: dnakeys",
          url: "https://images.unsplash.com/photo-1519699047748-de8e457a634e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "mlh6I5_jCw0",
          file_name: null,
          url: "https://images.unsplash.com/photo-1567441076134-8f80ef850fc5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman wearing black and white floral spaghetti-strap dress",
        },
      ],
    },
    followers: 4050,
    engagement_rate: 4.37,
    avg_like: 2214,
    avg_comment: 434,
    avg_share: 587,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 17000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 52000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000018",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000829",
    social_account_type: "user",
    social_name: "Chomesri Sripituksakul",
    recommended: false,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "lNm2Px2UYB4",
          file_name: "Strangers in Sync",
          url: "https://images.unsplash.com/photo-1521422529055-2d9adb679ed0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women standing in front of yellow wall",
        },
        {
          file_id: "dcZsxUAAJXs",
          file_name: "2016.10.03",
          url: "https://images.unsplash.com/photo-1475823678248-624fc6f85785?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing near body of water",
        },
        {
          file_id: "aXDj_NiS05o",
          file_name: "Girl.",
          url: "https://images.unsplash.com/photo-1515734674582-29010bb37906?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sitting on white metal stair railing during daytime",
        },
        {
          file_id: "y81Rtrrbdzw",
          file_name: "Tea ceremony",
          url: "https://images.unsplash.com/photo-1538722909817-e4243928a610?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman making coffee",
        },
        {
          file_id: "p-a7rnHtk1s",
          file_name: null,
          url: "https://images.unsplash.com/photo-1543458083-d625ee4ef9c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman doing yoga pose",
        },
      ],
    },
    followers: 7836,
    engagement_rate: 4.72,
    avg_like: 770,
    avg_comment: 1009,
    avg_share: 132,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 24000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 52000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000019",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000850",
    social_account_type: "user",
    social_name: "Chompoo Kurusarttra",
    recommended: false,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "4bmtMXGuVqo",
          file_name: null,
          url: "https://images.unsplash.com/photo-1460904577954-8fadb262612c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "3N2vAlrTWos",
          file_name: "Woman on the rocks",
          url: "https://images.unsplash.com/photo-1466314760659-7a0975a51304?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman holding clear glass bottle during daytime",
        },
        {
          file_id: "oWDRVgk04EA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1497340525489-441e8427c980?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "selective focus photography of girl sitting near tree",
        },
        {
          file_id: "vTru22pWbGM",
          file_name: "Woman standing in swimsuit",
          url: "https://images.unsplash.com/photo-1444913220552-fe31fed9c5bd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing on the shore",
        },
        {
          file_id: "qYanN54gIrI",
          file_name: "Contemplating woman",
          url: "https://images.unsplash.com/photo-1475938476802-32a7e851dad1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman praying",
        },
        {
          file_id: "3402kvtHhOo",
          file_name: null,
          url: "https://images.unsplash.com/photo-1514626585111-9aa86183ac98?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "shallow focus photo of woman face",
        },
        {
          file_id: "HwNCyLWw7hw",
          file_name: "Clara G",
          url: "https://images.unsplash.com/photo-1500305614571-ae5b6592e65d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sitting on brown sofa under slow down neon signage",
        },
      ],
    },
    followers: 42462,
    engagement_rate: 4.08,
    avg_like: 372,
    avg_comment: 1384,
    avg_share: 598,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 49000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 49000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000020",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000398",
    social_account_type: "user",
    social_name: "Thong Siriwanich",
    recommended: false,
    category: [
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
    ],
    media: {
      images: [
        {
          file_id: "-2vD8lIhdnw",
          file_name: null,
          url: "https://images.unsplash.com/photo-1516534775068-ba3e7458af70?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman biting pencil while sitting on chair in front of computer during daytime",
        },
        {
          file_id: "sLwkS2P-Ryw",
          file_name:
            "Two young women stopped to hoist their signs over their heads during the women’s march as onlookers with cameras took pictures, myself included. It was one of many moments that felt joyous during the march.",
          url: "https://images.unsplash.com/photo-1512404190248-ad677758134a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women raising banners",
        },
        {
          file_id: "wVYnSXIil6U",
          file_name:
            "The bee is a representation of our loved ones. They’re there to remind us that we are flowers and worthy of caring for. Never forget that you a so worthy a beautiful!",
          url: "https://images.unsplash.com/photo-1543366749-ad19659450ef?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman's face",
        },
        {
          file_id: "vLwH8bWoi_8",
          file_name: null,
          url: "https://images.unsplash.com/photo-1573164574001-518958d9baa2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sits on padded chair while using MacBook during daytime",
        },
        {
          file_id: "rfyXoYrcYoA",
          file_name: "Holding On",
          url: "https://images.unsplash.com/photo-1487611739259-6ccdf9a9daf3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing holding a brown leather tote bag",
        },
      ],
    },
    followers: 41439,
    engagement_rate: 4.36,
    avg_like: 992,
    avg_comment: 898,
    avg_share: 566,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 2000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 33500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000001",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000453",
    social_account_type: "user",
    social_name: "Karin Chaiyasan",
    recommended: false,
    category: [
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "i9uIhSlOWYc",
          file_name:
            "If you are lucky, you grow old and become a village elder. For the Karen people, they do not keep record of their ages. Instead, they recall a certain past event that occurred when they were born.",
          url: "https://images.unsplash.com/photo-1488820098099-8d4a4723a490?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man holding brown stick smiling",
        },
        {
          file_id: "RQAf_3Iagqw",
          file_name:
            "Omar is my main training partner at the Gym this was after his 13th fight, and 13th victory and 3rd National Belt. He has since won 4 more fights and collected another belt. He is one of the top amateur Nak Muays in America and I’m honored to get beat up by him regularly.",
          url: "https://images.unsplash.com/photo-1525871685609-48be2dbd54f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "grayscale photo of boxer",
        },
        {
          file_id: "1nRWSg1ipPs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1545252682-2d32e48111da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "white van on mountain",
        },
        {
          file_id: "UoLWIlRg-8A",
          file_name: null,
          url: "https://images.unsplash.com/photo-1528956357149-92a27fd6349e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown jacket wearing sunglasses facing the wall",
        },
        {
          file_id: "XK2H0My1ZL8",
          file_name: "Thai Street Vendor",
          url: "https://images.unsplash.com/photo-1524912158767-3e3c6e103529?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white top sitting in front of food stall",
        },
        {
          file_id: "jAke8NofTtE",
          file_name:
            "My friend Mo was fighting so he invited me to come along.  I took this picture of his coach and him in-between rounds.  Mo ended up winning the fight in a unanimous decision by the referee.",
          url: "https://images.unsplash.com/photo-1529165980561-f19d4acc4f3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black talking to boxer inside ring",
        },
        {
          file_id: "J1hMrwSKfE4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1615477777908-f0a892b84067?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "person holding clear glass ball",
        },
        {
          file_id: "lkv16-AfGgA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1522124719135-96ddb42c76d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman facing body of water at daytime",
        },
      ],
    },
    followers: 46170,
    engagement_rate: 4.2,
    avg_like: 1440,
    avg_comment: 645,
    avg_share: 304,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 13500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 62000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000002",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000046",
    social_account_type: "user",
    social_name: "Siddhi Vilailuck",
    recommended: false,
    category: [
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
    ],
    media: {
      images: [
        {
          file_id: "MVT64DA8dqQ",
          file_name: "We Bad and We Know It",
          url: "https://images.unsplash.com/photo-1533596145255-9591c8ba0b7e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white dress beside man",
        },
        {
          file_id: "CCQvy4C_WX4",
          file_name: "Together at sunset",
          url: "https://images.unsplash.com/photo-1496523720220-b62e33cf3161?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman holding hands white facing sunset",
        },
        {
          file_id: "vLENm-coX5Y",
          file_name: null,
          url: "https://images.unsplash.com/photo-1541089404510-5c9a779841fc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman hugging each other",
        },
        {
          file_id: "dqa2LjshmWY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1512141633501-8eb74114f568?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman hugging man selective focus photography",
        },
        {
          file_id: "5LZBFMe7C3c",
          file_name:
            "My soon to be wife and I on one of our first shoots.\n\nIf you find my photos useful, please consider subscribing to me on YouTube for the occasional photography tutorial and much more - https://bit.ly/3smVlKp ",
          url: "https://images.unsplash.com/photo-1504730513966-dfcd6e53fdc8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man hugging woman on top of hill during nighttime",
        },
      ],
    },
    followers: 47997,
    engagement_rate: 2.43,
    avg_like: 1684,
    avg_comment: 1218,
    avg_share: 229,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 1500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 42000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000003",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000955",
    social_account_type: "user",
    social_name: "Chaisai Tansoongnen",
    recommended: true,
    category: [
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
    ],
    media: {
      images: [
        {
          file_id: "i9uIhSlOWYc",
          file_name:
            "If you are lucky, you grow old and become a village elder. For the Karen people, they do not keep record of their ages. Instead, they recall a certain past event that occurred when they were born.",
          url: "https://images.unsplash.com/photo-1488820098099-8d4a4723a490?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man holding brown stick smiling",
        },
        {
          file_id: "RQAf_3Iagqw",
          file_name:
            "Omar is my main training partner at the Gym this was after his 13th fight, and 13th victory and 3rd National Belt. He has since won 4 more fights and collected another belt. He is one of the top amateur Nak Muays in America and I’m honored to get beat up by him regularly.",
          url: "https://images.unsplash.com/photo-1525871685609-48be2dbd54f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "grayscale photo of boxer",
        },
        {
          file_id: "1nRWSg1ipPs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1545252682-2d32e48111da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "white van on mountain",
        },
        {
          file_id: "UoLWIlRg-8A",
          file_name: null,
          url: "https://images.unsplash.com/photo-1528956357149-92a27fd6349e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown jacket wearing sunglasses facing the wall",
        },
        {
          file_id: "XK2H0My1ZL8",
          file_name: "Thai Street Vendor",
          url: "https://images.unsplash.com/photo-1524912158767-3e3c6e103529?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white top sitting in front of food stall",
        },
        {
          file_id: "jAke8NofTtE",
          file_name:
            "My friend Mo was fighting so he invited me to come along.  I took this picture of his coach and him in-between rounds.  Mo ended up winning the fight in a unanimous decision by the referee.",
          url: "https://images.unsplash.com/photo-1529165980561-f19d4acc4f3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black talking to boxer inside ring",
        },
        {
          file_id: "J1hMrwSKfE4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1615477777908-f0a892b84067?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "person holding clear glass ball",
        },
        {
          file_id: "lkv16-AfGgA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1522124719135-96ddb42c76d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5MQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman facing body of water at daytime",
        },
      ],
    },
    followers: 20286,
    engagement_rate: 1.87,
    avg_like: 1398,
    avg_comment: 334,
    avg_share: 97,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 31000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 73500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000004",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000604",
    social_account_type: "user",
    social_name: "Chuan Kriangsak",
    recommended: true,
    category: [],
    media: {
      images: [
        {
          file_id: "sySclyGGJv4",
          file_name: "Boy counting for hide and seek",
          url: "https://images.unsplash.com/photo-1529330622332-87c22f3114d7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "boy covering his face while standing",
        },
        {
          file_id: "y3CDFIbCo4I",
          file_name: "dope vintage look with cool sunglasses on a rooftop",
          url: "https://images.unsplash.com/photo-1596333104666-1e56f179269a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in black framed eyeglasses and black crew neck shirt",
        },
        {
          file_id: "2LdvigX5xkc",
          file_name: null,
          url: "https://images.unsplash.com/photo-1578832842586-514eb4695010?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man standing on the horse",
        },
        {
          file_id: "gi3hspD9F-4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1541980161-965b576fe443?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "topless man standing near wall",
        },
        {
          file_id: "oYQ7MpUeP44",
          file_name: null,
          url: "https://images.unsplash.com/photo-1596389662344-80f794f1ca99?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in black jacket and black pants sitting on brown concrete bench during daytime",
        },
        {
          file_id: "pU4J5VFnqCQ",
          file_name: null,
          url: "https://images.unsplash.com/photo-1563993297290-609c9406efcd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "women's white camisole top and black skirt",
        },
        {
          file_id: "RyinJoJNCS8",
          file_name: null,
          url: "https://images.unsplash.com/photo-1580551881592-a5c0f309e91f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in grey and white floral button up t-shirt and blue cap holding a black",
        },
        {
          file_id: "GZ7pOiR2VX4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1605042493231-d975adf96740?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxQcmV0dHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzkz&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black jacket standing on road during daytime",
        },
      ],
    },
    followers: 4515,
    engagement_rate: 3.73,
    avg_like: 1081,
    avg_comment: 208,
    avg_share: 87,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 39000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 42500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000005",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000648",
    social_account_type: "user",
    social_name: "Srimuang Praves",
    recommended: false,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "2Ts5HnA67k8",
          file_name: "Pug in a blanket",
          url: "https://images.unsplash.com/photo-1453227588063-bb302b62f50b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "pug covered with blanket on bedspread",
        },
        {
          file_id: "TwuPHbcQ57w",
          file_name: "City Scape",
          url: "https://images.unsplash.com/photo-1484515991647-c5760fcecfc7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man taking selfie",
        },
        {
          file_id: "_iIB6g19mq4",
          file_name: "Jandre Shoot 1",
          url: "https://images.unsplash.com/photo-1551860863-d98db3dbbee3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man wearing gray pullover hoodie",
        },
        {
          file_id: "uTZ9UIxUKVY",
          file_name: "Vintage model",
          url: "https://images.unsplash.com/photo-1576800774081-68c65e87becc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "RCfi7vgJjUY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1560114928-40f1f1eb26a0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "orange tabby kitten in grasses",
        },
        {
          file_id: "DMVD9RkZIwQ",
          file_name: "dark tones",
          url: "https://images.unsplash.com/photo-1507081323647-4d250478b919?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray crew-neck t-shirt",
        },
        {
          file_id: "9QW52RyBLao",
          file_name: null,
          url: "https://images.unsplash.com/photo-1593757147298-e064ed1419e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5Mw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white polo shirt",
        },
      ],
    },
    followers: 71225,
    engagement_rate: 2.7199999999999998,
    avg_like: 1127,
    avg_comment: 1146,
    avg_share: 104,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 45500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 60000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000006",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000203",
    social_account_type: "user",
    social_name: "Manitho Darawan",
    recommended: false,
    category: [
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "MVT64DA8dqQ",
          file_name: "We Bad and We Know It",
          url: "https://images.unsplash.com/photo-1533596145255-9591c8ba0b7e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white dress beside man",
        },
        {
          file_id: "CCQvy4C_WX4",
          file_name: "Together at sunset",
          url: "https://images.unsplash.com/photo-1496523720220-b62e33cf3161?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman holding hands white facing sunset",
        },
        {
          file_id: "vLENm-coX5Y",
          file_name: null,
          url: "https://images.unsplash.com/photo-1541089404510-5c9a779841fc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man and woman hugging each other",
        },
        {
          file_id: "dqa2LjshmWY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1512141633501-8eb74114f568?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman hugging man selective focus photography",
        },
        {
          file_id: "5LZBFMe7C3c",
          file_name:
            "My soon to be wife and I on one of our first shoots.\n\nIf you find my photos useful, please consider subscribing to me on YouTube for the occasional photography tutorial and much more - https://bit.ly/3smVlKp ",
          url: "https://images.unsplash.com/photo-1504730513966-dfcd6e53fdc8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxMb3ZlbHklMjBCb3l8ZW58MHx8fHwxNjU0MDA3Nzky&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man hugging woman on top of hill during nighttime",
        },
      ],
    },
    followers: 49749,
    engagement_rate: 2.16,
    avg_like: 1450,
    avg_comment: 473,
    avg_share: 590,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 11000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 37000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000007",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000990",
    social_account_type: "user",
    social_name: "Phassakorn Bunyasarn",
    recommended: true,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
    ],
    media: {
      images: [
        {
          file_id: "2Ts5HnA67k8",
          file_name: "Pug in a blanket",
          url: "https://images.unsplash.com/photo-1453227588063-bb302b62f50b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "pug covered with blanket on bedspread",
        },
        {
          file_id: "TwuPHbcQ57w",
          file_name: "City Scape",
          url: "https://images.unsplash.com/photo-1484515991647-c5760fcecfc7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man taking selfie",
        },
        {
          file_id: "_iIB6g19mq4",
          file_name: "Jandre Shoot 1",
          url: "https://images.unsplash.com/photo-1551860863-d98db3dbbee3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man wearing gray pullover hoodie",
        },
        {
          file_id: "uTZ9UIxUKVY",
          file_name: "Vintage model",
          url: "https://images.unsplash.com/photo-1576800774081-68c65e87becc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "RCfi7vgJjUY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1560114928-40f1f1eb26a0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "orange tabby kitten in grasses",
        },
        {
          file_id: "DMVD9RkZIwQ",
          file_name: "dark tones",
          url: "https://images.unsplash.com/photo-1507081323647-4d250478b919?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxDdXRlJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray crew-neck t-shirt",
        },
      ],
    },
    followers: 25323,
    engagement_rate: 1.17,
    avg_like: 868,
    avg_comment: 99,
    avg_share: 563,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 4500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 63000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000008",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000741",
    social_account_type: "user",
    social_name: "Theeranai Preecha",
    recommended: true,
    category: [],
    media: {
      images: [
        {
          file_id: "i9uIhSlOWYc",
          file_name:
            "If you are lucky, you grow old and become a village elder. For the Karen people, they do not keep record of their ages. Instead, they recall a certain past event that occurred when they were born.",
          url: "https://images.unsplash.com/photo-1488820098099-8d4a4723a490?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man holding brown stick smiling",
        },
        {
          file_id: "RQAf_3Iagqw",
          file_name:
            "Omar is my main training partner at the Gym this was after his 13th fight, and 13th victory and 3rd National Belt. He has since won 4 more fights and collected another belt. He is one of the top amateur Nak Muays in America and I’m honored to get beat up by him regularly.",
          url: "https://images.unsplash.com/photo-1525871685609-48be2dbd54f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "grayscale photo of boxer",
        },
        {
          file_id: "1nRWSg1ipPs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1545252682-2d32e48111da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "white van on mountain",
        },
        {
          file_id: "UoLWIlRg-8A",
          file_name: null,
          url: "https://images.unsplash.com/photo-1528956357149-92a27fd6349e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown jacket wearing sunglasses facing the wall",
        },
        {
          file_id: "XK2H0My1ZL8",
          file_name: "Thai Street Vendor",
          url: "https://images.unsplash.com/photo-1524912158767-3e3c6e103529?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white top sitting in front of food stall",
        },
        {
          file_id: "jAke8NofTtE",
          file_name:
            "My friend Mo was fighting so he invited me to come along.  I took this picture of his coach and him in-between rounds.  Mo ended up winning the fight in a unanimous decision by the referee.",
          url: "https://images.unsplash.com/photo-1529165980561-f19d4acc4f3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in black talking to boxer inside ring",
        },
        {
          file_id: "J1hMrwSKfE4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1615477777908-f0a892b84067?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxUaGFpJTIwR3V5fGVufDB8fHx8MTY1NDAwNzc5NQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "person holding clear glass ball",
        },
      ],
    },
    followers: 1262,
    engagement_rate: 1.65,
    avg_like: 737,
    avg_comment: 1249,
    avg_share: 520,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 22500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 61500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000009",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000520",
    social_account_type: "user",
    social_name: "Nuengnimman Chamlong",
    recommended: true,
    category: [
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "EZ4TYgXPNWk",
          file_name:
            "Portrait of a smiling man in clean cloths on a white background.\nSelf portrait taken to test a brand new EOS RP.",
          url: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray button up shirt",
        },
        {
          file_id: "7rIE9DPdo80",
          file_name: null,
          url: "https://images.unsplash.com/photo-1592023031338-ee30ef94abbc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in white button up shirt wearing black sunglasses",
        },
        {
          file_id: "v2aKnjMbP_k",
          file_name: "Tajik boy...",
          url: "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "men's gray crew-neck shirt",
        },
        {
          file_id: "BI91NrppE38",
          file_name:
            "There is nothing like a summer day in the heart of the Romanian mountains. Photograph of my brother, taken by one of his friends and edited by me.",
          url: "https://images.unsplash.com/photo-1508341591423-4347099e1f19?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man standing near to tree",
        },
        {
          file_id: "OZkKls0Rirc",
          file_name: "Instagram: www.instagram.com/davidboca/",
          url: "https://images.unsplash.com/photo-1535124406821-d2848dfbb25c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man climbing ciff",
        },
        {
          file_id: "orYdcTza06E",
          file_name:
            "My instagram - aiony\r\n \r\n Location: Almaty, Kazakhstan,ArtHouse\r\n Model: Dana Shomak\r\n Style: Bekzat Sultangali\r\n \r\n Please tag me if you post my photo!",
          url: "https://images.unsplash.com/photo-1542996966-2e31c00bae31?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxBc2lhbiUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing in front of wall",
        },
      ],
    },
    followers: 19492,
    engagement_rate: 1.63,
    avg_like: 2438,
    avg_comment: 1184,
    avg_share: 221,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 48000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 51000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000010",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000403",
    social_account_type: "user",
    social_name: "Pathit Yodsuwan",
    recommended: true,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "EZ4TYgXPNWk",
          file_name:
            "Portrait of a smiling man in clean cloths on a white background.\nSelf portrait taken to test a brand new EOS RP.",
          url: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in gray button up shirt",
        },
        {
          file_id: "XtmpHM7uKCs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1542847970-584270eb111b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man wearing tank top",
        },
        {
          file_id: "E9PFbdhZmus",
          file_name: "The man froze in the photo but not thoughts",
          url: "https://images.unsplash.com/photo-1579038773867-044c48829161?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man sitting on chair",
        },
        {
          file_id: "oYQ7MpUeP44",
          file_name: null,
          url: "https://images.unsplash.com/photo-1596389662344-80f794f1ca99?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "man in black jacket and black pants sitting on brown concrete bench during daytime",
        },
        {
          file_id: "r0l0Es5fbbs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1544179559-032b931c661e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMEd1eXxlbnwwfHx8fDE2NTQwMDc3OTY&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "man in brown zip-up jacket",
        },
      ],
    },
    followers: 19721,
    engagement_rate: 1.27,
    avg_like: 397,
    avg_comment: 341,
    avg_share: 54,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 16000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 32000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000011",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000777",
    social_account_type: "user",
    social_name: "Manya-phathon Srisai",
    recommended: false,
    category: [
      {
        id: 4,
        value: "study",
        name_en: "Study",
        name_th: "การศึกษา",
      },
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "emlKHDEydhg",
          file_name: null,
          url: "https://images.unsplash.com/photo-1485811661309-ab85183a729c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing blue denim jacket",
        },
        {
          file_id: "8a95EVm0ovQ",
          file_name: null,
          url: "https://images.unsplash.com/photo-1484399172022-72a90b12e3c1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing black knitted cap",
        },
        {
          file_id: "UH9ax_FS-bw",
          file_name: "Alone In The Woods",
          url: "https://images.unsplash.com/photo-1494290050113-90f628448a21?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "selective color of woman stepping downstairs",
        },
        {
          file_id: "JG3Vns8bNRI",
          file_name: "Save me.",
          url: "https://images.unsplash.com/photo-1509891970056-05b5353d4357?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in gray long-sleeved shirt seating under tree",
        },
        {
          file_id: "iHW-JTEkr2c",
          file_name: null,
          url: "https://images.unsplash.com/photo-1483840128890-2e61c0d5516d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in sleeveless top on plants field",
        },
        {
          file_id: "6Xai7XxOgBc",
          file_name: null,
          url: "https://images.unsplash.com/photo-1550928431-ee0ec6db30d3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBHaXJsfGVufDB8fHx8MTY1NDAwNzc5Nw&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing red dress kneeling on sand",
        },
      ],
    },
    followers: 56257,
    engagement_rate: 5.51,
    avg_like: 605,
    avg_comment: 734,
    avg_share: 203,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 17000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 25500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000012",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000672",
    social_account_type: "user",
    social_name: "Ma-dee Sawasdipon",
    recommended: false,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "rDEOVtE7vOs",
          file_name: null,
          url: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "shallow focus photography of woman outdoor during day",
        },
        {
          file_id: "LyeduBb2Auk",
          file_name: "Soft Curls",
          url: "https://images.unsplash.com/photo-1440589473619-3cde28941638?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman blonde hair and red lipstick",
        },
        {
          file_id: "va0YmkIFtPA",
          file_name:
            "I decided to print my own towel design for this shot. The model was someone I met through Instagram and she was great to work with and keen to sign the model release form.",
          url: "https://images.unsplash.com/photo-1482555670981-4de159d8553b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing monokini and standing on seashore",
        },
        {
          file_id: "6PROVhY2Yq4",
          file_name: "understand what you want in life",
          url: "https://images.unsplash.com/photo-1512310604669-443f26c35f52?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman sitting on gray concrete platform during daytime",
        },
        {
          file_id: "BGz8vO3pK8k",
          file_name: "Woman behind cherry blossoms",
          url: "https://images.unsplash.com/photo-1464863979621-258859e62245?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing near white petaled flower",
        },
        {
          file_id: "3hoAon9Mc88",
          file_name: "Brunette woman portrait",
          url: "https://images.unsplash.com/photo-1445404590072-16ef9c18bd83?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing spaghetti strap top in front of bush",
        },
        {
          file_id: "gG70fyu3qsg",
          file_name: null,
          url: "https://images.unsplash.com/photo-1514315384763-ba401779410f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman sitting on rock during daytime",
        },
        {
          file_id: "8HqPXTToMn0",
          file_name: "Stretching Her Arms",
          url: "https://images.unsplash.com/photo-1503342394128-c104d54dba01?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxBc2lhbiUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3Nzk3&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman stretching her arm upward",
        },
      ],
    },
    followers: 64245,
    engagement_rate: 1.8199999999999998,
    avg_like: 354,
    avg_comment: 40,
    avg_share: 416,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 20500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 58500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000013",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000247",
    social_account_type: "user",
    social_name: "Punngarm Srisati",
    recommended: false,
    category: [],
    media: {
      images: [
        {
          file_id: "-2vD8lIhdnw",
          file_name: null,
          url: "https://images.unsplash.com/photo-1516534775068-ba3e7458af70?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman biting pencil while sitting on chair in front of computer during daytime",
        },
        {
          file_id: "sLwkS2P-Ryw",
          file_name:
            "Two young women stopped to hoist their signs over their heads during the women’s march as onlookers with cameras took pictures, myself included. It was one of many moments that felt joyous during the march.",
          url: "https://images.unsplash.com/photo-1512404190248-ad677758134a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women raising banners",
        },
        {
          file_id: "wVYnSXIil6U",
          file_name:
            "The bee is a representation of our loved ones. They’re there to remind us that we are flowers and worthy of caring for. Never forget that you a so worthy a beautiful!",
          url: "https://images.unsplash.com/photo-1543366749-ad19659450ef?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman's face",
        },
        {
          file_id: "vLwH8bWoi_8",
          file_name: null,
          url: "https://images.unsplash.com/photo-1573164574001-518958d9baa2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sits on padded chair while using MacBook during daytime",
        },
        {
          file_id: "rfyXoYrcYoA",
          file_name: "Holding On",
          url: "https://images.unsplash.com/photo-1487611739259-6ccdf9a9daf3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing holding a brown leather tote bag",
        },
        {
          file_id: "R-8DsF0Dcwk",
          file_name: "So take a deep breath and let it go",
          url: "https://images.unsplash.com/photo-1484240950164-450be1af341f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman covering her mouth with green leaf",
        },
      ],
    },
    followers: 53908,
    engagement_rate: 3.54,
    avg_like: 1308,
    avg_comment: 823,
    avg_share: 212,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 31000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 74500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000014",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000078",
    social_account_type: "user",
    social_name: "Chawiwan Chao",
    recommended: false,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "lNm2Px2UYB4",
          file_name: "Strangers in Sync",
          url: "https://images.unsplash.com/photo-1521422529055-2d9adb679ed0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women standing in front of yellow wall",
        },
        {
          file_id: "dcZsxUAAJXs",
          file_name: "2016.10.03",
          url: "https://images.unsplash.com/photo-1475823678248-624fc6f85785?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing near body of water",
        },
        {
          file_id: "aXDj_NiS05o",
          file_name: "Girl.",
          url: "https://images.unsplash.com/photo-1515734674582-29010bb37906?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sitting on white metal stair railing during daytime",
        },
        {
          file_id: "y81Rtrrbdzw",
          file_name: "Tea ceremony",
          url: "https://images.unsplash.com/photo-1538722909817-e4243928a610?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman making coffee",
        },
        {
          file_id: "p-a7rnHtk1s",
          file_name: null,
          url: "https://images.unsplash.com/photo-1543458083-d625ee4ef9c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman doing yoga pose",
        },
      ],
    },
    followers: 8584,
    engagement_rate: 1.56,
    avg_like: 698,
    avg_comment: 454,
    avg_share: 42,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 41000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 48500,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000015",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000679",
    social_account_type: "user",
    social_name: "Butri Taksin",
    recommended: false,
    category: [
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "4bmtMXGuVqo",
          file_name: null,
          url: "https://images.unsplash.com/photo-1460904577954-8fadb262612c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "3N2vAlrTWos",
          file_name: "Woman on the rocks",
          url: "https://images.unsplash.com/photo-1466314760659-7a0975a51304?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman holding clear glass bottle during daytime",
        },
        {
          file_id: "oWDRVgk04EA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1497340525489-441e8427c980?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "selective focus photography of girl sitting near tree",
        },
        {
          file_id: "vTru22pWbGM",
          file_name: "Woman standing in swimsuit",
          url: "https://images.unsplash.com/photo-1444913220552-fe31fed9c5bd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing on the shore",
        },
        {
          file_id: "qYanN54gIrI",
          file_name: "Contemplating woman",
          url: "https://images.unsplash.com/photo-1475938476802-32a7e851dad1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3NTk0&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman praying",
        },
      ],
    },
    followers: 9549,
    engagement_rate: 5.67,
    avg_like: 1731,
    avg_comment: 225,
    avg_share: 328,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 16000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 56000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000016",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000535",
    social_account_type: "user",
    social_name: "Noklek Chalerm",
    recommended: false,
    category: [],
    media: {
      images: [
        {
          file_id: "k_MRo-VOWOg",
          file_name: "Red Dress & Jean Jacket",
          url: "https://images.unsplash.com/photo-1541647249291-71c1d98ce84f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing blue denim jacket",
        },
        {
          file_id: "5zsSJ5U3us4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1582164838301-3454c11a7523?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in red long sleeve shirt and blue denim jeans leaning on wall",
        },
        {
          file_id: "g0DdUOYHx5o",
          file_name: null,
          url: "https://images.unsplash.com/photo-1587762046729-c5fe0cd88abc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing on brown grass field during daytime",
        },
        {
          file_id: "-_tUHvw09jY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1595868321403-bac51fbdeb97?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing beside green plant",
        },
        {
          file_id: "epBh_ogRXiU",
          file_name: null,
          url: "https://images.unsplash.com/photo-1566942974683-0a1aa5d212f1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white long-sleeved dress",
        },
        {
          file_id: "0UECcInuCR4",
          file_name:
            "Model - Ciera Nicole: http://www.instagram.com/imcieranicole2\nPhotographer - Judeus Samson: https://www.instagram.com/shotbyjudeus/",
          url: "https://images.unsplash.com/photo-1545291730-faff8ca1d4b0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzc5OQ&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing in the middle of the road",
        },
      ],
    },
    followers: 19814,
    engagement_rate: 1.37,
    avg_like: 1211,
    avg_comment: 950,
    avg_share: 358,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 14500,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 65000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000017",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000390",
    social_account_type: "user",
    social_name: "Choi Kasamsun",
    recommended: true,
    category: [
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
    ],
    media: {
      images: [
        {
          file_id: "k_MRo-VOWOg",
          file_name: "Red Dress & Jean Jacket",
          url: "https://images.unsplash.com/photo-1541647249291-71c1d98ce84f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman wearing blue denim jacket",
        },
        {
          file_id: "5zsSJ5U3us4",
          file_name: null,
          url: "https://images.unsplash.com/photo-1582164838301-3454c11a7523?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in red long sleeve shirt and blue denim jeans leaning on wall",
        },
        {
          file_id: "g0DdUOYHx5o",
          file_name: null,
          url: "https://images.unsplash.com/photo-1587762046729-c5fe0cd88abc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing on brown grass field during daytime",
        },
        {
          file_id: "-_tUHvw09jY",
          file_name: null,
          url: "https://images.unsplash.com/photo-1595868321403-bac51fbdeb97?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman in white wedding dress standing beside green plant",
        },
        {
          file_id: "epBh_ogRXiU",
          file_name: null,
          url: "https://images.unsplash.com/photo-1566942974683-0a1aa5d212f1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman in white long-sleeved dress",
        },
        {
          file_id: "0UECcInuCR4",
          file_name:
            "Model - Ciera Nicole: http://www.instagram.com/imcieranicole2\nPhotographer - Judeus Samson: https://www.instagram.com/shotbyjudeus/",
          url: "https://images.unsplash.com/photo-1545291730-faff8ca1d4b0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing in the middle of the road",
        },
        {
          file_id: "_cvwXhGqG-o",
          file_name:
            "This series shows the beauty of each model. The point was to make them look super cute.- jekafe\nModel's IG: dnakeys",
          url: "https://images.unsplash.com/photo-1519699047748-de8e457a634e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "mlh6I5_jCw0",
          file_name: null,
          url: "https://images.unsplash.com/photo-1567441076134-8f80ef850fc5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw4fHxQcmV0dHklMjBMYWR5fGVufDB8fHx8MTY1NDAwNzgwMA&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman wearing black and white floral spaghetti-strap dress",
        },
      ],
    },
    followers: 4050,
    engagement_rate: 4.37,
    avg_like: 2214,
    avg_comment: 434,
    avg_share: 587,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 17000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 52000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000018",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000829",
    social_account_type: "user",
    social_name: "Chomesri Sripituksakul",
    recommended: false,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
    ],
    media: {
      images: [
        {
          file_id: "lNm2Px2UYB4",
          file_name: "Strangers in Sync",
          url: "https://images.unsplash.com/photo-1521422529055-2d9adb679ed0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women standing in front of yellow wall",
        },
        {
          file_id: "dcZsxUAAJXs",
          file_name: "2016.10.03",
          url: "https://images.unsplash.com/photo-1475823678248-624fc6f85785?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing near body of water",
        },
        {
          file_id: "aXDj_NiS05o",
          file_name: "Girl.",
          url: "https://images.unsplash.com/photo-1515734674582-29010bb37906?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sitting on white metal stair railing during daytime",
        },
        {
          file_id: "y81Rtrrbdzw",
          file_name: "Tea ceremony",
          url: "https://images.unsplash.com/photo-1538722909817-e4243928a610?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman making coffee",
        },
        {
          file_id: "p-a7rnHtk1s",
          file_name: null,
          url: "https://images.unsplash.com/photo-1543458083-d625ee4ef9c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxBc2lhbiUyMExhZHl8ZW58MHx8fHwxNjU0MDA3Nzk4&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman doing yoga pose",
        },
      ],
    },
    followers: 7836,
    engagement_rate: 4.72,
    avg_like: 770,
    avg_comment: 1009,
    avg_share: 132,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 24000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 52000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000019",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000850",
    social_account_type: "user",
    social_name: "Chompoo Kurusarttra",
    recommended: false,
    category: [
      {
        id: 5,
        value: "food",
        name_en: "Food",
        name_th: "อาหาร",
      },
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
      {
        id: 2,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
      },
      {
        id: 3,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
      },
    ],
    media: {
      images: [
        {
          file_id: "4bmtMXGuVqo",
          file_name: null,
          url: "https://images.unsplash.com/photo-1460904577954-8fadb262612c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: null,
        },
        {
          file_id: "3N2vAlrTWos",
          file_name: "Woman on the rocks",
          url: "https://images.unsplash.com/photo-1466314760659-7a0975a51304?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman holding clear glass bottle during daytime",
        },
        {
          file_id: "oWDRVgk04EA",
          file_name: null,
          url: "https://images.unsplash.com/photo-1497340525489-441e8427c980?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "selective focus photography of girl sitting near tree",
        },
        {
          file_id: "vTru22pWbGM",
          file_name: "Woman standing in swimsuit",
          url: "https://images.unsplash.com/photo-1444913220552-fe31fed9c5bd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing on the shore",
        },
        {
          file_id: "qYanN54gIrI",
          file_name: "Contemplating woman",
          url: "https://images.unsplash.com/photo-1475938476802-32a7e851dad1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman praying",
        },
        {
          file_id: "3402kvtHhOo",
          file_name: null,
          url: "https://images.unsplash.com/photo-1514626585111-9aa86183ac98?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw2fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "shallow focus photo of woman face",
        },
        {
          file_id: "HwNCyLWw7hw",
          file_name: "Clara G",
          url: "https://images.unsplash.com/photo-1500305614571-ae5b6592e65d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw3fHxTbWFydCUyMEdpcmx8ZW58MHx8fHwxNjU0MDA3ODAx&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sitting on brown sofa under slow down neon signage",
        },
      ],
    },
    followers: 42462,
    engagement_rate: 4.08,
    avg_like: 372,
    avg_comment: 1384,
    avg_share: 598,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 49000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 49000,
          currency: "THB",
        },
      },
    ],
  },
  {
    influencer_id: "INF-000020",
    latest_update_datetime: "2022-02-18T04:44:34",
    timeline_begin_date: "2022-01-31",
    timeline_end_date: "2022-05-31",
    platform: "tiktok",
    social_account_id: "TK-000398",
    social_account_type: "user",
    social_name: "Thong Siriwanich",
    recommended: false,
    category: [
      {
        id: 1,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
      },
    ],
    media: {
      images: [
        {
          file_id: "-2vD8lIhdnw",
          file_name: null,
          url: "https://images.unsplash.com/photo-1516534775068-ba3e7458af70?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwxfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman biting pencil while sitting on chair in front of computer during daytime",
        },
        {
          file_id: "sLwkS2P-Ryw",
          file_name:
            "Two young women stopped to hoist their signs over their heads during the women’s march as onlookers with cameras took pictures, myself included. It was one of many moments that felt joyous during the march.",
          url: "https://images.unsplash.com/photo-1512404190248-ad677758134a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwyfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "two women raising banners",
        },
        {
          file_id: "wVYnSXIil6U",
          file_name:
            "The bee is a representation of our loved ones. They’re there to remind us that we are flowers and worthy of caring for. Never forget that you a so worthy a beautiful!",
          url: "https://images.unsplash.com/photo-1543366749-ad19659450ef?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHwzfHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman's face",
        },
        {
          file_id: "vLwH8bWoi_8",
          file_name: null,
          url: "https://images.unsplash.com/photo-1573164574001-518958d9baa2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw0fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description:
            "woman sits on padded chair while using MacBook during daytime",
        },
        {
          file_id: "rfyXoYrcYoA",
          file_name: "Holding On",
          url: "https://images.unsplash.com/photo-1487611739259-6ccdf9a9daf3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDcxMTl8MHwxfHNlYXJjaHw1fHxTbWFydCUyMExhZHl8ZW58MHx8fHwxNjU0MDA3NTk5&ixlib=rb-1.2.1&q=80&w=1080",
          purpose: "profile_image",
          description: "woman standing holding a brown leather tote bag",
        },
      ],
    },
    followers: 41439,
    engagement_rate: 4.36,
    avg_like: 992,
    avg_comment: 898,
    avg_share: 566,
    rate_card: [
      {
        cotent_type: "video_post_30s",
        name: "Video Post 30 sec",
        price: {
          value: 2000,
          currency: "THB",
        },
      },
      {
        cotent_type: "video_post_60s",
        name: "Video Post 60 esc",
        price: {
          value: 33500,
          currency: "THB",
        },
      },
    ],
  },
];
