// dexp = 0 or undefined : default decimal
// dexp = -1 : 1 digit
// dexp = -2 : 2 digit

export const calculateNumberSocialUnit = (decimal: number, exp?: number) => {
  if (1000 <= decimal && decimal < 1000000) {
    return decimalAdjust(decimal / 1000, exp) + `K`;
  } else if (decimal >= 1000000) {
    return decimalAdjust(decimal / 1000000, exp) + `M`;
  } else {
    return String(decimalAdjust(decimal));
  }
};

function decimalAdjust(value: number, exp?: number) {
  if (typeof exp === "undefined" || +exp === 0) {
    return Math.floor(value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  const shift_string: string[] = value.toString().split("e");
  const shift_num: number = Math.floor(
    +(shift_string[0] + "e" + (shift_string[1] ? +shift_string[1] - exp : -exp))
  );
  const shift_back_string = shift_num.toString().split("e");
  return +(
    shift_back_string[0] +
    "e" +
    (shift_back_string[1] ? +shift_back_string[1] + exp : exp)
  );
}
