import React from "react";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";

export default function Loading() {
  const CircularProgressWithLabel = () => {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          color="secondary"
          className="loading-top"
          size={140}
          thickness={2.5}
        />
      </Box>
    );
  };
  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(120, 120, 120, 0.5)",
        zIndex: 9999,
      }}
      open={true}
    >
      <Box textAlign="center">
        <CircularProgressWithLabel />
        <Typography variant="h5" sx={{ marginTop: 2 }}>
          Loading...
        </Typography>
      </Box>
    </Backdrop>
  );
}
