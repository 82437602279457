import React, { useEffect, useState } from "react";
// Types
// import { IInfluencerImage } from "../../views/my-profile/MyProfile.types";
import { IImageProfileListApiProps } from "./InfluencerProfile.types";
// Utils
import { useInfluencerProfileStyle as classes } from "./InfluencerProfile.styles";
import { addDefaultImageSrc } from "../../utils/helpers/addDefaultImageSrc.helpers";
// Material
import { ImageList, ImageListItem } from "@mui/material";
// Other
import DefaultImage from "./../../assets/images/other/image-empty.jpg";

export default function ImageProfileList(props: IImageProfileListApiProps) {
  const { profileInfluencer } = props;
  const [frontal, setFrontal] = useState<string>("");
  const [portrait, setPortrait] = useState<string>("");
  const [headShot, setHeadShot] = useState<string>("");

  useEffect(() => {
    profileInfluencer?.media.images.filter((purpose) => {
      if (purpose.purpose === "influencer_frontal_image") {
        setFrontal(purpose.url);
      }
      if (purpose.purpose === "influencer_portrait_image") {
        setPortrait(purpose.url);
      }
      if (purpose.purpose === "influencer_headshot_image") {
        setHeadShot(purpose.url);
      }
    });
  }, [profileInfluencer]);

  return (
    <ImageList cols={3} gap={0} sx={classes.imageList}>
      <ImageListItem sx={classes.imageListItem}>
        {frontal !== "" ? (
          <img
            src={frontal}
            alt="images"
            onError={addDefaultImageSrc}
            style={{
              aspectRatio: "1 / 1",
            }}
          />
        ) : (
          <img
            src={DefaultImage}
            alt="images"
            style={{
              aspectRatio: "1 / 1",
            }}
          />
        )}
      </ImageListItem>
      <ImageListItem sx={classes.imageListItem}>
        {portrait !== "" ? (
          <img
            src={portrait}
            alt="images"
            onError={addDefaultImageSrc}
            style={{
              aspectRatio: "1 / 1",
            }}
          />
        ) : (
          <img
            src={DefaultImage}
            alt="images"
            style={{
              aspectRatio: "1 / 1",
            }}
          />
        )}
      </ImageListItem>
      <ImageListItem sx={classes.imageListItem}>
        {headShot !== "" ? (
          <img
            src={headShot}
            alt="images"
            onError={addDefaultImageSrc}
            style={{
              aspectRatio: "1 / 1",
            }}
          />
        ) : (
          <img
            src={DefaultImage}
            alt="images"
            style={{
              aspectRatio: "1 / 1",
            }}
          />
        )}
      </ImageListItem>
    </ImageList>
  );
}
