export const contentTypeData = {
  status: "OK",
  status_code: 200,
  result: {
    data: [
      {
        id: 1,
        value: "video_post_30s",
        name_en: "Video 30 second post",
        name_th: "วิดีโอไม่เกิน 30 วินาที",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 2,
        value: "video_post_60s",
        name_en: "Video 60 second post",
        name_th: "วิดีโอไม่เกิน 60 วินาที",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 3,
        value: "photo_album",
        name_en: "Photo album",
        name_th: "อัลบั้มรูป",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 4,
        value: "video_photo_album",
        name_en: "Video and photo album post",
        name_th: "วิดีโอและอัลบั้มรูป",
        platform: "facebook",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 5,
        value: "video_post_30s",
        name_en: "Video 30 second post",
        name_th: "วิดีโอไม่เกิน 30 วินาที",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 6,
        value: "video_post_60s",
        name_en: "Video 60 second post",
        name_th: "วิดีโอไม่เกิน 60 วินาที",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 7,
        value: "photo_album",
        name_en: "Photo album",
        name_th: "อัลบั้มรูป",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 8,
        value: "video_photo_album",
        name_en: "Video and photo album post",
        name_th: "วิดีโอและอัลบั้มรูป",
        platform: "instagram",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 9,
        value: "video_post_20m",
        name_en: "Video 20 minuite clip",
        name_th: "วิดีโอ 20 นาทีขึ้นไป",
        platform: "youtube",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 10,
        value: "video_post_30s",
        name_en: "Video 30 second post",
        name_th: "วิดีโอไม่เกิน 30 วินาที",
        platform: "tiktok",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 11,
        value: "video_post_60s",
        name_en: "Video 60 second post",
        name_th: "วิดีโอไม่เกิน 60 วินาที",
        platform: "tiktok",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
    ],
  },
};
