import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
// Component
import DialogAuthentication from "../global/dialog-authentication";
import TextFieldCustom from "../global/text-field";
import BasicSelectCustom from "../global/basic-select";
// Schema
import { CreateAccountFormSchema } from "./AuthenticationForm.schema";
// Types
import {
  ICreateAccountDialogProps,
  ICreateAccountFormSchema,
} from "./AuthenDialog.types";
import { IRegisterForm } from "./AuthenticationForm";
// Styles
import { useCreateAccountDialogStyle as classes } from "./authentication.styles";
// Material
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// Constants
import { genderArray } from "./../../utils/constants/genderArray.constants";
import nationalitiesArray from "./../../utils/constants/nationalities.constants.json";
// Api
import { createInfluencer } from "./../../api/authentication/Authentication";
//Sweet Alert
import SwalCustom from "./../global/sweet-alert-custom/SwalCustom";
// Theme
import { hAlertColor } from "../../theme";
import Loading from "../global/loading/Loading";

const initCreateAccountForm: IRegisterForm = {
  fullName: "",
  birthdate: null,
  gender: "none",
  nationality: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
};

function CreateAccountDialog(props: ICreateAccountDialogProps) {
  const history = useHistory();
  const { isOpened, setOpen, accountSelected } = props;

  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    register,
  } = useForm<IRegisterForm>({
    resolver: yupResolver(CreateAccountFormSchema),
    defaultValues: initCreateAccountForm,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  function compare(a: any, b: any) {
    if (a.nationality < b.nationality) {
      return -1;
    }
    if (a.nationality > b.nationality) {
      return 1;
    }
    return 0;
  }

  const nationalitiesArraySort = nationalitiesArray.sort(compare);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: any, name: keyof ICreateAccountFormSchema) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const handleDateChange = (
    newValue: Date | null,
    birthdate: keyof ICreateAccountFormSchema
  ) => {
    setValue("birthdate", newValue);
    clearErrors("birthdate");
  };

  const onSubmit = async (data: IRegisterForm) => {
    setLoading(true);
    console.log("submit: ", { ...data, type: accountSelected });
    try {
      setLoading(false);
      const reqBody = {
        fullname: data.fullName,
        birth_date: data.birthdate,
        gender: data.gender || "none",
        nationality: data.nationality,
        phone: data.phone,
        email: data.email,
        password: data.password,
      };
      const response = await createInfluencer(reqBody);
      const email = response.email;
      history.push(`/verify-email/${email}`);
    } catch (error: any) {
      setLoading(false);
      const data = error.response.data;
      if (data.status_code === 400) {
        switch (data.error.message) {
          case "error, fullname format is invalid (format is xxx xxx or xxx xxx xxx).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Full Name is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, gender format is invalid (gender must be male or female or lgbtq or none).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Gender is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, phone number format is invalid (need number as 10 digit).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Phone Number is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, email format is invalid (example@hasumail.com).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Email format is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, date format is invalid.":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Date format is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
        }
      } else {
        if (
          data.error.message ===
          'ERROR: duplicate key value violates unique constraint "user_email_key" (SQLSTATE 23505)'
        ) {
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, duplicate email !",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
        } else {
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Something when wrong !",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
        }
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <DialogAuthentication
          title="Create an Account"
          open={isOpened}
          onClose={handleClose}
          sx={classes.createAccountDialog}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={classes.firstDialogContent}>
              <Grid container columnSpacing={6} rowSpacing={4}>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Full Name :
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={getValues("fullName")}
                      onChange={(e) => handleChange(e, "fullName")}
                      register={register("fullName")}
                      variant="outlined"
                      placeholder="Full Name"
                      fullWidth
                      sx={!errors.fullName ? classes.textFieldGrey : undefined}
                      error={!!errors.fullName}
                      helperText={errors.fullName?.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Birthdate :
                      </Typography>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        value={getValues("birthdate")}
                        onChange={(newValue) =>
                          handleDateChange(newValue, "birthdate")
                        }
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextFieldCustom
                            variant="outlined"
                            placeholder="DD/MM/YY"
                            fullWidth
                            {...params}
                            sx={
                              !errors.birthdate
                                ? classes.textFieldGrey
                                : undefined
                            }
                            error={!!errors.birthdate}
                            helperText={errors.birthdate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Gender :
                      </Typography>
                    </Box>
                    <BasicSelectCustom
                      id="create-acc-gender"
                      value={getValues("gender")}
                      {...register("gender")}
                      onChange={(e) => handleChange(e, "gender")}
                      data={genderArray}
                      valueKey="value"
                      labelKey="label"
                      placeholder="Select"
                      fullWidth
                      sx={classes.selectGrey}
                      error={!!errors.gender}
                      helperText={errors.gender?.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Nationality :
                      </Typography>
                    </Box>
                    <BasicSelectCustom
                      id="create-acc-nationality"
                      value={getValues("nationality")}
                      onChange={(e) => handleChange(e, "nationality")}
                      data={nationalitiesArraySort}
                      valueKey="nationality"
                      labelKey="nationality"
                      placeholder="Select"
                      fullWidth
                      sx={classes.selectGrey}
                      error={!!errors.nationality}
                      helperText={errors.nationality?.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Phone :
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={getValues("phone")}
                      onChange={(e) => handleChange(e, "phone")}
                      variant="outlined"
                      placeholder="Phone"
                      fullWidth
                      sx={!errors.phone ? classes.textFieldGrey : undefined}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider sx={classes.divider} />
            <DialogContent sx={classes.secondDialogContent}>
              <Typography variant="body1" fontWeight="bold" mb={4}>
                Email for Sign in
              </Typography>
              <Grid container columnSpacing={6} rowSpacing={4}>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Email :
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={getValues("email")}
                      onChange={(e) => handleChange(e, "email")}
                      variant="outlined"
                      placeholder="Email"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              className="ico-hu-email-solid"
                              sx={classes.iconInput}
                            />
                          </InputAdornment>
                        ),
                      }}
                      sx={!errors.email ? classes.textFieldGrey : undefined}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Password :
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={getValues("password")}
                      onChange={(e) => handleChange(e, "password")}
                      type={!!showPassword ? "text" : "password"}
                      variant="outlined"
                      placeholder="Password"
                      fullWidth
                      sx={!errors.password ? classes.textFieldGrey : undefined}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {!!showPassword ? (
                                <Icon
                                  className="ico-hu-visability-off-solid"
                                  sx={classes.iconButton}
                                />
                              ) : (
                                <Icon
                                  className="ico-hu-visability-on-solid"
                                  sx={classes.iconButton}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                      <Typography variant="body2" fontWeight="bold">
                        Confirm Password :
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={getValues("confirmPassword")}
                      onChange={(e) => handleChange(e, "confirmPassword")}
                      type={!!showPassword ? "text" : "password"}
                      variant="outlined"
                      placeholder="Confirm Password"
                      fullWidth
                      sx={
                        !errors.confirmPassword
                          ? classes.textFieldGrey
                          : undefined
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {!!showPassword ? (
                                <Icon
                                  className="ico-hu-visability-off-solid"
                                  sx={classes.iconButton}
                                />
                              ) : (
                                <Icon
                                  className="ico-hu-visability-on-solid"
                                  sx={classes.iconButton}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider sx={classes.divider} />
            <DialogActions sx={classes.dialogAction}>
              <Button type="submit" sx={classes.registorButton}>
                <Typography variant="body1" fontWeight="bold">
                  Registor
                </Typography>
              </Button>
            </DialogActions>
          </form>
        </DialogAuthentication>
      )}
    </>
  );
}

export default CreateAccountDialog;
