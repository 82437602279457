import React from "react";
import { IMenuProps } from "./Sidebar.types";
import { Box, Typography } from "@mui/material";
import { MenusBox, IconsBox } from "./Sidebar.styles";

import ListIcon from "@mui/icons-material/List";
import CampaignIcon from "@mui/icons-material/CampaignOutlined";
import MoneyIcon from "@mui/icons-material/LocalAtm";
import ProfileIcon from "@mui/icons-material/AccountCircleOutlined";

export default function ItemSideBar(props: IMenuProps) {
  const { menus, handleMenuClick } = props;
  return (
    <Box>
      {menus &&
        menus.map(({ title, isActive }, index) => (
          <MenusBox
            key={index}
            sx={{
              fontWeight: isActive ? "bold" : "normal",
            }}
          >
            <Box
              onClick={() => {
                handleMenuClick(index);
              }}
            >
              <IconsBox
                sx={{
                  backgroundColor: isActive ? "primary.main" : "secondary.main",
                }}
              >
                {title === "My Jobs" ? (
                  <ListIcon fontSize="inherit" />
                ) : title === "Job Board" ? (
                  <CampaignIcon fontSize="inherit" />
                ) : title === "My Earnings" ? (
                  <MoneyIcon fontSize="inherit" />
                ) : title === "Campiagn" ? (
                  <CampaignIcon fontSize="inherit" />
                ) : (
                  <ProfileIcon fontSize="inherit" />
                )}
              </IconsBox>
            </Box>
            <Typography
              variant="caption"
              sx={{
                fontWeight: "inherit",
              }}
            >
              {title}
            </Typography>
          </MenusBox>
        ))}
    </Box>
  );
}
