import { Button, ButtonProps } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/system";
import { hColorful } from "../../theme";

export const useHomePricingPackageCardStyle = {
  packageCard: {
    height: "100%",
    minHeight: "370px",
    width: "275px",
    boxShadow: "unset",
    "& > .package-header": {
      boxShadow: "unset",
      "& > .MuiCardHeader-content": {
        "& > .MuiTypography-root > .MuiBox-root > .package-title": {
          color: "secondary.main",
        },
        "& > .package-sub-title": {
          color: "common.black",
        },
      },
    },
    "&:hover": {
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
      "& > .package-header": {
        background: `linear-gradient(296.45deg, ${hColorful[100]} -3.27%, ${hColorful[200]} 102.18%)`,
        "& > .MuiCardHeader-content": {
          "& > .MuiTypography-root > .MuiBox-root > .package-title": {
            color: "common.white",
          },
          "& > .package-sub-title": {
            color: "common.white",
          },
        },
      },
    },
  },
  faqAccordionSummary: {
    backgroundColor: "white",
    borderBottom: "1 solid red",
  },
};

export const ButtonPricing = styled(Button)<ButtonProps>(({ theme }) => ({
  border: "solid 2px transparent",
  borderRadius: "30px",
  minHeight: "38px",
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(296.45deg, ${hColorful[100]}, ${hColorful[200]})`,
  backgroundOrigin: "border-box",
  backgroundClip: "content-box, border-box",
  boxShadow: "2px 1000px 1px #fff inset",
  "& > p": {
    background: `-webkit-linear-gradient(296.45deg, ${hColorful[200]}, ${hColorful[100]})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "&:hover": {
    border: "solid 2px transparent",
    boxShadow: "none",
    color: theme.palette.common.white,
    "& > p": {
      background: "unset",
      WebkitBackgroundClip: "unset",
      WebkitTextFillColor: "unset",
    },
  },
}));

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "unset",
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props}></MuiAccordionSummary>
))(({ theme }) => ({
  borderBottom: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "white",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
  border: "unset",
}));
