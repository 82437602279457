import { hGrey, hPink, hWhite, hPurple } from "../../theme";
import { Button, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const InfluencerMyEarningStyles = {
  totalEarningBox: {
    height: {
      xs: "130px",
      sm: "150px",
      md: "180px",
    },
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: hPink[400],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    color: hWhite[100],
    "& svg": {
      width: "24px",
      height: "24px",
      fill: hWhite[100],
    },
  },
  totalEearning: {
    display: "flex",
    "& p": {
      fontSize: ["30px", "35px", "44px", "48px"],
      marginRight: "6px",
    },
    "& span": {
      alignSelf: "flex-end",
      marginBottom: ["6px", "9px", "13px"],
    },
  },
  brandsBox: {
    color: hWhite[100],
    height: ["130px", "150px", "180px"],
    borderRadius: "16px",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: hPurple[300],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  brands: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    "& div": {
      display: "flex",
      "& img": { width: "24px", height: "24px", marginRight: "10px" },
      justifyContent: "center",
      alignItems: "center",
    },
    "& .totalBrands": {
      fontSize: ["30px", "35px", "44px", "48px"],
    },
  },
  brandsLine: {
    width: "2px",
    height: "80%",
    backgroundColor: "#fff",
    margin: "auto 0",
    borderRadius: "2px",
  },
  bankAccountBox: {
    height: ["130px", "150px", "180px"],
    borderRadius: "16px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: hWhite[100],
  },
  bankAccountCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
    marginTop: "15px",
  },
  textSize: {
    opacity: 0.8,
    marginTop: ["2px", "4px", "2px", "6px"],
    fontSize: ["12px", "14px", "14px", "16px"],
  },
};

export const status = {
  status: {
    display: "flex",
    width: "80px",
    height: "24px",
    borderRadius: "50px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    margin: [0, , "auto"],
  },
};

export const InfluencerHistoryStyle = {
  campaignIcon: {
    borderRadius: "4px",
    width: "48px",
    height: "48px",
  },
  statusPaid: {
    ...status.status,
    backgroundColor: "#DBFFDC",
    color: "#4CAF50",
  },
  statusPending: {
    ...status.status,
    backgroundColor: "#FFEEDF",
    color: "#FB8C00",
  },
  cardContent: { borderRadius: "16px", backgroundColor: "#fff" },
  recipientIcon: { width: "16px", height: "16px", mr: "5px" },
  cardTable: {
    "& .cardTable": {
      display: "flex",
      justifyContent: "space-between",
      height: "40px",
      alignItems: "center",
      px: "15px",
      "&:first-of-type": {
        mt: "10px",
        backgroundColor: "#F5F6F8",
        borderRadius: "8px",
      },
      "&:last-of-type": {
        mb: "10px",
        backgroundColor: "#F5F6F8",
        borderRadius: "8px",
      },
      "& p": {
        fontSize: "14px",
      },
      "& p:first-of-type": {
        color: "#5C5C5C",
      },
    },
  },
  pagination: {
    "& .MuiTablePagination-displayedRows, .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-select, .MuiInputBase-root": {
      border: "1.5px solid #9E9E9E",
      borderRadius: "50px",
      width: "80px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiInputBase-root": {
      border: 0,
    },
    "& svg:first-child": {
      width: ["25px", "25px", "25px"],
      height: ["25px", "25px", "25px"],
      borderRadius: "50%",
      border: "1.5px solid #9E9E9E",
      "&:hover": {
        color: hWhite[100],
        backgroundColor: hPink[300],
        border: "none",
      },
    },
  },
};

export const useEditBankAccountDialogStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root .MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  dialogContent: {
    paddingTop: 3,
    paddingBottom: 0,
  },

  uploadImageBox: {
    borderRadius: "4px",
    width: "170px",
    height: "170px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
  },

  uploadImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px dashed gray",
    width: "150px",
    height: "150px",
    borderRadius: "4px",
    "& img": {
      opacity: 0.5,
      width: "70px",
    },
  },
};

export const DocumentButton = styled(Button)({
  backgroundColor: hPurple[200],
  color: hWhite[100],
  height: ["40px"],
  padding: "0 15px",
  "&:hover": {
    backgroundColor: hPurple[300],
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: hPurple[300],
  },
});

export const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableCellCustom = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: hPurple[200],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
