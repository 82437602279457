import React, { useEffect, useState } from "react";
// Component
import InfluencerSocial from "../../components/influencer-social/InfluencerSocial";
import InfluencerMyprofileTab from "../../components/influencer-myprofile-tab";
import CategoryDialog from "../../components/category-dialog";
// Types
import {
  IFacebookDetailRes,
  IInstagramDetailRes,
  IYoutubeDetailRes,
  ITwitterDetailRes,
  // IInfluencerSocialRes,
  // IScocialRes,
} from "./MyProfile.types";
// Material
import { Grid, Box } from "@mui/material";
// Style
import { styled } from "@mui/material/styles";
// Mock Data
import facebookDatas from "./facebook.data.json";
import instagramDatas from "./instagram.data.json";
// API
import { getInfluencerSocial } from "../../api/influencer/InfluencerAPI";

const Root = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "88px 16px 28px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "88px 28px 28px 120px",
  },
}));

function MyProfile() {
  const [facebookData, setFacebookData] = useState<IFacebookDetailRes | null>(
    null
  );
  const [instagramData, setInstagramData] =
    useState<IInstagramDetailRes | null>(null);
  const [youtube, setYoutube] = useState<IYoutubeDetailRes | null>(null);
  const [twitter, setTwitter] = useState<ITwitterDetailRes | null>(null);
  const [tiktokApi, setTiktokApi] = useState<any>([]);

  // const [isOpenCategory, setOpenCategory] = useState<boolean>(false);

  useEffect(() => {
    async function getSocialData() {
      setFacebookData(facebookDatas.result.data);
      setInstagramData(instagramDatas.result.data);
    }
    async function getResult() {
      const results = await getInfluencerSocial();
      setTiktokApi(results.tiktok);
      // const category = results.tiktok[0].social_account[0].category;
      // if (!category.length) {
      //   setOpenCategory(true);
      // } else {
      //   setOpenCategory(false);
      //   setTiktokApi(results.tiktok);
      // }
    }
    getSocialData();
    getResult();
  }, []);

  return (
    <React.Fragment>
      <Root>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box display={["none", "none", "block"]}>
              <InfluencerMyprofileTab />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md lg xl>
            <InfluencerSocial
              facebook={facebookData}
              instagram={instagramData}
              youtube={youtube}
              twitter={twitter}
              tiktokApi={tiktokApi}
            />
          </Grid>
        </Grid>
        {/* Category Dialog */}
        {/* <CategoryDialog isOpened={isOpenCategory} setOpen={setOpenCategory} /> */}
      </Root>
    </React.Fragment>
  );
}

export default MyProfile;
