import * as yup from "yup";

export const EditEmployerFormSchema = yup.object().shape({
  name: yup.string().required("Please enter your organization name."),
  size: yup
    .string()
    .required("Please select your business size.")
    .nullable(true),
  type: yup.string().required("Please select your type.").nullable(true),
  fullname: yup.string().required("Please enter your full name."),
  phone: yup
    .string()
    .required("Please enter your phone.")
    .matches(
      /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
      "Phone number should be 10 digits"
    ),
});
