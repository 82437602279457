import React, { useEffect, useState } from "react";
// MUI
import { Box, Typography, Card, Grid, Button } from "@mui/material";
// Style
import { useEmployersStyle as classes } from "./employers.style";
// Icon
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// Components
import TableEmployers from "../../components/employer/TableEmployers";
import {
  selectEmployer,
  selectPagination,
  // selectOnload,
  getEmployerListAsync,
} from "./EmployerSlice";
import { useAppDispatch, useAppSelector } from "./../../store/hook";
import { IEmployerListParams } from "./../../api/Employer/Types";
import PaginationCustom from "../../components/global/pagination-custom";
import CreateEmployerDialog from "../../components/employer/create-employer/CreateEmployerDialog";
import TextFieldCustom from "../../components/global/text-field";
import { deleteEmployer } from "../../api/Employer/employerAPI";

export default function Employer() {
  const dispatch = useAppDispatch();
  const employer = useAppSelector(selectEmployer);
  const { perPage } = useAppSelector(selectPagination);

  // const loading = useAppSelector(selectOnload);
  const [paginate, setPaginate] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(perPage ? 5 : 5);

  const [employerParams, setEmployerParams] = useState<IEmployerListParams>({
    order_by: "organisation",
    sort: "ASC",
    item_per_page: rowsPerPage,
    page: paginate,
  });
  const [isOpenCreateEmployer, setIsOpenCreateEmployer] =
    useState<boolean>(false);

  const handleChangePage = (event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const handleClose = () => {
    setIsOpenCreateEmployer(false);
  };

  useEffect(() => {
    setEmployerParams({
      order_by: "organisation",
      sort: "ASC",
      item_per_page: rowsPerPage,
      page: paginate,
    });
  }, [rowsPerPage, paginate, setEmployerParams]);

  useEffect(() => {
    dispatch(getEmployerListAsync(employerParams));
  }, [employerParams, dispatch]);

  const handleDelete = (id: string) => {
    const deleteEmp = () => {
      deleteEmployer(id);
    };
    deleteEmp();
    dispatch(getEmployerListAsync(employerParams));
  };

  return (
    <Box sx={classes.containnerMain}>
      <Box sx={{ marginBottom: "24px" }}>
        <Typography variant="h5">Emplyer List</Typography>
        <Typography variant="body1">รายชื่อผู้จ้างงาน</Typography>
      </Box>
      <Card sx={classes.cardEmployers}>
        <Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box sx={classes.boxSearch}>
                <TextFieldCustom fullWidth />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box sx={classes.boxNewEmployer}>
                <Button
                  onClick={() => setIsOpenCreateEmployer(true)}
                  sx={classes.buttonNewEmployer}
                >
                  <AddCircleOutlineIcon />
                  New Employer
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <TableEmployers
            employerList={employer.employerList}
            handleDelete={handleDelete}
          />
        </Box>
      </Card>
      <Box display="flex" justifyContent="center">
        <PaginationCustom
          page={paginate}
          count={
            paginate > 0 ? Math.ceil(employer.total_item / rowsPerPage) : 0
          }
          onPaginationChange={handleChangePage}
          rowsPerPageData={[5, 10, 20, 30]}
          rowsPerPage={rowsPerPage}
          onRowPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <CreateEmployerDialog
        isOpened={isOpenCreateEmployer}
        onClose={handleClose}
      />
    </Box>
  );
}
