import React, { useState } from "react";
// Component
import HomeHeaderBarDesktop from "./HomeHeaderBarDesktop";
import HomeHeaderBarMobile from "./HomeHeaderBarMobile";
import SignInDialog from "../authentication-dialog/SignInDialog";
import SelectAccountDialog from "../authentication-dialog/SelectAccountDialog";
import ForgetPasswordDialog from "../authentication-dialog/ForgetPasswordDialog";
import ResetPasswordDialog from "./../authentication-dialog/ResetPasswordDialog";
// Hooks
import useBreakpoint from "./../../utils/hooks/useBreakpoint";
import CreateAccountDialog from "../authentication-dialog/CreateAccountDialog";
// Types
import { IAccountSelected } from "../authentication-dialog/AuthenDialog.types";

export const menuList: { label: string; value: string; path: string }[] = [
  {
    label: "Influencer",
    value: "influencer",
    path: "/home/influencer",
  },
  {
    label: "Marketer",
    value: "marketer",
    path: "/home/marketer",
  },
  {
    label: "Pricing & Plan",
    value: "pricing",
    path: "/home/pricing",
  },
  {
    label: "Contact Us",
    value: "contact",
    path: "/home/contact",
  },
];

function HomeHeaderBar() {
  const bp = useBreakpoint();
  const [isOpenedSignIn, setIsOpenedSignIn] = useState<boolean>(false);
  const [isOpenSelectAccount, setIsOpenSelectAccount] =
    useState<boolean>(false);
  const [isOpenCreateAccount, setIsOpenCreateAccount] =
    useState<boolean>(false);
  const [accountSelected, setAccountSelected] =
    useState<IAccountSelected | null>(null);
  const [isOpeForgetPassword, setIsOpenForgetPassword] =
    useState<boolean>(false);
  const [isOpenResetPassword, setIsOpenResetPassword] =
    useState<boolean>(false);

  const openSelectAccountType = () => {
    setIsOpenedSignIn(false);
    setIsOpenSelectAccount(true);
  };

  const openCreateAccountDialog = (type: IAccountSelected) => {
    setIsOpenedSignIn(false);
    setIsOpenSelectAccount(false);
    setAccountSelected(type);
    setIsOpenCreateAccount(true);
  };

  const openForgetPassword = () => {
    setIsOpenedSignIn(false);
    setIsOpenForgetPassword(true);
  };

  const openResetPassword = () => {
    setIsOpenedSignIn(false);
    setIsOpenForgetPassword(false);
    setIsOpenResetPassword(true);
  };

  const openSigin = () => {
    setIsOpenedSignIn(true);
    setIsOpenForgetPassword(false);
  };

  return (
    <React.Fragment>
      {bp === "xl" || bp === "lg" ? (
        <HomeHeaderBarDesktop setIsOpenSignIn={setIsOpenedSignIn} />
      ) : (
        <HomeHeaderBarMobile
          breakpoint={bp}
          setIsOpenSignIn={setIsOpenedSignIn}
        />
      )}

      {/* SignIn Dialog */}

      {/* -- UAT comment here -- */}
      <SignInDialog
        isOpened={isOpenedSignIn}
        setIsOpenSignIn={setIsOpenedSignIn}
        openSelectAccountType={openSelectAccountType}
        openForgetPassword={openForgetPassword}
      />
      {/* -- End comment -- */}

      {/* Select Account Dialog */}
      <SelectAccountDialog
        isOpened={isOpenSelectAccount}
        setOpen={setIsOpenSelectAccount}
        openCreateAccountDialog={openCreateAccountDialog}
      />
      {/* Create Account Dialog */}
      {accountSelected !== null && (
        <CreateAccountDialog
          isOpened={isOpenCreateAccount}
          setOpen={setIsOpenCreateAccount}
          accountSelected={accountSelected}
        />
      )}

      {/* Forget Password Dialog */}
      <ForgetPasswordDialog
        isOpened={isOpeForgetPassword}
        setIsOpenForgetPassword={setIsOpenForgetPassword}
        openResetPassword={openResetPassword}
        openSignIn={openSigin}
      />

      {/* Reset Password Dialog */}
      <ResetPasswordDialog
        isOpened={isOpenResetPassword}
        setIsOpenResetPassword={setIsOpenResetPassword}
      />
    </React.Fragment>
  );
}

export default HomeHeaderBar;
