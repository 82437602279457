import { Box, Button, Divider, Grid, Icon, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateEmployer } from "../../../../api/Employer/employerAPI";
import { formatPhoneNumber } from "../../../global/format-number";
import EditShipping from "../edit/EditShipping";

export default function Shipping(props: any) {
  const { employerId, address } = props;
  console.log("employerId", employerId);

  const [isOpenEditeEmployer, setIsOpenEditEmployer] = useState<boolean>(false);
  const [updateAddress, setUpdateAddress] = useState(false);

  const handleClose = () => {
    setIsOpenEditEmployer(false);
  };

  useEffect(() => {}, [updateAddress]);

  const onSubmit = async (data: any) => {
    setUpdateAddress(!updateAddress);
    try {
      const reqBody = {
        address: {
          shipping_address: {
            fullname: data.fullname,
            phone: data.phone,
            address: data.address,
            province_id: data.province?.id as number,
            district_id: data.district?.id as number,
            subdistrict_id: data.subdistrict?.id as number,
          },
        },
      };
      const response = await updateEmployer(employerId, reqBody);
      console.log("response shipping", response);
      // handleClose();
    } catch (error: any) {
      console.log("error", error);
    }
  };
  // const onSubmit = async (data: any) => {
  //   setUpdateAddress(!updateAddress);
  //   try {
  //     const reqBody = {
  //       address: {
  //         shipping_address: {
  //           fullname: data.fullname,
  //           phone: data.phone,
  //           address: data.address,
  //           province_id: data.province?.id as number,
  //           district_id: data.district?.id as number,
  //           subdistrict_id: data.subdistrict?.id as number,
  //         },
  //       },
  //     };
  //     const response = await updateEmployer(employerId, reqBody);
  //     console.log("response shipping", response);
  //     // handleClose();
  //   } catch (error: any) {
  //     console.log("error", error);
  //   }
  // };

  return (
    <Box sx={{ border: "2px solid #E8DFFF", borderRadius: "12px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "20px" }}
      >
        <Typography variant="h6">Shipping Address</Typography>
        <Button
          onClick={() => setIsOpenEditEmployer(true)}
          sx={{ width: "100px", justifyContent: "space-evenly" }}
        >
          <Icon className="ico-hu-edit" />
          Edit
        </Button>
      </Box>
      <Divider />
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Full name
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {address?.fullname}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Phone
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {formatPhoneNumber(address?.phone)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Address
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {address?.address}, {address?.subdistrict.name_th},{" "}
              {address?.district.name_th}, {address?.province.name_th},{" "}
              {address?.postcode}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <EditShipping
        data={address !== null ? address : null}
        isOpened={isOpenEditeEmployer}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    </Box>
  );
}
