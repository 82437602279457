import {
  CalendarMonth,
  DehazeOutlined,
  FacebookOutlined,
} from "@mui/icons-material";

export const steps = [
  {
    id: "campaignOverview",
    title: "Overview",
    offset: -350,
  },
  {
    id: "campaignBriefDetail",
    title: "Brief",
    offset: -185,
  },
  {
    id: "targetAudience",
    title: "Target Audience",
    offset: -185,
  },
  {
    id: "budgetAndReward",
    title: "Budget",
    offset: -185,
  },
  {
    id: "termsAndConditions",
    title: "Terms & Conditions",
    offset: -185,
  },
  {
    id: "timeline",
    title: "Timline",
    offset: -185,
  },
];

export const categoryData = {
  status: "OK",
  status_code: 200,
  result: {
    data: [
      {
        id: 1,
        value: "real-estate",
        name_en: "Real Estate",
        name_th: "อสังหาริมทรัพย์",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 2,
        value: "retail",
        name_en: "Retail",
        name_th: "ค้าปลีก",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 3,
        value: "health_and_fitness",
        name_en: "Health & Fitness",
        name_th: "สุขภาพ",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 4,
        value: "sport",
        name_en: "Sport",
        name_th: "กีฬา",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 5,
        value: "game",
        name_en: "Game",
        name_th: "เกมส์",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 6,
        value: "technology",
        name_en: "Technology",
        name_th: "เทคโนโลยี",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 7,
        value: "telecom",
        name_en: "Telecom",
        name_th: "การสื่อสาร",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 8,
        value: "travel",
        name_en: "Travel",
        name_th: "ท่องเที่ยว",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 9,
        value: "food_and_beverage",
        name_en: "Food & Beverage",
        name_th: "อาหารและเครื่องดื่ม",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 10,
        value: "beauty",
        name_en: "Beauty",
        name_th: "ความสวยงาม",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 11,
        value: "fashion",
        name_en: "Fashion",
        name_th: "แฟชั่น",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 12,
        value: "lifestyle",
        name_en: "Life Style",
        name_th: "ไลฟ์สไตล์",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 13,
        value: "parenting",
        name_en: "Parenting",
        name_th: "พ่อแม่",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 14,
        value: "luxury",
        name_en: "Luxury",
        name_th: "ความหรูหรา",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 15,
        value: "pet_and_animal",
        name_en: "Pet & Animal",
        name_th: "สัตว์เลี้ยง",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 16,
        value: "luxury",
        name_en: "Luxury",
        name_th: "ความหรูหรา",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 17,
        value: "music",
        name_en: "Music",
        name_th: "ดนตรี",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 18,
        value: "photography",
        name_en: "Photography",
        name_th: "การถ่ายภาพ",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 19,
        value: "other",
        name_en: "Other",
        name_th: "อื่น ๆ",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
    ],
  },
};

export const campCardDetailTimelineData = [
  {
    label: "ID : 000321",
    icon: null,
  },
  {
    label: "01/08/2022",
    icon: <CalendarMonth />,
  },
  {
    label: "Private",
    icon: <DehazeOutlined />,
  },
  {
    label: "Photo Album 4-5 Pic",
    icon: <FacebookOutlined />,
  },
];

export const campDetailAcceptData = [
  {
    keyName: "timeline",
    label:
      "ข้าพเจ้าได้อ่านและทำความเข้าใจ เกี่ยวกับระยะเวลาของแคมเปญ(Timeline) เรียบร้อยแล้ว",
  },
  {
    keyName: "termsAndConditions",
    label: "ข้าพเจ้ายอมรับข้อกำหนดและเงื่อนไขการใช้บริการของ HashU",
  },
];
