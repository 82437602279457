import { Button, ButtonProps, styled } from "@mui/material";
import { hPink, hPurple, hYellow } from "../../theme";

export const HomeContactUsStyle = {
  contentMain: {
    display: "flex",
    justifyContent: "center",
    padding: "50px",
  },
  headerBackground: {
    height: ["400px", "350px", "350px", "400px", "400px"] as any,
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundImage:
      "linear-gradient(96.87deg, #F7DAE7 0%, #F1E0F2 25.69%, #F1E9F7 55.47%, #EDEAFF 100%)",
    zIndex: -1,
  },
};

export const ButtonInstagram = styled(Button)<ButtonProps>(({ theme }) => ({
  border: "solid 2px transparent",
  borderRadius: "30px",
  minHeight: "38px",
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, ${hPurple[400]} 0%, ${hPink[400]} 53.12%, ${hYellow[400]} 100%)`,
  backgroundOrigin: "border-box",
  backgroundClip: "content-box, border-box",
  boxShadow: "2px 1000px 1px #fff inset",
  "& > p": {
    background: `linear-gradient(180deg, ${hPurple[400]} 0%, ${hPink[400]} 53.12%, ${hYellow[400]} 100%)`,
    backgroundClip: "text",
    textFillColor: "transparent",
    textDecoration: "underline",
  },
}));
