import React from "react";
import { IInstagramTabProps } from "../InfluencerSocial.types";
import DefaultTab from "./DefaultTab";
// import DetailTab from "./DetailTab";

import { useInfluencerSocialStyle as classes } from "../InfluencerSocial.styles";
import { Box } from "@mui/material";

export default function InstagramTab(props: IInstagramTabProps) {
  const { TabPanel, tabSelected, instagram, matches } = props;

  return (
    <TabPanel value={tabSelected} index={matches ? 2 : 1}>
      <Box sx={classes.tabPanelBox}>
        {instagram !== null ? <DefaultTab /> : <DefaultTab />}
      </Box>
    </TabPanel>
  );
}
