import * as yup from "yup";

declare module "yup" {
  interface ArraySchema<T> {
    unique(mapper: (a: T) => T, message?: any): ArraySchema<T>;
  }
}

yup.addMethod(
  yup.array,
  "unique",
  function (
    mapper = (a: any) => a,
    // eslint-disable-next-line no-template-curly-in-string
    message: string = "${path} may not have duplicates."
  ) {
    return this.test("unique", message, (list) => {
      return (list as any).length === new Set((list as any).map(mapper)).size;
    });
  }
);

export const PersonalInformationFormSchema = yup.object().shape({
  fullname: yup.string().required("Please enter your full name.").matches(/^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/, "Full name is not Valid"),
  birth_date: yup.string().nullable(),
  gender: yup.string().required("Please select your gender."),
  nationality: yup.string().nullable(),
  phone: yup.string().required("Please enter your phone.").matches(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/, "Phone number should be 10 digits"),
  line_id: yup.string().nullable(),
  description: yup.string().nullable(),
  images: yup.array().nullable()
});

export const ChangePasswordFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your full name."),
  newPassword: yup
    .string()
    .required("Please select your new password.")
    .min(8, "Must be 8-20 characters")
    .max(20, "Must be 8-20 characters")
    .matches(
      /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d))+/g,
      "Must contain English uppercase letters (A-Z), lowercase letters (a-z), and numbers (0-9)"
    )
    .matches(
      /^(?!.*[,‘“'"&])+/g,
      "Can contain special characters except , ‘ “ &"
    ),
  confirmPassword: yup
    .string()
    .required("Please select confirm password.")
    .oneOf([yup.ref("newPassword")], "Passwords do not match."),
});
