import React from "react";
// Material UI
import {
  Box,
  Grid,
  Typography,
  Stack,
  Pagination,
  TablePagination,
} from "@mui/material";
// Components
import ExpenseTab from "../../components/expense/ExpenseTab";
import ExpenseDefault from "../../components/expense/Expense";
// Styles
import { expenseStyles as classes } from "./expense.styles";

export default function Expense() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Box sx={classes.expenseContainer}>
      <Typography mt={2} mb={4} variant="h5">
        Expense
      </Typography>
      <Box sx={classes.expenseBox}>
        <ExpenseTab />
        <ExpenseDefault />
      </Box>
      <Stack
        sx={classes.pagination}
        spacing={5}
        direction={["column", "row"]}
        justifyContent="center"
        alignItems="center"
        my={4}
      >
        <Pagination count={10} color="secondary" />
        <TablePagination
          component="div"
          labelRowsPerPage={<span>Shows : </span>}
          // rowsPerPageOptions={[10, 25, 100]}
          // count={data.length}
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Box>
  );
}
