import * as yup from "yup";

export const SignInFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),
  password: yup.string().required("Please select your password."),
});

export const CreateAccountFormSchema = yup.object().shape({
  fullName: yup.string().required("Please enter your full name."),
  birthdate: yup.date().required("Please enter your birthdate.").nullable(true),
  gender: yup.string().required("Please enter your gender.").nullable(true),
  nationality: yup
    .string()
    .required("Please enter your nationality.")
    .nullable(true),
  phone: yup.string().required("Please enter your phone."),
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),
  password: yup.string().required("Please enter your password."),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password.")
    .oneOf([yup.ref("password")], "Passwords do not match."),
});

export const ForgetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),
});
