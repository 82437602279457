import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Component
import { menuList } from "./HomeHeaderBar";
// Style
import { useHeaderBarMobileStyle as classes } from "./HomeHeaderBar.styles";
// Type
import { IHomeHeaderBarMobileProps } from "./HomeHeader.types";
// Material
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Link,
} from "@mui/material";
// Icon
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
// Image
import Logo from "./../../assets/images/logo/HashU logo 2.svg";
import LogoWhite from "./../../assets/images/logo/HashU logo 1.svg";

function HomeHeaderBarMobile(props: IHomeHeaderBarMobileProps) {
  const { breakpoint, setIsOpenSignIn } = props;
  const history = useHistory();
  const [menuMobileState, setMenuMobileState] = useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuMobileState(open);
    };

  const isHomePath = () => {
    switch (history.location.pathname) {
      case "/":
        return true;
      case "/home/influencer":
        return true;
      case "/home/marketer":
        return true;
      case "/home/pricing":
        return false;
      case "/home/contact":
        return false;
      default:
        return true;
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={menuMobileState}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={classes.drawerBox}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box px={8} py={3}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton aria-label="delete" color="primary">
                <CloseIcon
                  fontSize={breakpoint === "xs" ? "medium" : "large"}
                />
              </IconButton>
            </Box>
            <Box display="flex" justifyContent="center" mt={4} mb={7}>
              <Link href="/" underline="none">
                <img src={Logo} alt="logo" style={classes.logoImg} />
              </Link>
            </Box>
            <List sx={classes.menuList}>
              {menuList.map((menu, index) => (
                <ListItem key={index} disablePadding sx={classes.menuListItem}>
                  <ListItemButton>
                    <Link href={menu.path} underline="none" width="100%">
                      <ListItemText
                        primary={menu.label}
                        primaryTypographyProps={{
                          variant: breakpoint === "xs" ? "body1" : "h6",
                          fontWeight: "bold",
                          color:
                            history.location.pathname === menu.path
                              ? "secondary"
                              : "grey.700",
                          align: "center",
                        }}
                      />
                    </Link>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <ListItemButton onClick={() => setIsOpenSignIn(true)}>
              <ListItemText
                primary="Sign In"
                primaryTypographyProps={{
                  variant: breakpoint === "xs" ? "body1" : "h6",
                  fontWeight: "bold",
                  align: "center",
                  sx: classes.signInListItemText,
                }}
              />
            </ListItemButton>
          </Box>
        </Box>
      </Drawer>

      <Box sx={classes.menuAbsolute}>
        <img
          src={isHomePath() ? LogoWhite : Logo}
          alt="HashuWhite"
          style={{
            width: breakpoint === "xs" ? "32px" : "40px",
            objectFit: "contain",
          }}
        />
        <IconButton
          size="small"
          onClick={toggleDrawer(true)}
          sx={{
            ...classes.menuIconButton,
            color: isHomePath() ? "common.white" : "common.black",
          }}
        >
          <MenuIcon color="inherit" fontSize="inherit" />
        </IconButton>
      </Box>
    </React.Fragment>
  );
}

export default HomeHeaderBarMobile;
