export const useEmployerDetailStyle = {
  cardHeader: {
    height: ["500px", "300px", "300px", "160px"],
    marginTop: "20px",
    baxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  },

  boxAvatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
  },

  avatar: {
    borderRadius: "4px",
    width: "120px",
    height: "120px",
    objectFit: "cover",
  },

  boxOrganizationName: {
    display: "flex",
    justifyContent: ["center", "center", "center", "start"],
    margin: ["0 20px", "0 20px", "0 20px", "28px 20px 0px 20px"],
  },
  boxContainInfo: {
    display: "flex",
    flexDirection: "row",
  },
  boxEmployerInfo: {
    display: "flex",
    flexDirection: "row",
    marginTop: "8px",
    marginLeft: "20px",
    marginRight: "20px",
  },
};
