import { useEffect, useState } from "react";
/**
 * useScrollingUp
 * @returns {boolean}
 */
const useScrollingUp = () => {
  let prevScroll = window.pageYOffset;
  const [scrollingUp, setScrollingUp] = useState(true);
  const handleScroll = () => {
    const currScroll = window.pageYOffset;
    const isScrolled = prevScroll > currScroll;
    setScrollingUp(isScrolled);
    prevScroll = currScroll;
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return scrollingUp;
};

export default useScrollingUp;
