import { styled } from "@mui/system";
import { Avatar, Tab, TabProps } from "@mui/material";
import { hPurple } from "../../theme";

export const useInfluencerSocialStyle = {
  tabs: {
    width: "100%",
    minHeight: "fit-content",
    "& button svg": {
      fontSize: "18px",
    },
  },
  tabPanelBox: {
    display: "flex",
    backgroundColor: "common.white",
    borderRadius: "0px 0px 20px 20px",
    // minHeight: "560px",
    py: 4,
    px: 6,
    boxShadow: 3,
  },
};

export const SocialTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: hPurple[100],
  borderRadius: "20px 20px 0px 0px",
  minHeight: "42px",
  maxHeight: "42px",

  "&:not(:last-child)": {
    marginRight: ["0%", "0%", "0.6%"],
  },
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  [theme.breakpoints.up("xs")]: {
    width: "32%",
    fontSize: "14px",
    fontWeight: "500",
  },
  [theme.breakpoints.up("sm")]: {
    width: "16%",
  },
  [theme.breakpoints.up("md")]: {
    width: ["20%", "20%", "19.52%"],
  },
}));

export const AudienceTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: hPurple[100],
  borderRadius: "20px",
  minHeight: "28px",
  maxHeight: "28px",
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: "25px",
  height: "25px",
}));
