import React from "react";
// MUI
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
  Divider,
  Grid,
} from "@mui/material";
// Global
import WrapInputWithLabel from "../../global/wrap-input-label";
import TextFieldCustom from "../../global/text-field";
import DateRangeInput from "../../global/date-range-input";
// Icons
import WifiIcon from "@mui/icons-material/Wifi";
import GroupIcon from "@mui/icons-material/Group";
import PolylineRoundedIcon from "@mui/icons-material/PolylineRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CircleIcon from "@mui/icons-material/Circle";
// Styles
import { useTimelineStyle as classes } from "./CampaignBrief.style";
// Mock data
import { timelineData } from "./CampaignBriefData";
//  DayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
import { DatePicker } from "@mui/x-date-pickers";
dayjs.locale("th");

export default function Timeline({ form }: any) {
  const {
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    register,
    getValues,
  } = form;
  return (
    <Card id="timeline" sx={classes.cardContainer}>
      <CardHeader
        title={
          <Box sx={classes.cardHeader}>
            <Typography>Timeline</Typography>
            <Typography>ระยะเวลาของแคมเปญ</Typography>
          </Box>
        }
      />
      <Divider sx={classes.divider} />
      <CardContent>
        <Grid container>
          <Grid item xs={1} mt={2.5} position="relative" sx={{ zIndex: 1 }}>
            <Box sx={classes.timelineLeft}>
              <WifiIcon color="primary" />
            </Box>
            <Divider sx={classes.timelineDevider} orientation="vertical" />
          </Grid>

          <Grid item xs={11} mb={3}>
            <Box sx={classes.timelineRigthBox}>
              <Typography color="primary" fontWeight="500" mb="26px">
                เปิดรับสมัคร
              </Typography>
              <WrapInputWithLabel
                labelWidth="200px"
                title={
                  <Stack direction="row" spacing={1} sx={classes.announceDate}>
                    <CircleIcon />
                    <Typography>ช่วงที่เปิดรับสมัคร</Typography>
                  </Stack>
                }
                required
              >
                <DateRangeInput
                  clearErrors={clearErrors}
                  errors={errors}
                  watch={watch}
                  register={register}
                  startKeyName="timeline.annouceDateAndCloseDate.annouceDate"
                  endKeyName="timeline.annouceDateAndCloseDate.closeDate"
                  setValue={setValue}
                  placeholderFirst="วันเปิดรับสมัคร"
                  placeholderSecond="วันปิดรับสมัคร"
                />
              </WrapInputWithLabel>
            </Box>
          </Grid>

          <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
            <Box sx={classes.timelineLeft}>
              <GroupIcon color="primary" />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box sx={{ ...classes.timelineRigthBox, height: "87%" }}>
              <Typography color="primary" fontWeight="500" mb="26px">
                คัดเลือกและประกาศผลผู้เข้าร่วมแคมเปญ
              </Typography>
              <WrapInputWithLabel
                labelWidth="200px"
                title={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={classes.selectCampParticipants}
                  >
                    <CircleIcon />
                    <Typography>วันคัดเลือกอินฟลูเอนเซอร์</Typography>
                  </Stack>
                }
              >
                <Stack direction="row">
                  <TextFieldCustom
                    variant="outlined"
                    placeholder="วันเปิดรับสมัคร"
                    value={
                      getValues(
                        "timeline.annouceDateAndCloseDate.annouceDate"
                      ) &&
                      dayjs(
                        getValues(
                          "timeline.annouceDateAndCloseDate.annouceDate"
                        )
                      ).format("DD/MM/YYYY")
                    }
                    disabled
                    fullWidth
                    error={!!errors.timeline?.annouceDate}
                    helperText={errors.timeline?.annouceDate?.message}
                  />
                  <Typography display="flex" alignItems="center" mx={2}>
                    -
                  </Typography>
                  <TextFieldCustom
                    variant="outlined"
                    placeholder="วันปิดรับสมัคร+3"
                    value={
                      getValues("timeline.annouceDateAndCloseDate.closeDate") &&
                      dayjs(
                        getValues("timeline.annouceDateAndCloseDate.closeDate")
                      ).format("DD/MM/YYYY")
                    }
                    disabled
                    fullWidth
                    error={!!errors.timeline?.annouceDate}
                    helperText={errors.timeline?.annouceDate?.message}
                  />
                </Stack>
              </WrapInputWithLabel>
              <WrapInputWithLabel
                labelWidth="200px"
                title={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={classes.selectCampParticipants}
                  >
                    <CircleIcon />
                    <Typography>วันประกาศผล</Typography>
                  </Stack>
                }
              >
                <TextFieldCustom
                  variant="outlined"
                  placeholder="วันประกาศผล"
                  value={
                    getValues("timeline.annouceDateAndCloseDate.closeDate") &&
                    dayjs(
                      getValues("timeline.annouceDateAndCloseDate.closeDate")
                    ).format("DD/MM/YYYY")
                  }
                  disabled
                  fullWidth
                  error={!!errors.timeline?.annouceDate}
                  helperText={errors.timeline?.annouceDate?.message}
                  sx={{ width: "47.5%" }}
                />
              </WrapInputWithLabel>
              <Box sx={classes.text}>
                {timelineData.selectCampParticipantsData.map((item, index) => (
                  <ul key={index}>
                    <li>{item.label}</li>
                    {item.text.map((txtItem, txtIndex) => (
                      <li key={txtIndex}>{txtItem}</li>
                    ))}
                  </ul>
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
            <Box sx={classes.timelineLeft}>
              <PolylineRoundedIcon color="primary" />
            </Box>
          </Grid>
          <Grid item xs={11} mb={2}>
            <Box sx={{ ...classes.timelineRigthBox, height: "87%" }}>
              <Typography color="primary" fontWeight="500" mb="26px">
                ส่งงานรีวิว
              </Typography>
              <WrapInputWithLabel
                labelWidth="200px"
                title={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={classes.selectCampParticipants}
                  >
                    <CircleIcon />
                    <Typography>วันที่ต้องส่ง Draft ครั้งแรก</Typography>
                  </Stack>
                }
                required
              >
                <TextFieldCustom
                  variant="outlined"
                  placeholder="วันปิดรับสมัคร+3"
                  fullWidth
                  error={!!errors.timeline?.annouceDate}
                  helperText={errors.timeline?.annouceDate?.message}
                  {...register("timeline.annouceDate")}
                  sx={classes.closeDate}
                />
              </WrapInputWithLabel>
              <Box sx={classes.text}>
                {timelineData.submitDraft.map((item, index) => (
                  <ul key={index}>
                    <li>{item.label}</li>
                    {item.text.map((txtItem, txtIndex) => (
                      <li key={txtIndex}>{txtItem}</li>
                    ))}
                  </ul>
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
            <Box sx={classes.timelineLeft}>
              <WifiIcon color="primary" />
            </Box>
          </Grid>
          <Grid item xs={11} mb={2}>
            <Box sx={{ ...classes.timelineRigthBox, height: "80%" }}>
              <Typography color="primary" fontWeight="500" mb="26px">
                ช่วงโพสต์และแนบลิงค์ส่งงาน
              </Typography>
              <WrapInputWithLabel
                labelWidth="200px"
                title={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={classes.selectCampParticipants}
                  >
                    <CircleIcon />
                    <Typography>ช่วงที่โพสต์งาน</Typography>
                  </Stack>
                }
                required
              >
                <DateRangeInput
                  clearErrors={clearErrors}
                  errors={errors}
                  watch={watch}
                  register={register}
                  startKeyName="timeline.postDate.posting.startPost"
                  endKeyName="timeline.postDate.posting.endPost"
                  setValue={setValue}
                  placeholderFirst="วันแรกที่เริ่มโพสต์แคมเปญ"
                  placeholderSecond="วันสุดท้ายที่โพสต์แคมเปญ"
                />
              </WrapInputWithLabel>
              <WrapInputWithLabel
                labelWidth="200px"
                title={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={classes.selectCampParticipants}
                  >
                    <CircleIcon />
                    <Typography>ระยะเวลาในการโพสต์</Typography>
                  </Stack>
                }
                required
              >
                <TextFieldCustom
                  variant="outlined"
                  type="number"
                  placeholder="ระยะเวลาในการโพสต์"
                  fullWidth
                  error={!!errors.timeline?.postingPeriod}
                  helperText={errors.timeline?.postingPeriod?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Day(s)</InputAdornment>
                    ),
                  }}
                  {...register("timeline.postDate.postingPeriod", {
                    required: true,
                  })}
                  sx={{ ...classes.closeDate, width: "47.5%" }}
                />
              </WrapInputWithLabel>
            </Box>
          </Grid>

          <Grid item xs={1} mt={2.5} sx={{ zIndex: 1 }}>
            <Box sx={classes.timelineLeft}>
              <CheckRoundedIcon color="primary" />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box sx={{ ...classes.timelineRigthBox, height: "72%" }}>
              <Typography color="primary" fontWeight="500" mb="26px">
                สิ้นสุดแคมเปญ
              </Typography>
              <WrapInputWithLabel
                labelWidth="200px"
                title={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={classes.selectCampParticipants}
                  >
                    <CircleIcon />
                    <Typography>วันสิ้นสุดแคมเปญ</Typography>
                  </Stack>
                }
              >
                <TextFieldCustom
                  variant="outlined"
                  placeholder="วันสุดท้าย + posting period"
                  disabled
                  fullWidth
                  error={!!errors.timeline?.annouceDate}
                  helperText={errors.timeline?.annouceDate?.message}
                  {...register("timeline.annouceDate")}
                  sx={{ width: "48%" }}
                />
              </WrapInputWithLabel>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
