import React from "react";

export default function campaignsBrief() {
  return (
    <React.Fragment>
      <div className="container-main">
        <div>campaignsBrief</div>
      </div>
    </React.Fragment>
  );
}
