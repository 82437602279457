import { hPink } from "../../theme";

export const useEmployersStyle = {
  containnerMain: {
    padding: ["88px 20px 20px 20px", "88px 28px 28px 120px"],
  },
  cardEmployers: {
    width: "100%",
    borderRadius: "20px",
    marginBottom: "20px",
    boxShadow: "3",
  },
  boxSearch: {
    display: "flex",
    justifyContent: ["center", "center", "center", "flex-start"],
    margin: "20px 20px 0 20px",
  },
  boxNewEmployer: {
    display: "flex",
    justifyContent: ["center", "flex-end"],
    margin: "20px 20px 0 20px",
  },
  buttonNewEmployer: {
    justifyContent: "space-evenly",
    backgroundColor: "#ED1968",
    color: "white",
    width: "168px",
    shadow: 3,
    "&:hover": {
      backgroundColor: hPink[200],
    },
  },
};
