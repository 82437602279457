import { hGrey } from "../../theme";

export const useInfluencerMyPortfolioStyle = {
  portfolioCard: {
    width: "100%",
    borderRadius: "20px",
    marginBottom: "20px",
  },
  portfolioCardHeader: {
    backgroundColor: "primary.main",
    textAlign: "center",
    height: "42px",
    padding: "0",
    fontWeight: "bold",
  },
  cardContent: {
    padding: "0 20px 0",
  },
  cardActionBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    pb: 4,
    pt: 3,
  },
  line: {
    width: "100%",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: `1px solid ${hGrey[100]}`,
    margin: 0,
  },
  portfolioIconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
  // portfolioDetailBox: {
  //   paddingTop: "8px",
  // },
  portfolioCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
};

export const useEditMyPortfolioDialogStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "80%", "670px"],
      margin: [1, 2, 3],
    },
  },
  dialogContent: {
    paddingTop: 3,
    paddingBottom: 0,
  },
  dialogAction: {
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 5,
  },
  saveButton: {
    width: "14.4px",
    height: "14.4p",
  },
  saveTitel: {
    cursor: "pointer",
    "&:hover": { opacity: "0.8" },
    fontSize: "14px",
  },
};
