import { Box, Typography, Grid, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import CardInfluencerLg from "../global/card-influencer/CardInfluencerLg";

interface IInfluencerList {
  sortType: string | number;
  setSortType: Function;
  influencerList: Array<Object>;
  influencerFilter: Array<Object>;
  influencerSelected: Array<Object>;
  setInfluencerSelected: Function;
}

function InfluencerListContainer({
  sortType,
  setSortType,
  influencerList,
  influencerFilter,
  influencerSelected,
  setInfluencerSelected,
}: IInfluencerList) {
  const onHandleSort = (event: any) => {
    setSortType(event.target.value);
  };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBlock: "12px",
    },
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Typography variant="h6" color="common.black" fontWeight="500">
          {influencerList.length} Influencers
        </Typography>

        <Box display="flex" alignItems="center">
          <Typography
            variant="body1"
            color="common.black"
            fontWeight="400"
            mr={2}
          >
            Sort by :
          </Typography>
          <Select
            value={sortType}
            style={{ width: "250px", height: "50px" }}
            onChange={onHandleSort}
            defaultValue={0}
          >
            <MenuItem selected value={0} disabled>
              Sort by
            </MenuItem>
            <MenuItem value={1}>Price high to low</MenuItem>
            <MenuItem value={2}>Price low to high</MenuItem>
          </Select>
        </Box>
      </Box>

      <Grid container spacing={5}>
        {influencerFilter.map((profile) => {
          return (
            <Grid item>
              <CardInfluencerLg
                profile={profile}
                influencerSelected={influencerSelected}
                setInfluencerSelected={setInfluencerSelected}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default InfluencerListContainer;
