import React from "react";
import SocialImagesPost from "../global/social-post/SocialImagesPost";
import SocialVideoPost from "../global/social-post/SocialVideoPost";

interface image {
  img: string;
  title: string;
}

interface socialPost {
  id: number;
  name: string;
  textDetail: string;

  type: number;
  video: string;
  images: any[];
  rating: {
    likes: number;
    comments: number;
    shares: number;
  };
  status: {
    statusCode: number;
    statusText: string;
  };
}

function PostReview({ posts }: any) {
  return (
    <div>
      {posts.length
        ? posts.map((post: any) => {
            return post.type === 1 ? (
              <SocialImagesPost
                key={post.id}
                title="Post Preview"
                post={post}
              />
            ) : (
              <SocialVideoPost key={post.id} title="Post Preview" post={post} />
            );
          })
        : ""}
    </div>
  );
}

export default PostReview;
