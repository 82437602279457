import React, { useState, useEffect } from "react";
// MUI
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  Grid,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
// global
import WrapInputWithLabel from "../../global/wrap-input-label";
import TextFieldCustom from "../../global/text-field";
import BasicSelectCustom from "../../global/basic-select";
import CreatableSelectCustom from "../../global/creatable-select-custom";
import MultiImagesVideoInput from "../../global/multi-images-video-input";
import ImageInput from "../../global/image-input";
// React hook form
import { Controller } from "react-hook-form";
// Types
import {
  ICategory,
  IContentType,
} from "../../../views/create-campaign/CreateCampaign.type";
// mock data
import {
  contentTypeData,
  categoryData,
  hashtagData,
  emphasisOrProhibition,
  optionPlatform,
  descriptionAndMoodTone,
} from "./CampaignBriefData";
// Icons
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
// style
import { useCampaignBriefDetailStyle as classes } from "./CampaignBrief.style";

export default function CampaignBrief({ form }: any) {
  const [optionCategory, setOptionCategory] = useState<ICategory[]>([]);
  const [optionHashtag, setOptionHashtag] = useState<any[]>([]);
  const [optionContentType, setOptionContentType] = useState<IContentType[]>(
    []
  );
  const [copy, setCopy] = useState<string>("copy");

  const {
    formState: { errors },
    setValue,
    watch,
    control,
    register,
    getValues,
  } = form;

  const watchPlatForm = watch("campaignBrief.platform");

  const onHandleChange = (e: any, keyName: any, arr: Array<any>) => {
    const { value } = e.target;
    setValue(keyName, value);
  };

  useEffect(() => {
    if (categoryData && categoryData.result) {
      setOptionCategory(categoryData.result.data);
    }
  }, [categoryData]);

  useEffect(() => {
    setValue("campaignBrief.contentType", "");
    if (contentTypeData && contentTypeData.result && watchPlatForm) {
      const filterList = contentTypeData.result.data.filter(
        (item) => item.platform === watchPlatForm
      );

      setOptionContentType(filterList);
    } else {
      setOptionContentType([]);
    }
  }, [contentTypeData, watchPlatForm]);

  useEffect(() => {
    if (hashtagData && hashtagData.result) {
      const list = hashtagData.result.data.map((item) => {
        return {
          label: item.name_en,
          value: item.name_en,
          id: item.id,
        };
      });

      setOptionHashtag(list);
    }
  }, [hashtagData]);
  return (
    <Card id="campaignBriefDetail" sx={classes.cardContainer}>
      <CardHeader
        title={
          <Box sx={classes.cardHeader}>
            <Typography>Campaign Brief</Typography>
            <Typography>รายละเอียดแคมเปญ</Typography>
          </Box>
        }
      />
      <Divider sx={classes.divider} />
      <CardContent>
        <Stack spacing={4.5}>
          <WrapInputWithLabel title="Platform" required>
            <BasicSelectCustom
              id="platform-select"
              value={watch("campaignBrief.platform")}
              onChange={(e: any) => {
                onHandleChange(e, "campaignBrief.platform", optionPlatform);
              }}
              data={optionPlatform}
              valueKey="value"
              labelKey="label"
              iconKey="icon"
              placeholder="Select"
              fullWidth
              error={!!errors?.content?.platform}
              helperText={(errors?.content?.platform as any)?.message}
              sx={{ mb: "-10px" }}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Content Type" required>
            <BasicSelectCustom
              id="contentType-select"
              value={watch("campaignBrief.contentType")}
              onChange={(e: any) => {
                onHandleChange(
                  e,
                  "campaignBrief.contentType",
                  optionContentType
                );
              }}
              data={optionContentType}
              valueKey="value"
              labelKey="name_en"
              placeholder="Select"
              fullWidth
              error={!!errors?.content?.contentType}
              helperText={(errors?.content?.contentType as any)?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Cover image" required>
            <ImageInput
              errors={errors?.campaign?.logoOrganization}
              register={register}
              setValue={setValue}
              keyName="campaignBrief.coverImage"
              icon=""
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Brief image" required>
            <MultiImagesVideoInput
              register={register}
              imageKeyName="campaignBrief.briefImage.image"
              videoKeyName="campaignBrief.briefImage.video"
              videoErrors={errors?.campaignBrief?.moodAndToneImage?.video}
              imageErrors={errors.campaignBrief?.moodAndToneImage?.video}
            />
          </WrapInputWithLabel>

          {descriptionAndMoodTone.map((item, index) => (
            <WrapInputWithLabel key={index} title={item.title} required>
              <TextFieldCustom
                variant="outlined"
                placeholder="Please enter text..."
                fullWidth
                multiline
                minRows={5}
                error={
                  item.id === "description"
                    ? !!errors?.campaignBrief?.description
                    : !!errors?.campaignBrief?.moodAndTone
                }
                helperText={
                  item.id === "description"
                    ? errors?.campaignBrief?.description?.message
                    : errors?.campaignBrief?.moodAndTone?.message
                }
                inputProps={{
                  ...register(`campaignBrief.${item.id}`, { required: true }),
                }}
              />
            </WrapInputWithLabel>
          ))}

          <WrapInputWithLabel title="Mood and Tone image">
            <MultiImagesVideoInput
              register={register}
              imageKeyName="campaignBrief.moodAndToneImage.image"
              videoKeyName="campaignBrief.moodAndToneImage.video"
              videoErrors={errors?.campaignBrief?.moodAndToneImage?.video}
              imageErrors={errors.campaignBrief?.moodAndToneImage?.video}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Key Message" required>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              multiline
              minRows={5}
              error={!!errors?.campaignBrief?.keyMessage}
              helperText={errors?.campaignBrief?.keyMessage?.message}
              inputProps={{
                ...register("campaignBrief.keyMessage", { required: true }),
              }}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Hashtag">
            <Controller
              name="campaignBrief.hashtag"
              control={control}
              rules={{
                required: false,
              }}
              defaultValue=""
              render={({ field }) => {
                return (
                  <CreatableSelectCustom
                    options={optionHashtag}
                    {...field}
                    prefix="#"
                  />
                );
              }}
            />
          </WrapInputWithLabel>

          {emphasisOrProhibition.map((item, index) => (
            <WrapInputWithLabel key={index} title={<>{item.label}</>} required>
              <Controller
                name={`campaignBrief.${item.id}`}
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextFieldCustom
                    variant="outlined"
                    multiline
                    minRows={5}
                    placeholder="Please enter text..."
                    fullWidth
                    error={
                      item.id === "doOrEmphasis"
                        ? !!errors?.campaignBrief?.doOrEmphasis
                        : !!errors?.campaignBrief?.dontOrProhibitio
                    }
                    helperText={
                      item.id === "doOrEmphasis"
                        ? errors?.campaignBrief?.doOrEmphasis.message
                        : errors?.campaignBrief?.dontOrProhibitio.message
                    }
                    {...field}
                  />
                )}
              />
              <Box sx={classes.doOrEmphasisTextBox}>
                <Typography
                  color={item.id === "doOrEmphasis" ? "success.main" : "error"}
                >
                  {item.titleText}
                </Typography>
                <ul>
                  {item.textItems.map((data) => (
                    <li>{data}</li>
                  ))}
                </ul>
                <Tooltip title={copy} placement="top">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard
                        .writeText(item.textItems.toString())
                        .then(() => setCopy("Copied"));
                    }}
                    onMouseOut={() => {
                      setCopy("Copy");
                    }}
                    sx={
                      item.id === "doOrEmphasis"
                        ? classes.copyIcon
                        : { ...classes.copyIcon, top: "127px" }
                    }
                  >
                    <FileCopyOutlinedIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </WrapInputWithLabel>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
