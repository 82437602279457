import React from "react";

function MyJobs() {
  return (
    <React.Fragment>
      <div className="container-main">
        <div>My Jobs</div>
      </div>
    </React.Fragment>
  );
}

export default MyJobs;
