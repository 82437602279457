export const hashtagData = {
  status: "OK",
  status_code: 200,
  result: {
    data: [
      {
        id: 28,
        value: "travel",
        name_en: "Travel",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 29,
        value: "samsung",
        name_en: "Samsung",
        name_th: "",
        type: "brand",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 30,
        value: "beauty",
        name_en: "Beauty",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234651Z",
        updated_at: "2022-04-29T09:47:57.234651Z",
      },
      {
        id: 31,
        value: "food",
        name_en: "Food",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 32,
        value: "study",
        name_en: "Study",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 33,
        value: "pet",
        name_en: "Pet",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 34,
        value: "อร่อย",
        name_en: "Delicious",
        name_th: "อร่อย",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 35,
        value: "brand-name",
        name_en: "BrandName",
        name_th: "",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
      {
        id: 36,
        value: "ทะเล",
        name_en: "Sea",
        name_th: "ทะเล",
        type: "hashtag",
        created_at: "2022-04-29T09:47:57.234",
        updated_at: "2022-04-29T09:47:57.234",
      },
    ],
  },
};
