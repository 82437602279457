import React from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { calculateNumberSocialUnit } from "../../../utils/helpers/calculateNumberSocialUnit.helpers";
import { numberWithCommas } from "../../../utils/helpers/numberWithCommas";

interface ICardInfluencerSm {
  profile: any;
  setInfluencerSelected: any;
  influencerSelected: any[];
}

function CardInfluencerSm(props: ICardInfluencerSm) {
  const { profile, setInfluencerSelected, influencerSelected } = props;
  const {
    influencer_id,
    platform,
    media,
    social_name,
    followers,
    rate_card,
    avg_comment,
    avg_like,
    avg_share,
    recommended,
  } = profile;

  const styles = {
    card: {
      p: 0,
      width: "80px",
      borderRadius: "10px",
    },
    img: {
      width: "80px",
      height: "80px",
      objectFit: "cover" as "cover",
    },
    icon: {
      width: 15,
      height: 15,
      objectFit: "contain" as "contain",
      marginRight: 15,
    },
    detail: {
      p: 1,
      backgroundColor: "primary.100",
    },
    crossIcon: {
      position: "absolute" as "absolute",
      top: "-10px",
      right: "-10px",
      color: "black",
      backgroundColor: "white",
      padding: 0,
    },
    textName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: " -webkit-box",
      WebkitLineClamp: "1",
      lineClamp: "1",
      WebkitBoxOrient: "vertical",
      textAlign: "center",
      width: "100%",
    },
  };

  const onHandleDelete = () => {
    const profileFilter = influencerSelected.filter((item) => {
      return item.influencer_id !== profile.influencer_id;
    });

    setInfluencerSelected(profileFilter);
  };

  return (
    <div style={{ position: "relative" }} key={influencer_id}>
      <Card sx={styles.card}>
        <Avatar src={media.images[0].url} variant="square" sx={styles.img} />
        <CardContent sx={styles.detail}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="body2"
              color="common.black"
              fontWeight="500"
              sx={styles.textName}
            >
              {social_name.split(" ")[0]}
            </Typography>

            <Box display="flex" alignItems="center">
              {/* <img
                src="/assets/images/user.png"
                alt="user"
                style={styles.icon}
              /> */}
              <i className="ico-hu-user-circle-solid-in" />
              <Typography
                variant="body2"
                color="common.black"
                fontWeight="500"
                sx={{ marginLeft: "2px" }}
              >
                {calculateNumberSocialUnit(+followers)}
              </Typography>
            </Box>

            <Typography variant="body2" color="primary.300" fontWeight="500">
              ฿ {numberWithCommas(rate_card[0].price.value)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <IconButton
        component="span"
        style={styles.crossIcon}
        onClick={onHandleDelete}
      >
        {/* <CancelIcon /> */}
        <i className="ico-hu-cancel-circle-solid" />
      </IconButton>
    </div>
  );
}

export default CardInfluencerSm;
