import React, { useState } from "react";
// MUI
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  Tooltip,
  Grid,
  IconButton,
  Divider,
  InputAdornment,
  Button,
} from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
// Global
import TextFieldCustom from "../../global/text-field";
// MUI Icons
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
// Style
import { useTermsAndConditionStyle as classes } from "./CampaignBrief.style";
import WrapInputWithLabel from "../../global/wrap-input-label";
import { numberWithCommas } from "../../../utils/helpers/numberWithCommas";
// import useNav from "../../../../utils/hooks/useNav";
import { termAndConditionData } from "./CampaignBriefData";

export default function TermsAndConditions({ form }: any) {
  const [copy, setCopy] = useState<string>("Copy");

  const {
    formState: { errors },
    control,
    register,
    getValues,
    watch,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "termsAndConditions.termsAndConditions",
  });
  return (
    <Card id="termsAndConditions" sx={classes.cardContainer}>
      <CardHeader
        title={
          <Box sx={classes.cardHeader}>
            <Typography>Terms and Conditions</Typography>
            <Typography>เงื่อนไขและข้อตกลงในการทำงาน</Typography>
          </Box>
        }
      />
      <Divider sx={classes.divider} />
      <CardContent>
        <WrapInputWithLabel
          labelWidth="200px"
          title={
            <Typography>
              Terms and Conditions<span className="requir">*</span>
            </Typography>
          }
        >
          <Stack>
            {fields?.map((item, index) => {
              return (
                <Grid
                  container
                  spacing={2}
                  mb={3}
                  key={item.id}
                  justifyContent="flex-end"
                >
                  <Grid item xs={12}>
                    <Controller
                      name={`termsAndConditions.termsAndConditions.${index}.data`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextFieldCustom
                          variant="outlined"
                          multiline
                          fullWidth
                          error={!!errors?.termAndCondition}
                          {...field}
                          sx={classes.textfield}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                color="primary"
                                aria-label="add an alarm"
                                component="label"
                                sx={classes.IconButton}
                                onClick={() => {
                                  if (
                                    getValues(
                                      "termsAndConditions.termsAndConditions"
                                    ).length -
                                      1 ===
                                    index
                                  ) {
                                    append({ data: "" });
                                  } else {
                                    remove(index);
                                  }
                                }}
                              >
                                {getValues(
                                  "termsAndConditions.termsAndConditions"
                                ).length -
                                  1 ===
                                index ? (
                                  <AddIcon sx={classes.addIcon} />
                                ) : (
                                  <RemoveCircleIcon
                                    sx={classes.IconButton}
                                    color="error"
                                  />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
        </WrapInputWithLabel>

        <Grid container justifyContent="flex-end">
          <Grid item xs={9}>
            <Box sx={classes.textExample}>
              <Typography>ตัวอย่าง “ข้อตกลงและเงื่อนไข”</Typography>
              <ul>
                {termAndConditionData.map((data) => (
                  <li>{data}</li>
                ))}
              </ul>
              <Tooltip title={copy} placement="top">
                <IconButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(termAndConditionData.toString())
                      .then(() => setCopy("Copied"));
                  }}
                  onMouseOut={() => {
                    setCopy("Copy");
                  }}
                  sx={classes.coppyIcon}
                >
                  <FileCopyOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
