import React from "react";
// Mui
import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
// Style
import { useCampaignDetailBriefStyle as classes } from "./CampaignDetails.style";

export default function CampaignDetailBrief() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={3}>
        <Typography>Platform</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Fashion, Lifestyle</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>Content Type</Typography>
      </Grid>
      <Grid item xs={9} sx={classes.contentType}>
        <Avatar />
        <Avatar />
        <Avatar />
      </Grid>
      <Grid item xs={3}>
        <Typography>Description</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          ใกล้สิ้นปีแล้วเนอะทุกคน ปีนี้เป็นปีที่ผมได้ลองทำอะไรเยอะมากๆเลย
          และหนึ่งในนั้นก็คือ การที่ผมได้เปิด แบรนด์เป็นของตัวเอง ซึ่งก็คือ
          Wital นั่นเอง ผมได้ประสบการณ์ใหม่ ผ่านมุมมองการทำธุรกิจ
          เยอะมากและสนุกมากด้วยเพราะมี Lazada คอยเติมเต็มทุกโมเมนต์
          ของผมได้ตลอดและเค้ามีทุกสิ่งที่ใจค้นหาจริงๆ นะค้าบบ💖
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>Mood and Tone</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          1. สร้างภาพลักษณ์แบรนด์ใหม่ <br />
          2. สร้างแคมเปญช้อปปิ้งใหม่ที่เจาะจงกลุ่มลูกค้า <br />
          3. เน้นสีหลักของแบรนด์เช่นสีชมพู, น้ำเงิน, ส้ม
        </Typography>
        <Avatar sx={classes.moodAndtoneImg} />
      </Grid>
      <Grid item xs={3}>
        <Typography>Key Message</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          1. เติมเงินมือถือ ครองแชมป์สินค้าที่มีจำนวนคำสั่งซื้อมากที่สุด
          <br />
          2. กระเป๋าสะพาย เป็นสินค้าถูกค้นหามากที่สุด
          <br />
          3. สินค้าไลฟ์สไตล์ โดนใจนักช้อปมากที่สุด
          <br />
          4. LazGame Shop & Match คือเกมส์สุดฮิตที่มีนักช้อป
          <br />
          5. วันจันทร์ คือวันคนช้อปเยอะที่สุด
          <br />
          6. 10-11 โมง คือช่วงเวลาที่คนช้อปเยอะที่สุด
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>Hashtag</Typography>
      </Grid>
      <Grid item xs={9}>
        <Button sx={classes.hashtag} variant="outlined">
          #Lazada1212Supershow
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Typography>Do’s</Typography>
      </Grid>
      <Grid item xs={9}>
        <Box sx={classes.do}>
          <Typography>“สิ่งที่ต้องทำ”</Typography>
          <Typography>
            1. ถ่ายรูปให้เห็นสินค้าชัดเจน เห็นโลโก้ <br />
            2. ถ่ายรูปให้ใส่เสื้อเน้นสีหลักของแบรนด์เช่นสีชมพู, ส้ม,น้ำเงิน
            เป็นต้น
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Typography>Don’ts</Typography>
      </Grid>
      <Grid item xs={9}>
        <Box sx={classes.dont}>
          <Typography>“สิ่งที่ต้องทำ”</Typography>
          <Typography>
            1. ถ่ายรูปให้เห็นสินค้าชัดเจน เห็นโลโก้ <br />
            2. ถ่ายรูปให้ใส่เสื้อเน้นสีหลักของแบรนด์เช่นสีชมพู, ส้ม,น้ำเงิน
            เป็นต้น
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
