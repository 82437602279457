import React, { useState } from "react";
import { useTiktokTabStyle as classes } from "./TiktokTab.styles";
// import { ISocialPost } from "./../../../views/my-profile/MyProfile.types";

import { addDefaultImageSrc } from "../../../utils/helpers/addDefaultImageSrc.helpers";
import { calculateNumberSocialUnit } from "../../../utils/helpers/calculateNumberSocialUnit.helpers";

import { Box, Typography } from "@mui/material";

import HeartIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Chat";
import ShareIcon from "@mui/icons-material/Reply";
import PlayIcon from "@mui/icons-material/PlayArrow";

export function ImagePost({ post }: { post: any }) {
  const [image, setImage] = useState<string>(post.media.image_cover_url);

  return (
    <Box sx={classes.boxImages}>
      <Box sx={classes.imageBox}>
        <div
          className="post-main-profile-tiktok"
          onMouseOver={() => setImage(post.media.animated_cover_url)}
          onMouseOut={() => setImage(post.media.image_cover_url)}
        >
          <img
            src={image}
            srcSet={image}
            alt="videos"
            onError={addDefaultImageSrc}
            className="post-first-layer-tiktok"
          />
          <div className="post-second-layer-tiktok"></div>
          <div className="post-third-layer">
            <Box sx={classes.valuesBox}>
              <Box sx={classes.IconsBox} pr={1}>
                <HeartIcon sx={classes.imagePostIcon} />
                <Typography variant="caption" color="inherit">
                  {calculateNumberSocialUnit(post.like, -1)}
                </Typography>
              </Box>
              <Box sx={classes.IconsBox} pl={1}>
                <CommentIcon sx={classes.imagePostIcon} />
                <Typography variant="caption" color="inherit">
                  {calculateNumberSocialUnit(post.comment, -1)}
                </Typography>
              </Box>
              <Box
                sx={{
                  ...classes.IconsBox,
                  display: ["flex", "none", "flex"],
                }}
                pl={2}
              >
                <ShareIcon sx={classes.imagePostIcon} />
                <Typography variant="caption" color="inherit">
                  {calculateNumberSocialUnit(post.share, -1)}
                </Typography>
              </Box>
            </Box>
          </div>
          <div className="post-fourth-layer">
            <Box width="100%" color="common.white">
              <PlayIcon sx={classes.imageTypePostIcon} />
            </Box>
          </div>
        </div>
      </Box>
    </Box>
  );
}
