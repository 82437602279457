export const provinceData = {
  status: "OK",
  status_code: 200,
  result: {
    data: [
      {
        id: 2,
        name_en: "Bangkok",
        name_th: "กรุงเทพมหานคร",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 19,
        name_en: "Nakhon Pathom",
        name_th: "นครปฐม",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 23,
        name_en: "Nakhon Phanom",
        name_th: "นครพนม",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 25,
        name_en: "Nakhon Ratchasima",
        name_th: "นครราชสีมา",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 26,
        name_en: "Nakhon Nayok",
        name_th: "นครนายก",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 27,
        name_en: "Nakhon Sawan",
        name_th: "นครสวรรค์",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 28,
        name_en: "Nakhon Si Thammarat",
        name_th: "นครศรีธรรมราช",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 64,
        name_en: "Sakon Nakhon",
        name_th: "สกลนคร",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
      {
        id: 77,
        name_en: "Phra Nakhon Sri Ayutthaya",
        name_th: "พระนครศรีอยุธยา",
        created_at: "2022-04-29T09:47:58.238654Z",
        updated_at: "2022-04-29T09:47:58.238654Z",
      },
    ],
  },
};
