import React, { useState, useEffect } from "react";
// Material UI
import { Grid, Box, Typography } from "@mui/material";
// Components
import InfluencerMyEarning from "../../components/influencer-myEarning/InfluencerMyEarningTab";
import InfluencerHistory from "../../components/influencer-myEarning/InfluencerHistory";
// API
import { getInfluencer } from "../../api/influencer/InfluencerAPI";
// Type
import {
  IInfluencerProfileRes,
  IInfluencerBankAccountRes,
} from "./MyEarning.types";

export default function MyEarning() {
  const [influencerProfile, setInfluencerProfile] =
    useState<IInfluencerProfileRes | null>(null);

  useEffect(() => {
    async function getResult() {
      const results = await getInfluencer();
      setInfluencerProfile(results);
    }
    getResult();
  }, []);

  const onSubmitBankAccount = (data: {
    bank_account: IInfluencerBankAccountRes;
  }) => {
    if (influencerProfile !== null) {
      const bankData = {
        ...influencerProfile.bank_account,
        account_name: data.bank_account.account_name,
        account_number: data.bank_account.account_number,
        account_type: data.bank_account.account_type,
        book_bank_image_url: data.bank_account.book_bank_image,
        branch: data.bank_account.branch,
        id_card_image_url: data.bank_account.id_card_image,
        tax_id: data.bank_account.tax_id,
      };
      setInfluencerProfile({
        ...influencerProfile,
        bank_account: { ...bankData },
      });
      console.log("submit bank account: ", data);
    }
  };

  return (
    <Box
      sx={{
        margin: {
          xs: "80px 20px 20px 20px ",
          sm: "80px 20px 20px 110px",
        },
      }}
    >
      <InfluencerMyEarning data={influencerProfile} />
      <InfluencerHistory />
    </Box>
  );
}
