import React from "react";

import {
  Box,
  Card,
  CardHeader,
  Typography,
  CardContent,
  Stack,
  Button,
  Avatar,
  CardActions,
} from "@mui/material";
import ImageGallery from "../image-gallery";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";

interface ISocialPost {
  title: string;
  post: any;
  // post: {
  //   id: string | number;
  //   name: string;
  //   textDetail: string;
  //   images: Array<any>;
  //   rating: {
  //     likes: string | number;
  //     comments: string | number;
  //     shares: string | number;
  //   };
  // };
}

function SocialVideoPost(props: ISocialPost) {
  const {
    title,
    post: { name, textDetail, video, rating },
  } = props;

  const styles = {
    boxContent: {
      p: 2,
      borderRadius: "10px",
      borderColor: "secondary.main",
      borderStyle: "solid",
      borderWidth: "3px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
    container: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
    avatarImg: {
      width: 44,
      height: 44,

      mr: 1,
    },
  };

  const displayAvater = (
    <Box sx={styles.container}>
      <Avatar
        src="/assets/images/lazada.png"
        sx={styles.avatarImg}
        alt="profile-pic"
      />

      <Typography variant="h6" color="common.black" fontWeight="500">
        {name}
      </Typography>
    </Box>
  );

  const displayTextDetail = (
    <Box sx={styles.container} width="100%">
      <Typography
        variant="body1"
        color="common.black"
        fontWeight="inherit"
        width="100%"
      >
        {/* {textDetail} */}
        <div dangerouslySetInnerHTML={{ __html: textDetail }} />
      </Typography>
    </Box>
  );

  const displayGallery = (
    <Box sx={styles.container}>
      {/* <video src="/assets/videos/too-cute.mp4"></video> */}

      <video width="100%" height="100%" controls>
        <source src={video} type="video/mp4" />
      </video>
    </Box>
  );

  return (
    <Card sx={styles.boxContent}>
      <CardHeader
        title={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" color="common.black" fontWeight="inherit">
              {title}
            </Typography>

            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "secondary.main",
                border: "none !important",
              }}
              endIcon={<ArrowCircleRightRoundedIcon />}
            >
              Send Feedback
            </Button>
          </Box>
        }
      />
      <CardContent>
        <Stack>
          {displayAvater}
          {displayTextDetail}
          {displayGallery}
        </Stack>
      </CardContent>

      <CardActions>
        <Stack direction="row" justifyContent="center" spacing={10}>
          <Box display="flex" alignItems="center">
            <i
              className="ico-hu-like"
              style={{ fontSize: "20px", margin: "5px" }}
            />
            <Typography
              variant="subtitle1"
              color="common.black"
              fontWeight="inherit"
            >
              {rating.likes}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <i
              className="ico-hu-comment-line-line"
              style={{ fontSize: "20px", margin: "5px" }}
            />
            <Typography
              variant="subtitle1"
              color="common.black"
              fontWeight="inherit"
            >
              {rating.comments}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <i
              className="ico-hu-eye-circle"
              style={{ fontSize: "20px", margin: "5px" }}
            />
            <Typography
              variant="subtitle1"
              color="common.black"
              fontWeight="inherit"
            >
              69K
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <i
              className="ico-hu-share"
              style={{ fontSize: "20px", margin: "5px" }}
            />
            <Typography
              variant="subtitle1"
              color="common.black"
              fontWeight="inherit"
            >
              {rating.shares}
            </Typography>
          </Box>
        </Stack>
      </CardActions>
    </Card>
  );
}

export default SocialVideoPost;
