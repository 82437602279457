import * as yup from "yup";

export const CreateEmployerFormSchema = yup.object().shape({
  name: yup.string().required("Please enter your organization name."),
  size: yup
    .string()
    .required("Please select your business size.")
    .nullable(true),
  type: yup.string().required("Please select your type.").nullable(true),
  // category: yup.string().required("Please select your category."),
  // .nullable(true),
  fullname: yup.string().required("Please enter your full name."),
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),

  password: yup.string().required("Please enter your password."),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password.")
    .oneOf([yup.ref("password")], "Passwords do not match."),
  phone: yup
    .string()
    .required("Please enter your phone.")
    .matches(
      /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
      "Phone number should be 10 digits"
    ),
  // lineID: yup.string().required("Please enter your line id."),
});
