import React from "react";
import { IYoutubeTabProps } from "../InfluencerSocial.types";
import DefaultTab from "./DefaultTab";
// import DetailTab from "./DetailTab";

import { useInfluencerSocialStyle as classes } from "../InfluencerSocial.styles";
import { Box } from "@mui/material";

export default function YoutubeTab(props: IYoutubeTabProps) {
  const { TabPanel, tabSelected, youtube, matches } = props;
  return (
    <TabPanel value={tabSelected} index={matches ? 3 : 2}>
      <Box sx={classes.tabPanelBox}>{youtube === null && <DefaultTab />}</Box>
    </TabPanel>
  );
}
