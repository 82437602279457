import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory } from "react-router-dom";

interface LinkTabProps {
  label?: string;
  href?: string;
  className?: string;
}

interface INavTabs {
  menu: any[];
}

function LinkTab(props: LinkTabProps) {
  const history = useHistory();
  const { pathname } = history.location;

  const styles = {
    tab: {
      backgroundColor: pathname === props.href ? "#8C499C" : "",
      borderRadius: "90px",
      color: pathname === props.href ? "white" : "inherit",
    },
  };

  return (
    <Tab
      style={styles.tab}
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function NavTabs(props: INavTabs) {
  const { menu } = props;

  const [value, setValue] = useState(0);
  const history = useHistory();

  const { pathname } = history.location;

  const styles = {
    tabsContainer: {
      backgroundColor: "grey.100",
      borderRadius: "90px",
    },
  };

  const handleChange = (event: any, newValue: number) => {
    const { pathname } = event.target;

    // setValue(newValue);
    history.push(pathname);
  };

  useEffect(() => {
    const list = menu.find((item) => item.href === pathname);
    const getIndex = menu.indexOf(list);

    setValue(getIndex);
  }, [pathname]);

  return (
    <Box id="menu-tabs">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs "
        sx={styles.tabsContainer}
      >
        {menu.length
          ? menu.map((item, index) => {
              return (
                <LinkTab
                  key={index}
                  label={item.label}
                  href={item.href}
                  className={`menuTabs`}
                />
              );
            })
          : ""}
      </Tabs>
    </Box>
  );
}

export default NavTabs;
