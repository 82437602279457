import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Style
import { NotiBadge, useHeaderBarStyle as classes } from "./HeaderBar.styles";
// Mui
import {
  Avatar,
  Button,
  Typography,
  Paper,
  Divider,
  Icon,
} from "@mui/material";
import { AppBar, Box, Toolbar, IconButton, Popover } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
// Logo
import logo from "./../../assets/images/logo/HashU_logo-white.png";
// Helper
import { getTokenFromStorage } from "./../../utils/helpers/getTokenFromStorage";
import { useAuth } from "../../contexts/Auth";
// API
import { signOutWithAPI } from "./../../api/authentication/Authentication";

function UserCard({ onClose }: { onClose?: () => void }) {
  const history = useHistory();
  const { signOut } = useAuth();
  const { session_token } = getTokenFromStorage();

  const confirmLogout = async () => {
    try {
      await signOutWithAPI();
      const next = () => history.push("/");
      signOut(next);
    } catch (error) {
      const next = () => history.push("/");
      signOut(next);
    }
  };

  return (
    <Paper sx={{ width: "230px", padding: "10px" }}>
      <Box display="flex" justifyContent="center">
        {session_token?.profile_image_url ? (
          <Avatar
            alt="user-profile-img"
            src={session_token?.profile_image_url}
            sx={classes.profileAvatar}
          />
        ) : (
          <Avatar sx={classes.profileAvatar}>
            {session_token?.fullname.charAt(0)}
          </Avatar>
        )}
      </Box>
      <Typography align="center" sx={{ fontWeight: 700 }}>
        {session_token && session_token.fullname}
      </Typography>
      <Typography variant="subtitle2" align="center">
        (H
        {session_token && session_token.influencer_id.slice(0, 8).toUpperCase()}
        )
      </Typography>

      <Box marginTop="10px">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{ marginBottom: "4px" }}
        >
          <Icon
            className="ico-hu-edit user-button-icon"
            fontSize="inherit"
            sx={{ marginRight: "8px" }}
          />
          <Typography variant="caption">แก้ไขข้อมูลส่วนตัว</Typography>
        </Box>
        <Divider />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "8px", cursor: "pointer" }}
          onClick={confirmLogout}
        >
          <Icon
            className="ico-hu-logout"
            fontSize="inherit"
            sx={{ marginRight: "8px" }}
          />
          <Typography variant="caption">ออกจากระบบ</Typography>
        </Box>
      </Box>
    </Paper>
  );
}

function HeaderBar() {
  const { session_token } = getTokenFromStorage();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSelected, setMenuSelected] = useState<
    null | "notification" | "user"
  >(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    menuName: "notification" | "user"
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setMenuSelected(menuName);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuSelected(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <AppBar position="fixed" color="primary" elevation={1}>
        <Toolbar sx={classes.toolbar}>
          <Box display={["block", "none"]} sx={classes.logoBox}>
            <img src={logo} alt="Hashu Logo" width={"52px"} />
          </Box>
          <Box sx={classes.boxToobar}>
            <IconButton>
              <NotiBadge variant="dot">
                <NotificationsIcon />
              </NotiBadge>
            </IconButton>

            <Button
              variant="contained"
              size="small"
              disableElevation
              sx={classes.headerBarButton}
              onClick={(event) => {
                handleClick(event, "user");
              }}
            >
              {session_token?.profile_image_url ? (
                <Box mr={2}>
                  <Avatar
                    src={session_token?.profile_image_url}
                    alt="profile"
                  />
                </Box>
              ) : (
                <Box mr={2}>
                  <Avatar className="avatar-img">
                    {session_token?.fullname.charAt(0)}
                  </Avatar>
                </Box>
              )}
              <Box sx={classes.boxFullname}>
                <Typography variant="subtitle2" color="common.white">
                  {session_token?.fullname.split(" ")[0]}
                </Typography>
              </Box>
              <Box sx={classes.boxIcon}>
                <KeyboardArrowDown style={{ fontSize: "16px" }} />
              </Box>
            </Button>
            {menuSelected && (
              <Popover
                sx={{ mt: "15px" }}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {menuSelected === "user" && <UserCard onClose={handleClose} />}
              </Popover>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default HeaderBar;
