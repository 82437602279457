import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
// Icons
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// Styles
import { useTimelineDeatailStyle as classes } from "./CampaignDetails.style";
// data
import { timelineData } from "./CampaignDetailsData";

export default function TimelineDetail() {
  return (
    <Grid
      container
      spacing={10}
      mt="-25px"
      // sx={{ width: "100%", ml: 0, mt: 0 }}
    >
      <Grid item xs={12} display="flex" position="relative">
        <Box sx={classes.leftCon} />
        <Box sx={classes.leftConButton}>
          <PeopleAltOutlinedIcon />
        </Box>
        <Box ml="20px">
          <Typography>ประกาศรับสมัคร</Typography>
          <Typography>20/09/2022 - 30/09/2022</Typography>
        </Box>
      </Grid>

      {timelineData.map((item, index) => (
        <Grid key={index} item xs={12} display="flex">
          <Box sx={classes.leftConButton2}>{item.icon}</Box>

          <Box ml="20px">
            <Typography>{item.label}</Typography>
            <Typography>{item.subLabel}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
