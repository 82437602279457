import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  Tooltip,
  Grid,
  IconButton,
} from "@mui/material";
import TextFieldCustom from "../global/text-field";
import { Controller, useFieldArray } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";

function TermAndCondition({ form }: any) {
  const {
    formState: { errors },
    setValue,
    clearErrors,
    control,
    getValues,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "termAndCondition",
  });

  const styles = {
    cardContainer: {
      margin: "8px",
    },
    addFieldBtn: {
      width: "100%",
      borderRadius: "25px",
      backgroundColor: "primary.main",
      "&:hover": {
        backgroundColor: "primary.300",
      },
    },
    addIcon: { color: "common.white" },
  };

  const onHandleChange = (e: React.BaseSyntheticEvent, keyName: string) => {
    const { value } = e.target;
    setValue(keyName, value);
    clearErrors(keyName);
  };

  return (
    <Card sx={styles.cardContainer}>
      <CardHeader
        title={
          <Typography
            variant="h6"
            color="common.black"
            fontWeight="500"
            display="flex"
            alignItems="center"
          >
            Term and condition <span className="requir">*</span>
            <Tooltip
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.white",
                    "& .MuiTooltip-arrow": {
                      color: "common.white",
                    },
                    color: "common.black",
                    width: 500,
                    p: 3,
                  },
                },
              }}
              title={
                <React.Fragment>
                  <div>
                    <Typography variant="h6" color="inherit" fontWeight="500">
                      Example of term and Condition
                    </Typography>
                    <Typography color="rgba(0, 0, 0, 0.54)">
                      <ul>
                        <li>
                          โปรดทบทวนนโยบายและความเป็นส่วนตัวของบริษัท
                          เพื่อปฎิบัติตามอย่าง เคร่งครัด
                        </li>
                        <li>
                          ให้สิทธิ์บริษัทในการทำซ้ำ ดัดแปลง แก้ไข
                          หรือประชาสัมพันธ์ บทความการรีวิว ของคุณ
                          ผ่านช่องทางออนไลน์ต่างๆของบริษัทได้
                          โดยคุณไม่สามารถเรียกร้อง
                          ค่าตอบแทนใดๆกับทางบริษัทเพิ่มเติมได้
                        </li>
                        <li>
                          เปิดบัญชีสาธารณะ และไม่ทำการลบโพสต์
                          จนกว่าจะครบกำหนดเวลา หากทำการลบโพสต์ก่อนเวลา ที่กำหนด
                          คุณจะไม่ได้รับค่าตอบแทนและบริษัท
                          สามารถเรียกร้องค่าเสียหายจากคุณได้
                        </li>
                        <li>
                          โปรดอ่านและทำความเข้าใจ รายละเอียด
                          รวมถึงสิ่งที่ต้องทำและข้อห้ามใน
                          การรีวิวสินค้าหรือบริการ
                          ก่อนยอมรับข้อตกลงและเงื่อนไขในการรีวิว
                        </li>
                      </ul>
                    </Typography>
                  </div>
                </React.Fragment>
              }
            >
              <img
                src="/assets/images/info.png"
                style={{ marginLeft: "5px" }}
              />
            </Tooltip>
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          {fields?.map((item, index) => {
            return (
              <Grid container spacing={2} mb={3} key={item.id}>
                <Grid item xs={1} display="flex" justifyContent="flex-end">
                  {index + 1}
                </Grid>
                <Grid item xs={10}>
                  <TextFieldCustom
                    variant="outlined"
                    placeholder="Please enter text..."
                    fullWidth
                    value={getValues(`termAndCondition.${index}.data`)}
                    onChange={(e) =>
                      onHandleChange(e, `termAndCondition.${index}.data`)
                    }
                    error={!!errors.termAndCondition}
                    helperText={errors.termAndCondition?.message}
                    multiline
                    minRows={3}
                  />

                  {/* <Controller
                    name={`termAndCondition.${index}.data`}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldCustom
                        variant="outlined"
                        multiline
                        minRows={3}
                        fullWidth
                        error={!!errors?.termAndCondition}
                        {...field}
                      />
                    )}
                  /> */}
                </Grid>
                <Grid item xs={1}>
                  <IconButton>
                    <RemoveCircleOutlinedIcon
                      sx={{ color: "primary.main", fontSize: 30 }}
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Stack>

        <IconButton
          sx={styles.addFieldBtn}
          onClick={() => {
            append({ data: "" });
          }}
        >
          <AddIcon sx={styles.addIcon} />
        </IconButton>
      </CardContent>
    </Card>
  );
}

export default TermAndCondition;
