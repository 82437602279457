import { Box, Grid } from "@mui/material";
import React from "react";
import { useEmployerDetailStyle as classes } from "../../../../views/employer/detail/EmployerDetail.style";
import { IEmployerProfileTabProps } from "../../../../views/employer/detail/employerDetailType";
import Billing from "./Billing";
import Information from "./Information";
import Shipping from "./Shipping";

export default function ProfileTab(props: IEmployerProfileTabProps) {
  const { TabPanel, tabSelected, employerDetail } = props;
  return (
    <TabPanel index={0} value={tabSelected}>
      <Box sx={classes.tabPanelBox}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <Information employerDetail={employerDetail} />
          </Grid>
          <Grid item xs={7}>
            <Shipping
              employerId={employerDetail?.employer_full_id}
              address={employerDetail?.address.shipping_address}
            />
            <Billing
              employerId={employerDetail?.employer_full_id}
              billing={employerDetail?.address.billing_address}
            />
          </Grid>
        </Grid>
      </Box>
    </TabPanel>
  );
}
