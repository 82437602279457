import React from "react";
// import { ITiktokTabProps } from "../InfluencerSocial.types";
import DefaultTab from "./DefaultTab";
import DetailTab from "./DetailTab";
import InformationTab from "./InformationTab";

import { useInfluencerSocialStyle as classes } from "../InfluencerSocial.styles";
import { Box } from "@mui/material";

export default function TiktokTab(props: any) {
  const { TabPanel, tabSelected, matches, tiktokApi } = props;

  return (
    <TabPanel value={tabSelected} index={matches ? 1 : 0}>
      {tiktokApi.length === 0 ? (
        <Box sx={classes.tabPanelBox}>
          <DefaultTab />
        </Box>
      ) : tiktokApi[0].social_account.length === 0 ? (
        <Box sx={classes.tabPanelBox}>
          <DefaultTab />
        </Box>
      ) : (
        <Box>
          <Box sx={classes.tabPanelBox}>
            <DetailTab data={tiktokApi} />
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <InformationTab data={tiktokApi} />
          </Box>
        </Box>
      )}
    </TabPanel>
  );
}
