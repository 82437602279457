import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
  Avatar,
  IconButton,
  Grid,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { calculateNumberSocialUnit } from "../../../utils/helpers/calculateNumberSocialUnit.helpers";
import ImageGallery from "../image-gallery";
import { numberWithCommas } from "../../../utils/helpers/numberWithCommas";

interface ICardInfluencerLg {
  profile: any;

  setInfluencerSelected: Function;
  influencerSelected: Array<any>;
}

function CardInfluencerLg(props: ICardInfluencerLg) {
  const { profile, setInfluencerSelected, influencerSelected } = props;
  const {
    influencer_id,
    platform,
    media,
    social_name,
    followers,
    rate_card,
    avg_comment,
    avg_like,
    avg_share,
    recommended,
    category,
  } = profile;
  const [isSelected, setIsSelected] = useState(false);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const mapImages = media?.images?.map((item: any) => {
      return {
        img: item.url,
        if: item.file_id,
      };
    });

    setImageList(mapImages);
  }, []);

  const styles = {
    card: {
      ul: {
        margin: 0,
      },
      padding: 0,
      width: "300px",
      borderRadius: "10px",
      borderWidth: "2.5px",
      borderStyle: "solid",
      borderColor: isSelected ? "secondary.main" : "white",
    },
    img: {
      width: "80px",
      height: "80px",
      objectFit: "cover" as "cover",
    },
    icon: {
      width: "15px",
      height: "15px",
      objectFit: "contain" as "contain",
      marginInline: 1,
    },
    detail: {
      padding: "4px",
      backgroundColor: "primary.100",
    },
    iconBtn: {
      position: "absolute" as "absolute",
      top: "-20px",
      left: "-20px",
      color: "black",
      backgroundColor: "common.white",
    },
    addIcon: {
      fontSize: "50px",
      color: "primary.main",
    },
    checkedIcon: {
      fontSize: "50px",
      color: "secondary.main",
    },
    saleTag: {
      backgroundColor: "grey.100",
      padding: "5px 10px",
      borderRadius: "25px",
    },

    iconCard: {
      width: "40px",
      height: "40px",
      objectFit: "contain" as "contain",
      marginInline: "4px",
    },
    textName: {
      marginInline: "4px",

      overflow: "hidden",
      textOverflow: "ellipsis",
      display: " -webkit-box",
      WebkitLineClamp: "1",
      lineClamp: "1",
      WebkitBoxOrient: "vertical",
    },
  };

  const onHandleAdd = () => {
    if (influencerSelected.length < 24) {
      setInfluencerSelected((influencerSelected: any) => [
        ...influencerSelected,
        profile,
      ]);
    }
  };

  const onHandleDelete = () => {
    const profileFilter = influencerSelected.filter((item) => {
      return item.influencer_id !== profile.influencer_id;
    });

    setInfluencerSelected(profileFilter);
  };

  useEffect(() => {
    const getValue = influencerSelected.find(
      (item) => item.influencer_id === profile.influencer_id
    );

    setIsSelected(Boolean(getValue));
  }, [profile, influencerSelected]);

  const displayInfo = (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Stack>
          <Box display="flex" alignItems="center">
            <FacebookRoundedIcon sx={{ fontSize: 24 }} />
            <Typography
              variant="body1"
              color="common.black"
              fontWeight="500"
              sx={styles.textName}
              whiteSpace="nowrap"
            >
              {social_name}
            </Typography>

            {recommended ? (
              <Avatar src="/assets/images/star.png" alt="fb" sx={styles.icon} />
            ) : (
              ""
            )}
          </Box>

          <Typography
            variant="body2"
            color="grey.700"
            fontWeight="400"
            whiteSpace="nowrap"
            height="25px"
          >
            {category.map((item: any) => item.value).join(", ")}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Typography
            variant="body2"
            color="primary.main"
            fontWeight="500"
            sx={styles.saleTag}
          >
            THB {numberWithCommas(rate_card[0].price.value)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const displayStats = (
    <Grid
      container
      spacing={2}
      mt={3}
      alignItems="center"
      justifyContent="space-around"
    >
      <Grid item lg={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Avatar
            variant="square"
            src="/assets/images/user.png"
            alt="user"
            sx={styles.icon}
          />

          <Typography variant="body2" color="common.black" fontWeight="500">
            {calculateNumberSocialUnit(+followers)}
          </Typography>
        </Box>
      </Grid>

      <Grid item lg={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Avatar
            variant="square"
            src="/assets/images/like.png"
            alt="like"
            sx={styles.icon}
          />

          <Typography variant="body2" color="common.black" fontWeight="500">
            {calculateNumberSocialUnit(+avg_like)}
          </Typography>
        </Box>
      </Grid>

      <Grid item lg={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Avatar
            variant="square"
            src="/assets/images/comment.png"
            alt="comment"
            sx={styles.icon}
          />

          <Typography variant="body2" color="common.black" fontWeight="500">
            {calculateNumberSocialUnit(+avg_comment)}
          </Typography>
        </Box>
      </Grid>

      <Grid item lg={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Avatar
            variant="square"
            src="/assets/images/share.png"
            alt="share"
            sx={styles.icon}
          />

          <Typography variant="body2" color="common.black" fontWeight="500">
            {calculateNumberSocialUnit(+avg_share)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <div style={{ position: "relative" }} key={influencer_id}>
      <Card sx={styles.card}>
        <ImageGallery
          itemData={imageList.slice(0, 5)}
          size={80}
          cols={4}
          rows={4}
          featureCol={2}
          featureRow={2}
        />
        <CardContent>
          {displayInfo}
          {displayStats}
        </CardContent>
      </Card>

      {isSelected ? (
        <IconButton
          component="span"
          style={styles.iconBtn}
          onClick={onHandleDelete}
        >
          <Avatar
            src="/assets/images/checked-circle.png"
            alt="checked-circle"
            sx={styles.iconCard}
          />
        </IconButton>
      ) : (
        <IconButton
          component="span"
          style={styles.iconBtn}
          onClick={onHandleAdd}
          disabled={influencerSelected.length >= 24 ? true : false}
        >
          <Avatar
            src="/assets/images/add-circle.png"
            alt="add-circle"
            sx={styles.iconCard}
          />
        </IconButton>
      )}
    </div>
  );
}

export default CardInfluencerLg;
