import { hGrey, hPink, hWhite, hPurple } from "../../theme";
import { Button, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const expenseTabStyles = {
  expenseTabContainer: {
    borderRadius: ["15px", , "none"],
    backgroundColor: ["#fff", , "none"],
    boxShadow: ["0px 4px 8px rgba(0, 0, 0, 0.1)", , "none"],
  },
  date: {
    display: "flex",
    width: "100%",
    "& .MuiOutlinedInput-root, .MuiInputBase-root": {
      width: "100%",
      height: "40px",
    },
    "& label": {
      height: "100%",
      margin: ["-6px 3px", "-3px 3px", "-3px 5px"],
      fontSize: "14px",
    },
  },
  selectStatus: {
    width: "100%",
    my: ["10px", 0],
    pl: [0, "10px"],
    "& .css-pe0pe4-MuiFormControl-root": {
      width: ["100%", "70%", "65%", "60%"],
    },
  },
  exportAndCertificateBox: {
    "& .css-h4ero1-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
      color: "#9E9E9E",
      border: "none",
      backgroundColor: "#E0E0E0",
    },
  },

  exportAndCertificateButton: {
    width: {
      xs: "100%",
      sm: "auto",
      xl: "250px",
    },
    minWidth: "205px",
    height: "40px",
    "& .bankAccountAndCertificate": {
      fontSize: ["16px", "14px"],
    },
    "& svg": { width: ["20px", "18px"] },
  },
};

export const status = {
  status: {
    display: "flex",
    width: "80px",
    height: "24px",
    borderRadius: "50px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    margin: [0, , "auto"],
  },
};

export const eyeIcon = {
  border: "1px solid #1AA1F1",
  backgroundColor: "white",
  margin: "auto",
  cursor: "pointer",
  color: "#1AA1F1",
};

export const expenseCampaign = {
  campaignIcon: {
    borderRadius: "4px",
    width: "48px",
    height: "48px",
  },
  statusPaid: {
    ...status.status,
    backgroundColor: "#DBFFDC",
    color: "#4CAF50",
  },
  statusPending: {
    ...status.status,
    backgroundColor: "#FFEEDF",
    color: "#FB8C00",
  },
  eyeIconPaid: {
    ...eyeIcon,
  },
  eyeIconPending: {
    ...eyeIcon,
    color: "#9E9E9E",
    border: "none",
    backgroundColor: "#E0E0E0",
  },
  cardContent: { borderRadius: "16px", backgroundColor: "#fff" },
  recipientIcon: { width: "16px", height: "16px", mr: "5px" },
  cardTable: {
    "& .cardTable": {
      display: "flex",
      justifyContent: "space-between",
      height: "40px",
      alignItems: "center",
      px: "15px",
      "&:first-of-type": {
        mt: "10px",
        backgroundColor: "#F5F6F8",
        borderRadius: "8px",
      },
      "&:last-of-type": {
        mb: "10px",
        backgroundColor: "#F5F6F8",
        borderRadius: "8px",
      },
      "& p": {
        fontSize: "14px",
      },
      "& p:first-of-type": {
        color: "#5C5C5C",
      },
    },
    "& .css-ed2ajq-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
      color: "#9E9E9E",
      border: "none",
      backgroundColor: "#E0E0E0",
    },
    "& .css-ed2ajq-MuiButtonBase-root-MuiButton-root": {
      color: "#1AA1F1",
    },
  },
};

export const DocumentButton = styled(Button)({
  backgroundColor: hPurple[200],
  color: hWhite[100],
  height: ["40px"],
  padding: "0 15px",
  "&:hover": {
    backgroundColor: hPurple[300],
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: hPurple[300],
  },
});

export const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
    // border: `1px solid red`,
    overFlow: "hidden",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiTableCell-body": {
    // border: "1px solid red",
  },
}));

export const TableCellCustom = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: hPurple[200],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
