import { Avatar, Box, Card, Chip, Grid, Icon, Typography } from "@mui/material";
import React from "react";
import { useEmployerDetailStyle as classes } from "./EmployerDetail.style";

export default function HeaderDetail(props: any) {
  const { employerDetail } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <Card sx={classes.cardHeader}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Box sx={classes.boxAvatar}>
                <Avatar
                  alt="Employer"
                  src={employerDetail?.organisation.attachment.url}
                  sx={classes.avatar}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <Box sx={classes.boxOrganizationName}>
                <Typography variant="h6">
                  {employerDetail?.organisation.name}
                </Typography>
              </Box>
              <Box sx={classes.boxContainInfo}>
                <Box sx={classes.boxEmployerInfo}>
                  <Typography variant="body2">Employer ID : </Typography>
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.25)">
                    {employerDetail?.employer_id}
                  </Typography>
                </Box>
                <Box sx={classes.boxEmployerInfo}>
                  <Typography variant="body2">E-mail : </Typography>
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.25)">
                    {employerDetail?.email}
                  </Typography>
                </Box>
                <Box sx={classes.boxEmployerInfo}>
                  <Typography variant="body2">Phone : </Typography>
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.25)">
                    {employerDetail?.phone}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ margin: "8px 8px 0px 20px" }}>
                {employerDetail?.organisation.category.map((category: any) => {
                  return (
                    <Chip
                      label={category.name_en}
                      key={category.id}
                      sx={{
                        fontSize: "12px",
                        px: 2,
                        height: "28px",
                        width: "80px",
                        backgroundColor: "#EBDFFF",
                        color: "#8C499C",
                        fontWeight: 600,
                        marginRight: "8px",
                      }}
                    />
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <Card
          sx={{
            height: "160px",
            marginTop: "20px",
            baxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container>
            <Grid item xs={7}>
              <Box display="flex" flexDirection="row">
                <Box sx={{ margin: "24px 12px 0px 24px" }}>
                  <Typography>Wallet</Typography>
                </Box>
                <Box sx={{ marginTop: "24px" }}>
                  <Chip
                    label="Basic"
                    sx={{
                      fontSize: "12px",
                      px: 2,
                      height: "28px",
                      width: "80px",
                      backgroundColor: "#ffd4ed",
                      color: "#ED1968",
                      fontWeight: 600,
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ margin: "12px 0 0 24px" }}>
                <Typography variant="caption">Balance</Typography>
              </Box>
              <Box sx={{ margin: "4px 0 0 24px" }}>
                <Typography variant="h4" color="#ED1968" fontWeight="600">
                  {employerDetail?.wallet.balance}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  sx={{
                    width: "60px",
                    height: "70px",
                    border: "1px solid #F5F6F8",
                    borderRadius: "8px",
                    color: "#ED1968",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    margin: "45px 20px",
                  }}
                >
                  <Icon className="ico-hu-mail" />
                  <Typography variant="caption">Top-up</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  sx={{
                    width: "60px",
                    height: "70px",
                    border: "1px solid #F5F6F8",
                    borderRadius: "8px",
                    color: "#ED1968",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    margin: "45px 20px",
                  }}
                >
                  <Icon className="ico-hu-mail" />
                  <Typography variant="caption">History</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
