import {
  CardContent,
  Card,
  Box,
  Avatar,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import _ from "lodash";

import React, { useState, useEffect } from "react";
import { influencerData } from "../../data/example_influencers";
import SearchFilterInfluencer from "../../components/influencer-search-filter";
import PaginationCustom from "../../components/global/pagination-custom";
import { Controller, useForm } from "react-hook-form";
import { ISearchForm } from "./influencer-list.types";
import CircleIcon from "@mui/icons-material/Circle";
import Summary from "../../components/influencer-list/Summary";
import InfluencerListContainer from "../../components/influencer-list/InfluencerListContainer";
import Information from "../../components/influencer-list/Information";

const initSearchForm: ISearchForm = {
  searchTerm: [],
  category: [],
  location: "",
  gender: "",
  age: {
    min: "",
    max: "",
  },
  follower: {
    min: "",
    max: "",
  },
  Engagement: {
    min: "",
    max: "",
  },
  RateCard: {
    min: "",
    max: "",
  },
};

function InfluencerList() {
  const [influencerList, setInfluencerList] = useState<any[]>([]);
  const [influencerSearched, setInfluencerSearched] = useState<any[]>([]);
  const [influencerSorted, setInfluencerSorted] = useState<any[]>([]);
  const [influencerPaginated, setInfluencerPaginated] = useState<any[]>([]);
  const [influencerSelected, setInfluencerSelected] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [sortType, setSortType] = useState(0);

  const hooksForm = useForm<ISearchForm>({
    defaultValues: initSearchForm,
  });

  const { watch } = hooksForm;

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const rowsPerPageData = [10, 20, 30, 50, 100];
  const totalPage = Math.ceil(influencerList.length / rowsPerPage);
  const watchSearchTerm = watch("searchTerm");
  const watchCategory = watch("category");

  const onhandlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const handleChangeRowsPerPage = (event: SelectChangeEvent<any>) => {
    setRowsPerPage(event.target.value as number);
    if (page !== 1) setPage(1);
  };

  const styles = {
    boxInfoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cardInfoContainer: {
      marginBlock: "12px",
      borderWidth: "3px",
      borderStyle: "solid",
      borderColor: "primary.main",
    },

    saveBtn: {
      position: "fixed",
      bottom: "25%",
      right: "0px",
      width: "100px",
      height: "100px",
      borderRadius: "20px",
    },
  };

  useEffect(() => {
    setInfluencerList(influencerData);
  }, []);

  useEffect(() => {
    const list = [...influencerList];

    const searchVal = watchSearchTerm?.map((item: any) =>
      item.value.toLowerCase()
    );

    // const categoryVal = watchCategory?.map((item: any) =>
    //   JSON.parse(item).value.toLowerCase()
    // );

    const result = list.filter((item) => {
      const filterText = searchVal.some(
        (val: any) => item.social_name.toLowerCase().indexOf(val) !== -1
      );

      return filterText;
    });

    setInfluencerSearched(result.length ? result : list);
  }, [influencerList, watchSearchTerm, watchCategory]);

  useEffect(() => {
    const list = [...influencerSearched];
    if (sortType == 1) {
      const sortList = list.sort((a, b) => {
        return +b.rate_card[0].price.value - +a.rate_card[0].price.value;
      });

      setInfluencerSorted(sortList);
    } else if (sortType == 2) {
      const sortList = list.sort((a, b) => {
        return +a.rate_card[0].price.value - +b.rate_card[0].price.value;
      });

      setInfluencerSorted(sortList);
    } else {
      setInfluencerSorted(list);
    }
  }, [influencerSearched, sortType]);

  useEffect(() => {
    const list = [...influencerSorted];
    let start = page * rowsPerPage - rowsPerPage;
    let end = page * rowsPerPage;

    setInfluencerPaginated(list.slice(start, end));
  }, [influencerSorted, page, rowsPerPage]);

  return (
    <React.Fragment>
      <SearchFilterInfluencer Controller={Controller} hooksForm={hooksForm} />
      <div>
        <div className="container-main" style={{ position: "relative" }}>
          <Stack>
            <Information />
            <Summary
              influencerSelected={influencerSelected}
              setInfluencerSelected={setInfluencerSelected}
            />

            <InfluencerListContainer
              sortType={sortType}
              setSortType={setSortType}
              influencerList={influencerList}
              influencerFilter={influencerPaginated}
              influencerSelected={influencerSelected}
              setInfluencerSelected={setInfluencerSelected}
            />
          </Stack>

          <div
            style={{
              margin: "8px",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PaginationCustom
              count={totalPage}
              page={page}
              onPaginationChange={onhandlePageChange}
              rowsPerPageData={rowsPerPageData}
              rowsPerPage={rowsPerPage}
              onRowPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <IconButton>
            <Avatar
              src="/assets/images/save-btn.png"
              variant="square"
              sx={styles.saveBtn}
            />
          </IconButton>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InfluencerList;
