import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { readFileDataTo64 } from "../../../utils/helpers/readFileDataTo64";
import uniqid from "uniqid";
import imageIcon from "../../../assets/images/other/photo.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import "./multiImageVideoInput.css";

interface IMultiImageVideoInput {
  register: Function;
  imageKeyName: string;
  videoKeyName: string;
  imageErrors: any;
  videoErrors: any;
  [any: string]: any;
}

export default function MultiImagesVideoInput(props: IMultiImageVideoInput) {
  const { register, imageKeyName, videoKeyName, imageErrors, videoErrors } =
    props;
  const [imageData, setImageData] = useState<any>([]);
  const [videoData, setVideoData] = useState("");

  const onInputImage = async (ev: any) => {
    const files = Array.from(ev.target.files);

    files.forEach(async (item: any) => {
      const img64 = await readFileDataTo64(item);

      const list = {
        img64,
        id: uniqid("bImg-"),
      };

      setImageData((imageData: any) => [...imageData, list]);
    });
  };

  const onHandleRemove = (data: any) => {
    const selectedItem = imageData.filter((item: any) => item.id !== data.id);

    setImageData(selectedItem);
  };

  const onInputVideo = async (ev: any) => {
    const file = await ev.target.files[0];
    if (file) {
      setVideoData(file);
    }
  };

  const onHandleVideoRemove = () => {
    setVideoData("");
  };
  return (
    <Grid container spacing={4} sx={{ width: "100%", mr: 1 }}>
      {imageData?.map((item: any, index: number) => {
        return (
          <Grid item xs={2.4} key={index}>
            <Box position="relative" width="100px" height="100px" key={item.id}>
              <img src={item.img64} alt="img" className="image" />
              <IconButton
                className="button"
                onClick={() => onHandleRemove(item)}
              >
                {/* <CancelIcon style={{ color: "black", fontSize: "30px" }} /> */}
                <i
                  className="ico-hu-cancel-circle-solid"
                  style={{
                    color: "black",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </Box>
          </Grid>
        );
      })}

      <Grid item>
        <div>
          <label
            className="label-upload"
            style={{
              borderColor: imageErrors ? "red" : "",
              width: "100px",
              height: "100px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "40px",
                // marginBottom: "-5px",
              }}
            >
              <img src={imageIcon} alt="img" style={{ color: "white" }} />
            </div>
            <div>+ Image</div>
            <input
              className=""
              type="file"
              multiple
              accept="image/*"
              id="input-file"
              {...register(imageKeyName, {
                required: true,
                onChange: onInputImage,
              })}
            />
          </label>
        </div>
        {imageErrors ? (
          <Typography variant="caption" color="error.main" fontWeight={400}>
            {imageErrors?.message}
          </Typography>
        ) : (
          ""
        )}
      </Grid>

      <Grid item>
        <div style={{ marginLeft: "10px" }}>
          <Box position="relative" width="100px" height="100px">
            <div>
              <label
                className="label-video-upload"
                style={{
                  borderColor: videoErrors ? "red" : "",
                  width: "100px",
                  height: "100px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    width: "40px",
                    marginBottom: "-5px",
                  }}
                >
                  <img
                    src={
                      require("../../../assets/images/other/r-video-clip.svg")
                        .default
                    }
                    alt="vdo"
                    style={{ color: "white" }}
                  />
                </div>

                {videoData ? (
                  <div style={{ textAlign: "center" }}>Uploaded 1 Video</div>
                ) : (
                  <div>+ Video</div>
                )}

                <input
                  className=""
                  type="file"
                  accept="video/*"
                  id="input-file"
                  {...register(videoKeyName, {
                    onChange: onInputVideo,
                  })}
                />
              </label>
            </div>
            {videoData ? (
              <IconButton
                className="videoCancleIcon"
                onClick={onHandleVideoRemove}
                sx={{ position: "absolute", top: "-15px", right: "-15px" }}
              >
                <CancelIcon style={{ color: "black", fontSize: "30px" }} />
              </IconButton>
            ) : (
              ""
            )}
          </Box>

          {videoErrors ? (
            <Typography variant="caption" color="error.main" fontWeight={400}>
              {videoErrors.message}
            </Typography>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </Grid>
  );
}
