import { styled } from "@mui/system";
import BadgeUnstyled from "@mui/base/BadgeUnstyled";
import { BadgeProps } from "@mui/material";
import { hGrey } from "../../theme";

export const useHeaderBarStyle = {
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  boxToobar: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "8px",
  },
  profileAvatar: {
    height: "32px",
    width: "32px",
  },
  headerBarButton: {
    width: "100%",
    border: "2px solid",
    borderColor: "primary.main",
  },
  logoBox: {
    display: "flex",
    width: "100%",
    height: "42px",
    borderColor: "grey.100",
  },
  profileAvatarSidebar: {
    height: "80px",
    width: "80px",
    // objectFit: "cover",
  },
  line: {
    width: "100%",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: `1px solid ${hGrey[100]}`,
    margin: "8px 0",
  },

  iconSideBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    borderRadius: "10px",
    fontSize: "24px",
    color: "common.white",
    cursor: "pointer",
    marginRight: "12px",
  },
  boxIcon: {
    display: ["none", "none", "flex"],
    alignItems: "center",
    ml: 2,
    color: "common.white",
  },
  boxFullname: {
    display: ["none", "none", "flex"],
    width: "100%",
  },
};

export const NotiBadge = styled(BadgeUnstyled)<BadgeProps>(({ theme }) => ({
  boxSizing: "border-box",
  margin: 0,
  padding: 0,
  color: theme.palette.common.white,
  fontSize: "14px",
  position: "relative",
  display: "inline-block",
  "& .MuiSvgIcon-root": {
    fontSize: "28px",
  },
  "& .MuiBadge-dot": {
    padding: 0,
    zIndex: "auto",
    minWidth: "6px",
    width: "10px",
    height: "10px",
    background: "#f7685b",
    borderRadius: "100%",
    boxShadow: `0 0 0 1px ${theme.palette.common.white}`,
  },
  "& .MuiBadge-anchorOriginTopRight": {
    position: "absolute",
    top: "7px",
    right: "8px",
    transform: "translate(50%, -50%)",
    transformOrigin: "100% 0",
  },
}));
