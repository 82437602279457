import React from "react";
// Components
import { GridMobileReverse } from "../global/grid-mobile-reverse";
// Types
import { IBreakpoint, IDevice } from "../../views/global/global.types";
// Utils
import { setValueByBreakpoint } from "../../utils/helpers/setValueByBreakpoint.helpers";
import { calculateNumberSocialUnit } from "../../utils/helpers/calculateNumberSocialUnit.helpers";
// Material
import { Box, Grid, Stack, Typography } from "@mui/material";
// Styles
import { hNavyBlue, hPurple } from "../../theme";
// Image
import OurService1 from "./../../assets/images/home/3_Marketer page/3 - Our service/01.png";
import OurService2 from "./../../assets/images/home/3_Marketer page/3 - Our service/02.png";
import OurService3 from "./../../assets/images/home/3_Marketer page/3 - Our service/03.png";
import OurService4 from "./../../assets/images/home/3_Marketer page/3 - Our service/04.png";
import OurServiceCampaigns from "./../../assets/images/home/3_Marketer page/3 - Our service/Campaigns.png";
import OurServiceReach from "./../../assets/images/home/3_Marketer page/3 - Our service/Reach.png";
import OurServiceImpression from "./../../assets/images/home/3_Marketer page/3 - Our service/Impression.png";
import OurServiceEngagement from "./../../assets/images/home/3_Marketer page/3 - Our service/Engagement.png";

function OurService({
  device,
  breakpoint,
  engagement,
}: {
  device: IDevice;
  breakpoint: IBreakpoint;
  engagement: any[];
}) {
  const searchEngagementImage = (type: string) => {
    switch (type) {
      case "campaigns":
        return OurServiceCampaigns;
      case "reach":
        return OurServiceReach;
      case "impression":
        return OurServiceImpression;
      case "engagement":
        return OurServiceEngagement;
    }
  };
  return (
    <Stack
      alignItems="center"
      height="100%"
      py={[10, 15, 15, 21]}
      px={[4, 2, 3, 0, 20]}
    >
      <Typography
        variant={setValueByBreakpoint(breakpoint, "h5", null, null, "h3")}
        fontWeight={setValueByBreakpoint(
          breakpoint,
          "bold",
          null,
          null,
          "normal"
        )}
        align="center"
        mb={[5, 4, 4, 7]}
        sx={{
          color: hNavyBlue[100],
        }}
      >
        Our Service
      </Typography>
      <Typography
        color="grey.700"
        variant={setValueByBreakpoint(breakpoint, "body1", null, null, "h5")}
        align="center"
        mb={[14, 8, 8, 12]}
      >
        แพลตฟอร์มที่รวบรวม Influencer {device === "mobile" && <br />}
        คุณภาพที่มีคาแรกเตอร์โดดเด่น
        {device !== "mobile" && <br />}
        ‘หลากสไตล์’ {device === "mobile" && <br />}และ ‘หลายประเภท’
        เพื่อช่วยทําการตลาดออนไลน์{device === "mobile" && <br />}อย่างสร้างสรรค์
        {device !== "mobile" && <br />}
        และสร้างแคมเปญให้ตอบโจทย์แบรนด์ของคุณ
      </Typography>
      <GridMobileReverse breakpoint={breakpoint}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(breakpoint, "center", "start")}
        >
          <img
            src={OurService1}
            alt="OurService1"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "70%",
                "90%",
                "auto",
                "auto"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "340px",
                "380px"
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
            >
              Create a Campaign
            </Typography>
            <Typography
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[2, 2, 2, 4]}
              sx={{ color: hNavyBlue[100] }}
            >
              สร้างสรรค์แคมเปญ
              <br />
              พร้อมหานักรีวิวง่ายๆ เพียงไม่กี่นาที
            </Typography>
            <ul className="our-service-list">
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  รวบรวมข้อมูลของ Influencer และนักรีวิว หลากหลายประเภท
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  คัดกรอง จัดเรียง และแสดงข้อมูล Influencer ได้อย่างรวดเร็ว
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  สามารถเลือกนักรีวิวตามความต้องการของคุณที่สุด!
                </Typography>
              </li>
            </ul>
          </Stack>
        </Grid>
      </GridMobileReverse>
      <GridMobileReverse breakpoint={breakpoint} isMobileRevers>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
            >
              Information Influencers
            </Typography>
            <Typography
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[2, 2, 2, 4]}
              sx={{ color: hNavyBlue[100] }}
            >
              รวบรวม เปรียบเทียบข้อมูล Influencer
              <br />
              เพื่อหาคนที่ดีที่สุด
            </Typography>
            <ul className="our-service-list">
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  คัดเลือก Influencer พร้อมแสดงข้อมูลเชิงลึก ตั้งแต่อายุ เพศ
                  ภูมิลำเนา ไลฟ์สไตล์ พร้อมผลงานรีวิวที่ผ่านมา
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  เปรียบเทียบข้อมูลของ Influencer อย่างละเอียด
                  ประกอบการพิจารณาในการคัดเลือกนักรีวิว
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  เลือก Influencer หรือ นักรีวิวที่ตรงใจของคุณได้เลย!
                </Typography>
              </li>
            </ul>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(
            breakpoint,
            "center",
            "flex-end"
          )}
        >
          <img
            src={OurService2}
            alt="OurService2"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "90%",
                "100%",
                "auto",
                "100%",
                "80%"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "300px",
                "auto",
                "auto"
              ),
            }}
          />
        </Grid>
      </GridMobileReverse>
      <GridMobileReverse breakpoint={breakpoint}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(breakpoint, "center", "start")}
        >
          <img
            src={OurService3}
            alt="OurService3"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "90%",
                "100%",
                "auto",
                "100%",
                "90%"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "290px",
                "auto",
                "auto"
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
            >
              Package Campaign
            </Typography>
            <Typography
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[2, 2, 2, 4]}
              sx={{ color: hNavyBlue[100] }}
            >
              ระบบจัดการแคมเปญ
              <br />
              และติดต่อดีลงานได้โดยตรง
            </Typography>
            <ul className="our-service-list">
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  <span style={{ color: hNavyBlue[100] }}>
                    Packaging Campaign:
                  </span>{" "}
                  สร้างสรรค์แคมเปญใหม่ สะดวก รวดเร็วโดย HashU
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  <span style={{ color: hNavyBlue[100] }}>
                    Custom Campaign:
                  </span>{" "}
                  จัดตั้งแคมเปญได้ง่าย ๆ เพื่อให้ สามารถหา Influencer
                  ได้ด้วยตนเอง
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  <span style={{ color: hNavyBlue[100] }}>ปรึกษาทีมงาน</span>{" "}
                  หรือเปรียบเทียบแพ็กเกจแคมเปญ เพื่อสร้างแคมเปญที่ตอบโจทย์ที่สุด
                </Typography>
              </li>
            </ul>
          </Stack>
        </Grid>
      </GridMobileReverse>
      <GridMobileReverse breakpoint={breakpoint} isMobileRevers>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
            >
              Analysis and Report
            </Typography>
            <Typography
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[2, 2, 2, 4]}
              sx={{ color: hNavyBlue[100] }}
            >
              วัดผลลัพธ์ พร้อมประเมินแคมเปญ
              <br />
              ด้วยค่าสถิติ และคอนเทนต์
            </Typography>
            <ul className="our-service-list">
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  วัดผลลัพธ์ตามที่ต้องการ แม่นยำ น่าเชื่อถือ
                  เมื่อจบแคมเปญแต่ละครั้ง
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  ประเมินแคมเปญด้วยการคำนวณผล และลักษณะคอนเทนต์ของ Influencer
                </Typography>
              </li>
              <li>
                <Typography
                  color="common.black"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    null,
                    null,
                    "h6"
                  )}
                  display="contents"
                >
                  แสดงผลลัพธ์จากการประเมินแคมเปญ
                </Typography>
              </li>
            </ul>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(
            breakpoint,
            "center",
            "flex-end"
          )}
        >
          <img
            src={OurService4}
            alt="OurService4"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "90%",
                "100%",
                "auto",
                "100%",
                "90%"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "290px",
                "auto",
                "auto"
              ),
            }}
          />
        </Grid>
      </GridMobileReverse>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columns={{
          xs: 12,
          sm: 8,
          md: 8,
        }}
        rowSpacing={{
          xs: 8,
          sm: 0,
        }}
        px={[4, 6, 8, 18]}
      >
        {engagement.map((engage, index) => (
          <Grid key={index} item xs={6} sm={2} md={2}>
            <Stack>
              <Box textAlign="center">
                <img
                  src={searchEngagementImage(engage.type)}
                  alt={engage.type}
                  style={{
                    width: setValueByBreakpoint(
                      breakpoint,
                      "50px",
                      "80px",
                      "80px",
                      "120px"
                    ),
                    height: "auto",
                  }}
                />
              </Box>
              <Box
                pt={[1, 2, 2, 3]}
                pb={[1, 2, 2, 2]}
                sx={
                  device !== "mobile" && engagement.length !== index + 1
                    ? { borderRight: `3px solid ${hPurple[100]}` }
                    : undefined
                }
              >
                <Typography
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "h5",
                    "h4",
                    "h4",
                    "h3"
                  )}
                  fontWeight={setValueByBreakpoint(
                    breakpoint,
                    "bold",
                    null,
                    null,
                    "normal"
                  )}
                  color="common.black"
                  align="center"
                >
                  {`${calculateNumberSocialUnit(engage.point, -1)}${"+"}`}
                </Typography>
              </Box>
              <Typography
                variant={setValueByBreakpoint(
                  breakpoint,
                  "body2",
                  "body1",
                  "body1",
                  "h6"
                )}
                color="grey.700"
                align="center"
              >
                Followers
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default OurService;
