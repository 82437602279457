import axios from "axios";
// Types
import {
  // IEmployerCreateBody,
  IEmployerCreateRes,
  IEmployerDetailRes,
  IEmployerListParams,
  IEmployerListResponse,
} from "./Types";
// Constans
import { API_KEY, API_URL } from "../../utils/constants/api.constants";
// Helper
import { getTokenFromStorage } from "../../utils/helpers/getTokenFromStorage";

export function getEmployerList(params: IEmployerListParams) {
  return new Promise(
    (resolve: (value: IEmployerListResponse) => void, reject) => {
      const { access_token } = getTokenFromStorage();
      const header = { Authorization: `Bearer ${access_token}` };
      axios
        .get(`${API_URL}/employers`, { headers: header, params: params })
        .then((response) => {
          const data = response.data;
          if (data.status_code === 200) {
            resolve(data.result.data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
}

export function getEmployerDetail(employerId: string) {
  return new Promise((resolve: (value: IEmployerDetailRes) => void, reject) => {
    const { access_token } = getTokenFromStorage();
    const header = { Authorization: `Bearer ${access_token}` };
    axios
      .get(`${API_URL}/employers/${employerId}`, { headers: header })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createEmployer(data: any) {
  return new Promise((resolve: (value: IEmployerCreateRes) => void, reject) => {
    const header = {
      "X-API-Key": API_KEY,
    };
    axios
      .post(`${API_URL}/employers`, data, {
        headers: header,
      })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 201) {
          resolve(data.result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateEmployer(employerId: string, data: any) {
  return new Promise((resolve: (value: IEmployerCreateRes) => void, reject) => {
    const { access_token } = getTokenFromStorage();
    const header = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(`${API_URL}/employers/${employerId}`, data, {
        headers: header,
      })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 201) {
          resolve(data.result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteEmployer(employerId: string) {
  return new Promise((resolve: (value: string) => void, reject) => {
    const { access_token } = getTokenFromStorage();
    const header = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .delete(`${API_URL}/employers/${employerId}`, {
        headers: header,
      })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 204) {
          resolve(data.result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
