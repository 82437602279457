import React from "react";
import { styled } from "@mui/system";
import { TextField, TextFieldProps, SxProps, Theme } from "@mui/material";

type ITextFieldCustomProps = TextFieldProps & {
  sx?: SxProps<Theme>;
  [any: string]: any;
};

const TextFieldStyle = styled(TextField)<TextFieldProps>(
  ({ theme, ...props }) => {
    if (props.disabled) {
      return {
        "& .MuiOutlinedInput-root": {
          backgroundColor: theme.palette.grey[100],
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
          color: theme.palette.common.black,
          WebkitTextFillColor:
            props.value && props.value !== ""
              ? theme.palette.common.black
              : theme.palette.grey[300],
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.grey[100] + " !important",
        },
        "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
          color: theme.palette.primary.main,
        },
      };
    } else if (props.error) {
      return {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main,
        },
        "&:focus": {
          border: `2px solid ${theme.palette.error.main}`,
        },
        "&:hover": {
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.main,
          },
        },
        "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
          color: theme.palette.error.main,
        },
      };
    } else {
      return {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
          borderRadius: props.multiline ? "12px" : "50px",
        },
        "&:focus": {
          border: `2px solid ${theme.palette.primary.main}`,
        },
        "&:hover": {
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
        },
        "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
          color: theme.palette.primary.main,
        },
      };
    }
  }
);

function TextFieldCustom({ sx, ...props }: ITextFieldCustomProps) {
  return (
    <React.Fragment>
      <TextFieldStyle
        variant="outlined"
        size="small"
        color="primary"
        sx={sx && (sx as object)}
        {...props}
      />
    </React.Fragment>
  );
}

export default TextFieldCustom;
