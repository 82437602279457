import { Box, Button, Divider, Grid, Icon, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateEmployer } from "../../../../api/Employer/employerAPI";
import { formatTaxId } from "../../../global/format-number";
import EditBilling from "../edit/EditBilling";

export default function Billing(props: any) {
  const { employerId, billing } = props;

  const [isOpenEditeEmployer, setIsOpenEditEmployer] = useState<boolean>(false);
  const [updateAddress, setUpdateAddress] = useState(false);

  const handleClose = () => {
    setIsOpenEditEmployer(false);
  };

  useEffect(() => {}, [updateAddress]);

  const onSubmit = async (data: any) => {
    setUpdateAddress(!updateAddress);
    try {
      const reqBody = {
        address: {
          billing_address: {
            fullname: data.fullname,
            tax_id: data.tax_id,
            address: data.address,
            province_id: data.province?.id as number,
            district_id: data.district?.id as number,
            subdistrict_id: data.subdistrict?.id as number,
          },
        },
      };
      const response = await updateEmployer(employerId, reqBody);
      console.log("response shipping", response);
      handleClose();
    } catch (error: any) {
      console.log("error", error);
    }
  };

  return (
    <Box
      sx={{
        marginTop: "24px",
        border: "2px solid #E8DFFF",
        borderRadius: "12px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "20px" }}
      >
        <Typography variant="h6">Billing Address</Typography>
        <Button
          onClick={() => setIsOpenEditEmployer(true)}
          sx={{ width: "100px", justifyContent: "space-evenly" }}
        >
          <Icon className="ico-hu-edit" />
          Edit
        </Button>
      </Box>
      <Divider />
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Full name
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {billing?.fullname}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Tax ID
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {formatTaxId(billing?.tax_id)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Address
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {billing?.address}, {billing?.subdistrict.name_th},{" "}
              {billing?.district.name_th}, {billing?.province.name_th},{" "}
              {billing?.subdistrict.postcode}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <EditBilling
        data={billing !== null ? billing : null}
        isOpened={isOpenEditeEmployer}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    </Box>
  );
}
