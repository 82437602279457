import { hPurple } from "../../../theme";
import { darken } from "@mui/material/styles";

export const useInstagramTabStyle = {
  stackContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    width: "100%",
  },
  audienceTabs: {
    minHeight: "fit-content",
    backgroundColor: hPurple[100],
    borderRadius: "45px",
  },

  rateCardPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    mt: 3,
    border: "3px solid",
    borderColor: "primary.main",
  },
  rateCardIconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "70px",
    minHeight: "70px",
    maxHeight: "70px",
    backgroundColor: "primary.main",
    borderRadius: "15px",
    fontSize: "40px",
    color: "common.white",
  },
  rateCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  rateCardSaveIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    backgroundColor: "primary.main",
    fontSize: "16px",
    color: "common.white",
    "&:hover": {
      backgroundColor: darken(hPurple[200], 0.1),
    },
  },
  rateCardBox: {
    display: "flex",
    width: "100%",
    px: 4,
    py: 3,
  },
  imageBox: {
    height: "inherit",
    width: "inherit",
    borderRadius: "10px",
  },
  imagePostIcon: {
    fontSize: "12px",
    pr: 1,
    pb: 1,
  },
  imageTypePostIcon: {
    fontSize: "20px",
  },
  instagramProfileAvatar: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  instagramBadge: {
    "& .MuiBadge-badge": {
      top: "5px",
      left: "4px",
    },
  },
  overviewPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "92px",
    borderRadius: "10px",
    mt: 3,
  },
  overviewBox: {
    display: "flex",
    height: "70px",
    width: "100%",
  },
  overviewStack: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  averagePostPaper: {
    display: "flex",
    borderRadius: "10px",
    py: 4,
  },
  averageIcon: {
    fontSize: "28px",
  },
  audiencePaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    mt: 3,
  },
  audienceBox: { width: "100%", px: 6, py: 3 },
  socialMediaScreenshotStack: {
    border: "3px solid",
    borderColor: "secondary.main",
    borderRadius: "10px",
    pt: 4,
    pb: 2,
    px: 2,
  },
  socialMediaScreenshotAvatar: {
    height: "44px",
    width: "44px",
  },
  socialMediaScreenshotStateIcon: {
    fontSize: "28px",
    pr: 1,
  },
  syncButton: {
    width: "220px",
    mb: 2,
  },
  carouselItemStack: {
    width: "inherit",
    paddingBottom: "44px",
  },
  carouselItemImage: {
    height: "100%",
    width: "inherit",
  },
  carouselItemIcon: {
    fontSize: "28px",
    paddingRight: "4px",
  },
};
