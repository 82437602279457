import React from "react";

// Component
import DialogScrollingCustom from "../../global/dialog-scrolling-custom";
import { BillingAddressSection } from "./BillingAddressSection";
// Types
import {
  IBillingAddressForm,
  IEditAddressDialogProps,
} from "../InfluencerAddress.types";
// Utils
import { useEditAddressDialogStyle as classes } from "../InfluencerAddress.styles";
//  DayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

const EditAddressBillingDialog = React.memo(
  (props: IEditAddressDialogProps) => {
    const { data, isOpened, onClose, onSubmitBillingAddress } = props;

    const handleClose = () => {
      onClose();
    };

    const billingAddressSubmitHandler = async (value: IBillingAddressForm) => {
      onSubmitBillingAddress(value);
    };

    return (
      <React.Fragment>
        <DialogScrollingCustom
          title="Address"
          open={isOpened}
          onClose={handleClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={false}
          sx={classes.dialog}
        >
          <BillingAddressSection
            address={data}
            billingAddressSubmitHandler={billingAddressSubmitHandler}
            closeDialog={handleClose}
          />
        </DialogScrollingCustom>
      </React.Fragment>
    );
  }
);

export default EditAddressBillingDialog;
