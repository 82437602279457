import React from "react";
import InfluencerMyprofileTab from "../../../components/influencer-myprofile-tab";
import { Box } from "@mui/material";

function DetailTab() {
  return (
    <React.Fragment>
      <Box>
        <InfluencerMyprofileTab />
      </Box>
    </React.Fragment>
  );
}

export default DetailTab;
