import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
} from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";
import TextFieldCustom from "../global/text-field";
import DateRangeInput from "../global/date-range-input";

function Timeline({ form }: any) {
  const {
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    register,
    getValues,
  } = form;

  const styles = {
    cardContainer: {
      margin: "8px",
    },
  };

  const onHandleChange = (e: any, keyName: string) => {
    const { value } = e.target;
    setValue(keyName, value);
    clearErrors(keyName);
  };

  return (
    <Card sx={styles.cardContainer}>
      <CardHeader
        title={
          <Typography variant="h6" color="common.black" fontWeight="500">
            Timeline
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Recruiting Date"
            required
          >
            <DateRangeInput
              clearErrors={clearErrors}
              errors={errors}
              watch={watch}
              register={register}
              startKeyName="timeline.recruiting.startDate"
              endKeyName="timeline.recruiting.endDate"
              setValue={setValue}
            />
          </WrapInputWithLabel>
          <WrapInputWithLabel labelWidth="200px" title="Annouce Date">
            <TextFieldCustom
              variant="outlined"
              placeholder=""
              disabled
              fullWidth
              error={!!errors.timeline?.annouceDate}
              helperText={errors.timeline?.annouceDate?.message}
              value={getValues("timeline.annouceDate")}
              onChange={(e) => onHandleChange(e, "timeline.annouceDate")}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel labelWidth="200px" title="Campaign Date" required>
            <DateRangeInput
              clearErrors={clearErrors}
              errors={errors}
              watch={watch}
              register={register}
              startKeyName="timeline.campaign.startDate"
              endKeyName="timeline.campaign.endDate"
              setValue={setValue}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel
            labelWidth="200px"
            title="Posting Period"
            required
          >
            <TextFieldCustom
              variant="outlined"
              type="number"
              placeholder=""
              fullWidth
              error={!!errors.timeline?.postingPeriod}
              helperText={errors.timeline?.postingPeriod?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Day(s)</InputAdornment>
                ),
              }}
              value={getValues("timeline.postingPeriod")}
              onChange={(e) =>
                onHandleChange(e, "camtimelinepaign.postingPeriod")
              }
            />
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
    </Card>
  );
}
export default Timeline;
