import React from "react";
// Component
import DialogScrollingCustom from "../../global/dialog-scrolling-custom";
import MyPortfolioSection from "./MyPortfolioSection";
// Types
import {
  IEditMyPortfolioDialogProps,
  IMyPortfolio,
} from "../InfluencerMyPortfolio.types";
// Styles
import { useEditMyPortfolioDialogStyle as classes } from "../InfluencerMyPortfolio.styles";
//  DayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

export default function EditMyPortfolioDialog(
  props: IEditMyPortfolioDialogProps
) {
  const {
    data,
    isOpened,
    onClose,
    onSubmitMyPortfolio,
    myPortfolioIndex,
    openEditMyProfileDialog,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const myPortfolioSubmitHandler = async (value: IMyPortfolio[]) => {
    onSubmitMyPortfolio(value);
  };

  return (
    <React.Fragment>
      <DialogScrollingCustom
        title="My Portfolio"
        open={isOpened}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={false}
        sx={classes.dialog}
      >
        <MyPortfolioSection
          data={data !== null ? data.portfolio : null}
          myPortfolioSubmitHandler={myPortfolioSubmitHandler}
          closeDialog={handleClose}
          myPortfolioIndex={myPortfolioIndex}
          openEditMyProfileDialog={openEditMyProfileDialog}
        />
      </DialogScrollingCustom>
    </React.Fragment>
  );
}
