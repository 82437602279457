export const InfluencerMyEarningMockData = [
  {
    campaign: {
      url: "",
      name: "lazada",
      transaction: "HU202022-HU212022",
    },
    recipient: {
      url: "",
      name: "dee",
    },
    completeData: "20/10/2022",
    receiptData: "20/10/2022",
    amount: 1000.00,
    status: "pending",
    document: {
      status: "disbled",
      name: "หัก ณ ที่จ่าย",
    },
  },
  {
    campaign: {
      url: "",
      name: "Central",
      transaction: "HU202022-HU212022",
    },
    recipient: {
      url: "",
      name: "Tat",
    },
    completeData: "20/10/2022",
    receiptData: "20/10/2022",
    amount: 1000.00,
    status: "pending",
    document: {
      status: "disbled",
      name: "หัก ณ ที่จ่าย",
    },
  },
  {
    campaign: {
      url: "",
      name: "Shopee",
      transaction: "HU202022-HU212022",
    },
    recipient: {
      url: "",
      name: "dee",
    },
    completeData: "20/10/2022",
    receiptData: "20/10/2022",
    amount: 1000.00,
    status: "paid",
    document: {
      status: "display",
      name: "หัก ณ ที่จ่าย",
    },
  },
];
