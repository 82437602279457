import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// style
import { useCreateCampaignStyle as classes } from "./CreateCampaign.style";
// components
import CampaignBriefTabs from "../../components/create-campaign/campaign-brief-tabs/CampaignBriefTabs";
import CampaignOverview from "../../components/create-campaign/campaign-brief/CampaignOverview";
import CampaignBrief from "../../components/create-campaign/campaign-brief/CampaignBrief";
import TargetAudience from "../../components/create-campaign/campaign-brief/TargetAudience";
import BudgetAndReward from "../../components/create-campaign/campaign-brief/BudgetAndReward";
import TermsAndConditions from "../../components/create-campaign/campaign-brief/TermsAndConditions";
import Timeline from "../../components/create-campaign/campaign-brief/Timeline";
import CampaignDetailOverview from "../../components/create-campaign/campaign-details/CampaignDetailOverview";
import TimelineDetail from "../../components/create-campaign/campaign-details/TimelineDetail";
import CampaignDetailBrief from "../../components/create-campaign/campaign-details/CampaignDetailBrief";
import TargetAudienceDetail from "../../components/create-campaign/campaign-details/TargetAudienceDetail";
import BudgetAndRewardDetail from "../../components/create-campaign/campaign-details/BudgetAndRewardDetail";
import TermsAndConditionsDetail from "../../components/create-campaign/campaign-details/TermsAndConditionsDetail";
import TermsAndConditionsDialog from "../../components/create-campaign/campaign-details/TermsAndConditionsDialog";
import TimelineDetailDialog from "../../components/create-campaign/campaign-details/TimelineDetailDialog";
import CampaignDetailCard from "../../components/create-campaign/campaign-details/CampaignDetailCard";
// mock data
import {
  campCardDetailTimelineData,
  campDetailAcceptData,
} from "./CreateCampaignData";
// types
import { IInitCampaignBriefForm } from "./CreateCampaign.type";
// react-hook
import { useForm } from "react-hook-form";
// mui icons
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";

import { Link } from "react-scroll";

const initFile = {
  file_id: "",
  file_name: "",
  url: "",
  purpose: "",
  description: "",
};

const initCampaignBriefForm: IInitCampaignBriefForm = {
  campaignOverview: {
    organization: "ICreativeSystem",
    organizationLogo: initFile,
    companyName: {
      id: 0,
      value: "",
      label: "",
    },
    companyLogo: initFile,
    campaignName: "",
    category: [],
    objective: "",
    campaignType: "",
    review: "",
  },
  campaignBrief: {
    platform: "",
    contentType: "",
    coverImage: initFile,
    briefImage: {
      image: [],
      video: [],
    },
    description: "",
    moodAndTone: "",
    moodAndToneImage: {
      image: [],
      video: [],
    },
    keyMessage: "",
    hashtag: [],
    doOrEmphasis: "",
    dontOrProhibition: "",
  },
  targetAudience: {
    userAge: {
      startAge: "",
      endAga: "",
    },
    userGender: [],
    userLocation: [],
    influFollower: {
      startFollower: "",
      endFollower: "",
    },
    influAge: {
      startAge: "",
      endAge: "",
    },
    influGender: [],
    influLocation: [],
  },
  budgetAndReward: {
    campaignBudget: "",
    influencerAmount: "",
    eachInfluencer: "",
    compensation: "",
    productAndService: [],
  },
  termsAndConditions: {
    termsAndConditions: [
      {
        data: "",
      },
    ],
  },
  timeline: {
    annouceDateAndCloseDate: {
      annouceDate: "",
      closeDate: "",
    },
    selectionAndAnnouncement: {
      selectionInfluecer: {
        startDate: "",
        endDate: "",
      },
      annouceDate: "",
    },
    reviewDate: "",
    postDate: {
      posting: {
        startPost: "",
        endPost: "",
      },
      postDuration: "",
    },
    completeCampaign: "",
  },
};

const campaignDetailData = [
  {
    id: "campaignOverview",
    title: "Campaign Details",
    subTitle: "แสดงรายละเอียดของแคมเปญ",
    component: <CampaignDetailOverview />,
    xs: 9,
    offset: -350,
  },
  {
    id: "timeline",
    title: "Timeline",
    subTitle: "ระยะเวลาของแคมเปญ",
    component: <TimelineDetail />,
    xs: 3,
    offset: -100,
  },
  {
    id: "campaignBriefDetail",
    title: "Campaign Breif",
    subTitle: "รายละเอียดแคมเปญ",
    component: <CampaignDetailBrief />,
    xs: 9,
    offset: -200,
  },
  {
    id: "campaignCardDetail",
    title: "Campaign Accounce",
    subTitle: "ประกาศรับสมัคร",
    component: <CampaignDetailCard />,
    xs: 3,
    offset: -100,
  },
  {
    id: "targetAudience",
    title: "Target Audience",
    subTitle: "กลุ่มเป้าหมาย",
    component: <TargetAudienceDetail />,
    xs: 9,
    offset: -185,
  },
  {
    id: "budgetAndReward",
    title: "Budget and Reward",
    subTitle: "สิ่งที่อินฟลูเอนเซอร์จะได้รับ",
    component: <BudgetAndRewardDetail />,
    xs: 9,
    offset: -185,
  },
  {
    id: "termsAndConditions",
    title: "Terms and Conditions",
    subTitle: "โปรดอ่านรายละเอียดและตรวจสอบข้อมูลก่อนทำการสมัคร",
    component: <TermsAndConditionsDetail />,
    xs: 9,
    offset: -185,
  },
];

export default function CreateCampaign() {
  const [openCampaign, setOpenCampaign] = useState<boolean>(true);
  const [openTimelineDetailDialog, setOpenTimelineDetailDialog] =
    useState<boolean>(false);
  const [openTermsAndCondDetailDialog, setOpenTermsAndCondDetailDialog] =
    useState<boolean>(false);

  const form = useForm<IInitCampaignBriefForm>({
    defaultValues: initCampaignBriefForm,
    // resolver: yupResolver(CampaignFormSchema),
  });

  const { handleSubmit, watch, getValues } = form;

  const handleOpenCampaignDetailDialog = (
    e: React.ChangeEvent<HTMLInputElement>,
    condition: string
  ) => {
    const value = e.target.checked;
    switch (condition) {
      case "timeline":
        setOpenTermsAndCondDetailDialog(false);
        setOpenTimelineDetailDialog(value);
        break;
      case "termsAndConditions":
        setOpenTimelineDetailDialog(false);
        setOpenTermsAndCondDetailDialog(value);
        break;
    }
  };

  const handleOpenCampaignBrief = () => {
    setOpenCampaign(!openCampaign);
    // setOpenCampaignBreif(true);
    // <Link href="#campaignBriefDetail" />;
  };

  const handleOpenCampaign = () => {
    setOpenCampaign(!openCampaign);
  };

  const onSubmit = () => {};

  return (
    <Box className="container-main">
      {openCampaign ? (
        <>
          <Box sx={classes.createCampaignTitle}>
            <Typography>Campaign Brief</Typography>
            <Typography>กรุณากรอกข้อมูลเพื่อสร้างแคมเปญ</Typography>
          </Box>
          <Box sx={classes.createCampaignCon}>
            <CampaignBriefTabs form={form} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={classes.boxContent}>
                <CampaignOverview
                  form={form}
                  // handleOpenCampaignBrief={handleOpenCampaignBrief}
                />
                <CampaignBrief form={form} />
                <TargetAudience form={form} />
                <BudgetAndReward form={form} />
                <TermsAndConditions form={form} />
                <Timeline form={form} />
                <Box sx={classes.saveBtn}>
                  <Button
                    onClick={() => setOpenCampaign(false)}
                    variant="contained"
                    startIcon={<SaveRoundedIcon />}
                  >
                    บันทึกและดูตัวอย่าง
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Typography onClick={handleOpenCampaign} sx={classes.back}>
              <ChevronLeftIcon />
              Back
            </Typography>
          </Box>
          <Box sx={classes.campCardCon}>
            <Box display="flex">
              <Avatar sx={classes.campCardImg} />
              <Box ml={3}>
                <Typography mb={2} fontSize="20px" fontWeight="500">
                  Lazada Shop Marathon 12.12 (Lazada)
                </Typography>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  sx={{ color: "#5C5C5C" }}
                >
                  {campCardDetailTimelineData.map((item, index) => (
                    <Box key={index} sx={classes.campCardTimeline}>
                      {item.icon}
                      <Typography>{item.label}</Typography>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Box>
            <Box sx={classes.campDraftCon}>
              <Typography>
                <CircleIcon />
                ร่างแคมเปญ
              </Typography>
            </Box>
          </Box>
          <Box sx={classes.campDetailCon}>
            <Box sx={classes.campDetailTitle}>
              <Typography>Campaign Details</Typography>
              <Typography>แสดงรายละเอียดของแคมเปญ</Typography>
            </Box>
            <Divider sx={{ my: "24px" }} />
            <Grid container spacing={6}>
              {campaignDetailData.map((item, index) => (
                <>
                  {item.title === "Campaign Accounce" ? (
                    <Grid key={index} item xs={item.xs}>
                      {item.component}
                    </Grid>
                  ) : (
                    <Grid key={index} item xs={item.xs}>
                      <Box sx={classes.campCard}>
                        <Box sx={classes.campDetailCardTitle}>
                          <Box>
                            <Typography>{item.title}</Typography>
                            <Typography>{item.subTitle}</Typography>
                          </Box>
                          <Link
                            to={item.id}
                            spy={true}
                            smooth={true}
                            offset={item.offset}
                            duration={50}
                          >
                            <Button
                              onClick={handleOpenCampaign}
                              sx={{ width: "80px", maxHeight: "35px" }}
                              variant="outlined"
                              startIcon={<EditIcon />}
                            >
                              Edit
                            </Button>
                          </Link>
                        </Box>
                        <Divider sx={{ my: "20px", mx: "-25px" }} />
                        {item.component}
                      </Box>
                    </Grid>
                  )}
                </>
              ))}
              <Grid item xs={9}>
                {campDetailAcceptData.map((item, index) => (
                  <Box key={index} sx={classes.campDetailAccept}>
                    <Checkbox
                      color="secondary"
                      onChange={(e) => {
                        handleOpenCampaignDetailDialog(e, item.keyName);
                      }}
                    />
                    <Typography display="flex">
                      {item.label}
                      <Typography
                        onClick={() =>
                          item.keyName === "timeline"
                            ? setOpenTimelineDetailDialog(true)
                            : setOpenTermsAndCondDetailDialog(true)
                        }
                        color="#1A77F1"
                        ml={1}
                        sx={{ cursor: "pointer" }}
                      >
                        อ่านเพิ่มเติม
                      </Typography>
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={9} sx={classes.accnouceDateButton}>
                <Button variant="contained" disabled endIcon={<SendIcon />}>
                  ประกาศรับสมัครอินฟลูเอนเซอร์
                </Button>
              </Grid>
            </Grid>
            <TimelineDetailDialog
              openTimelineDialog={openTimelineDetailDialog}
              setOpenTimelineDialog={setOpenTimelineDetailDialog}
            />
            <TermsAndConditionsDialog
              openTermsAndCondDialog={openTermsAndCondDetailDialog}
              setOpenTermsAndCondDialog={setOpenTermsAndCondDetailDialog}
            />
            {/* <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backDrop}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop> */}
          </Box>
        </>
      )}
    </Box>
  );
}
