import { hPurple } from "../../../theme";
import { darken } from "@mui/material/styles";
import { hGrey } from "../../../theme";
import { hBlack } from "../../../theme";
import { styled } from "@mui/material/styles";

export const useTiktokTabStyle = {
  stackContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    width: "100%",
  },
  audienceTabs: {
    minHeight: "fit-content",
    backgroundColor: hPurple[100],
    borderRadius: "45px",
  },

  rateCardPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    mt: 3,
    border: "3px solid",
    borderColor: "primary.main",
  },
  rateCardIconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "70px",
    minHeight: "70px",
    maxHeight: "70px",
    backgroundColor: "primary.main",
    borderRadius: "15px",
    fontSize: "40px",
    color: "common.white",
  },
  rateCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  rateCardSaveIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    backgroundColor: "primary.main",
    fontSize: "16px",
    color: "common.white",
    "&:hover": {
      backgroundColor: darken(hPurple[200], 0.1),
    },
  },
  rateCardBox: {
    display: "flex",
    width: "100%",
    px: 4,
    py: 3,
  },
  imageBox: {
    height: "inherit",
    width: "inherit",
    borderRadius: "10px",
  },
  imagePostIcon: {
    fontSize: "12px",
    pr: 1,
    pb: 1,
  },
  imageTypePostIcon: {
    fontSize: "20px",
  },
  tiktokProfileAvatar: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  tiktokBadge: {
    "& .MuiBadge-badge": {
      top: "5px",
      left: "4px",
    },
  },
  overviewPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "92px",
    borderRadius: "10px",
    mt: 3,
  },
  overviewBox: {
    display: "flex",
    height: "70px",
    width: "100%",
  },
  overviewStack: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  averagePostPaper: {
    display: "flex",
    borderRadius: "10px",
    py: 4,
  },
  averageIcon: {
    fontSize: "28px",
  },
  audiencePaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    mt: 3,
  },
  audienceBox: { width: "100%", px: 6, py: 3 },
  socialMediaScreenshotStack: {
    border: "3px solid",
    borderColor: "secondary.main",
    borderRadius: "10px",
    pt: 4,
    pb: 2,
    // px: 2,
    width: "100%",
    height: "100%",
  },
  socialMediaScreenshotAvatar: {
    height: "44px",
    width: "44px",
  },
  socialMediaScreenshotStateIcon: {
    fontSize: "28px",
    pr: 1,
  },
  syncButton: {
    width: "220px",
    mb: 2,
  },
  carouselItemBox: {
    width: "inherit",
    paddingBottom: "52px",
  },
  tiktokBoxMain: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    aspectRatio: "9 / 16",
  },
  tiktokFirstLayer: {
    position: "relative" as any,
    top: "6px" as any,
    left: 0 as any,
    width: "inherit" as any,
    aspectRatio: "9 / 16" as any,
    objectFit: "cover" as any,
  },
  tiktokSecondLayer: {
    position: "absolute",
    bottom: "46px",
    left: "0",
    width: "100%",
    aspectRatio: "9 / 16",
    background:
      "linear-gradient(0deg, #373737 0%, #00000000 28%, #00000000 100%)",
  },
  tiktokThirdLayer: {
    position: "absolute",
    bottom: "46px",
    left: "0px",
    width: "inherit",
  },
  tiktokFourthLayer: {
    position: "absolute",
    bottom: "140px",
    right: "0px",
    width: "inherit",
  },
  tiktokCaption: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  tiktokAvatar: {
    height: "44px",
    width: "44px",
    border: "2px solid white",
  },
  tiktokItemIcon: {
    fontSize: "34px",
    color: "#ffffff",
  },
  line: {
    width: "100%",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: `1px solid ${hGrey[100]}`,
    margin: "8px 0",
    display: ["block", "none"],
  },
  captionSocialMediaScreenshot: {
    width: "80%",
    color: "common.white",
    padding: "0px 2px 6px 6px",
  },
  textItemsSocialMediaScreenshot: {
    alignItems: "center",
    color: "#ffffff",
  },
  cardInformationTiktok: {
    width: "100%",
    borderRadius: "20px",
    marginBottom: "20px",
    boxShadow: "3",
  },
  textOverviewTimeline: {
    color: "#ED1968",
    fontWeight: "bold",
  },
  averageOnRecentPosts: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  //Header Profile TikTok Tabs
  InformationHeaderBox: {
    justifyContent: "space-between",
    height: "100%",
  },
  NameHeaderProfile: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
  },

  //Most Popular Posts
  boxImages: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  valuesBox: {
    width: "100%",
    display: "flex",
    justifyContent: ["center", "space-around", "center"],
    color: "common.white",
    paddingBottom: "1px",
  },
  IconsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  IconsBoxImage: {
    display: "none",
    alignItems: "center",
  },

  //Social Media Screenshot
  HeadSocialMediaScreenshot: {
    fontSize: ["16px", "16px", "20px"],
    fontWeight: "bold",
    px: 3,
  },
  // Default Tiktok Tab
  tiktokAccount: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    "& .MuiAvatar-root": {
      width: ["50px", "70px", "80px"],
      height: ["50px", "70px", "80px"],
    },
    "& p": {
      margin: ["15px", "20px"],
      fontSize: ["16px", "20px"],
      fontWeight: 550,
    },
  },
  boxUrl: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    width: "100%",
  },
  fillURLTiktokBox: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    width: "100%",
    "& .fillUrlTiktokTitle": {
      margin: ["12px auto 10px 20px", "12px auto 16px 20px"],
      fontSize: ["14px", "16px"],
    },
  },
  fillURLTiktok: {
    display: ["block", "flex"],
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 20px",
    "& p": {
      fontSize: ["12px", "14px"],
      width: ["100%", "15%"],
      height: "50%",
      marginBottom: ["10px", 0],
      alignSelf: "center",
      "& span": {
        color: "red",
      },
    },
    "& .MuiFormControl-root": {
      width: ["100%", "60%"],
      height: "40px",
    },
  },
  fillTiktokInput: {
    width: "480px",
    marginLeft: "24px",
    height: "40px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${hPurple[200]}`,
      " &:hover": {
        border: `1px solid ${hPurple[200]}`,
      },
    },
  },
  verifyCodeToTiktokBioBox: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    width: "100%",
    "& .verifyCodeToTiktokBioTitle": {
      margin: "20px 0 16px 20px",
      fontSize: ["14px", "16px"],
    },
  },
  verifyCodeToTiktokBio: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "16px auto",
    "& p": {
      fontSize: ["14px", "16px"],
      fontWeight: 500,
    },
    "& img": {
      width: ["250px", "400px", "525px"],
      height: ["130px", "200px", "233px"],
      margin: "12px auto",
      border: `1px solid ${hGrey[200]}`,
      borderRadius: "8px",
    },
  },
  verifyCodeBox: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    margin: "20px 0",
    "& .verifyCodeTitle": {
      margin: "15px 0 16px 20px",
      fontSize: ["14px", "16px"],
    },
  },
  verifyCode: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "10px auto",
    "& .verifyCode": {
      fontSize: "16px",
      fontWeight: 500,
    },
    "& div": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& button": {
        border: "none",
        margin: "10px auto",
        width: ["200px", "300px"],
        height: ["35px", "42px"],
        fontSize: ["12px", "16px"],
        " &:hover": { border: "none" },
      },
      "& p": {
        color: "#5C5C5C",
        fontSize: {
          xs: "12px",
          sm: "14px",
        },
        fontWeight: 400,
        width: "80%",
        textAlign: "center",
        margin: "10px auto",
      },
    },
  },
  buttonVerifyCode: {
    display: "flex",
    justifyContent: "center",
    width: {
      xs: "250px",
      sm: "320px",
    },
    height: {
      xs: "55px",
      sm: "64px",
    },
    margin: "12px auto",
    alignItems: "center",
    position: "relative",
    borderRadius: "8px",
    background: hPurple[100],
    "& p:first-child": {
      color: hPurple[300],
      fontSize: ["20px", "24px"],
    },
  },
  coppyVerifyCode: {
    right: "26px",
    position: "absolute",
    cursor: "pointer",
    " &:hover": {
      color: "#616161",
    },
    width: {
      xs: "20px",
      sm: "40px",
    },
  },
  verifyTiktokAccount: {
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
    width: "100%",
    margin: ["20px 0", "5px 0"],
    "& p": {
      margin: "20px 5px 0 20px",
      fontSize: ["14px", "16px"],
    },
    "& button": {
      backgroundColor: hBlack[100],
      display: "flex",
      justifyContent: "center",
      margin: ["25px auto", "32px auto"],
      width: ["200px", "300px"],
      height: "42px",
      border: "none",
      " &:hover": { border: "none" },
    },
  },
};

export const HeaderProfileMobile = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "8px",
  },
}));

export const ConnectTiktokAccount = {
  tiktokAccount: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  tiktokAccLogo: {
    marginTop: "60px",
    width: "80px",
    height: "80px",
  },
  tiktokAccTitle: {
    margin: "20px",
    fontSize: "20px",
    fontWeight: 550,
  },
  fillURLTiktok: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  fillTiktokInput: {
    width: "480px",
    marginLeft: "24px",
    height: "40px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #8C499C",
      " &:hover": {
        border: "1px solid #8C499C",
      },
    },
  },
  verifyCodeBox: {
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
    margin: "24px 0",
  },
  verifyCodeTitle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "16px auto",
  },
  buttonVerifyCode: {
    display: "flex",
    justifyContent: "center",
    width: "320px",
    height: "64px",
    margin: "12px auto",
    alignItems: "center",
    position: "relative",
    borderRadius: "8px",
    background: "#EBDFFF",
  },
  coppyVerifyCode: {
    right: "26px",
    position: "absolute",
    cursor: "pointer",
    " &:hover": {
      color: "#616161",
    },
  },
  verifyCodeToTiktokBio: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "16px auto",
  },
  tiktokVerify: {
    width: "525px",
    height: "233px",
    margin: "12px auto",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
  verifyTiktokAcc: {
    backgroundColor: hBlack[100],
    display: "flex",
    justifyContent: "center",
    margin: "32px auto",
    width: "300px",
    height: "42px",
    border: "none",
    " &:hover": { border: "none" },
  },
};
