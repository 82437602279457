import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material";

export const SaveButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: "none",
  maxWidth: 300,
  width: "85%",
  "&:hover": {
    border: "none",
  },
}));
