import { Card, CardHeader, Typography, CardContent, Box } from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";

function Approved() {
  const styles = {
    boxContent: {
      padding: "8px",
      backgroundColor: "primary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
    container: {
      display: "flex",
    },
    image: {
      height: "125px",
      width: "125px",
      margin: "8px",
    },
    cardContent: {
      paddingInline: "30px",
    },
  };

  return (
    <Box sx={styles.boxContent}>
      <Card sx={{ margin: "8px" }}>
        <CardHeader
          title={
            <Typography variant="h6" color="common.black" fontWeight="500">
              Approved
            </Typography>
          }
        />
        <CardContent sx={styles.cardContent}>
          <WrapInputWithLabel title="Selected Image" alignItems="start">
            <Box>
              <img
                style={styles.image}
                alt="laz"
                src="/assets/images/laz-1.png"
              />
              <img
                style={styles.image}
                alt="laz"
                src="/assets/images/laz-2.jpeg"
              />
            </Box>
          </WrapInputWithLabel>

          <WrapInputWithLabel title=" Note" labelWidth="80px">
            XXXXXXX
          </WrapInputWithLabel>
        </CardContent>
      </Card>
      <Card sx={{ margin: "8px" }}>
        <CardHeader
          title={
            <Typography variant="h6" color="common.black" fontWeight="inherit">
              Date Post
            </Typography>
          }
        />
        <CardContent sx={styles.cardContent}>
          <WrapInputWithLabel title="Date range" labelWidth="120px">
            11/12/2021 - 15/12/2021
          </WrapInputWithLabel>
          <WrapInputWithLabel title="Time range" labelWidth="120px">
            09.00AM - 18.00AM
          </WrapInputWithLabel>
          <WrapInputWithLabel title="Note" labelWidth="120px">
            ขอความร่วมมือ
          </WrapInputWithLabel>
        </CardContent>
      </Card>
      <Card sx={{ margin: "8px" }}>
        <CardHeader
          title={
            <Typography variant="h6" color="common.black" fontWeight="inherit">
              Conversion
            </Typography>
          }
        />
        <CardContent sx={styles.cardContent}>
          <WrapInputWithLabel title="Lead link" labelWidth="120px">
            https://www.facebook.com/hashtag/lazada1212supershow?source=feed_text&epa=HASHU
          </WrapInputWithLabel>
          <WrapInputWithLabel title="Coupon code" labelWidth="120px">
            12.12
          </WrapInputWithLabel>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Approved;
