import React from "react";
// Styles
import "./HomeHeaderBar.styles.css";
import {
  useHeaderBarDesktopStyle as classes,
  ButtonSignIn,
} from "./HomeHeaderBar.styles";
// Hooks
import useScrollingUp from "../../utils/hooks/useScrollingUp";
// Types
import { IHomeHeaderBarDesktopProps } from "./HomeHeader.types";
// Material
import { Box, Link, Stack, Typography } from "@mui/material";
// Image
import logo from "./../../assets/images/logo/HashU logo 2.svg";
// Data
import { menuList } from "./HomeHeaderBar";
import { useHistory } from "react-router-dom";

function HomeHeaderBarDesktop({ setIsOpenSignIn }: IHomeHeaderBarDesktopProps) {
  const isScrollingUp = useScrollingUp();
  const history = useHistory();

  return (
    <header className={`home-bar ${isScrollingUp ? "visible" : "hidden"}`}>
      <Box boxShadow={3} sx={classes.headerBox}>
        <Stack direction="row" spacing={11}>
          <Box display="flex" alignItems="center">
            <Link href="/" underline="none">
              <img src={logo} alt="Hashu Logo" style={classes.logoImg} />
            </Link>
          </Box>
          {menuList.map((menu, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Link href={menu.path} underline="none">
                <Typography
                  variant="body1"
                  color={
                    history.location.pathname === menu.path
                      ? "secondary"
                      : "grey.700"
                  }
                  fontWeight={
                    history.location.pathname === menu.path ? "bold" : "normal"
                  }
                  sx={classes.menuLabel}
                >
                  {menu.label}
                </Typography>
              </Link>
            </Box>
          ))}
        </Stack>
        <Box>
          <ButtonSignIn onClick={() => setIsOpenSignIn(true)}>
            <Typography variant="body1" fontWeight="bold">
              Sign In
            </Typography>
          </ButtonSignIn>
        </Box>
      </Box>
    </header>
  );
}

export default HomeHeaderBarDesktop;
