import { styled, Tab, TabProps } from "@mui/material";
import { hWhite } from "../../../theme";

export const EmployerDetailTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: hWhite[100],
  borderRadius: "20px 20px 0px 0px",
  minHeight: "44px",
  maxHeight: "44px",
  minWidth: "160px",
  maxWidth: "160px",
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  [theme.breakpoints.up("xs")]: {
    width: "32%",
    fontSize: "14px",
    fontWeight: "500",
  },
  [theme.breakpoints.up("sm")]: {
    width: "16%",
  },
  [theme.breakpoints.up("md")]: {
    width: ["20%", "20%", "19.52%"],
  },
}));

export const useEmployerDetailStyle = {
  containnerMain: {
    padding: ["88px 20px 20px 20px", "88px 28px 28px 120px"],
  },
  tabPanelBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "common.white",
    borderRadius: "0px 0px 20px 20px",
    py: 4,
    px: 6,
    boxShadow: 3,
  },
};
