import React, { useEffect, useState } from "react";
// Component
import HomeMain from "../../components/home-main/HomeMain";
import SelectAccountDialog from "../../components/authentication-dialog/SelectAccountDialog";
import CreateAccountDialog from "../../components/authentication-dialog/CreateAccountDialog";
// Types
import { IAccountSelected } from "../../components/authentication-dialog/AuthenDialog.types";
import { IDevice } from "../global/global.types";
// Hooks
import useBreakpoint from "../../utils/hooks/useBreakpoint";

function HomeMainPage() {
  const bp = useBreakpoint();
  const [device, setDevice] = useState<IDevice>("mobile");
  const [isOpenSelectAccount, setIsOpenSelectAccount] =
    useState<boolean>(false);
  const [accountSelected, setAccountSelected] =
    useState<IAccountSelected | null>(null);
  const [isOpenCreateAccount, setIsOpenCreateAccount] =
    useState<boolean>(false);

  useEffect(() => {
    if (bp === "xs") {
      setDevice("mobile");
    } else if (bp === "sm" || bp === "md") {
      setDevice("tablet");
    } else {
      setDevice("desktop");
    }
  }, [bp]);

  const openCreateAccountDialog = (type: IAccountSelected) => {
    setIsOpenSelectAccount(false);
    setAccountSelected(type);
    setIsOpenCreateAccount(true);
  };

  return (
    <div>
      <HomeMain
        device={device}
        breakpoint={bp}
        setIsOpenSelectAccount={setIsOpenSelectAccount}
      />

      {/* Select Account Dialog */}
      {/* -- UAT comment here -- */}
      <SelectAccountDialog
        isOpened={isOpenSelectAccount}
        setOpen={setIsOpenSelectAccount}
        openCreateAccountDialog={openCreateAccountDialog}
      />
      {/* -- End comment -- */}

      {/* Create Account Dialog */}
      {accountSelected !== null && (
        <CreateAccountDialog
          isOpened={isOpenCreateAccount}
          setOpen={setIsOpenCreateAccount}
          accountSelected={accountSelected}
        />
      )}
    </div>
  );
}

export default HomeMainPage;
