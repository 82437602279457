import * as React from "react";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  Stack,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Pagination,
  Chip,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
// Styles
import {
  expenseCampaign as classes,
  DocumentButton,
  TableRowCustom,
  TableCellCustom,
} from "./Expense.styles";
// Mock Data
import { InfluencerMyEarningMockData as earningData } from "./../influencer-myEarning/InfluencerMyEarningMockData";
import { Download } from "@mui/icons-material";
// import EyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeRounded";

export default function ExpenseDefault() {
  return (
    <Box mt={3}>
      <TableContainer
        component={Paper}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Table sx={{ minWidth: [980, 1100] }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCellCustom>Campaign</TableCellCustom>
              <TableCellCustom align="left">Recipient</TableCellCustom>
              <TableCellCustom align="left">Completed Date</TableCellCustom>
              <TableCellCustom align="center">Amount</TableCellCustom>
              <TableCellCustom align="center">Status</TableCellCustom>
              <TableCellCustom align="center">Actions</TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            {earningData.map((row, index) => {
              return (
                <TableRowCustom key={index}>
                  <TableCellCustom align="left">
                    <Stack direction="row" alignItems="center">
                      <Avatar sx={classes.campaignIcon} alt="Campaign" src="" />
                      <Box ml={2.5}>
                        <Typography color="#8C499C">
                          {row.campaign.name}
                        </Typography>
                        <Typography fontSize={["12px", "14px"]}>
                          {row.campaign.transaction}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Stack
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Typography>Fardee Tat</Typography>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          sx={{ width: "20px", height: "20px" }}
                          alt="Recipient"
                          src=""
                        />
                        <Typography ml={2}>{row.recipient.name}</Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="left">
                    {row.completeData}
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    {row.amount.toFixed(2)}
                    <span>THB</span>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Box
                      sx={
                        row.status === "paid"
                          ? classes.statusPaid
                          : classes.statusPending
                      }
                    >
                      {row.status}
                    </Box>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Avatar
                      sx={
                        row.status === "paid"
                          ? classes.eyeIconPaid
                          : classes.eyeIconPending
                      }
                    >
                      <EyeOutlinedIcon />
                    </Avatar>
                  </TableCellCustom>
                </TableRowCustom>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container my={1} display={{ md: "none" }} spacing={5}>
        {earningData.map((row, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              display="flex"
              justifyContent="center"
            >
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <CardContent sx={classes.cardContent}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={1}
                    mb={2.5}
                  >
                    <span>{`ID : ${row.campaign.transaction}`}</span>
                    <Box
                      sx={
                        row.status === "paid"
                          ? classes.statusPaid
                          : classes.statusPending
                      }
                    >
                      {row.status}
                    </Box>
                  </Box>
                  <Divider sx={{ my: "10px" }} />
                  <Box display="flex" alignItems="center">
                    <Avatar sx={classes.campaignIcon} />
                    <Box ml={3}>
                      <Typography
                        color="primary"
                        sx={{ fontSize: "16px", fontWeigth: "500" }}
                      >
                        {row.campaign.name}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Avatar sx={classes.recipientIcon} />
                        <Typography>{row.recipient.name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={classes.cardTable}>
                    <Box className="cardTable">
                      <Typography>Recipient</Typography>
                      <Typography color="primary">
                        Putthipong Assaratanakul
                      </Typography>
                    </Box>
                    <Box className="cardTable">
                      <Typography>Completed Data</Typography>
                      <Typography>{row.completeData}</Typography>
                    </Box>
                    <Box className="cardTable">
                      <Typography>Amount Data</Typography>
                      <Typography>{`${new Intl.NumberFormat("jp-JP", {
                        minimumFractionDigits: 2,
                      }).format(row.amount)} THB`}</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={classes.cardTable}
                  >
                    <Typography fontSize="15px" ml={4}>
                      หัก ณ ที่จ่าย
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      color="info"
                      startIcon={<EyeOutlinedIcon />}
                      disabled={
                        row.document.status === "display" ? false : true
                      }
                    >
                      Document
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
