import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { formatPhoneNumber } from "../../../global/format-number";
import EditInformation from "../edit/EditInformation";

export default function Information(props: any) {
  const { employerDetail } = props;
  const [isOpenEditeEmployer, setIsOpenEditEmployer] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpenEditEmployer(false);
  };
  return (
    <Box sx={{ border: "2px solid #E8DFFF", borderRadius: "12px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "20px" }}
      >
        <Typography variant="h6">Employer Information</Typography>
        <Button
          onClick={() => setIsOpenEditEmployer(true)}
          sx={{ width: "100px", justifyContent: "space-evenly" }}
        >
          <Icon className="ico-hu-edit" />
          Edit
        </Button>
      </Box>
      <Divider />
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Profile
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Avatar
              src={employerDetail?.organisation.attachment.url}
              sx={{
                width: "120px",
                height: "120px",
                borderRadius: "10px",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Organization
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {employerDetail?.organisation.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Size
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {employerDetail?.organisation.size}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Type
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {employerDetail?.organisation.type}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Category
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {employerDetail?.organisation.category.map((category: any) => {
              return (
                <Typography variant="body1" color="#5C5C5C">
                  • {category.name_en}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Full name
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {employerDetail?.fullname}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Phone
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {formatPhoneNumber(employerDetail?.phone)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1" fontWeight="600">
              Line ID
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="#5C5C5C">
              {employerDetail?.line_id}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <EditInformation isOpened={isOpenEditeEmployer} onClose={handleClose} />
    </Box>
  );
}
