import React from "react";
// Mui
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
import { termsAndConditionsDetailData } from "./CampaignDetailsData";
// Style
import { useTermsAndConditionsDetailStyle as classes } from "./CampaignDetails.style";

export default function TermsAndConditionsDetail() {
  return (
    <FormGroup sx={classes.formGroup}>
      {termsAndConditionsDetailData.map((item, index) => (
        <FormControlLabel
          key={index}
          control={<Checkbox />}
          label={item.label}
        />
      ))}
    </FormGroup>
  );
}
