import React from "react";
import { IMyprofileTabProps } from "../InfluencerSocial.types";
import DetailTab from "./DetailTab";

import { Box } from "@mui/material";

export default function MyProfileTab(props: IMyprofileTabProps) {
  const { TabPanel, tabSelected } = props;

  return (
    <Box display={["block", "block", "none"]}>
      <TabPanel value={tabSelected} index={0}>
        <DetailTab />
      </TabPanel>
    </Box>
  );
}
