export const accountType = [
  {
    id: 1,
    value: "saving",
    label: "Savings Account",
  },
  {
    id: 2,
    value: "current",
    label: "Current Account",
  },
];
