import React, { useEffect, useState } from "react";
// Labrary
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PersonalInformationFormSchema } from "../InfluencerProfileForm.schema";
// Component
import TextFieldCustom from "../../global/text-field/index";
import BasicSelectCustom from "../../global/basic-select/index";
import { SaveButton } from "../../../views/my-profile/MyProfile.styles";
// API
import { uploadImage } from "../../../api/appInformation";
import { editInfluencerProfileAPI } from "../../../api/influencer/InfluencerAPI";
// Types
import {
  IPersonalInformationFormSchema,
  IPersonalInformationForm,
  IPersonalInformationProps,
  IPersonalInformationFormImages,
} from "../InfluencerProfile.types";
// Utils
import { compareNationality } from "../../../utils/helpers/compareNationality.helpers";
import { genderArray } from "../../../utils/constants/genderArray.constants";
import { useEditProfileDialogStyle as classes } from "../InfluencerProfile.styles";
import { addDefaultImageSrc } from "../../../utils/helpers/addDefaultImageSrc.helpers";
// Material
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { darken, Theme } from "@mui/material/styles";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import AddIcon from "@mui/icons-material/Add";
import { SaveIcon } from "../../../assets/icons/social/social";
// Other
import DefaultImage from "./../../../assets/images/other/image-empty.jpg";
import SwalCustom from "../../global/sweet-alert-custom/SwalCustom";
import { formatPhoneNumber } from "../../global/format-number";
import NumberFormat from "react-number-format";
//  DayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

const initImages: IPersonalInformationFormImages = {
  file_id: "",
  file_name: "",
  file_type: "",
  url: "",
  purpose: "",
  description: "",
};

const initPersonalInformationForm: IPersonalInformationForm = {
  fullname: "",
  birth_date: "",
  gender: "",
  nationality: "",
  phone: "",
  line_id: "",
  description: "",
  media: {
    images: [initImages],
  },
};

export function PersonalInformationSection({
  data,
  personalSubmitHandler,
  closeDialog,
}: IPersonalInformationProps) {
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<IPersonalInformationFormSchema>({
    resolver: yupResolver(PersonalInformationFormSchema),
    defaultValues: initPersonalInformationForm,
  });

  const [profile, setProfile] =
    useState<IPersonalInformationFormImages>(initImages);
  const [frontal, setFrontal] =
    useState<IPersonalInformationFormImages>(initImages);
  const [portrait, setPortrait] =
    useState<IPersonalInformationFormImages>(initImages);
  const [headShot, setHeadShot] =
    useState<IPersonalInformationFormImages>(initImages);
  const [person, setPerson] =
    useState<IPersonalInformationFormImages>(initImages);
  const [imagePosition, setImagePosition] = useState<string>("");
  const [influencerId, setInfluencerId] = useState<string | null>("");

  useEffect(() => {
    if (data) {
      if (
        (data.influencer_id && influencerId === null) ||
        data.influencer_id !== influencerId
      ) {
        setInfluencerId(data.influencer_id);
      }

      const form = {
        fullname: data.fullname,
        birth_date: dayjs(data.birth_date).format("YYYY-MM-DD"),
        gender: data.gender,
        // nationality: compareNationality(data.nationality),
        nationality: data.nationality,
        phone: data.phone,
        line_id: data.line_id,
        description: data.description,
        media: data.media,
      };
      reset(form);

      if (getValues("media.images")) {
        getValues("media.images").filter((image) => {
          if (image.purpose === "profile_image") {
            setProfile(image);
          } else if (image.purpose === "influencer_frontal_image") {
            setFrontal(image);
          } else if (image.purpose === "influencer_portrait_image") {
            setPortrait(image);
          } else if (image.purpose === "influencer_headshot_image") {
            setHeadShot(image);
          } else if (image.purpose === "influencer_person_image") {
            setPerson(image);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickuploadImages = async (e: any, fileId: string) => {
    let data = {
      file: e.target.files[0],
      destination: `influencers/${influencerId}`,
      permission: "public",
    };
    const result = await uploadImage(data);
    if (result) {
      const imagesData = {
        file_name: result.file_name,
        file_type: result.file_type,
        url: (result.public_url as string) || (result.private_url as string),
      };

      if (imagePosition === "profile") {
        setProfile({
          ...profile,
          file_id: profile.file_id === "" ? "" : profile.file_id,
          purpose: profile.purpose === "" ? "profile_image" : profile.purpose,
          ...imagesData,
        });
      } else if (imagePosition === "frontal") {
        setFrontal({
          ...frontal,
          file_id: frontal.file_id === "" ? "" : frontal.file_id,
          purpose:
            frontal.purpose === ""
              ? "influencer_frontal_image"
              : frontal.purpose,
          ...imagesData,
        });
      } else if (imagePosition === "portrait") {
        setPortrait({
          ...portrait,
          file_id: portrait.file_id === "" ? "" : portrait.file_id,
          purpose:
            portrait.purpose === ""
              ? "influencer_portrait_image"
              : portrait.purpose,
          ...imagesData,
        });
      } else if (imagePosition === "headShot") {
        setHeadShot({
          ...headShot,
          file_id: headShot.file_id === "" ? "" : headShot.file_id,
          purpose:
            headShot.purpose === ""
              ? "influencer_headshot_image"
              : headShot.purpose,
          ...imagesData,
        });
      } else if (imagePosition === "person") {
        setPerson({
          ...person,
          file_id: person.file_id === "" ? "" : person.file_id,
          purpose:
            person.purpose === "" ? "influencer_person_image" : person.purpose,
          ...imagesData,
        });
      }
    }
  };

  setValue("phone", getValues("phone").split("-").join(""));

  const onSubmit = async (value: IPersonalInformationForm) => {
    const imagesData = [];
    if (profile.url !== "") {
      imagesData.push(profile);
    }
    if (frontal.url !== "") {
      imagesData.push(frontal);
    }
    if (portrait.url !== "") {
      imagesData.push(portrait);
    }
    if (headShot.url !== "") {
      imagesData.push(headShot);
    }
    if (person.url !== "") {
      imagesData.push(person);
    }
    setValue("media.images", imagesData);

    const data = {
      profile: value,
    };

    try {
      await editInfluencerProfileAPI(data);
      closeDialog();
      await SwalCustom.fire({
        icon: "success",
        title: "Updated profile success",
        showConfirmButton: false,
        timer: 1600,
      });
      personalSubmitHandler(value);
      console.log("submit Edit profile: ", value);
    } catch (error: unknown) {
      await SwalCustom.fire({
        icon: "error",
        title: "Opps !",
        text: "Error, There are something when wrong",
        showConfirmButton: true,
        timer: 1600,
      });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IPersonalInformationForm
  ) => {
    console.log(e.target.value);
    setValue(name, e.target.value);
    clearErrors(name);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      m={0}
      p={0}
    >
      <DialogContent sx={classes.dialogContent}>
        <Box px={[0, 2]} pt={1}>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              Personal Information
            </Typography>
          </Box>
          <Stack px={[1.5, 5]} pt={2}>
            {/* Profile */}
            <Grid container pb={5}>
              <Grid item xs={12} md={3} xl={2}>
                <Typography variant="body2">Profile :</Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <Box sx={classes.profileContainerBox}>
                  <Box sx={classes.profileBox}>
                    <img
                      src={profile ? profile.url : DefaultImage}
                      alt="images"
                      onError={addDefaultImageSrc}
                    />
                    <Button
                      variant="contained"
                      component="label"
                      disableElevation
                      onChange={(e: any) =>
                        handleClickuploadImages(e, profile.file_id)
                      }
                      onClick={() => setImagePosition("profile")}
                      sx={classes.profileButton}
                    >
                      <input
                        hidden
                        name="profile"
                        accept="image/*"
                        multiple
                        type="file"
                      />
                      <CameraIcon fontSize="inherit" />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* Image */}
            <Grid container pb={5}>
              <Grid item xs={12} md={3} xl={2} mb={2}>
                <Typography variant="body2">Image :</Typography>
              </Grid>
              <Grid container item spacing={6} xs={12} md={9} xl={10}>
                <Grid item xs="auto">
                  <Stack alignItems="center">
                    <Box sx={classes.imagesContainerBox}>
                      <Box sx={classes.imagesBox}>
                        <img
                          src={frontal.url ? frontal.url : DefaultImage}
                          // srcSet={DefaultImage}
                          alt="images"
                          onError={addDefaultImageSrc}
                        />
                        <Button
                          variant="contained"
                          component="label"
                          disableElevation
                          onChange={(e: any) =>
                            handleClickuploadImages(e, frontal.file_id)
                          }
                          onClick={() => setImagePosition("frontal")}
                          sx={classes.imagesButton}
                        >
                          <input
                            hidden
                            name="frontal"
                            accept="image/*"
                            multiple
                            type="file"
                          />
                          <CameraIcon fontSize="inherit" />
                        </Button>
                      </Box>
                    </Box>
                    <Typography variant="caption">Frontal</Typography>
                  </Stack>
                </Grid>
                <Grid item xs="auto">
                  <Stack alignItems="center">
                    <Box sx={classes.imagesContainerBox}>
                      <Box sx={classes.imagesBox}>
                        <img
                          src={portrait ? portrait.url : DefaultImage}
                          // srcSet={DefaultImage}
                          alt="images"
                          onError={addDefaultImageSrc}
                        />
                        <Button
                          variant="contained"
                          component="label"
                          disableElevation
                          onChange={(e: any) =>
                            handleClickuploadImages(e, portrait.file_id)
                          }
                          onClick={() => setImagePosition("portrait")}
                          sx={classes.imagesButton}
                        >
                          <input
                            hidden
                            name="portrait"
                            accept="image/*"
                            multiple
                            type="file"
                          />
                          <CameraIcon fontSize="inherit" />
                        </Button>
                      </Box>
                    </Box>
                    <Typography variant="caption">Portrait</Typography>
                  </Stack>
                </Grid>
                <Grid item xs="auto">
                  <Stack alignItems="center">
                    <Box sx={classes.imagesContainerBox}>
                      <Box sx={classes.imagesBox}>
                        <img
                          src={headShot ? headShot.url : DefaultImage}
                          // srcSet={DefaultImage}
                          alt="images"
                          onError={addDefaultImageSrc}
                        />
                        <Button
                          variant="contained"
                          component="label"
                          disableElevation
                          onChange={(e: any) =>
                            handleClickuploadImages(e, headShot.file_id)
                          }
                          onClick={() => setImagePosition("headShot")}
                          sx={classes.imagesButton}
                        >
                          <input
                            hidden
                            name="headshot"
                            accept="image/*"
                            multiple
                            type="file"
                          />
                          <CameraIcon fontSize="inherit" />
                        </Button>
                      </Box>
                    </Box>
                    <Typography variant="caption">Head shot</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {/* Full Name */}
            <Grid container pb={3}>
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={12}
                md={3}
                xl={2}
              >
                <Typography variant="body2">
                  Full Name<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("fullname")}
                  onChange={(e) => handleChange(e, "fullname")}
                  type="text"
                  placeholder="Full Name"
                  fullWidth
                  error={!!errors.fullname}
                  helperText={errors.fullname?.message}
                />
              </Grid>
            </Grid>
            {/* Birthdate */}
            <Grid container pb={3}>
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={12}
                md={3}
                xl={2}
              >
                <Typography variant="body2">Birthdate :</Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={dayjs(getValues("birth_date")).format("DD/MM/YYYY")}
                  onChange={(e) => handleChange(e, "birth_date")}
                  variant="outlined"
                  placeholder="Birthdate"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </Grid>
            </Grid>
            {/* Gender */}
            <Grid container pb={3}>
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={12}
                md={3}
                xl={2}
              >
                <Typography variant="body2">
                  Gender<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <BasicSelectCustom
                  id="gender-select-1"
                  value={getValues("gender")}
                  onChange={(e) => handleChange(e, "gender")}
                  data={genderArray}
                  valueKey="value"
                  labelKey="label"
                  placeholder="Gender"
                  fullWidth
                  error={!!errors.gender}
                  helperText={errors.gender?.message}
                />
              </Grid>
            </Grid>
            {/* Nationality */}
            <Grid container pb={3}>
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={12}
                md={3}
                xl={2}
              >
                <Typography variant="body2">Nationality :</Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("nationality")}
                  onChange={(e) => handleChange(e, "nationality")}
                  variant="outlined"
                  placeholder="Nationality"
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
            {/* Phone */}
            <Grid container pb={3}>
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={12}
                md={3}
                xl={2}
              >
                <Typography variant="body2">
                  Phone<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={formatPhoneNumber(getValues("phone"))}
                  onChange={(e) => handleChange(e, "phone")}
                  type="text"
                  placeholder="Phone"
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              </Grid>
            </Grid>
            {/* Line ID */}
            <Grid container pb={3}>
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={12}
                md={3}
                xl={2}
              >
                <Typography variant="body2">Line ID :</Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("line_id")}
                  onChange={(e) => handleChange(e, "line_id")}
                  variant="outlined"
                  placeholder="Line ID"
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* Description */}
            <Grid container pb={0}>
              <Grid item xs={12} md={3} xl={2} pt={1}>
                <Typography variant="body2">Description :</Typography>
              </Grid>
              <Grid item xs={12} md={9} xl={10} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("description")}
                  onChange={(e) => handleChange(e, "description")}
                  variant="outlined"
                  multiline
                  rows={4}
                  placeholder="Description"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <SaveButton
          variant="contained"
          type="submit"
          startIcon={<SaveIcon sx={{ width: "14.4px", height: "14.4px" }} />}
          disabled={
            !getValues("fullname") ||
            !getValues("gender") ||
            !getValues("phone")
              ? true
              : false
          }
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
      </DialogActions>
    </Box>
  );
}
