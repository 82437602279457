import React from "react";
import { Box, CircularProgress } from "@mui/material";
// Styles
import { useVertifyEmail as classes } from "./verifyEmail.styles";

export interface IVerifiedEmail {
  email: string;
  grant_type: string;
}

export default function LoadingVerified() {
  return (
    <div>
      <Box sx={classes.boxDialog}>
        <CircularProgress
          size={100}
          color="secondary"
          variant="indeterminate"
        />
      </Box>
    </div>
  );
}
