import React from "react";
// Component
import DialogAuthentication from "../global/dialog-authentication";
// Types
import { ISelectAccountDialogProps } from "./AuthenDialog.types";
// Styles
import { useSelectAccountDialogStyle as classes } from "./authentication.styles";
// Material
import {
  Avatar,
  DialogActions,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
// Images
import InfluencerImage from "./../../assets/images/people/influencer.png";
import MarketerImage from "./../../assets/images/people/marketer.png";

function SelectAccountDialog(props: ISelectAccountDialogProps) {
  const { isOpened, setOpen, openCreateAccountDialog } = props;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogAuthentication
      open={isOpened}
      onClose={handleClose}
      sx={classes.selectAccountDialog}
    >
      <DialogActions sx={classes.selectAccountDialogAction}>
        <Grid container spacing={8} sx={classes.gridContainer}>
          <Grid item xs={12} sm={6}>
            <Paper
              onClick={() => openCreateAccountDialog("influencer")}
              sx={classes.influencerPaper}
            >
              <Stack alignItems="center" py={10} px={5}>
                <Avatar
                  alt="Influencer"
                  src={InfluencerImage}
                  sx={classes.imageAvatar}
                />
                <Typography
                  variant="h6"
                  color="secondary"
                  align="center"
                  mt={4}
                  mb={2}
                >
                  Influencer
                </Typography>
                <Typography variant="body1" color="grey.700" align="center">
                  ร่วมสร้างสังคมใหม่ เปิดโลก Influencer ไปกับ HashU
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              onClick={() => openCreateAccountDialog("marketer")}
              sx={classes.marketerPaper}
            >
              <Stack alignItems="center" py={10} px={5}>
                <Avatar
                  alt="Influencer"
                  src={MarketerImage}
                  sx={classes.imageAvatar}
                />
                <Typography
                  variant="h6"
                  color="primary"
                  align="center"
                  mt={4}
                  mb={2}
                >
                  Marketer
                </Typography>
                <Typography variant="body1" color="grey.700" align="center">
                  เลือกนักรีวิวที่ใช่ พร้อมจัดการ แคมเปญของคุณในที่เดียว
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogAuthentication>
  );
}

export default SelectAccountDialog;
