import React, { useEffect, useState } from "react";
// Library
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Component
import TextFieldCustom from "../../global/text-field/index";
import BasicSelectCustom from "../../global/basic-select";
import ImageInput from "../../global/image-input";
import WrapInputWithLabel from "../../global/wrap-input-label";
// Types
import {
  IBankAccountFormSchema,
  IBankAccountSectionProps,
  IImageUrl,
  IBankAccountForm,
} from "../InfluencerBankAccount.types";
import { IBank } from "../../../views/my-profile/MyProfile.types";
// Utils
import { useEditBankAccountDialogStyle as classes } from "../InfluencerBankAccount.styles";
import { BankAccountFormSchema } from "../InfluencerBankAccountForm.schema";
import { accountType } from "../../../utils/constants/accountType.constants";
// API
import {
  getBankLists,
  uploadImage,
  editBankAccountAPI,
} from "../../../api/appInformation";
// Material
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// Icons
import { SaveButton } from "../../../views/my-profile/MyProfile.styles";
import iconImage from "../../../assets/images/other/photo.svg";
import SwalCustom from "../../global/sweet-alert-custom/SwalCustom";
import { SaveIcon } from "../../../assets/icons/social/social";
// Globall
import { formatTaxId } from "../../global/format-number";

const initImage: IImageUrl = {
  file_id: "",
  file_name: "",
  file_type: "",
  url: "",
  purpose: "",
  description: "",
};
const initBankAccountForm: IBankAccountForm = {
  account_name: "",
  account_number: "",
  bank: null,
  branch: "",
  account_type: null,
  tax_id: "",
  id_card_image_url: initImage,
  book_bank_image_url: initImage,
};

export function BankAccountSection({
  bankAccount,
  bankAccountSubmitHandler,
  closeDialog,
}: IBankAccountSectionProps) {
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<IBankAccountFormSchema>({
    resolver: yupResolver(BankAccountFormSchema),
    defaultValues: initBankAccountForm,
  });

  const [bankList, setBankList] = useState<IBank[]>([]);
  const [imageName, setImageName] = useState<string>("");
  const [idCardImageUrl, setIdCardImageUrl] = useState<IImageUrl>(initImage);
  const [bookBank, setBookbank] = useState<IImageUrl>(initImage);

  useEffect(() => {
    async function getBank() {
      const result = await getBankLists();
      const bankList = result.map((bank: IBank) => ({
        id: bank.id,
        value: bank.value,
        name_en: bank.name_en,
        name_th: bank.name_th,
        logo_image_url: bank.logo_image_url,
      }));
      setBankList(bankList);
    }
    getBank();
  }, []);

  useEffect(() => {
    if (bankAccount) {
      const form = {
        account_name: bankAccount.account_name,
        account_number: bankAccount.account_number,
        bank: bankAccount.bank,
        branch: bankAccount.branch,
        account_type: bankAccount.account_type,
        tax_id: bankAccount.tax_id,
        id_card_image_url: bankAccount.id_card_image_url,
        book_bank_image_url: bankAccount.book_bank_image_url,
      };
      reset(form);
      setIdCardImageUrl(bankAccount.id_card_image_url);
      setBookbank(bankAccount.book_bank_image_url);
    }
  }, [bankAccount]);

  const handleChangeUploadImages = async (e: any) => {
    let data = {
      file: e.target.files[0],
      destination: "test",
      permission: "public",
    };
    const result = await uploadImage(data);

    if (result) {
      const imageData = {
        file_name: result.file_name,
        file_type: result.file_type,
        url: (result.public_url as string) || (result.private_url as string),
      };
      if (imageName === "copyofthainationalid") {
        setIdCardImageUrl({
          ...idCardImageUrl,
          file_id: idCardImageUrl.file_id === "" ? "" : idCardImageUrl.file_id,
          purpose:
            idCardImageUrl.purpose === ""
              ? "citizen_id_image"
              : idCardImageUrl.purpose,
          ...imageData,
        });
      } else if (imageName === "bookbank") {
        setBookbank({
          ...bookBank,
          file_id: bookBank.file_id === "" ? "" : bookBank.file_id,
          purpose:
            bookBank.purpose === ""
              ? "bank_account_book_image"
              : bookBank.purpose,
          ...imageData,
        });
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IBankAccountForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const bankHandleChange = (e: { target: { value: number } }) => {
    const bankId = e.target.value;
    const bankSelect = bankList.find((bank) => bank.id === bankId);
    setValue("bank", bankSelect || null);
    clearErrors("bank");
  };

  setValue("tax_id", getValues("tax_id").split("-").join(""));

  const onSubmit = async (value: IBankAccountForm) => {
    console.log("submit Edit bank account: ", value);
    if (value.bank) {
      let data = {
        bank_account: {
          account_name: value.account_name,
          account_number: value.account_number,
          bank_id: value.bank.id,
          branch: value.branch,
          tax_id: value.tax_id,
          account_type: value.account_type,
          id_card_image: idCardImageUrl,
          book_bank_image: bookBank,
        },
      };

      try {
        const result = await editBankAccountAPI(data);
        closeDialog();
        await SwalCustom.fire({
          icon: "success",
          title: "Updated Bank account success",
          showConfirmButton: false,
          timer: 1600,
        });
      } catch (error: unknown) {
        await SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
          timer: 1600,
        });
      }
      bankAccountSubmitHandler(data);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      m={0}
      p={0}
    >
      <DialogContent sx={classes.dialogContent}>
        <Box px={[0, 2]} py={0}>
          <Stack px={2} pt={3}>
            {/* Account Name */}
            <Grid container pb={3}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Account Name<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} xl={9} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("account_name")}
                  onChange={(e) => handleChange(e, "account_name")}
                  variant="outlined"
                  placeholder="Account Name"
                  fullWidth
                  error={!!errors.account_name}
                  helperText={errors.account_name?.message}
                />
              </Grid>
            </Grid>
            {/* Account Number */}
            <Grid container pb={3}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Account Number<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} xl={9} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("account_number")}
                  onChange={(e) => handleChange(e, "account_number")}
                  variant="outlined"
                  placeholder="Account Number"
                  fullWidth
                  error={!!errors.account_number}
                  helperText={errors.account_number?.message}
                />
              </Grid>
            </Grid>
            {/* Bank */}
            <Grid container pb={3}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Bank<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} xl={9} mt={[1.5, , 0]}>
                <BasicSelectCustom
                  id="bank-bank-select-1"
                  value={getValues("bank.id")}
                  onChange={bankHandleChange}
                  data={bankList}
                  valueKey="id"
                  labelKey="name_th"
                  iconKey="logo_image_url"
                  iconType="imageUrl"
                  placeholder="Select Bank"
                  fullWidth
                  error={!!errors.bank}
                  helperText={(errors.bank as any)?.message}
                />
              </Grid>
            </Grid>
            {/* Branch */}
            <Grid container pb={3}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">Branch :</Typography>
              </Grid>
              <Grid item xs={12} md={8} xl={9} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={getValues("branch")}
                  onChange={(e) => handleChange(e, "branch")}
                  variant="outlined"
                  placeholder="Branch"
                  fullWidth
                  error={!!errors.branch}
                  helperText={errors.branch?.message}
                />
              </Grid>
            </Grid>
            {/* Account Type */}
            <Grid container pb={3}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">Account Type :</Typography>
              </Grid>
              <Grid item xs={12} md={8} xl={9} mt={[1.5, , 0]}>
                <BasicSelectCustom
                  id="bank-accountType-select-1"
                  value={getValues("account_type")}
                  onChange={(e) => handleChange(e, "account_type")}
                  data={accountType}
                  valueKey="value"
                  labelKey="label"
                  placeholder="Account Type"
                  fullWidth
                  error={!!errors.account_type}
                  helperText={errors.account_type?.message}
                />
              </Grid>
            </Grid>
            {/* Tax ID */}
            <Grid container pb={5}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Tax ID<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} xl={9} mt={[1.5, , 0]}>
                <TextFieldCustom
                  value={formatTaxId(getValues("tax_id"))}
                  onChange={(e) => handleChange(e, "tax_id")}
                  variant="outlined"
                  placeholder="Tax ID"
                  fullWidth
                  error={!!errors.tax_id}
                  helperText={errors.tax_id?.message}
                />
              </Grid>
            </Grid>
            {/* Copy of Thai National ID */}
            <Grid container pb={8}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Copy of Thai National ID<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                xl={9}
                mt={[1.5, , 0]}
                display="flex"
                justifyContent={["center", "start"]}
              >
                <Box
                  sx={classes.uploadImageBox}
                  component="label"
                  onClick={() => setImageName("copyofthainationalid")}
                  onChange={handleChangeUploadImages}
                >
                  {idCardImageUrl.url !== "" ? (
                    <img
                      style={{
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={idCardImageUrl.url}
                      alt="Copy of Thai National ID"
                    />
                  ) : (
                    <Box sx={classes.uploadImage}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <img src={iconImage} />
                        <span>+ JPG/PDF</span>
                      </Box>
                    </Box>
                  )}
                  <input
                    hidden
                    name="idCardImageUrl"
                    accept="image/*"
                    multiple
                    type="file"
                  />
                </Box>
              </Grid>
            </Grid>
            {/* Book Bank */}
            <Grid container pb={3}>
              <Grid item xs={12} md={4} xl={3} mt={[0, 0, 2]}>
                <Typography variant="body2">
                  Book Bank<span className="requir">*</span> :
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                xl={9}
                mt={[1.5, , 0]}
                display="flex"
                justifyContent={["center", "start"]}
              >
                <Box
                  sx={classes.uploadImageBox}
                  component="label"
                  onClick={() => setImageName("bookbank")}
                  onChange={handleChangeUploadImages}
                >
                  {bookBank.url !== "" ? (
                    <img
                      style={{
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={bookBank.url}
                      alt="Book Bank"
                    />
                  ) : (
                    <Box sx={classes.uploadImage}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <img src={iconImage} />
                        <span>+ JPG/PDF</span>
                      </Box>
                    </Box>
                  )}
                  <input
                    hidden
                    name="bookbank"
                    accept="image/*"
                    multiple
                    type="file"
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginTop: 2,
          paddingBottom: 5,
        }}
      >
        <SaveButton
          type="submit"
          variant="contained"
          disabled={
            !getValues("account_name") ||
            !getValues("account_number") ||
            !getValues("tax_id") ||
            !getValues("bank") ||
            !idCardImageUrl ||
            !bookBank
              ? true
              : false
          }
          startIcon={<SaveIcon sx={{ width: "14.4px", height: "14.4px" }} />}
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
      </DialogActions>
    </Box>
  );
}
