import React from "react";
import { Chip } from "@mui/material";

const style = {
  fontSize: "12px",
  px: 2,
  height: "28px",
};

function CategoryChip(props: { label?: React.ReactNode }) {
  const { label } = props;
  return <Chip label={label} color="primary" sx={style} />;
}

export default CategoryChip;
