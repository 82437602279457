import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";

import { Typography, Box } from "@mui/material";

import HomeMainPage from "./../views/home-main";
import HomePricing from "../views/home-pricing";
import ContactUs from "../views/home-contact-us";
import Campaign from "./../views/campaign";
import MyJobs from "../views/my-jobs";
// import Callback from "../components/influencer-social/twitter-tab/Callback";
import MyProfile from "../views/my-profile";
import Job from "../views/job";
import InfluencerList from "../views/influencer-list";
import MyEarning from "../views/my-earning";

// Verify Email
import VertifyEmail from "./../views/vertifyEmail/index";

// import VerifiedEmail from "./../components/vertify-email/VerifiedEmail";
import VerifiedEmail from "../views/vertifyEmail/VerifiedEmail";

// import InfluencerSidebar from "../components/influencer-sidebar";

import HomeInfluencerPage from "../views/home-influencer";
import HomeMarketerPage from "./../views/home-marketer/index";

//employer campaigns
import CampaignsList from "../components/employer/campaigns/index";
import campaignsBrief from "../components/employer/campaigns/campaignsBrief";
import CreateCampaign from "../views/create-campaign";
//campaigns influencers
import InfluencerLists from "../components/employer/campaigns/influencer/influecerList";
import Approve from "./../components/employer/campaigns/influencer/approve";
import Feedback from "./../components/employer/campaigns/influencer/feedback";
//campaigns report
import Report from "../components/employer/campaigns/report/index";

import HeaderBar from "../components/header-bar/HeaderBar";
import Sidebar from "../components/sidebar";
import HomeFooter from "../components/home-footer/HomeFooter";
import BackToTop from "../components/global/back-to-top/BackToTop";
import HomeHeaderBar from "../components/home-header-bar/HomeHeaderBar";
import NavTabs from "../components/global/nav-tab/NavTabs";
import TitleTabs from "../components/global/title-tabs/TitleTabs";
import Global from "../views/global";

// Admin
import Expense from "../views/expense";
//Employer
import Employers from "../views/employer";
import EmployerDetail from "../views/employer/detail";

// function AdminRoute(props: RouteProps) {
//   const auth = useAuth();
//   return auth.isAuthenticated && auth.role === "admin" ? (
//     <Route {...props}>
//       <h1>Admin</h1>
//     </Route>
//   ) : (
//     <Redirect to={auth.role === "agent" ? "/quote-page" : "/"} />
//   );
// }

const tabsMenu = [
  {
    id: 1,
    label: "Brief",
    href: "/campaign",
  },
  {
    id: 2,
    label: "Influencers",
    href: "/influencer-list",
  },
  {
    id: 3,
    label: "Report",
    href: "/report",
  },
];

// function PrivateRoute(props: RouteProps) {
//   // const history = useHistory();
//   const auth = true;
//   if (auth) {
//     return <Route {...props} />;
//   } else {
//     return <Redirect to={"/"} />;
//   }
// }
function PrivateRoute({ component: Component, ...rest }: any) {
  // const history = useHistory();
  const auth = true;
  if (auth) {
    return (
      <Route
        render={(props) => {
          return (
            <div>
              <TitleTabs
                title={rest.title ?? ""}
                subTitle={rest.subTitle ?? ""}
                tabsMenu={tabsMenu}
              />
              <Component {...props}></Component>
            </div>
          );
        }}
        {...rest}
      />
    );
  } else {
    return <Redirect to={"/"} />;
  }
}

function PublicRoute({ component: Component, ...rest }: any) {
  // const { path } = props;

  // const history = useHistory();
  // if (history.location.pathname === "/")
  const auth = false;
  if (auth) {
    return <Redirect to={"/global"} />;
  } else {
    return (
      <Route
        render={(props) => {
          return (
            <div>
              <TitleTabs
                title={rest.title ?? ""}
                subTitle={rest.subTitle ?? ""}
                backBtn={rest.backBtn}
                tabsMenu={tabsMenu}
              />
              <Component {...props}></Component>
            </div>
          );
        }}
        {...rest}
      />
    );
  }
  // const auth = useAuth();
  // return !auth.isAuthenticated ? (
  //   <Route {...props} />
  // ) : (
  //   <Redirect
  //     to={
  //       auth.role === "agent"
  //         ? {
  //             pathname: "/quote-page",
  //             state: { from: props.location },
  //           }
  //         : auth.role === "admin"
  //         ? {
  //             pathname: "/policy-report",
  //             state: { from: props.location },
  //           }
  //         : {
  //             pathname: "/",
  //             state: { from: props.location },
  //           }
  //     }
  //   />
  // );
}

function Lists() {
  const history = useHistory();
  const { pathname } = history.location;

  const isHomePath = (path: string) => {
    switch (path) {
      case "/":
        return true;
      case "/home/influencer":
        return true;
      case "/home/marketer":
        return true;
      case "/home/pricing":
        return true;
      case "/home/contact":
        return true;
      default:
        return false;
    }
  };
  const isVerifyPath = (path: string) => {
    let isVerifyEmailPath =
      path.includes("/verify-email") || path.includes("/verified-email");
    path = isVerifyEmailPath ? "/verify-email" : path;

    console.log("path", path);

    switch (path) {
      case "/verify-email":
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      {isHomePath(history.location.pathname) ? (
        <HomeHeaderBar />
      ) : isVerifyPath(history.location.pathname) ? (
        <></>
      ) : (
        <>
          <HeaderBar />
          <Sidebar />
        </>
      )}

      {/* <InfluencerSidebar /> */}

      <main
        style={{ paddingLeft: pathname === "/influencer-list" ? "320px" : "" }}
      >
        <Switch>
          <PublicRoute path="/" component={HomeMainPage} exact />
          {/* Verify Email */}
          <PublicRoute
            path="/verify-email/:email"
            component={VertifyEmail}
            exact
          />
          <PublicRoute
            path="/verified-email/:email"
            component={VerifiedEmail}
            exact
          />
          <PublicRoute
            path="/home/influencer"
            component={HomeInfluencerPage}
            exact
          />
          <PublicRoute
            path="/home/marketer"
            component={HomeMarketerPage}
            exact
          />
          <PublicRoute path="/home/pricing" component={HomePricing} exact />
          <PublicRoute path="/home/contact" component={ContactUs} exact />
          <PrivateRoute path="/global" component={Global} exact />
          <PrivateRoute
            path="/campaign"
            component={Campaign}
            title="Campaign Detail"
            subTitle="กรุณากรอกข้อมูลเพื่อสร้างแคมเปญ"
            exact
          />
          <PublicRoute
            path="/job"
            component={Job}
            title="Review Post"
            subTitle="ตรวจสอบข้อมูล"
            backBtn
          />
          <PublicRoute
            path="/influencer-list"
            component={InfluencerList}
            title="Influencer List"
            subTitle="เลือกอินฟลูเอนเซอร์ที่คุณต้องการ"
          />

          {/* Employer */}
          <PrivateRoute path="/employers" component={Employers} exact />
          <PrivateRoute
            path="/employers/:id"
            component={EmployerDetail}
            exact
          />

          {/* Employer Campaigns */}
          <PrivateRoute path="/campaigns" component={CampaignsList} exact />
          <PrivateRoute path="/brief" component={campaignsBrief} exact />
          {/* path="/brief?camp-id=campId" */}
          <PrivateRoute
            path="/campaign/create-campaign"
            component={CreateCampaign}
            exact
          />

          {/* influencer */}
          <PrivateRoute
            path="/campaigns/influencers"
            component={InfluencerLists}
            exact
          />
          <PrivateRoute
            path="/influencer-lists"
            component={InfluencerList}
            exact
          />
          {/* path="/influencer-list?camp_id=campId" */}
          <PrivateRoute path="/jobs" component={Job} exact />
          {/* path="/jobs?camp-id=campId" /> */}
          <PrivateRoute
            path="/campaigns/:campId/jobs/:jobId"
            component={MyJobs}
            exact
          />
          <PrivateRoute path="/approve" component={Approve} exact />
          {/* path="/approve?camp-id=campId&job-id=jobId" */}
          <PrivateRoute path="/feedback" component={Feedback} exact />
          {/* path="/feedback?camp-id=campId&job-id=jobId" */}

          {/* report */}
          <PrivateRoute path="/report" component={Report} exact />
          {/* path="/report?campaign-id:campId" */}
          <PrivateRoute path="/report" component={Report} exact />
          {/* path="/report?campaign-id:campId&job-id=:jobId" */}

          {/* Employer Profile */}
          {/* Job */}
          <PrivateRoute path="/my-jobs" component={MyJobs} exact />
          <PrivateRoute path="/job-bord" component={MyJobs} exact />
          <PrivateRoute path="/job/:jobId" component={MyJobs} exact />
          <PrivateRoute path="/brief" component={MyJobs} exact />
          {/* path="/brief?job-id=jobId" */}
          <PrivateRoute path="/post" component={MyJobs} exact />
          {/* path="/post?job-id=jobId" */}
          <PrivateRoute path="/report" component={MyJobs} exact />
          {/* path="/report?job-id=jobId" */}
          {/* Profile */}
          <PrivateRoute path="/my-profile" component={MyProfile} exact />
          {/* <PrivateRoute path="/twitter/callback" component={Callback} exact /> */}
          {/* <PrivateRoute path="/twitter/callback" component={Callback} exact /> */}

          {/* My Earning */}
          <PrivateRoute path="/my-earnings" component={MyEarning} exact />

          {/* Admin */}
          {/* Expense */}
          <PrivateRoute path="/expense" component={Expense} exact />
        </Switch>
      </main>

      {isHomePath(history.location.pathname) && (
        <>
          <HomeFooter />
          <BackToTop />
        </>
      )}
    </>
  );
}

const RouteList = withRouter(Lists);

function Routes() {
  return (
    <Router>
      <RouteList />
    </Router>
  );
}

export default Routes;
