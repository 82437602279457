import React, { useState, useEffect } from "react";
import chroma from "chroma-js";
import CreatableSelect from "react-select/creatable";
// import { StylesConfig } from "react-select";
import Select, { StylesConfig } from "react-select";
import { Box, IconButton, Typography, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { log } from "console";

interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

interface ICreatableSelect {
  options: any[];
  [any: string]: any;
}

export default function SearchNameCustom(props: ICreatableSelect) {
  const { options, value, onChange, prefix, badgeColor, toggle } = props;
  const getColor = "#8C499C";

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "20px",
      borderColor: "#8C499C",
      whiteSpace: "nowrap",
      boxShadow: "0 0 0 0.1px #8C499C",
      ":focus": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
      ":hover": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 0.1px #8C499C",
      },
      ":active": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
      ":visited": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
      ":target": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(getColor);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? getColor
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : getColor,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          borderColor: "#8C499C",
          backgroundColor: !isDisabled
            ? isSelected
              ? getColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(getColor);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
        borderRadius: "20px",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: getColor,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: getColor,
      ":hover": {
        backgroundColor: getColor,
        color: "white",
      },
    }),
  };

  const styles = {
    badge: {
      // backgroundColor: "primary.main",
      backgroundColor: badgeColor ? badgeColor : "rgba(140, 73, 156, 1)",
      paddingInline: "5px ",
      borderRadius: "25px",
      marginRight: "5px",
      color: "white",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
    },
    valueContainer: {
      display: "flex",
      alignItems: "center",
      marginBlock: "10px",
      flexWrap: "wrap",
    },
  };

  // const handleRemoveValue = (valueItem: any) => {
  //   if (!onChange) return;

  //   const removedValue = value.find(
  //     (val: any) => val.value === valueItem.value
  //   );

  //   if (!removedValue) return;
  //   onChange(
  //     value.filter((val: any) => val.value !== valueItem.value),
  //     { name: valueItem.label, action: "remove-value", removedValue }
  //   );
  // };

  // const onHandleToggleActive = (item: any) => {
  //   const res = value.map((val: any) => {
  //     return val.value === item.value ? { ...val, active: !val.active } : val;
  //   });

  //   onChange(res);
  // };

  const handleChangeValue = (ev: any) => {
    let value = ev[0];
    console.log("value", value);
    // setValue("campaignOverview.companyName", value);
  };

  return (
    <div>
      <CreatableSelect
        isMulti
        controlShouldRenderValue={false}
        styles={colourStyles}
        options={options}
        onChange={(ev) => {
          handleChangeValue(ev);
        }}
        value={value}
        // {...props}
      />
    </div>
  );
}
