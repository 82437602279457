import { styled } from "@mui/system";
import { Button, ButtonProps } from "@mui/material";
import { hColorful } from "../../theme";

export const useHeaderBarDesktopStyle = {
  headerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "72%",
    backgroundColor: "common.white",
    height: "80px",
    borderRadius: "50px",
    paddingInline: 21,
  },
  logoImg: {
    height: "52px",
    cursor: "pointer",
  },
  menuLabel: {
    cursor: "pointer",
  },
};

export const useHeaderBarMobileStyle = {
  drawerBox: {
    width: [240, 320, 320],
  },
  logoImg: {
    width: "96px",
    objectFit: "contain" as any,
  },
  menuList: {
    marginBottom: 14,
  },
  menuListItem: {
    marginBottom: 2,
    borderRadius: "20px",
  },
  signInListItemText: {
    background: `linear-gradient(296.45deg, ${hColorful[100]} -3.27%, ${hColorful[200]} 102.18%)`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  menuAbsolute: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute" as any,
    width: "-webkit-fill-available",
    "@supports (-moz-appearance:none)": {
      width: "-moz-available",
    },
    paddingInline: [6, 4, 9, 14],
    paddingTop: 5,
  },
  menuIconButton: {
    fontSize: [20, 28],
  },
};

export const ButtonSignIn = styled(Button)<ButtonProps>(({ theme }) => ({
  border: "solid 2px transparent",
  borderRadius: "30px",
  width: "126px",
  minHeight: "42px",
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(296.45deg, ${hColorful[100]}, ${hColorful[200]})`,
  backgroundOrigin: "border-box",
  backgroundClip: "content-box, border-box",
  boxShadow: "2px 1000px 1px #fff inset",
  "& > p": {
    background: `-webkit-linear-gradient(296.45deg, ${hColorful[200]}, ${hColorful[100]})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "&:hover": {
    border: "solid 2px transparent",
    boxShadow: "none",
    color: theme.palette.common.white,
    "& > p": {
      background: "unset",
      WebkitBackgroundClip: "unset",
      WebkitTextFillColor: "unset",
    },
  },
}));
