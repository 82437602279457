import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { hNavyBlue } from "../../theme";
import useBreakpoint from "../../utils/hooks/useBreakpoint";
import { setValueByBreakpoint } from "../../utils/helpers/setValueByBreakpoint.helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useHomePricingPackageCardStyle as classes,
} from "./HomePricing.style";

function Faq() {
  const bp = useBreakpoint();
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const items = [
    {
      title: "ทำไมต้องใช้ Influencer Marketing ?",
      detail: `Influencer Marketing ถือเป็นการตลาดที่ช่วยสร้างภาพลักษณ์ และความน่าเชื่อถือให้กับแบรนด์ของคุณ 
        อีกทั้งสร้างความไว้วางใจ และความภักดีของผู้บริโภคจาก Influencer ในโซเชียลมีเดีย<br/>
        ซึ่งการใช้ Influencer ในการทำการตลาดโดย ”การรีวิว หรือ พลังแห่งการบอกต่อ” จะช่วยโน้มน้าวใจ 
        เพิ่มความน่าเชื่อถือให้กับผู้บริโภค และสร้างปฏิสัมพันธ์ด้วยคอนเทนต์ครีเอทีฟที่ตรงใจกลุ่มเป้าหมาย 
        อีกทั้งช่วยเพิ่มทราฟฟิก และการมีส่วนร่วมของลูกค้ากับแบรนด์ ที่สามารถนำไปสู่การสร้างยอดขายได้`,
    },
    {
      title: "ทำไมต้องเลือกแพลตฟอร์ม HashU ?",
      detail: `“HashU ที่จะสร้าง “พลังแห่งการบอกต่อ” ให้คำพูดเล็ก ๆ มีความหมายยิ่งใหญ่ในทุกพื้นที่” 
      ระบบ filter และคัดแยกจัดหมวดหมู่ของ Influencers แบบละเอียด และลงลึก ไม่ใช่แต่เพศ อายุ 
      และไลฟ์สไตล์ แต่ยังเปิดกว้างให้กับความสนใจใหม่อาทิ เช่น การลงทุน สัตว์เลี้ยง หรือแม้แต่ Influencer ผู้สูงอายุ<br/>  
      และยังสามารถสร้างแคมเปญง่าย ๆ พร้อมการคาดการณ์ผลลัพธ์ก่อนการสร้างแคมเปญจริง 
      เพื่อแคมเปญที่มีประสิทธิภาพ และหลังการจบแคมเปญระบบจะรายงาน 
      ผลลัพธ์ที่เที่ยงตรง และแม่นยำเพื่อการพัฒนาแคมเปญในครั้งต่อไป`,
    },
    {
      title: "แพลตฟอร์มเราสร้างแคมเปญ ให้ตอบโจทย์ความต้องการของคุณได้อย่างไร ?",
      detail: `คุณสามารถเลือก Package ที่เหมาะสมกับแคมเปญ และธุรกิจของคุณ 
      เริ่มสร้างแคมเปญโดยเลือกระดับ Influencer Tier ของคุณ หรือความสนใจพิเศษได้เลย !!!`,
    },
  ];

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Card sx={{ paddingY: "25px", boxShadow: 0 }}>
      <CardHeader
        sx={{ marginTop: 6 }}
        title={
          <Typography
            textAlign="center"
            variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
            fontWeight="medium"
          >
            FAQ
          </Typography>
        }
      />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box width={["95%", "90%", "80%", "70%"]} pb={16}>
          {items.map((item, idx) => {
            return (
              <Accordion
                key={idx}
                expanded={expanded === `panel${idx}`}
                onChange={handleChange(`panel${idx}`)}
                sx={{
                  color:
                    expanded === `panel${idx}`
                      ? `${hNavyBlue[100]}`
                      : "common.black",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      fontSize="large"
                      sx={{
                        color:
                          expanded === `panel${idx}`
                            ? `${hNavyBlue[100]}`
                            : "common.black",
                      }}
                    />
                  }
                  sx={classes.faqAccordionSummary}
                  aria-controls={`panel${idx}bh-content`}
                  id={`panel${idx}bh-header`}
                >
                  <Typography
                    variant={setValueByBreakpoint(
                      bp,
                      "body1",
                      "body1",
                      "body1",
                      "h6"
                    )}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item.detail.split("<br/>").map((detail, i) => (
                    <Typography
                      key={i}
                      variant={setValueByBreakpoint(
                        bp,
                        "body2",
                        "body2",
                        "body2",
                        "body1"
                      )}
                      mt={4}
                    >
                      {detail}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
}

export default Faq;
