import { hGrey, hPink, hWhite, hPurple } from "../../theme";
export const expenseStyles = {
  expenseContainer: {
    margin: {
      xs: "80px 20px 20px 20px ",
      sm: "80px 20px 20px 110px",
    },
  },
  expenseBox: {
    borderRadius: ["none", , "15px"],
    backgroundColor: ["none", , "#fff"],
    boxShadow: ["none", , "0px 4px 8px rgba(0, 0, 0, 0.1)"],
  },
  pagination: {
    "& .MuiTablePagination-displayedRows, .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-select, .MuiInputBase-root": {
      border: "1.5px solid #9E9E9E",
      borderRadius: "50px",
      width: "80px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiInputBase-root": {
      border: 0,
    },
    "& svg:first-child": {
      width: ["25px", "25px", "25px"],
      height: ["25px", "25px", "25px"],
      borderRadius: "50%",
      border: "1.5px solid #9E9E9E",
      "&:hover": {
        color: hWhite[100],
        backgroundColor: hPink[300],
        border: "none",
      },
    },
  },
};
