import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
  Grid,
} from "@mui/material";
import WrapInputWithLabel from "../global/wrap-input-label";
import TextFieldCustom from "../global/text-field";
import { Controller } from "react-hook-form";
import BasicSelectCustom from "../global/basic-select";
import { numberWithCommas } from "../../utils/helpers/numberWithCommas";
import RangeInput from "../global/range-input";
import React from "react";
import { onlyNumber } from "../../utils/helpers/onlyNumber";

function Target({ form }: any) {
  const {
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    control,
    setError,

    getValues,
  } = form;

  const styles = {
    cardContainer: {
      margin: "8px",
    },
  };

  const onHandleChange = (value: string | undefined, keyName: string) => {
    // const { value } = e.target;
    setValue(keyName, value);
    clearErrors(keyName);
  };

  const onHandleMinRange = (
    e: React.BaseSyntheticEvent,
    keyNameMax: string,
    keyNameMin: string
  ) => {
    let value = e.target.value.replaceAll(",", "");

    if (watch(keyNameMax) && +value > +watch(keyNameMax)) {
      value = watch(keyNameMax);
    } else {
      value = e.target.value;
    }

    onHandleChange(value, keyNameMin);
  };

  const onHandleMaxRange = (
    e: React.BaseSyntheticEvent,
    keyNameMax: string,
    keyNameMin: string
  ) => {
    let value = e.target.value.replaceAll(",", "");

    if (watch(keyNameMin) && +value < +watch(keyNameMin)) {
      value = watch(keyNameMin);
    } else {
      value = e.target.value;
    }

    onHandleChange(value, keyNameMax);
  };

  const onHandleMinRangeWithCommas = (
    e: React.BaseSyntheticEvent,
    keyNameMax: string,
    keyNameMin: string
  ) => {
    let value = onlyNumber(e.target.value ?? 0);
    let maxValue = onlyNumber(watch(keyNameMax));

    if (maxValue && +value > +maxValue) {
      value = maxValue;
    } else {
      value = e.target.value;
    }

    onHandleChange(numberWithCommas(value), keyNameMin);
  };
  const onHandleMaxRangeWithCommas = (
    e: React.BaseSyntheticEvent,
    keyNameMax: string,
    keyNameMin: string
  ) => {
    let value = onlyNumber(e.target.value ?? 0);
    let minValue = onlyNumber(watch(keyNameMin));

    if (minValue && +value < +minValue) {
      value = minValue;
    } else {
      value = e.target.value;
    }

    onHandleChange(numberWithCommas(value), keyNameMax);
  };

  return (
    <Card sx={styles.cardContainer}>
      <CardHeader
        title={
          <Typography variant="h6" color="common.black" fontWeight="inherit">
            Target
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <WrapInputWithLabel title="Total Follower">
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              value={getValues("target.totalFollowers")}
              onChange={(e) => {
                const newNum = numberWithCommas(e.target.value)?.toString();

                onHandleChange(newNum, "target.totalFollowers");
              }}
              error={!!errors.target?.totalFollowers}
              helperText={errors.target?.totalFollowers?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Reach">
            <TextFieldCustom
              variant="outlined"
              fullWidth
              value={getValues("target.reach")}
              onChange={(e) => {
                const newNum = numberWithCommas(e.target.value)?.toString();

                onHandleChange(newNum, "target.reach");
              }}
              error={!!errors.target?.reach}
              helperText={errors.target?.reach?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">View</InputAdornment>
                ),
              }}
            />
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
      <CardHeader
        title={
          <Typography variant="h6" color="common.black" fontWeight="500">
            Audience
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <WrapInputWithLabel title="Age">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextFieldCustom
                  value={getValues("audience.age.min")}
                  onChange={(e) =>
                    onHandleChange(e.target.value, "audience.age.min")
                  }
                  onBlur={(e) =>
                    onHandleMinRange(e, "audience.age.max", "audience.age.min")
                  }
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 1,
                    },
                  }}
                  fullWidth
                  error={!!errors.audience?.age?.min}
                  helperText={errors.audience?.age?.min?.message}
                />
              </Grid>
              <Grid
                item
                xs={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                -
              </Grid>
              <Grid item xs={5}>
                <TextFieldCustom
                  value={getValues("audience.age.max")}
                  onChange={(e) =>
                    onHandleChange(e.target.value, "audience.age.max")
                  }
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 1,
                    },
                  }}
                  onBlur={(e) =>
                    onHandleMaxRange(e, "audience.age.max", "audience.age.min")
                  }
                  fullWidth
                  error={!!errors.audience?.age?.max}
                  helperText={errors.audience?.age?.max?.message}
                />
              </Grid>
            </Grid>
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Gender">
            <BasicSelectCustom
              id="audience-gender-select"
              value={watch("audience.gender")}
              onChange={(e: any) => {
                onHandleChange(e.target.value, "audience.gender");
              }}
              data={[]}
              valueKey="id"
              labelKey="name_en"
              placeholder="Please select"
              fullWidth
              error={!!errors.audience?.gender}
              helperText={(errors.audience?.gender as any)?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Location">
            <BasicSelectCustom
              id="audience-location-select"
              value={watch("audience.location")}
              onChange={(e: any) => {
                onHandleChange(e.target.value, "audience.location");
              }}
              data={[]}
              valueKey="id"
              labelKey="name_en"
              placeholder="Please select"
              fullWidth
              error={!!errors.audience?.location}
              helperText={(errors.audience?.location as any)?.message}
            />
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
      <CardHeader
        title={
          <Typography variant="h6" color="common.black" fontWeight="500">
            Influencer
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <WrapInputWithLabel title="Followers">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextFieldCustom
                  value={watch("influencer.followers.min")}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    const newNum = numberWithCommas(e.target.value);

                    onHandleChange(newNum, "influencer.followers.min");
                  }}
                  onBlur={(e) => {
                    onHandleMinRangeWithCommas(
                      e,
                      "influencer.followers.max",
                      "influencer.followers.min"
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.influencer?.age?.min}
                  helperText={errors.influencer?.age?.min?.message}
                />
              </Grid>
              <Grid
                item
                xs={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                -
              </Grid>
              <Grid item xs={5}>
                <TextFieldCustom
                  value={watch("influencer.followers.max")}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    // let ageValue = e.target.value;
                    const newNum = numberWithCommas(e.target.value);

                    onHandleChange(newNum, "influencer.followers.max");
                  }}
                  onBlur={(e) => {
                    onHandleMaxRangeWithCommas(
                      e,
                      "influencer.followers.max",
                      "influencer.followers.min"
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.influencer?.age?.max}
                  helperText={errors.influencer?.age?.max?.message}
                />
              </Grid>
            </Grid>

            {/* <RangeInput
              setError={setError}
              clearErrors={clearErrors}
              startKeyName="influencer.followers.min"
              endKeyName="influencer.followers.max"
              Controller={Controller}
              control={control}
              errors={errors}
              watch={watch}
            /> */}
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Age">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextFieldCustom
                  value={watch("influencer.age.min")}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    onHandleChange(e.target.value, "influencer.age.min");
                  }}
                  onBlur={(e) =>
                    onHandleMinRange(
                      e,
                      "influencer.age.max",
                      "influencer.age.min"
                    )
                  }
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 1,
                    },
                  }}
                  fullWidth
                  error={!!errors.influencer?.age?.min}
                  helperText={errors.influencer?.age?.min?.message}
                />
              </Grid>
              <Grid
                item
                xs={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                -
              </Grid>
              <Grid item xs={5}>
                <TextFieldCustom
                  value={watch("influencer.age.max")}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    let ageValue = e.target.value;

                    onHandleChange(ageValue, "influencer.age.max");
                  }}
                  onBlur={(e) =>
                    onHandleMaxRange(
                      e,
                      "influencer.age.max",
                      "influencer.age.min"
                    )
                  }
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 1,
                    },
                  }}
                  fullWidth
                  error={!!errors.influencer?.age?.max}
                  helperText={errors.influencer?.age?.max?.message}
                />
              </Grid>
            </Grid>
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Gender">
            <BasicSelectCustom
              id="influencer-gender-select"
              value={watch("influencer.gender")}
              onChange={(e: any) => {
                onHandleChange(e.target.value, "influencer.gender");
              }}
              data={[]}
              valueKey="id"
              labelKey="name_en"
              placeholder="Please select"
              fullWidth
              error={!!errors.influencer?.gender}
              helperText={(errors.influencer?.gender as any)?.message}
            />
          </WrapInputWithLabel>

          <WrapInputWithLabel title="Location">
            <BasicSelectCustom
              id="influencer-location-select"
              value={watch("influencer.location")}
              onChange={(e: any) => {
                onHandleChange(e.target.value, "influencer.location");
              }}
              data={[]}
              valueKey="id"
              labelKey="name_en"
              placeholder="Please select"
              fullWidth
              error={!!errors.influencer?.location}
              helperText={(errors.influencer?.location as any)?.message}
            />
          </WrapInputWithLabel>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Target;
