import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";

interface WrapInputWithLabelProps {
  title: any;
  children: any;
  [any: string]: any;
  subTitle?: string;
}

function WrapInputWithLabel(props: WrapInputWithLabelProps) {
  const {
    title,
    children,
    gridRatio,
    tooltips,
    required,
    labelWidth,
    alignItems,
    subTitle,
  } = props;

  const styles = {
    container: {
      display: "flex",
      // alignItems: alignItems ? alignItems : "center",
      marginBottom: "10px",
    },
    label: {
      minWidth: "80px",
      width: labelWidth ? labelWidth : "150px",
    },
  };

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={3} sx={styles.label}>
        <Typography
          variant="body1"
          fontWeight="400"
          fontSize={14}
          display={"flex"}
          mt={subTitle ? 0 : 2.5}
        >
          <Box display="flex" flexDirection="column">
            <Typography fontSize="14px" display="flex">
              {title}
              {required && <span className="requir">*</span>}
            </Typography>
            {subTitle && (
              <Typography fontSize="12px" color="#5C5C5C">
                {`(${subTitle})`}
              </Typography>
            )}
          </Box>
          {required ? (
            <Box display="flex" alignItems="end">
              <Typography variant="body1" fontWeight="400"></Typography>
              {/* <span> :</span> */}
            </Box>
          ) : (
            "  "
          )}{" "}
          <Box display="flex" alignItems="end">
            {tooltips ? (
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.white",
                      },
                      color: "common.black",
                      width: 500,
                      p: 3,
                    },
                  },
                }}
                title={tooltips}
              >
                <span>
                  <img
                    src="/assets/images/info.png"
                    style={{
                      marginLeft: "5px",
                      width: "15px",
                      height: "15px",
                      objectFit: "contain",
                    }}
                  />
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </Box>
        </Typography>
      </Grid>
      <Grid xs={9} width="100%">
        {children}
      </Grid>
    </Grid>
  );
}

export default WrapInputWithLabel;

{
  /* <Grid container pb={3}>
<Grid item xs={12} sm={12} md={4} lg={4} xl={3} mt={[0, 0, 2]}>
  <Typography variant="body2">
    {title}
    <span className="requir">*</span> :
  </Typography>
</Grid>
<Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
  {children}
</Grid>
</Grid> */
}
