const API_URL = process.env.REACT_APP_API_URL ?? "";
const MOCK_API_URL = process.env.REACT_APP_MOCK_API_URL ?? "";
const API_KEY = process.env.REACT_APP_API_KEY ?? "";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? "";
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET ?? "";
const JWT_SECRET = process.env.REACT_APP_JWT_SECRET_KEY ?? "";
const API_SOCIAL_URL = process.env.REACT_APP_API_SOCIAL_URL ?? "";
const SOCIAL_API_KEY = process.env.REACT_APP_SOCIAL_API_KEY ?? "";

export {
  API_URL,
  MOCK_API_URL,
  API_KEY,
  CLIENT_ID,
  CLIENT_SECRET,
  JWT_SECRET,
  API_SOCIAL_URL,
  SOCIAL_API_KEY,
};
