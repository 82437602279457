import React from "react";
import { Box, Typography } from "@mui/material";
import NavTabs from "../nav-tab/NavTabs";
import { useHistory } from "react-router-dom";

function TitleTabs({ title, subTitle, tabsMenu, backBtn }: any) {
  const history = useHistory();
  const { pathname } = history.location;

  const presentMenu = tabsMenu.find((item: any) => item.href === pathname);
  const isExistingTab = Boolean(presentMenu);

  const styles = {
    tab: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    container: {
      padding:
        pathname == "/campaign" ||
        pathname == "/job" ||
        pathname == "/influencer-list"
          ? `100px 28px 0px 120px`
          : "",
    },
    backContent: {
      cursor: "pointer",
      display: "inline",
      fontWeight: "500",
    },
  };

  const displayBackBtn = (
    <Box sx={{ marginBottom: 2 }}>
      <Typography
        variant="subtitle2"
        color="common.black"
        style={styles.backContent}
        onClick={() => {
          history.goBack();
        }}
      >
        <i className="ico-hu-chevron-left" style={{ marginRight: "4px" }} />
        Back
      </Typography>
    </Box>
  );

  return (
    <div style={styles.container}>
      {backBtn ? displayBackBtn : ""}
      <Box sx={styles.tab}>
        <Box>
          <Typography variant="h5" color="common.black" fontWeight="500">
            {title}
          </Typography>
          <Typography variant="body1" color="common.black" fontWeight="400">
            {subTitle}
          </Typography>
        </Box>
        <Box>{isExistingTab ? <NavTabs menu={tabsMenu} /> : ""}</Box>
      </Box>
    </div>
  );
}

export default TitleTabs;
