import React, { useState } from "react";
// Component
import CreateAccountDialog from "../authentication-dialog/CreateAccountDialog";
// Material
import { Box, Grid, Icon, Link, Stack, Typography } from "@mui/material";
// Utils
import { setValueByBreakpoint } from "../../utils/helpers/setValueByBreakpoint.helpers";
// hooks
import useBreakpoint from "../../utils/hooks/useBreakpoint";
// Types
import { IBreakpoint } from "../../views/global/global.types";
// Styles
import { ButtonLine, ButtonTrial } from "./HomeFooter.styles";
import { useFooterStyle as classes } from "./HomeFooter.styles";
// Images
import HashuWhite from "./../../assets/images/logo/HashU logo 1.svg";

function SocialContact({ bp }: { bp: IBreakpoint }) {
  return (
    <Box
      display="flex"
      justifyContent={["center", "space-around"]}
      alignItems="center"
      gap={[8, 2, 2, 4]}
      mt={[10, 0]}
    >
      <Icon
        className="ico-hu-facebook-solid"
        onClick={() => {
          window.open("https://www.facebook.com/HashU.co");
        }}
        sx={{
          color: "common.white",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
        }}
      />
      <Icon
        className="ico-hu-ig-solid"
        onClick={() => {
          window.open("https://www.instagram.com/?hl=en");
        }}
        sx={{
          color: "common.white",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
        }}
      />
      <Icon
        className="ico-hu-twitter-solid"
        onClick={() => {
          window.open("https://twitter.com/home?lang=th");
        }}
        sx={{
          color: "common.white",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
        }}
      />
      <Icon
        className="ico-hu-youtube-solid"
        onClick={() => {
          window.open("https://www.youtube.com/");
        }}
        sx={{
          color: "common.white",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
        }}
      />
    </Box>
  );
}

function HomeFooter() {
  const bp = useBreakpoint();
  const [isOpenCreateAccount, setIsOpenCreateAccount] =
    useState<boolean>(false);

  return (
    <Box sx={classes.mainBox}>
      <Box px={[5, 5, 10, 30]} pt={10} pb={2}>
        <Grid container>
          <Grid item xs={12} sm={3} md={3} lg={5}>
            <Stack width={["auto", "fit-content"]}>
              <Box
                textAlign={setValueByBreakpoint(bp, "center", "left")}
                mb={[8, 4]}
              >
                <img
                  src={HashuWhite}
                  alt="Hashu White"
                  style={{
                    width: "auto",
                    height: setValueByBreakpoint(
                      bp,
                      "96px",
                      "120px",
                      "120px",
                      "140px"
                    ),
                  }}
                />
              </Box>
              {bp !== "xs" && <SocialContact bp={bp} />}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Stack gap={3} mb={[14, 0]}>
              <Typography
                variant={setValueByBreakpoint(bp, "h5", null, null, "h4")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                fontWeight={setValueByBreakpoint(bp, "bold", "normal")}
                mb={2}
              >
                Explore
              </Typography>
              <Link href="/home/influencer" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Influencer
                </Typography>
              </Link>
              <Link href="/home/marketer" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Marketer
                </Typography>
              </Link>
              <Link href="/home/pricing" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Pricing
                </Typography>
              </Link>
              <Link href="/home/contact" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Contact Us
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack gap={3} mb={[14, 0]}>
              <Typography
                variant={setValueByBreakpoint(bp, "h5", null, null, "h4")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                fontWeight={setValueByBreakpoint(bp, "bold", "normal")}
                mb={2}
              >
                สมัครทดลองใช้
              </Typography>
              <Typography
                variant={setValueByBreakpoint(bp, "body2", null, null, "body1")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                mb={[2, 3]}
              >
                แพลตฟอร์มตลาดงานรีวิวเสรี
                <br />
                หานักรีวิว หางาน ง่าย สะดวกรวดเร็ว
                <br />
                ทำการตลาดได้เองแบบมืออาชีพ
              </Typography>
              <Box textAlign={setValueByBreakpoint(bp, "center", "left")}>
                <ButtonTrial
                  variant="contained"
                  disableElevation
                  onClick={() => setIsOpenCreateAccount(true)}
                >
                  <Typography
                    variant="button"
                    color="secondary"
                    fontWeight="bold"
                  >
                    ทดลองใช้
                  </Typography>
                </ButtonTrial>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <Stack gap={3}>
              <Typography
                variant={setValueByBreakpoint(bp, "h5", null, null, "h4")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                fontWeight={setValueByBreakpoint(bp, "bold", "normal")}
                mb={2}
              >
                ติดต่อเรา
              </Typography>
              <Typography
                variant={setValueByBreakpoint(bp, "body2", null, null, "body1")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                mb={[2, 3]}
              >
                marketing@hashu.co
                <br />
                Tel. 092-455-2225
              </Typography>
              <Box textAlign={setValueByBreakpoint(bp, "center", "left")}>
                <ButtonLine
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    window.open("https://lin.ee/dez0phm");
                  }}
                >
                  <Typography
                    variant="button"
                    color="common.white"
                    fontWeight="bold"
                  >
                    LINE Office
                  </Typography>
                </ButtonLine>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        {bp === "xs" && <SocialContact bp={bp} />}
        <Box mt={[10, 6, 6, 3]} mb={3}>
          <Typography
            component="p"
            variant={setValueByBreakpoint(
              bp,
              "caption",
              "caption",
              "caption",
              "body2"
            )}
            color="common.white"
            align={setValueByBreakpoint(bp, "center", "left")}
          >
            Copyright &copy; 2022 Hashu All Rights Reserved.
          </Typography>
        </Box>
      </Box>

      {/* -- UAT comment here -- */}
      <CreateAccountDialog
        isOpened={isOpenCreateAccount}
        setOpen={setIsOpenCreateAccount}
        accountSelected={"marketer"}
      />
      {/* -- End comment -- */}
    </Box>
  );
}

export default HomeFooter;
