import React from "react";
// Component
import DialogScrollingCustom from "../../global/dialog-scrolling-custom";
import { BankAccountSection } from "./BankAccountSection";
// Types
import {
  IBankAccountRes,
  IEditBankAccountDialogProps,
} from "../InfluencerBankAccount.types";
// Utils
import { useEditBankAccountDialogStyle as classes } from "../InfluencerBankAccount.styles";
//  DayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

const EditBankAccountDialog = React.memo(
  (props: IEditBankAccountDialogProps) => {
    const { data, isOpened, onClose, onSubmitBankAccount } = props;

    const handleClose = () => {
      onClose();
    };

    const bankAccountSubmitHandler = async (value: IBankAccountRes) => {
      onSubmitBankAccount(value);
    };
    return (
      <React.Fragment>
        <DialogScrollingCustom
          title="Bank Account"
          open={isOpened}
          onClose={handleClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={false}
          sx={classes.dialog}
        >
          <BankAccountSection
            bankAccount={data !== null ? data.bank_account : null}
            bankAccountSubmitHandler={bankAccountSubmitHandler}
            closeDialog={handleClose}
          />
        </DialogScrollingCustom>
      </React.Fragment>
    );
  }
);

export default EditBankAccountDialog;
