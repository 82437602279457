import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategories } from "../../api/influencer/InfluencerAPI";
import { RootState } from "../../store/store";
import { ICategoryState } from "./CategoryType";

const initialState: ICategoryState = {
  categoryList: [],
  loading: false,
  error: "",
};

export const getCategoryAsync = createAsyncThunk("categoryList", async () => {
  try {
    const response = await getCategories();
    return response;
  } catch (err) {
    console.log("catch", err);
    throw err;
  }
});

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categoryList: (state, { payload }) => {
      state.categoryList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCategoryAsync.fulfilled, (state, action) => {
        state.categoryList = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getCategoryAsync.rejected, (state, action) => {
        state.categoryList = [];
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const selectCategory = (state: RootState) => state.categoryList;

export default categorySlice.reducer;
