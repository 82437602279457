import axios from "axios";
// Types
import {
  // IInfluencerRes,
  IVertifyCodeRes,
  IVertifyCodeBody,
  IInfluencerSocialRes,
  IInfluencerUpdateAPI,
  IProfileUpload,
  IShippingAddressAPI,
  IBillingAddressAPI,
  ICategoryRes,
  IInfluencerMyPortfolioApi,
  IInfluencerRes,
} from "./Types";
import { IAddressAPI } from "../appInformation/appInformation.types";
// Helper
import { getTokenFromStorage } from "./../../utils/helpers/getTokenFromStorage";
// Constans
import {
  API_URL,
  SOCIAL_API_KEY,
  API_SOCIAL_URL,
} from "../../utils/constants/api.constants";
// import { instance } from "../global/instance";

export function updateInfluencerCategory(body: any) {
  return new Promise((resolve: (value: any) => void, reject) => {
    const { access_token, session_token } = getTokenFromStorage();
    const header = { Authorization: `Bearer ${access_token}` };
    const data: any = {
      social_account_id: body.social_account_id,
      social_account_category: [],
    };
    body.social_account_category.map((item: any) =>
      data.social_account_category.push({
        platform: "tiktok",
        category_id: item.category_id,
      })
    );

    axios
      .patch(
        `${API_URL}/influencers/${session_token?.influencer_id}/socials`,
        data,
        {
          headers: header,
        }
      )
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCategories() {
  return new Promise((resolve: (value: ICategoryRes[]) => void, reject) => {
    const { access_token } = getTokenFromStorage();
    const header = { Authorization: `Bearer ${access_token}` };
    axios
      .get(`${API_URL}/info/categories?search=`, { headers: header })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInfluencerSocial() {
  return new Promise(
    (resolve: (value: IInfluencerSocialRes) => void, reject) => {
      const { access_token, session_token } = getTokenFromStorage();
      const header = { Authorization: `Bearer ${access_token}` };
      axios
        .get(
          `${API_URL}/influencers/${session_token?.influencer_id}?grant_type=social`,
          {
            headers: header,
          }
        )
        .then((response) => {
          const data = response.data;
          if (data.status_code === 200) {
            resolve(data.result.data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
}

// export async function getInfluencer() {
//   const { access_token, session_token } = getTokenFromStorage();
//   const header = { Authorization: `Bearer ${access_token}` };
//   const { data } = await instance.get(
//     `/influencers/${session_token?.influencer_id}?grant_type=profile`,
//     {
//       headers: header,
//     }
//   );

//   return data.result.data;
// }

// return new Promise((resolve: (value: IInfluencerRes) => void, reject) => {
//   const { access_token, session_token } = getTokenFromStorage();
//   const header = { Authorization: `Bearer ${access_token}` };
//   axios
//     .get(
//       `${API_URL}/influencers/${session_token?.influencer_id}?grant_type=profile`,
//       {
//         headers: header,
//       }
//     )
//     .then((response) => {
//       let data = response.data;
//       if (data.status_code === 200) {
//         resolve(data.result.data);
//       } else {
//         reject("Something when wrong");
//       }
//     })
//     .catch((error) => {
//       reject(error);
//     });
// });
// }

export function getInfluencer() {
  return new Promise((resolve: (value: IInfluencerRes) => void, reject) => {
    const { access_token, session_token } = getTokenFromStorage();
    const header = { Authorization: `Bearer ${access_token}` };
    axios
      .get(
        `${API_URL}/influencers/${session_token?.influencer_id}?grant_type=profile`,
        {
          headers: header,
        }
      )
      .then((response) => {
        let data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject("Something when wrong");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getVertifyCode(body: IVertifyCodeBody) {
  return new Promise((resolve: (value: IVertifyCodeRes) => void, reject) => {
    const header = {
      "X-API-KEY": SOCIAL_API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    axios
      .post(
        `${API_SOCIAL_URL}/tiktok/verify-code`,
        new URLSearchParams({
          influencer_id: body.influencer_id,
          url: body.url,
        }),
        {
          headers: header,
        }
      )
      .then((response) => {
        const data = response.data;

        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function verifyAccount(body: any) {
  return new Promise((resolve: (value: any) => void, reject) => {
    const header = {
      "X-API-Key": SOCIAL_API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    axios
      .post(
        `${API_SOCIAL_URL}/tiktok/verify-account`,
        new URLSearchParams({
          influencer_id: body.influencer_id,
          username: body.username,
        }),
        {
          headers: header,
        }
      )
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function uploadImage(data: IProfileUpload) {
  return new Promise((resolve: (value: any) => void, reject) => {
    const { access_token } = getTokenFromStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("destination", data.destination);
    formData.append("permission", data.permission);
    axios
      .post(`${API_URL}/files/upload`, formData, config)
      .then((response) => {
        resolve(response.data);
        console.log("Data", response.data);
      })
      .catch((error) => {
        reject(error.response.data);
        console.log(error.response.data);
      });
  });
}

export function getAddressLists() {
  return new Promise((resolve: (value: IAddressAPI) => void, reject) => {
    const { access_token } = getTokenFromStorage();
    const header = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(`${API_URL}/info/all`, { headers: header })
      .then((response) => {
        const data = response.data;
        if (data.status_code === 200) {
          resolve(data.result.data);
        }
      })
      .catch((error: any) => {
        console.log("error");
      });
  });
}

export function editInfluencerProfileAPI(data: IInfluencerUpdateAPI) {
  return new Promise((resolve: (value: unknown) => void, reject) => {
    const { access_token, session_token } = getTokenFromStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .patch(
        `${API_URL}/influencers/${session_token?.influencer_id}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          const result = response.data.result;
          resolve(result);
          console.log("success", result.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function editInfluencerShippingAPI(data: IShippingAddressAPI) {
  return new Promise((resolve: (value: unknown) => void, rejects) => {
    const { access_token, session_token } = getTokenFromStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .patch(
        `${API_URL}/influencers/${session_token?.influencer_id}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          const result = response.data.result;
          resolve(result);
          console.log("Edit Shipping Address Success");
        } else {
          rejects(response.data);
        }
      })
      .catch((error) => {
        rejects(error.response.data);
      });
  });
}

export function editInfluencerBillingAPI(data: IBillingAddressAPI) {
  return new Promise((resolve: (value: unknown) => void, rejects) => {
    const { access_token, session_token } = getTokenFromStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .patch(
        `${API_URL}/influencers/${session_token?.influencer_id}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          const result = response.data.result;
          resolve(result);
          console.log("Edit Billing Address Success");
        } else {
          rejects(response.data);
        }
      })
      .catch((error) => {
        rejects(error.response.data);
      });
  });
}

export function editInfluencerMyPortfolio(data: {
  portfolio: IInfluencerMyPortfolioApi[];
}) {
  return new Promise((resolve: (value: unknown) => void, rejects) => {
    const { access_token, session_token } = getTokenFromStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .patch(
        `${API_URL}/influencers/${session_token?.influencer_id}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          const result = response.data.result;
          resolve(result);
          console.log("Edit My Portfolio Success");
        } else {
          rejects(response.data);
        }
      })
      .catch((error) => {
        rejects(error.response.data);
      });
  });
}
