import React, { useState } from "react";
// Utils
import { IInfluencerAddressProps } from "./InfluencerAddress.types";
import { useInfluencerAddressStyle as classes } from "./InfluencerAddress.styles";
// Material
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
} from "@mui/material";
// Icons
// import EditIcon from "@mui/icons-material/Edit";
import {
  AddressShippingIcon,
  AddressBillingIcon,
  EditSolidIcon,
} from "../../assets/icons/social/social";
// Globall
import { formatPhoneNumber, formatTaxId } from "../global/format-number";

function InfluencerAddress(props: IInfluencerAddressProps) {
  const { address, openEditAddressDialog, openEditBilling } = props;

  console.log("address", address);
  return (
    <Card sx={classes.addressCard} elevation={4}>
      <CardHeader
        sx={classes.addressCardHeader}
        title={
          <Typography variant="body1" color="common.white" fontWeight="inherit">
            Address
          </Typography>
        }
      />

      <CardContent sx={classes.cardContent}>
        <Box>
          <Box pt={4} pb={5}>
            <Box display="flex" justifyContent="space-between">
              <Box sx={classes.addressIconBox}>
                <IconButton aria-label="save" color="primary" size="small">
                  <AddressShippingIcon fontSize="inherit" />
                </IconButton>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Shipping Address
                </Typography>
              </Box>
              <Box>
                <IconButton
                  aria-label="save"
                  color="primary"
                  size="small"
                  sx={classes.rateCardEditIcon}
                  onClick={openEditAddressDialog}
                >
                  <EditSolidIcon sx={{ fontSize: "15px" }} />
                </IconButton>
              </Box>
            </Box>
            {address !== null && address.shipping_address ? (
              <Box sx={classes.addressDetailBox}>
                <Typography variant="body1" color={"common.black"}>
                  {address.shipping_address.fullname &&
                    `${address.shipping_address.fullname} , `}
                  {address.shipping_address.phone &&
                    `${formatPhoneNumber(address.shipping_address.phone)} ,`}
                </Typography>
                <Typography variant="body1" color={"common.black"}>
                  {address.shipping_address.address &&
                    `${address.shipping_address.address}, 
                  ${address.shipping_address.subdistrict?.name_th}, 
                  ${address.shipping_address.district?.name_th}, 
                  ${address.shipping_address.province?.name_th}, 
                  ${address.shipping_address.postcode}`}
                </Typography>
              </Box>
            ) : (
              <Box sx={classes.addressDetailBox}>
                <Typography variant="body1" color={"text.secondary"}>
                  + Add Shipping Address
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <hr style={classes.line} />

        <Box pt={4} pb={5}>
          <Box display="flex" justifyContent="space-between">
            <Box sx={classes.addressIconBox}>
              <IconButton aria-label="save" color="primary" size="small">
                <AddressBillingIcon fontSize="inherit" />
              </IconButton>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Billing Address
              </Typography>
            </Box>
            <Box>
              <IconButton
                aria-label="save"
                color="primary"
                size="small"
                sx={classes.rateCardEditIcon}
                onClick={openEditBilling}
              >
                <EditSolidIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          </Box>
          {address !== null && address.shipping_address ? (
            <Box sx={classes.addressDetailBox}>
              <Typography variant="body1" color={"common.black"}>
                {address.billing_address.fullname &&
                  `${address.billing_address.fullname} , `}
                {address.billing_address.tax_id &&
                  `${formatTaxId(address.billing_address.tax_id)} ,`}
              </Typography>
              <Typography variant="body1" color={"common.black"}>
                {address.billing_address.address &&
                  `${address.billing_address.address}, 
                ${address.billing_address.subdistrict?.name_th}, 
                ${address.billing_address.district?.name_th}, 
                ${address.billing_address.province?.name_th}, 
                ${address.billing_address.postcode}`}
              </Typography>
            </Box>
          ) : (
            <Box sx={classes.addressDetailBox}>
              <Typography variant="body1" color={"text.secondary"}>
                + Add Billing Address
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default InfluencerAddress;
