import React from "react";

function campaignsList() {
  return (
    <React.Fragment>
      <div className="container-main">
        <div>Campaigns List</div>
      </div>
    </React.Fragment>
  );
}

export default campaignsList;
