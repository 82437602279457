import React from "react";
import { IFacebookTabProps } from "../InfluencerSocial.types";
import DefaultTab from "./DefaultTab";
// import DetailTab from "./DetailTab";

import { useInfluencerSocialStyle as classes } from "../InfluencerSocial.styles";
import { Box } from "@mui/material";

export default function FacebookTab(props: IFacebookTabProps) {
  const { TabPanel, tabSelected, facebook, matches } = props;

  return (
    <TabPanel value={tabSelected} index={matches ? 1 : 0}>
      <Box sx={classes.tabPanelBox}>
        {facebook !== null ? <DefaultTab /> : <DefaultTab />}
      </Box>
    </TabPanel>
  );
}
