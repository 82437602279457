import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// MUI
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
// Helper
import { addDefaultImageSrc } from "../../../utils/helpers/addDefaultImageSrc.helpers";
// Components
import DialogScrollingCustom from "../../global/dialog-scrolling-custom";
import TextFieldCustom from "../../global/text-field";
import { SaveButton } from "../../../views/my-profile/MyProfile.styles";
import MultipleSelectCustom from "../../global/multiple-select";
import BasicSelectCustom from "../../global/basic-select";
// Style
import { useEditProfileDialogStyle as classes } from "../../influencer-social/myprofile-tab/MyProfileTab.style";
// Images
import DefaultImage from "../../../assets/images/other/image-empty.jpg";
// Icons
import CameraIcon from "@mui/icons-material/PhotoCamera";
// Type
import { ICategory } from "../../category-dialog/CategoryType";
import { ICreateEmployerFormSchema, IImageUrl } from "../Types";
import { IEmployerForm } from "./CreateEmployerForm";
// API
import { getCategories } from "../../../api/influencer/InfluencerAPI";
import { uploadImage } from "../../../api/appInformation";
// Schema
import { CreateEmployerFormSchema } from "./EmployerForm.Schema";
import { createEmployer } from "../../../api/Employer/employerAPI";
import SwalCustom from "../../global/sweet-alert-custom/SwalCustom";
import { hAlertColor } from "../../../theme";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import {
  getEmployerListAsync,
  selectPagination,
} from "../../../views/employer/EmployerSlice";
import { IEmployerListParams } from "../../../api/Employer/Types";

interface ICreateEmployerDialogProps {
  isOpened: boolean;
  onClose: () => void;
}

const initImage: IImageUrl = {
  file_id: "",
  file_name: "",
  file_type: "",
  url: "",
  purpose: "",
  description: "",
};

const initEmployerForm: IEmployerForm = {
  fullname: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  line_id: "",
  id: 0,
  name: "",
  size: "",
  type: "",
  category: [],
  attachment: initImage,
};

export default function CreateEmployerDialog(
  props: ICreateEmployerDialogProps
) {
  const {
    formState: { errors },
    setValue,
    getValues,
    register,
    handleSubmit,
    clearErrors,
  } = useForm<IEmployerForm>({
    resolver: yupResolver(CreateEmployerFormSchema),
    defaultValues: initEmployerForm,
  });
  const { isOpened, onClose } = props;
  const dispatch = useAppDispatch();
  const { perPage } = useAppSelector(selectPagination);

  const [optionCategory, setOptionCategory] = useState<ICategory[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categorySelected, setCategorySelected] = useState<any>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [imageName, setImageName] = useState<string>("");
  const [imageOrgLogo, setImageOrgLogo] = useState<IImageUrl>(initImage);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paginate, setPaginate] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rowsPerPage, setRowsPerPage] = useState<number>(perPage ? 5 : 5);
  const [employerParams, setEmployerParams] = useState<IEmployerListParams>({
    order_by: "organisation",
    sort: "ASC",
    item_per_page: rowsPerPage,
    page: paginate,
  });

  const optionBusinessSize = [
    {
      id: 1,
      value: "small",
      label: "Small Business",
    },
    {
      id: 2,
      value: "medium",
      label: "Medium Business",
    },
    {
      id: 3,
      value: "large",
      label: "Large Business",
    },
  ];
  const optionType = [
    {
      id: 1,
      value: "brand or company",
      label: "Brand or Company",
    },
    {
      id: 2,
      value: "agency",
      label: "Agency",
    },
    {
      id: 3,
      value: "individual",
      label: "Individual",
    },
    {
      id: 4,
      value: "enterpise",
      label: "Enterprise",
    },
    {
      id: 5,
      value: "other",
      label: "Other",
    },
  ];

  useEffect(() => {
    setEmployerParams({
      order_by: "organisation",
      sort: "ASC",
      item_per_page: rowsPerPage,
      page: paginate,
    });
  }, [rowsPerPage, paginate, setEmployerParams]);

  useEffect(() => {
    dispatch(getEmployerListAsync(employerParams));
  }, [employerParams, dispatch]);

  useEffect(() => {
    async function getResult() {
      const results = await getCategories();
      setOptionCategory(results);
    }
    getResult();
  }, []);

  const handleChange = (
    e: any,
    nameChange: keyof ICreateEmployerFormSchema
  ) => {
    setValue(nameChange, e.target.value);
    clearErrors(nameChange);
  };

  const handleChangeUploadImage = async (e: any) => {
    let data = {
      file: e.target.files[0],
      destination: "organisation",
      permission: "public",
    };
    const result = await uploadImage(data);
    console.log("result", result);

    if (result) {
      const imageData = {
        file_name: result.file_name,
        file_type: result.file_type,
        url: (result.public_url as string) || (result.private_url as string),
      };
      if (imageName === "imageOrgLogo") {
        setImageOrgLogo({
          ...imageOrgLogo,
          file_id: imageOrgLogo.file_id === "" ? "" : imageOrgLogo.file_id,
          purpose:
            imageOrgLogo.purpose === ""
              ? "organization_logo_image"
              : imageOrgLogo.purpose,
          ...imageData,
        });
      }
    }
  };

  const onSubmitCreateEmployer = async (data: IEmployerForm) => {
    const newData = data.category.map((item) => {
      return {
        id: item,
      };
    });

    try {
      const reqBody = {
        fullname: data.fullname,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        phone: data.phone,
        line_id: data.line_id,
        organisation: {
          id: data.id,
          name: data.name,
          size: data.size,
          type: data.type,
          attachment: imageOrgLogo,
          category: newData,
        },
      };
      const response = await createEmployer(reqBody);
      console.log("response employer", response);
      onClose();
      dispatch(getEmployerListAsync(employerParams));
    } catch (error: any) {
      const data = error.response.data;
      if (data.status_code === 400) {
        switch (data.error.message) {
          case "error, fullname format is invalid (format is xxx xxx or xxx xxx xxx)":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Full Name is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
        }
        switch (data.error.message) {
          case "error, length of category must be less than 3 and more than 0":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Category must be less than 3 and more than 0 !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
        }
      } else {
        switch (data.error.message) {
          case "error, can't insert a category":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Please insert a category !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, this email is already taken":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Email is already taken !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
        }
      }
    }
  };

  return (
    <DialogScrollingCustom
      open={isOpened}
      onClose={onClose}
      title="New Employer"
      sx={classes.dialog}
    >
      <form onSubmit={handleSubmit(onSubmitCreateEmployer)}>
        <DialogContent sx={classes.dialogContent}>
          <Box px={2} pt={1}>
            <Box display="flex" flexDirection="row">
              <Icon
                className="ico-hu-s-acc-business"
                sx={{ marginRight: "8px" }}
              />
              <Typography variant="body1" fontWeight="600">
                Personal Information
              </Typography>
            </Box>
            <Stack px={5} pt={4}>
              <Grid container pb={5}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body2">Organization Logo :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box sx={classes.profileContainerBox}>
                    <Box sx={classes.profileBox}>
                      {imageOrgLogo.url !== "" ? (
                        <img
                          src={imageOrgLogo.url}
                          alt="images"
                          onError={addDefaultImageSrc}
                        />
                      ) : (
                        <img
                          src={DefaultImage}
                          alt="images"
                          onError={addDefaultImageSrc}
                        />
                      )}
                      <Button
                        variant="contained"
                        component="label"
                        disableElevation
                        onChange={handleChangeUploadImage}
                        onClick={() => setImageName("imageOrgLogo")}
                        sx={classes.profileButton}
                      >
                        <input
                          hidden
                          name="imageName"
                          accept="image/*"
                          multiple
                          type="file"
                        />
                        <CameraIcon fontSize="inherit" />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Organization Name<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("name")}
                    onChange={(e) => handleChange(e, "name")}
                    type="text"
                    placeholder="Organization Name"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Full Name<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("fullname")}
                    onChange={(e) => handleChange(e, "fullname")}
                    type="text"
                    placeholder="Full Name"
                    fullWidth
                    error={!!errors.fullname}
                    helperText={errors.fullname?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Size<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <BasicSelectCustom
                    id="business-size-select"
                    value={getValues("size")}
                    {...register("size")}
                    onChange={(e) => handleChange(e, "size")}
                    data={optionBusinessSize}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select"
                    fullWidth
                    error={!!errors.size}
                    helperText={errors.size?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Type<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <BasicSelectCustom
                    id="type-select"
                    value={getValues("type")}
                    {...register("type")}
                    onChange={(e) => handleChange(e, "type")}
                    data={optionType}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select"
                    fullWidth
                    error={!!errors.type}
                    helperText={errors.type?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">
                      Categories<span className="requir">*</span> :
                    </Typography>
                    <Typography variant="caption" color="#5C5C5C">
                      (เลือกได้สูงสุด 3 ประเภท)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <MultipleSelectCustom
                    id="category-select"
                    value={getValues("category")}
                    setValue={categorySelected}
                    onChange={(e) => handleChange(e, "category")}
                    placeholder={"Select"}
                    data={optionCategory}
                    fullWidth
                    valueKey="id"
                    labelKey="name_en"
                    error={!!errors.category}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Divider />

            <Box display="flex" flexDirection="row" pt={4}>
              <Icon
                className="ico-hu-people-top-card"
                sx={{ marginRight: "8px" }}
              />
              <Typography variant="body1" fontWeight="600">
                Contact
              </Typography>
            </Box>
            <Stack px={5} pt={4}>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Email<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("email")}
                    onChange={(e) => handleChange(e, "email")}
                    type="text"
                    placeholder="Email"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="ico-hu-email-solid"
                            // sx={classes.iconInput}
                          />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Password<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("password")}
                    onChange={(e) => handleChange(e, "password")}
                    type={!!showPassword ? "text" : "password"}
                    placeholder="Password"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!!showPassword ? (
                              <Icon
                                className="ico-hu-visability-off-solid"
                                // sx={classes.iconButton}
                              />
                            ) : (
                              <Icon
                                className="ico-hu-visability-on-solid"
                                // sx={classes.iconButton}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Confirm Password<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("confirmPassword")}
                    onChange={(e) => handleChange(e, "confirmPassword")}
                    type={!!showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!!showPassword ? (
                              <Icon
                                className="ico-hu-visability-off-solid"
                                // sx={classes.iconButton}
                              />
                            ) : (
                              <Icon
                                className="ico-hu-visability-on-solid"
                                // sx={classes.iconButton}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">
                    Phone<span className="requir">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("phone")}
                    onChange={(e) => handleChange(e, "phone")}
                    type="text"
                    placeholder="Phone"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="body2">Line ID :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("line_id")}
                    onChange={(e) => handleChange(e, "line_id")}
                    type="text"
                    placeholder="Line ID"
                    fullWidth
                    error={!!errors.line_id}
                    helperText={errors.line_id?.message}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            marginTop: 3,
            marginBottom: 3,
          }}
        >
          <SaveButton
            variant="contained"
            type="submit"
            startIcon={<Icon className="ico-hu-save" />}
          >
            <Typography variant="button">Save</Typography>
          </SaveButton>
        </DialogActions>
      </form>
    </DialogScrollingCustom>
  );
}
