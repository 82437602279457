import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./carousel.css";
import { Carousel } from "react-responsive-carousel";

import { hGrey } from "../../../theme";

const getConfigurableProps = () => ({
  showArrows: false,
  showStatus: false,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: false,
  autoPlay: false,
  stopOnHover: false,
  swipeable: true,
  autoFocus: false,
  dynamicHeight: false,
});

const indicatorStyles: React.CSSProperties = {
  background: hGrey[200],
  display: "inline-block",
  margin: "0 4px",
  borderRadius: "50px",
  cursor: "pointer",
};

function CarouselCustom(props: {
  currentSlide: number;
  indicatorChange: (index: number) => void;
  indicatorColor?: string;
  children?: any;
}) {
  const onChange = (index: number) => {
    props.indicatorChange(index);
    setTimeout(() => {
      const currentSlide = document.getElementsByClassName("slide selected");
      const sliderWrapper = document.getElementsByClassName("slider-wrapper");
      const slideBox = currentSlide[0].firstElementChild as HTMLElement;
      const height = slideBox.offsetHeight;
      (sliderWrapper[0] as HTMLElement).style.height = `${height}px`;
    }, 100);
  };
  return (
    <Carousel
      selectedItem={props.currentSlide}
      {...getConfigurableProps()}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              className="indicator-style"
              style={{
                ...indicatorStyles,
                background: props.indicatorColor || hGrey[400],
              }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            className="indicator-style"
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
      onChange={(index: number, item: React.ReactNode) => onChange(index)}
      className="carousel-main"
    >
      {props.children}
    </Carousel>
  );
}

export default CarouselCustom;
