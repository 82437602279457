import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  InputAdornment,
  Box,
  Button,
} from "@mui/material";
import TextFieldCustom from "../global/text-field";
import { Controller } from "react-hook-form";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import MultiImageInput from "../global/multi-image-input";
import { data } from "../../views/job/mockup-data";

function Images({ hooksForm }: any) {
  const {
    formState: { errors },
    register,
    control,
  } = hooksForm;

  const styles = {
    boxContent: {
      padding: "8px",
      backgroundColor: "primary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
    iconFeedbackBtn: {
      backgroundColor: "#ED1968",
      padding: "8px",
      color: "white",
      borderRadius: "50%",
    },
    cardContent: {
      paddingInline: "50px",
    },
    checkCircile: {
      color: "white",
      backgroundColor: "secondary.main",
      width: "350px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBlock: "0",
      height: "40px",
      borderColor: "secondary.main",
      "&:hover": {
        borderColor: "secondary.main",
        backgroundColor: "secondary.main",
      },
    },
    commentBtn: {
      color: "secondary.main",
      width: "350px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBlock: "0",
      height: "40px",
      "&:hover": {
        backgroundColor: "white",
        borderColor: "secondary.main",
      },
    },
    saveBtn: {
      color: "white",
      backgroundColor: "primary.main",
      width: "300px",
      borderColor: "primary.main",
      "&:hover": {
        backgroundColor: "primary.main",
        borderColor: "primary.main",
        color: " white",
      },
    },
  };

  return (
    <React.Fragment>
      <Box sx={styles.boxContent}>
        <Card sx={{ margin: "8px" }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                color="common.black"
                fontWeight="inherit"
              >
                Image
              </Typography>
            }
          />
          <CardContent sx={styles.cardContent}>
            {data.images.length
              ? data.images.map((img, index) => {
                  return (
                    <img
                      key={index}
                      style={{
                        height: "125px",
                        width: "125px",
                        margin: "4px",
                      }}
                      alt="laz"
                      src={img}
                    />
                  );
                })
              : ""}
          </CardContent>
        </Card>
        <Card sx={{ margin: "8px" }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                color="common.black"
                fontWeight="inherit"
              >
                Caption
              </Typography>
            }
          />
          <CardContent sx={styles.cardContent}>
            {data.caption ? (
              <Typography
                variant="body1"
                color="common.black"
                fontWeight="inherit"
              >
                <div dangerouslySetInnerHTML={{ __html: data.caption }} />
              </Typography>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button variant="contained" sx={styles.commentBtn}>
            <i
              className="ico-hu-comment-line-line"
              style={styles.iconFeedbackBtn}
            />
            <Typography variant="body1" fontWeight="500">
              Feedback
            </Typography>
            <div />
          </Button>
          <Button variant="contained" sx={styles.checkCircile}>
            <CheckCircleIcon sx={{ fontSize: "30px" }} />
            <Typography variant="body1" fontWeight="500">
              Approve
            </Typography>
            <div />
          </Button>
        </Stack>
      </Box>

      <Box sx={styles.boxContent}>
        <Card sx={{ margin: "8px" }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                color="common.black"
                fontWeight="inherit"
              >
                Add Image
              </Typography>
            }
          />
          <CardContent sx={styles.cardContent}>
            <MultiImageInput
              register={register}
              errors={errors?.images}
              keyName="images"
            />
          </CardContent>
        </Card>
        <Card sx={{ margin: "8px" }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                color="common.black"
                fontWeight="inherit"
              >
                Caption
              </Typography>
            }
          />
          <CardContent sx={styles.cardContent}>
            <Controller
              name="caption"
              control={control}
              rules={{
                required: false,
              }}
              defaultValue=""
              render={({ field }) => (
                <TextFieldCustom
                  variant="outlined"
                  multiline
                  minRows={3}
                  placeholder="Please enter text..."
                  fullWidth
                  defaultValue={data.caption}
                  error={!!errors?.caption}
                  helperText={errors?.caption?.message}
                  {...field}
                />
              )}
            />
          </CardContent>
        </Card>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button
            variant="contained"
            sx={styles.saveBtn}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export default Images;
