import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSidebarStyle as classes } from "./Sidebar.styles";
import { Box, Stack } from "@mui/material";

import { IMenu, IMenuProps } from "./Sidebar.types";

import logo from "./../../assets/images/logo/HashU logo 2.svg";
import allMenu from "./menus_list.json";
import ItemSideBar from "./ItemSideBar";

function Menus(props: IMenuProps) {
  const { menus, handleMenuClick } = props;
  return <ItemSideBar menus={menus} handleMenuClick={handleMenuClick} />;
}

const Sidebar = React.memo(() => {
  const history = useHistory();
  const [menus, setMenus] = useState<IMenu[]>([]);

  //? Mock up User
  // const user = { user_role: "influencer" };
  const user = { user_role: "company" };

  useEffect(() => {
    const pathSplitted = history.location.pathname.split("/");
    pathSplitted.shift(); // * ไม่เอาค่าว่างมานับ index: 0 : sidebar
    const mainPath = "/" + pathSplitted[0];
    const menuList: IMenu[] =
      user?.user_role === "influencer" ? allMenu.influencer : allMenu.company;
    // const menuList: IMenu[] =
    //   user?.user_role === "influencer" ? allMenu.influencer : [];

    for (const menu of menuList) {
      if (menu.path === mainPath) {
        menu.isActive = true;
      }
    }
    setMenus(menuList);
  }, [user?.user_role, history]);

  const setDefaultMenu = () => {
    for (const menu of menus) {
      menu.isActive = false;
    }
  };

  const goPage = (path?: string) => {
    if (path !== undefined) {
      history.push(path);
    } else {
      history.push("/");
    }
  };

  const handleMenuClick = (index: number) => {
    setDefaultMenu();
    let path = "";
    if (menus) {
      menus[index].isActive = true;
      path = menus[index].path;
      setMenus([...menus]);
      goPage(path);
    }
  };

  return (
    <Box display={["none", "block"]}>
      <Box sx={classes.sideBar}>
        <Stack>
          <Box sx={classes.logoBox}>
            <img src={logo} alt="Hashu Logo" width={"52px"} />
          </Box>
          <Menus menus={menus} handleMenuClick={handleMenuClick}></Menus>
        </Stack>
      </Box>
    </Box>
  );
});

export default Sidebar;
