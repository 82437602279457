import React from "react";

// Component
import DialogScrollingCustom from "../../global/dialog-scrolling-custom";
import { ShippingAddressSection } from "./ShippingAddressSection";
// Types
import {
  IEditAddressDialogProps,
  IShippingAddressForm,
} from "./../InfluencerAddress.types";
// Utils
import { useEditAddressDialogStyle as classes } from "./../InfluencerAddress.styles";
//  DayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

const EditAddressDialog = React.memo((props: IEditAddressDialogProps) => {
  const { data, isOpened, onClose, onSubmitShippingAddress } = props;

  const shippingAddressSubmitHandler = async (value: IShippingAddressForm) => {
    onSubmitShippingAddress(value);
    console.log("onSubmitShippingAddress", value);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <DialogScrollingCustom
        title="Address"
        open={isOpened}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={false}
        sx={classes.dialog}
      >
        <ShippingAddressSection
          address={data}
          shippingAddressSubmitHandler={shippingAddressSubmitHandler}
          closeDialog={handleClose}
        />
      </DialogScrollingCustom>
    </React.Fragment>
  );
});

export default EditAddressDialog;
