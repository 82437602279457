import * as React from "react";
// Material UI
import { Box, Grid, Stack, Button, Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// Icons
import { BankIcon, Export } from "../../assets/icons/social/social";
// Globall
import BasicSelectCustom from "../global/basic-select";
// Styles
import { expenseTabStyles as classes } from "./Expense.styles";

export default function ExpenseTab() {
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  return (
    <Box sx={classes.expenseTabContainer} p={4}>
      <Grid container alignItems="center" mt={2}>
        <Grid item xs={12} sm={12} lg={8} sx={{ display: { sm: "flex" } }}>
          <Box sx={classes.date}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From"
                value={startDate}
                onChange={(newStartDate) => {
                  setStartDate(newStartDate);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Divider sx={{ alignItems: "center" }}> - </Divider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To"
                value={endDate}
                onChange={(newEndDate) => {
                  setEndDate(newEndDate);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={classes.selectStatus}>
            <BasicSelectCustom
              id="status-select-1"
              value="all"
              onChange={(e) => {}}
              data={[
                { value: "all", label: "All" },
                { value: "pending", label: "Pending" },
                { value: "paid", label: "Paid" },
              ]}
              valueKey="value"
              labelKey="label"
              placeholder="Gender"
              className="selectStatus"
              sx={{ height: "40px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} mt={[0, 4, , 0]}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            justifyContent={["center", "start", , "flex-end"]}
            sx={classes.exportAndCertificateBox}
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={classes.exportAndCertificateButton}
              startIcon={<BankIcon />}
              disabled={(startDate && endDate) === null && true}
            >
              <span className="bankAccountAndCertificate">
                Export Bank Account
              </span>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                border: "none",
                "&:hover": {
                  border: "none",
                },
                ...classes.exportAndCertificateButton,
              }}
              startIcon={<Export />}
            >
              <span className="bankAccountAndCertificate">
                หนังสือรับรองหัก ณ ที่จ่าย
              </span>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
