import React from "react";
// Mui
import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";

export default function TargetAudienceDetail() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography color="#8C499C" fontWeight="500">
          กลุ่มลูกค้าของแบรนด์
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <CircleIcon sx={{ fontSize: "7px", mx: "5px" }} />
          Age
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>25 - 35 ปี</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <CircleIcon sx={{ fontSize: "7px", mx: "5px" }} />
          Gender
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>ทุกเพศ</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <CircleIcon sx={{ fontSize: "7px", mx: "5px" }} />
          Location
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>กรุงเทพมหานคร นนทบุรี</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="#8C499C" fontWeight="500">
          คุณสมบัติของอินฟลูเอนเซอร์
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <CircleIcon sx={{ fontSize: "7px", mx: "5px" }} />
          Followers
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>1,000 - 30,000</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <CircleIcon sx={{ fontSize: "7px", mx: "5px" }} />
          Age
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>25 - 35 ปี</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <CircleIcon sx={{ fontSize: "7px", mx: "5px" }} />
          Gender
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>ทุกเพศ</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <CircleIcon sx={{ fontSize: "7px", mx: "5px" }} />
          Location
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>กรุงเทพมหานคร นนทบุรี</Typography>
      </Grid>
    </Grid>
  );
}
