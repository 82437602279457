import {
  Box,
  Typography,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import TextFieldCustom from "../global/text-field";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { provinceData } from "../../data/province";
import { genderArray } from "../../utils/constants/genderArray.constants";
import RangeInput from "../global/range-input";
import { useForm, Controller } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CreatableSelectCustom from "../global/creatable-select-custom";

import ClearIcon from "@mui/icons-material/Clear";

interface ISearchProps {
  Controller: Function;
  hooksForm: any;

  [any: string]: any;
}

function SearchFilterInfluencer(props: ISearchProps) {
  const {
    Controller,
    hooksForm: {
      control,
      reset,
      watch,
      setError,
      clearErrors,
      errors,
      register,
      setValue,
    },
  } = props;

  const provinceOptions = provinceData.result.data;
  const watchCategory = watch("category");
  const [genderChecked, setGenderChecked] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [locationChecked, setLocationChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const styles = {
    sideBar: {
      position: "fixed",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "block",
      width: "280px",
      minHeight: "560px",
      height: "100%",
      backgroundColor: "info.A100",
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: "0px 50px 50px 0px;",
      paddingLeft: "100px",

      paddingBlock: "50px",
      zIndex: 1110,
      color: "common.white",
    },
    btnClear: {
      backgroundColor: "primary.main",
      paddingBlock: "5px",
      paddingInline: "10px",
    },
    line: {
      color: "common.white",
    },
    accordion: {
      backgroundColor: "info.A100",
      boxShadow: "0",
      color: "common.white",
    },
    checkbox: {
      color: "common.white",
      "&.Mui-checked": {
        color: "common.white",
      },
    },
    formControlLabel: {
      ".MuiFormControlLabel-label": {
        fontFamily: "Kanit",
        fontSize: "12px",
        fontWeight: "400",
      },
    },
    badge: {
      // backgroundColor: "secondary.main",
      backgroundColor: "#ED1968",
      paddingInline: "5px ",
      borderRadius: "25px",
      margin: "5px",
      color: "white",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
    },
    valueContainer: {
      display: "flex",
      alignItems: "center",
      marginBlock: "10px",
      flexWrap: "wrap",
    },
  };

  const categoryOptions = [
    { id: 1, label: "Beauty", value: "1" },
    { id: 2, label: "Cosmetics", value: "2" },
    { id: 3, label: "Travel", value: "3" },
    { id: 4, label: "IT", value: "4" },
    { id: 5, label: "Lifestyle", value: "5" },
    { id: 6, label: "Food", value: "6" },
    { id: 7, label: "Musician", value: "7" },
    { id: 8, label: "Gym", value: "8" },
  ];

  const onGenderChange = (ev: any, gIndex: any) => {
    const { checked } = ev.target;
    let presentValue = [
      genderChecked[0],
      genderChecked[1],
      genderChecked[2],
      genderChecked[3],
    ];

    presentValue[gIndex] = checked;

    setGenderChecked(presentValue);
  };

  const onLocationChange = (ev: any, lIndex: any) => {
    const { checked } = ev.target;
    let presentValue = [
      locationChecked[0],
      locationChecked[1],
      locationChecked[2],
      locationChecked[3],
      locationChecked[4],
      locationChecked[5],
      locationChecked[6],
      locationChecked[7],
      locationChecked[8],
    ];

    presentValue[lIndex] = checked;

    setLocationChecked(presentValue);
  };

  const handleRemoveValue = (item: string) => {
    const mockArr = [...watchCategory];

    const res = mockArr.filter((data) => {
      return data != item;
    });

    setValue("category", res);
  };

  const displayCategory = (
    <Box>
      <Box display="flex" flexWrap="wrap">
        {watchCategory?.map((item: any, index: number) => {
          const jsonItem = categoryOptions.find((el) => el.value == item);
          console.log({ jsonItem });

          return (
            <Box style={styles.badge} key={index}>
              <Typography variant="body1" color="commin.white">
                {jsonItem?.label}
              </Typography>
              <IconButton
                id={jsonItem?.value}
                name={jsonItem?.value}
                onClick={() => handleRemoveValue(item)}
              >
                <ClearIcon sx={{ color: "white", fontSize: "16px" }} />
              </IconButton>
            </Box>
          );
        })}
      </Box>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <Grid container>
            <Controller
              name="category"
              control={control}
              render={({ field }: any) => (
                <>
                  {categoryOptions.map((item, index) => (
                    <Grid item xs={6} key={index}>
                      <FormControlLabel
                        label={item.label}
                        control={
                          <Checkbox
                            sx={styles.checkbox}
                            value={item.value}
                            checked={field.value?.some(
                              (existingValue: any) =>
                                existingValue === item.value
                            )}
                            onChange={(event, checked) => {
                              if (checked) {
                                field.onChange([
                                  ...field.value,
                                  event.target.value,
                                ]);
                              } else {
                                field.onChange(
                                  field.value?.filter(
                                    (value: any) => value !== event.target.value
                                  )
                                );
                              }
                            }}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </>
              )}
            />
          </Grid>
        </FormGroup>
      </FormControl>
    </Box>
  );
  const displayLocation = (
    <Box>
      <TextFieldCustom
        variant="outlined"
        placeholder="Search Location.."
        fullWidth
        error={!!errors?.location}
        helperText={errors?.location?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlinedIcon sx={{ color: "grey.500" }} />
            </InputAdornment>
          ),
        }}
      />
      <Grid container mt={2} spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={styles.checkbox}
                checked={
                  locationChecked[0] &&
                  locationChecked[1] &&
                  locationChecked[2] &&
                  locationChecked[3] &&
                  locationChecked[4] &&
                  locationChecked[5] &&
                  locationChecked[6] &&
                  locationChecked[7] &&
                  locationChecked[8]
                }
                onChange={(ev) =>
                  setLocationChecked([
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                  ])
                }
              />
            }
            label="All"
            value="all"
            sx={styles.formControlLabel}
          />
        </Grid>

        {provinceOptions.map((item, index) => {
          return (
            <Grid item xs={6} key={item.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={styles.checkbox}
                    checked={locationChecked[index]}
                    onChange={(ev) => onLocationChange(ev, index)}
                  />
                }
                label={item.name_en}
                value={item.id}
                sx={styles.formControlLabel}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );

  const displayAccordion = (
    <div>
      <Accordion sx={styles.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "common.white" }} />}
          aria-controls="category-panel-content"
          id="category-panel-header"
        >
          <Typography variant="body2" color="common.white" fontWeight="500">
            Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{displayCategory}</AccordionDetails>
      </Accordion>
      <hr style={styles.line} />
      <Accordion sx={styles.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "common.white" }} />}
          aria-controls="location-panel-content"
          id="location-panel-header"
        >
          <Typography variant="body2" color="common.white" fontWeight="500">
            Location
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{displayLocation}</AccordionDetails>
      </Accordion>
    </div>
  );

  const displayGender = (
    <Box>
      <Typography variant="body2" color="common.white" fontWeight="500" p={1}>
        Gender
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={styles.checkbox}
                checked={
                  genderChecked[0] &&
                  genderChecked[1] &&
                  genderChecked[2] &&
                  genderChecked[3]
                }
                onChange={(ev) =>
                  setGenderChecked([
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                    ev.target.checked,
                  ])
                }
              />
            }
            label="All"
            value="all"
            sx={styles.formControlLabel}
          />
        </Grid>
        {genderArray?.map((item, index) => {
          return (
            <Grid item xs={6} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={styles.checkbox}
                    checked={genderChecked[index]}
                    onChange={(ev) => onGenderChange(ev, index)}
                  />
                }
                label={item.label}
                value={item.value}
                sx={styles.formControlLabel}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );

  const displayAge = (
    <Box>
      <Typography variant="body2" color="common.white" fontWeight="500" p={1}>
        Age
      </Typography>
      <Box mt={3}>
        <RangeInput
          setError={setError}
          clearErrors={clearErrors}
          startKeyName="age.min"
          endKeyName="age.max"
          Controller={Controller}
          control={control}
          errors={errors}
          watch={watch}
        />
      </Box>
    </Box>
  );

  const displayFollower = (
    <Box>
      <Typography variant="body2" color="common.white" fontWeight="500" p={1}>
        Follower
      </Typography>
      <Box mt={3}>
        <RangeInput
          setError={setError}
          clearErrors={clearErrors}
          startKeyName="follower.min"
          endKeyName="follower.max"
          Controller={Controller}
          control={control}
          errors={errors}
          watch={watch}
        />
      </Box>
    </Box>
  );
  const displayEngagement = (
    <Box>
      <Typography variant="body2" color="common.white" fontWeight="500" p={1}>
        Engagement Rate
      </Typography>
      <Box mt={3}>
        <RangeInput
          setError={setError}
          clearErrors={clearErrors}
          startKeyName="engagement.min"
          endKeyName="engagement.max"
          Controller={Controller}
          control={control}
          errors={errors}
          watch={watch}
          unit="%"
        />
      </Box>
    </Box>
  );
  const displayRateCard = (
    <Box height={200}>
      <Typography variant="body2" color="common.white" fontWeight="500" p={1}>
        Rate Card
      </Typography>
      <Box mt={3}>
        <RangeInput
          setError={setError}
          clearErrors={clearErrors}
          startKeyName="rateCard.min"
          endKeyName="rateCard.max"
          Controller={Controller}
          control={control}
          errors={errors}
          watch={watch}
        />
      </Box>
    </Box>
  );

  return (
    <div>
      <Box sx={styles.sideBar}>
        <div
          id="influencer-search"
          style={{ overflowY: "auto", height: "100vh", paddingRight: "20px" }}
        >
          <Stack spacing={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" color="common.white" fontWeight="500">
                Search & Filter
              </Typography>

              <Button sx={styles.btnClear} onClick={() => reset()}>
                <Typography
                  variant="body2"
                  color="common.white"
                  fontWeight="500"
                >
                  Clear All
                </Typography>
              </Button>
            </Box>

            <Controller
              name="searchTerm"
              control={control}
              rules={{
                required: false,
              }}
              defaultValue=""
              render={({ field }: { field: any }) => {
                return (
                  <CreatableSelectCustom
                    options={[]}
                    placeholder="Search Name, #hashtag or @brand"
                    badgeColor="#ED1968"
                    toggle
                    {...field}
                  />
                );
              }}
            />
            <hr />
            {displayAccordion}
            <hr />
            {displayGender}
            <hr />
            {displayAge}
            <hr />
            {displayFollower}
            <hr />
            {displayEngagement}
            <hr />
            {displayRateCard}
          </Stack>
        </div>
      </Box>
    </div>
  );
}

export default SearchFilterInfluencer;
