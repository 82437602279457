export const data = {
  images: [
    "/assets/images/laz-1.png",
    "/assets/images/laz-2.jpeg",
    "/assets/images/laz-3.jpeg",
    "/assets/images/laz-4.jpeg",
  ],
  caption:
    "ต้องเข้าแอปไปช้อป ‘Lazada 12.12 เซลใหญ่ ส่งท้ายปี’ แล้วมั้ยล่ะ !! เค้าลดหนักจริงไม่มีแกง ลดสูงถึง90%ส่งท้ายปีกันไปเลยจุกๆ ปีนี้อยากได้อะไรแล้วยังไม่ได้ซื้อ รีบเข้าไปซื้อกันด่วนๆๆเลยจ้า วันนี้ - 14 ธ.ค. 64 เท่านั้น 📦 <br/>💙ลดแรงสุด 90% <br/>💙ส่งฟรีทั่วไทย <br/>💙Lazada Bonus ช้อปทุกๆ 500.- ลดอีก 50.-  <br/>💙มีดีลพิเศษจากแบรนด์ดังใน LazMall ให้ช้อปทุกชั่วโมงแถมยังใช้ส่วนลดพร้อมกันได้ถึง 6 ต่อ ลดแล้วลดไปอี๊ก ใครไม่ช้อปพลาดมาก อย่าหาว่าพีไม่เตือนนะ  <br/>💙#Lazada1212TH#Lazada1212GrandYearEndSaleTH",
};
