import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  Box,
} from "@mui/material";

function FeedbackReview() {
  const styles = {
    boxContent2: {
      padding: "8px",
      backgroundColor: "secondary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
  };

  return (
    <Box sx={styles.boxContent2}>
      <Card sx={{ margin: "8px" }}>
        <CardHeader
          title={
            <Typography
              variant="h6"
              color="common.black"
              fontWeight="inherit"
              display="flex"
              alignItems="center"
            >
              <img
                src="/assets/images/comments.png"
                style={{ marginRight: "20px" }}
              />
              Feedback
            </Typography>
          }
        />
        <CardContent sx={{ paddingInline: "30px" }}>
          <Stack>
            <Box>
              <Typography variant="body1" color="common.black" fontWeight="400">
                1 : XXXXXXXXXXXXXXXXXXXXXXXXXXX
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="common.black" fontWeight="400">
                2 : XXXXXXXXXXXXXXXXXXXXXXXXXXX
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="common.black" fontWeight="400">
                3 : XXXXXXXXXXXXXXXXXXXXXXXXXXX
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default FeedbackReview;
