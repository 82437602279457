import { configureStore, createAction } from "@reduxjs/toolkit";
// import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import EmployerReducer from "../views/employer/EmployerSlice";
import EmployerDetailSlice from "../views/employer/detail/EmployerDetailSlice";
// import MySocialProfileReducer from "../views/my-profile/MyProfileSlice";
import CategoryReducer from "../components/category-dialog/CategorySlice";

const reducers = combineReducers({
  employers: EmployerReducer,
  employerDetail: EmployerDetailSlice,
  // mySocialProfile: MySocialProfileReducer,
  categoryList: CategoryReducer,
});

export const resetStore = createAction("reset");

const rootReducer = (state: any, action: any) => {
  if (resetStore.match(action)) {
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["home"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
