import React, { useEffect, useState } from "react";
// MUI
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  Box,
  Divider,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
} from "@mui/material";
// globall
import WrapInputWithLabel from "../../global/wrap-input-label";
// data
import { categoryData } from "../../../views/create-campaign/CreateCampaignData";
// style
import { useCampaignOverviewStyle as classes } from "./CampaignBrief.style";
import TextFieldCustom from "../../global/text-field";
import { Controller } from "react-hook-form";
import SearchNameCustom from "../../global/search-name";
import ImageInput from "../../global/image-input";
import MultipleSelectCustom from "../../global/multiple-select";
import {
  campaignOverviewOptionsData,
  campaignOverviewRadioData,
} from "./CampaignBriefData";

interface ICampaignProps {
  form: any;
  // handleOpenCampaignBrief: () => void;
}

export default function CampaignOverview(props: ICampaignProps) {
  const [optionCategory, setOptionCategory] = useState<any[]>([]);

  const {
    form: {
      formState: { errors },
      setValue,
      watch,
      register,
      control,
    },
    // handleOpenCampaignBrief,
  } = props;

  useEffect(() => {
    if (categoryData && categoryData.result) {
      const list = categoryData.result.data.map((item) => {
        return {
          label: item.name_en,
          value: item.value,
          id: item.id,
        };
      });

      setOptionCategory(list);
    }
  }, [categoryData]);

  const onHandleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    keyName: string,
    _value: string
  ) => {
    setValue(keyName, (event.target as HTMLInputElement).value);
  };

  return (
    <Card id="campaignOverview" sx={classes.cardContainer}>
      <CardHeader
        title={
          <Box sx={classes.cardHeader}>
            <Typography>Campaign Overview</Typography>
            <Typography>ภาพรวมแคมเปญ</Typography>
          </Box>
        }
      />
      <Divider sx={classes.divider} />
      <CardContent>
        <Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <WrapInputWithLabel
                labelWidth="200px"
                title="Organization"
                required
              >
                <TextFieldCustom
                  variant="outlined"
                  fullWidth
                  disabled
                  placeholder="Organization Name"
                  error={!!errors.campaign?.organization}
                  helperText={errors.campaign?.organization?.message}
                  inputProps={{
                    ...register("campaignOverview.organization", {
                      required: true,
                    }),
                  }}
                />
              </WrapInputWithLabel>
            </Grid>

            <Grid item xs={12}>
              <WrapInputWithLabel
                labelWidth="200px"
                title="Organization Logo"
                required
              >
                <Avatar sx={classes.orgLogo} />
              </WrapInputWithLabel>
            </Grid>

            <Grid item xs={12}>
              <WrapInputWithLabel
                labelWidth="200px"
                title="Company Name"
                required
              >
                <Controller
                  name="campaignOverview.companyName"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <SearchNameCustom
                        options={campaignOverviewOptionsData}
                        {...field}
                      />
                    );
                  }}
                />
              </WrapInputWithLabel>
            </Grid>

            <Grid item xs={12} sx={classes.companyLogo}>
              <WrapInputWithLabel
                labelWidth="200px"
                title="Company Logo"
                required
              >
                <ImageInput
                  errors={errors?.campaign?.logoCompany}
                  register={register}
                  setValue={setValue}
                  keyName="campaignOverview.companyLogo"
                  icon="camera"
                />
              </WrapInputWithLabel>
            </Grid>

            <Grid item xs={12}>
              <WrapInputWithLabel
                labelWidth="200px"
                title="Campaign Name"
                required
              >
                <TextFieldCustom
                  variant="outlined"
                  placeholder="Campaign Name"
                  fullWidth
                  error={!!errors.campaign?.campaignName}
                  helperText={errors.campaign?.campaignName?.message}
                  inputProps={{
                    ...register("campaignOverview.campaignName", {
                      required: true,
                    }),
                  }}
                />
              </WrapInputWithLabel>
            </Grid>

            <Grid item xs={12}>
              <WrapInputWithLabel
                title="Category"
                subTitle="เลือกได้สูงสุด 3 ประเภท"
                required
              >
                <MultipleSelectCustom
                  id="category-select"
                  value={watch("campaignOverview.category")}
                  setValue={(e: any) => {
                    setValue("campaignOverview.category", e);
                  }}
                  placeholder="Select..."
                  data={campaignOverviewOptionsData}
                  fullWidth
                  valueKey="id"
                  labelKey="name_en"
                />
              </WrapInputWithLabel>
            </Grid>

            {campaignOverviewRadioData.map((item, _index) => (
              <>
                <Grid item xs={3}>
                  <Typography fontSize="14px" mt={2}>
                    {item.label}
                    <span className="requir">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={watch(item.id)}
                      onChange={(event) => {
                        onHandleChangeValue(event, item.id, item.value);
                      }}
                      sx={classes.optionRadio}
                    >
                      {item.options.map((optItems, optIndex) => (
                        <FormControlLabel
                          key={optIndex}
                          value={optItems}
                          control={<Radio sx={classes.optionLabelRadio} />}
                          {...register(item.id)}
                          label={optItems}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            ))}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}
