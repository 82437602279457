import React from "react";
import ImageList, { imageListClasses } from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, Typography } from "@mui/material";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

interface IImageGallery {
  itemData: any[];
  cols: number;
  rows: number;
  size: number;
  featureCol?: number | boolean;
  featureRow?: number | boolean;
  lastShowIndex?: number;
}

function ImageGallery(props: IImageGallery) {
  const { itemData, cols, rows, featureCol, featureRow, size, lastShowIndex } =
    props;

  return (
    <ImageList
      sx={{ width: "100%" }}
      variant="quilted"
      cols={cols ? cols : 3}
      rowHeight={size ? size : 121}
    >
      {itemData.length
        ? itemData.map((item: any, index: number) => {
            if (!lastShowIndex) {
              return (
                <ImageListItem
                  key={item.img}
                  cols={featureCol && index === 0 ? featureCol : item.cols || 1}
                  rows={featureRow && index === 0 ? featureRow : item.rows || 1}
                >
                  <img
                    {...srcset(item.img, size ? size : 121, rows, cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              );
            } else {
              if (index + 1 < lastShowIndex) {
                return (
                  <ImageListItem
                    key={item.img}
                    cols={
                      featureCol && index === 0 ? featureCol : item.cols || 1
                    }
                    rows={
                      featureRow && index === 0 ? featureRow : item.rows || 1
                    }
                  >
                    <img
                      {...srcset(item.img, size ? size : 121, rows, cols)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                );
              } else if (index + 1 === lastShowIndex) {
                return (
                  <ImageListItem
                    key={item.img}
                    cols={
                      featureCol && index === 0 ? featureCol : item.cols || 1
                    }
                    rows={
                      featureRow && index === 0 ? featureRow : item.rows || 1
                    }
                  >
                    <img
                      {...srcset(item.img, size ? size : 121, rows, cols)}
                      alt={item.title}
                      loading="lazy"
                      style={{ filter: "brightness(0.5)" }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgba(0,0,0,0.1)",
                      }}
                    >
                      <Typography
                        fontSize="36px"
                        color="common.white"
                        fontWeight={500}
                      >
                        {itemData.length - 3} +
                      </Typography>
                    </Box>
                  </ImageListItem>
                );
              }
            }
          })
        : ""}
    </ImageList>
  );
}

export default ImageGallery;
