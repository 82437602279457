import React, { useEffect, useState } from "react";
import "./InfluencerProfile.styles.css";
import {
  InfluencerProfileBox,
  useInfluencerProfileStyle as classes,
} from "./InfluencerProfile.styles";
import ImageProfileList from "./ImageProfileList";
import ContactList from "./ContactList";
// Types
import { IInfluencerProfileProps } from "./InfluencerProfile.types";
// helper
import { addDefaultImageSrc } from "../../utils/helpers/addDefaultImageSrc.helpers";
import { capitalizeFirstLetter } from "../../utils/helpers/capitalizeFirstLetter.helpers";
import { compareNationality } from "../../utils/helpers/compareNationality.helpers";
// global
import EditProfileButton from "../global/edit-profile-button";
import { formatPhoneNumber } from "../global/format-number";
// Material UI
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
// Icons
import {
  Line,
  MailSolid,
  Phone,
  EditSolidIcon,
} from "../../assets/icons/social/social";
import DefaultImage from "./../../assets/images/other/image-empty.jpg";
import nl2br from "react-nl2br";
// Day Js
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

const InfluencerProfile = React.memo((props: IInfluencerProfileProps) => {
  const { openEditProfileDialog, profileInfluencer } = props;

  let image = 0;
  if (profileInfluencer && profileInfluencer.media.images !== null) {
    image = profileInfluencer.media.images.findIndex((purpose) => {
      return purpose.purpose === "profile_image";
    });
  }

  return (
    <InfluencerProfileBox>
      <Card elevation={4}>
        <Box display={["none", "none", "block"]}>
          <CardHeader
            sx={classes.profileCardHeader}
            title={
              <Typography
                variant="body1"
                color="common.white"
                fontWeight="inherit"
              >
                My Profile
              </Typography>
            }
          />
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6} md={12}>
            <CardContent sx={classes.cardContent}>
              <div>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  m={0}
                  p={0}
                  sx={classes.imageBox}
                >
                  <div className="main-profile">
                    {profileInfluencer?.media.images[image] !== undefined ? (
                      <img
                        src={profileInfluencer.media.images[image].url}
                        alt="images"
                        onError={addDefaultImageSrc}
                        className="first-layer"
                      />
                    ) : (
                      <img
                        src={DefaultImage}
                        alt="images"
                        onError={addDefaultImageSrc}
                        className="first-layer"
                      />
                    )}
                    <div className="second-layer"></div>
                    <div className="third-layer">
                      <Typography variant="h6" color={"common.white"}>
                        {profileInfluencer && profileInfluencer.fullname}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#C2A8EF" }}>
                        {`Age ${
                          profileInfluencer &&
                          dayjs().diff(profileInfluencer.birth_date, "year")
                        } , 
                  ${
                    profileInfluencer &&
                    capitalizeFirstLetter(profileInfluencer.gender)
                  } , 
                  ${
                    profileInfluencer && profileInfluencer.nationality
                    // compareNationality(profileInfluencer.nationality)
                  }`}
                      </Typography>
                    </div>
                  </div>
                </Box>
                <Box sx={classes.imageListBox}>
                  <ImageProfileList profileInfluencer={profileInfluencer} />
                </Box>
              </div>
            </CardContent>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            {profileInfluencer && profileInfluencer.description && (
              <CardContent sx={classes.cardContent}>
                <Box sx={classes.descriptionBox}>
                  <Typography variant="body2">
                    {nl2br(profileInfluencer.description)}
                  </Typography>
                </Box>
              </CardContent>
            )}
            <CardContent sx={classes.cardContent}>
              <Stack>
                <Box pl={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Contact
                  </Typography>
                </Box>
                {/* Phone Number */}
                <ContactList
                  data={
                    profileInfluencer &&
                    formatPhoneNumber(profileInfluencer.phone)
                  }
                  text="Phone Number"
                  icon={<Phone color="primary" sx={{ fontSize: "18px" }} />}
                />
                <hr style={classes.contactLine} />
                {/* Line ID */}
                <ContactList
                  data={profileInfluencer && profileInfluencer.line_id}
                  text="Line ID"
                  icon={
                    <Line
                      viewBox="3 5 45 40"
                      color="primary"
                      sx={{ fontSize: "20px" }}
                    />
                  }
                />
                <hr style={classes.contactLine} />
                {/* Email */}
                <ContactList
                  data={profileInfluencer && profileInfluencer.email}
                  text=" Email"
                  icon={<MailSolid color="primary" sx={{ fontSize: "20px" }} />}
                  // icon={<EmailIcon color="primary" fontSize="inherit" />}
                />
              </Stack>
            </CardContent>
            <CardActions>
              <Box sx={classes.cardActionBox}>
                <EditProfileButton
                  startIcon={
                    <EditSolidIcon
                      sx={{ width: "12.55px", height: "12.52px" }}
                    />
                  }
                  onClick={openEditProfileDialog}
                >
                  <Typography variant="button" fontWeight="inherit">
                    Edit Profile
                  </Typography>
                </EditProfileButton>
              </Box>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </InfluencerProfileBox>
  );
});

export default InfluencerProfile;
