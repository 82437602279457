import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { IPackageCardProps } from "../../views/home-pricing/HomePricing.types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarIcon from "@mui/icons-material/Star";
import { ButtonPricing } from "./HomePricing.style";
import { useHomePricingPackageCardStyle as classes } from "./HomePricing.style";
import { hYellow } from "../../theme";

function PackageCard({ data }: IPackageCardProps) {
  return (
    <Card className="package-card" sx={classes.packageCard}>
      <CardHeader
        className="package-header"
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography className="package-title" variant="h6">
              {data.package}
            </Typography>
            {!!data.recommend && <StarIcon sx={{ color: hYellow[300] }} />}
          </Box>
        }
        subheader={
          <Typography
            className="package-sub-title"
            variant="h4"
          >{`${data.currency} ${data.price}`}</Typography>
        }
      />
      <Divider variant="middle" sx={{ marginX: 5 }} />
      <CardContent
        sx={{
          position: "relative",
          height: data.package === "Custom" ? `calc(100% - 150px)` : undefined,
        }}
      >
        {data.details.map((detail, idx) => {
          return (
            <Box
              key={idx}
              height={data.package === "Custom" ? "78%" : undefined}
              display={data.package === "Custom" ? "flex" : undefined}
              justifyContent={data.package === "Custom" ? "center" : undefined}
              alignItems={data.package === "Custom" ? "center" : undefined}
            >
              <Box display="flex" paddingY={3} paddingX={2}>
                <CheckCircleIcon color="secondary" />
                <Typography sx={{ marginLeft: 3 }}>{detail}</Typography>
              </Box>
              <Divider variant="middle" />
            </Box>
          );
        })}
        {data.button && (
          <ButtonPricing
            sx={{ marginTop: 5 }}
            fullWidth
            disableElevation
            variant="outlined"
            onClick={() => {
              if (data.button?.onClick !== undefined) {
                data.button.onClick();
              }
            }}
          >
            <Typography>{data.button.text}</Typography>
          </ButtonPricing>
        )}
      </CardContent>
    </Card>
  );
}

export default PackageCard;
