import React from "react";
import {
  CardContent,
  Card,
  Box,
  Avatar,
  Typography,
  Grid,
  Stack,
  CardHeader,
  Button,
} from "@mui/material";

interface IStatsTab {
  stats: any;
  label: string;
  icon: string;
  [any: string]: any;
}

function StatsTab(props: IStatsTab) {
  const { stats, label, icon, total } = props;

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      {total ? (
        <Box display="flex">
          <Typography variant="h5" color="secondary.main" fontWeight="500">
            ฿ {stats}
          </Typography>
          <Typography variant="h5" color="primary.300" fontWeight="500">
            /{total}
          </Typography>
        </Box>
      ) : (
        <Typography variant="h5" color="primary.300" fontWeight="500">
          {stats}
        </Typography>
      )}
      <Stack
        mt={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {/* <img
          src={iconUrl}
          alt={label}
          style={{ width: 15, height: 15, objectFit: "contain" }}
        /> */}

        <i className={icon} />

        <Typography variant="body1" color="common.black" fontWeight="400">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default StatsTab;
