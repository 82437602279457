import React, { useRef } from "react";
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import SunEditor from "suneditor-react";

function Feedback({ hooksForm }: any) {
  const sunEditorRef = useRef<any>();

  const styles = {
    boxContent: {
      padding: "8px",
      backgroundColor: "primary.100",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "25px",
    },
    iconFeedbackBtn: {
      backgroundColor: "#ED1968",
      padding: "8px",
      color: "white",
      borderRadius: "50%",
    },
    feedbackBtn: {
      color: "white",
      backgroundColor: "secondary.main",
      width: "300px",
      borderColor: "secondary.main",

      border: "none !important",
    },
  };

  const getSunEditorInstance = (sunEditor: any) => {
    sunEditorRef.current = sunEditor;
  };

  const handleEditorChange = (content: any) => {
    console.log(content);
  };

  return (
    <Box sx={styles.boxContent}>
      <Card sx={{ m: 2 }}>
        <CardHeader
          title={
            <Typography variant="h6" color="common.black" fontWeight="inherit">
              Feedback
            </Typography>
          }
        />
        <CardContent sx={{ paddingInline: "30px" }}>
          <SunEditor
            height="200px"
            getSunEditorInstance={getSunEditorInstance}
            onChange={handleEditorChange}
            placeholder="Please enter text..."
            setOptions={{
              buttonList: [
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "list",
                  "align",
                  "fontSize",
                  "formatBlock",
                  "table",
                  "image",
                ],
              ],
            }}
          />
        </CardContent>
      </Card>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          variant="contained"
          sx={styles.feedbackBtn}
          endIcon={<ArrowCircleRightRoundedIcon />}
        >
          Send Feedback
        </Button>
      </Stack>
    </Box>
  );
}

export default Feedback;
