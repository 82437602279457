import React, { useEffect, useState } from "react";
// import { RateCard } from "./RateCard";
import { ImagePost } from "./ImagePost";
import AudienceDemographic from "./AudienceDemographic";
import CarouselCustom from "../../global/carousel-custom";
import NextSlideButton from "../../global/carousel-custom/NextSlideButton";
import PrevSlideButton from "../../global/carousel-custom/PrevSlideButton";

// import {
//   IFacebookDetailRes,
//   ISocialPost,
//   IPostRes,
//   IInfluencerSocialRes,
// } from "../../../views/my-profile/MyProfile.types";

import { useTiktokTabStyle as classes } from "./TiktokTab.styles";

import {
  Avatar,
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

import { calculateNumberSocialUnit } from "../../../utils/helpers/calculateNumberSocialUnit.helpers";

import HeartIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Chat";
import ShareIcon from "@mui/icons-material/Reply";

function InformationTab({ data }: { data: any }) {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [maxSlide, setMaxSlide] = useState<number>(0);
  // const [rateCardEditId, setRateCardEditId] = useState<number | null>(null);

  const tiktokAccount = data[0].social_account[0];

  useEffect(() => {
    setMaxSlide(tiktokAccount.social_screenshot.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextSlide = () => {
    if (currentSlide + 1 < maxSlide) {
      setCurrentSlide((prevState) => prevState + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevState) => prevState - 1);
    } else {
      setCurrentSlide(maxSlide - 1);
    }
  };
  const indicatorChange = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const createCarouselItemImage = (index: number, item: any) => (
    <Box key={index} display="flex" m={0} sx={classes.carouselItemBox}>
      <Box sx={classes.tiktokBoxMain}>
        <img
          src={item.media.image_cover_url}
          alt=""
          style={classes.tiktokFirstLayer}
        />
      </Box>
      <Box sx={classes.tiktokSecondLayer}></Box>
      <Box sx={classes.tiktokThirdLayer}>
        <Stack sx={classes.captionSocialMediaScreenshot}>
          <Box textAlign="left" pb={1}>
            <Typography variant="body1">@{tiktokAccount.name}</Typography>
          </Box>
          <Box textAlign="left">
            <Typography variant="body2" sx={classes.tiktokCaption}>
              {item.description}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box sx={classes.tiktokFourthLayer}>
        <Stack px={2} spacing={2}>
          <Box pb={1}>
            <Avatar
              alt="profile_tiktok"
              src={tiktokAccount.profile_image}
              sx={classes.tiktokAvatar}
            />
          </Box>
          <Stack sx={classes.textItemsSocialMediaScreenshot}>
            <HeartIcon sx={classes.tiktokItemIcon} />
            <Typography variant="caption">
              {calculateNumberSocialUnit(item.like, -1)}
            </Typography>
          </Stack>
          <Stack sx={classes.textItemsSocialMediaScreenshot}>
            <CommentIcon sx={classes.tiktokItemIcon} />
            <Typography variant="caption">
              {calculateNumberSocialUnit(item.comment, -1)}
            </Typography>
          </Stack>
          <Stack sx={classes.textItemsSocialMediaScreenshot}>
            <ShareIcon sx={classes.tiktokItemIcon} />
            {item.share && (
              <Typography variant="caption">
                {calculateNumberSocialUnit(item.share, -1)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );

  // const rateCardEditSelected = (id: number) => {
  //   setRateCardEditId(id);
  // };
  // const editRateCard = (newValue: number) => {
  //   console.log(newValue);
  //   setRateCardEditId(null);
  // };

  return (
    <React.Fragment>
      <Card sx={classes.cardInformationTiktok}>
        <CardContent sx={{ margin: "0 12px 0" }}>
          <Stack width={"100%"}>
            <Box component="div" mt={2}>
              <Grid container>
                <Grid item xs={12} sm={6} md={5} lg={5} pr={[0, 3]}>
                  <Stack>
                    <Box mb={4}>
                      <Typography variant="body1" fontWeight="bold">
                        Overview Timeline
                      </Typography>
                      <Paper elevation={2} sx={classes.overviewPaper}>
                        <Box sx={classes.overviewBox}>
                          <Stack
                            sx={classes.overviewStack}
                            style={{ borderRight: "2px solid #E5E5E5" }}
                          >
                            <Typography
                              variant="h5"
                              sx={classes.textOverviewTimeline}
                            >
                              {tiktokAccount.engagement_rate}%
                            </Typography>
                            <Typography variant="body1" color="common.black">
                              Engagement Rate
                            </Typography>
                          </Stack>
                          <Stack sx={classes.overviewStack}>
                            <Typography
                              variant="h5"
                              sx={classes.textOverviewTimeline}
                            >
                              {tiktokAccount.avg_post_per_week}
                            </Typography>
                            <Typography variant="body1" color="common.black">
                              Post per week
                            </Typography>
                          </Stack>
                        </Box>
                      </Paper>
                    </Box>
                    <Box mb={4}>
                      <Paper elevation={2} sx={classes.averagePostPaper}>
                        <Stack width="100%">
                          <Typography variant="body1" pl={4}>
                            Average on recent posts
                          </Typography>
                          <Grid container px={4} pt={3}>
                            <Grid item xs sx={classes.averageOnRecentPosts}>
                              <HeartIcon
                                color="primary"
                                sx={classes.averageIcon}
                              />
                              <Typography variant="h6" fontWeight="bold" pl={1}>
                                {calculateNumberSocialUnit(
                                  tiktokAccount.avg_like
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs sx={classes.averageOnRecentPosts}>
                              <CommentIcon
                                color="primary"
                                sx={classes.averageIcon}
                              />
                              <Typography variant="h6" fontWeight="bold" pl={1}>
                                {calculateNumberSocialUnit(
                                  tiktokAccount.avg_comment
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs sx={classes.averageOnRecentPosts}>
                              <ShareIcon
                                color="primary"
                                sx={classes.averageIcon}
                              />
                              <Typography variant="h6" fontWeight="bold" pl={1}>
                                {calculateNumberSocialUnit(
                                  tiktokAccount.avg_share
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Paper>
                    </Box>
                    <Box mb={4}>
                      <Typography variant="body1" fontWeight="bold">
                        Audience Demographic
                      </Typography>
                      <Paper elevation={2} sx={classes.audiencePaper}>
                        {/* <AudienceDemographic audienceData={data.audience} /> */}
                        <AudienceDemographic />
                      </Paper>
                    </Box>
                    <Box mb={4}>
                      <Typography variant="body1" fontWeight="bold">
                        Rate Card
                      </Typography>
                      <Stack>
                        Wating data...
                        {/* {tiktokAccount.rate_card.map(
                          (card: any, index: any) => (
                            <RateCard
                              key={index}
                              data={card}
                              rateCardEditId={rateCardEditId}
                              rateCardEditSelected={rateCardEditSelected}
                              editRateCard={editRateCard}
                            />
                          )
                        )} */}
                      </Stack>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={7} pl={[0, 4]}>
                  <Stack>
                    <Box mb={4} textAlign={["left", "right"]}>
                      <Typography variant="body1" fontWeight="bold">
                        Most Popular Posts
                      </Typography>
                      <Grid container spacing={[1, 1, 1, 1, 2, 4]} pt={1}>
                        {tiktokAccount.most_popular_post.map((post: any) => (
                          <Grid key={post.social_post_id} item xs={6} sm={4}>
                            <Box
                              onClick={() => {
                                window.open(post.published_url);
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              <ImagePost post={post} />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box mb={7} textAlign={["left", "right"]}>
                      <Typography variant="body1" fontWeight="bold">
                        Recent Post
                      </Typography>
                      <Grid container spacing={[1, 1, 1, 1, 2, 4]} pt={1}>
                        {tiktokAccount.recent_post.map((post: any) => (
                          <Grid key={post.social_post_id} item xs={6} sm={4}>
                            <Box
                              onClick={() => {
                                window.open(post.published_url);
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              <ImagePost post={post} />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack sx={classes.socialMediaScreenshotStack}>
                      <Typography sx={classes.HeadSocialMediaScreenshot}>
                        Social Media Screenshot
                      </Typography>
                      <Box display="flex" alignItems="center" px={15} py={3}>
                        <Avatar
                          alt="profile_tiktok"
                          src={tiktokAccount.profile_image}
                          sx={classes.socialMediaScreenshotAvatar}
                        />
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{ pl: 3 }}
                        >
                          {tiktokAccount.name}
                        </Typography>
                      </Box>
                      <Box display="flex" px={7}>
                        <Box display="flex">
                          <PrevSlideButton
                            prevSlide={prevSlide}
                            height="260px"
                          />
                        </Box>
                        <Box display="flex">
                          <CarouselCustom
                            currentSlide={currentSlide}
                            indicatorChange={indicatorChange}
                          >
                            {tiktokAccount.social_screenshot.map(
                              (item: any, index: number) => (
                                <Box
                                  onClick={() => {
                                    window.open(item.published_url);
                                  }}
                                  sx={{ cursor: "pointer" }}
                                >
                                  {createCarouselItemImage(index, item)}
                                </Box>
                              )
                            )}
                          </CarouselCustom>
                        </Box>
                        <Box display="flex">
                          <NextSlideButton
                            nextSlide={nextSlide}
                            height="260px"
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default InformationTab;
