import { createSvgIcon } from "@mui/material/utils";

export const Line = createSvgIcon(
  <path d="M 9 4 C 6.24 4 4 6.24 4 9 L 4 41 C 4 43.76 6.24 46 9 46 L 41 46 C 43.76 46 46 43.76 46 41 L 46 9 C 46 6.24 43.76 4 41 4 L 9 4 z M 25 11 C 33.27 11 40 16.359219 40 22.949219 C 40 25.579219 38.959297 27.960781 36.779297 30.300781 C 35.209297 32.080781 32.660547 34.040156 30.310547 35.660156 C 27.960547 37.260156 25.8 38.519609 25 38.849609 C 24.68 38.979609 24.44 39.039062 24.25 39.039062 C 23.59 39.039062 23.649219 38.340781 23.699219 38.050781 C 23.739219 37.830781 23.919922 36.789063 23.919922 36.789062 C 23.969922 36.419063 24.019141 35.830937 23.869141 35.460938 C 23.699141 35.050938 23.029062 34.840234 22.539062 34.740234 C 15.339063 33.800234 10 28.849219 10 22.949219 C 10 16.359219 16.73 11 25 11 z M 23.992188 18.998047 C 23.488379 19.007393 23 19.391875 23 20 L 23 26 C 23 26.552 23.448 27 24 27 C 24.552 27 25 26.552 25 26 L 25 23.121094 L 27.185547 26.580078 C 27.751547 27.372078 29 26.973 29 26 L 29 20 C 29 19.448 28.552 19 28 19 C 27.448 19 27 19.448 27 20 L 27 23 L 24.814453 19.419922 C 24.602203 19.122922 24.294473 18.992439 23.992188 18.998047 z M 15 19 C 14.448 19 14 19.448 14 20 L 14 26 C 14 26.552 14.448 27 15 27 L 18 27 C 18.552 27 19 26.552 19 26 C 19 25.448 18.552 25 18 25 L 16 25 L 16 20 C 16 19.448 15.552 19 15 19 z M 21 19 C 20.448 19 20 19.448 20 20 L 20 26 C 20 26.552 20.448 27 21 27 C 21.552 27 22 26.552 22 26 L 22 20 C 22 19.448 21.552 19 21 19 z M 31 19 C 30.448 19 30 19.448 30 20 L 30 26 C 30 26.552 30.448 27 31 27 L 34 27 C 34.552 27 35 26.552 35 26 C 35 25.448 34.552 25 34 25 L 32 25 L 32 24 L 34 24 C 34.553 24 35 23.552 35 23 C 35 22.448 34.553 22 34 22 L 32 22 L 32 21 L 34 21 C 34.552 21 35 20.552 35 20 C 35 19.448 34.552 19 34 19 L 31 19 z" />,
  "Line"
);

export const MailSolid = createSvgIcon(
  <path d="M21.6,2.4H2.4C1.08,2.4,0,3.48,0,4.8v14.4c0,1.32,1.08,2.4,2.4,2.4H21.6c1.32,0,2.4-1.08,2.4-2.4V4.8c0-1.32-1.08-2.4-2.4-2.4Zm-.48,5.1l-7.85,4.91c-.78,.49-1.76,.49-2.54,0L2.88,7.5c-.3-.19-.48-.52-.48-.86,0-.8,.88-1.28,1.56-.86l8.04,5.03,8.04-5.03c.68-.42,1.56,.06,1.56,.86,0,.35-.18,.67-.48,.86Z" />,
  "MailSolid"
);

export const Phone = createSvgIcon(
  <path d="M23.32,16.96l-5.25-2.25c-.46-.2-1-.06-1.31,.32l-2.33,2.84c-3.65-1.72-6.59-4.66-8.31-8.31l2.84-2.33c.39-.32,.52-.85,.32-1.31L7.04,.68c-.22-.5-.76-.77-1.29-.65L.87,1.15C.36,1.27,0,1.73,0,2.25,0,14.27,9.75,24,21.75,24c.52,0,.98-.36,1.1-.87l1.13-4.88c.12-.53-.16-1.08-.66-1.29h0Z" />,
  "Phone"
);

export const BankIcon = createSvgIcon(
  <path d="M13.3,5.8c0,.3-.1,.6-.4,.9-.2,.2-.6,.4-.9,.4s-.7-.1-.9-.4c-.2-.2-.4-.5-.4-.9s.1-.6,.4-.9c.2-.2,.6-.4,.9-.4s.7,.1,.9,.4c.2,.2,.4,.5,.4,.9Zm0-4.9c-.4-.3-.8-.4-1.3-.4s-.9,.1-1.3,.4L1.2,7.6C0,8.5,.6,10.4,2.1,10.4h.3v7.2c-.6,.3-1.1,.7-1.4,1.3s-.5,1.2-.5,1.8v1.9c0,.5,.4,.9,1,.9H22.5c.3,0,.5,0,.7-.3,.2-.2,.3-.4,.3-.7v-1.9c0-.6-.2-1.2-.5-1.8s-.8-1-1.4-1.3v-7.2h.3c1.5,0,2.2-1.9,.9-2.8L13.3,.9h0Zm-1.5,1.5h.4l8.7,6.2H3.1L11.8,2.4h0Zm7.9,8v6.8h-2.6v-6.8h2.6Zm-4.5,0v6.8h-2.2v-6.8h2.2Zm-4.2,0v6.8h-2.2v-6.8h2.2Zm-7,8.7h16c.9,0,1.6,.7,1.6,1.6v.9H2.4v-.9c0-.9,.7-1.6,1.6-1.6Zm.3-1.9v-6.8h2.6v6.8h-2.6Z" />,
  "Bank"
);

export const Export = createSvgIcon(
  <path d="M8.05,6.85l2.75-2.76V15.6c0,.66,.54,1.2,1.2,1.2s1.2-.54,1.2-1.2V4.09l2.75,2.76c.47,.47,1.23,.47,1.7,0h0c.47-.47,.47-1.23,0-1.7h0L12.85,.35c-.11-.11-.25-.19-.4-.25-.29-.12-.62-.12-.91,0-.15,.06-.28,.14-.4,.25L6.35,5.15c-.47,.47-.47,1.23,0,1.7,.47,.47,1.23,.47,1.7,0h0Zm14.75,7.55c-.66,0-1.2,.54-1.2,1.2v4.8c0,.66-.54,1.2-1.2,1.2H3.6c-.66,0-1.2-.54-1.2-1.2v-4.8c0-.66-.54-1.2-1.2-1.2s-1.2,.54-1.2,1.2v4.8c0,1.99,1.61,3.6,3.6,3.6H20.4c1.99,0,3.6-1.61,3.6-3.6v-4.8c0-.66-.54-1.2-1.2-1.2Z" />,
  "Export"
);

export const Facebook = createSvgIcon(
  <path d="M24,12.07C24,5.45,18.63,.07,12,.07S0,5.45,0,12.07c0,5.99,4.39,10.95,10.12,11.85V15.54h-3.05v-3.47h3.05v-2.64c0-3.01,1.79-4.67,4.53-4.67,1.31,0,2.69,.24,2.69,.24v2.95h-1.51c-1.49,0-1.96,.93-1.96,1.87v2.25h3.33l-.53,3.47h-2.8v8.39c5.74-.9,10.13-5.87,10.13-11.86Z" />,
  "Facebook"
);

export const Instagram = createSvgIcon(
  <path d="M12,0c3.3,0,3.7,0,4.9,0,1.3,0,2.1,.3,2.9,.6,.8,.3,1.5,.7,2.1,1.4,.6,.6,1.1,1.3,1.4,2.1,.3,.8,.5,1.6,.6,2.9,0,1.3,0,1.7,0,4.9s0,3.7,0,4.9c0,1.3-.3,2.1-.6,2.9-.3,.8-.8,1.5-1.4,2.1-.6,.6-1.3,1.1-2.1,1.4-.8,.3-1.6,.5-2.9,.6-1.3,0-1.7,0-4.9,0s-3.7,0-4.9,0c-1.3,0-2.1-.3-2.9-.6-.8-.3-1.5-.8-2.1-1.4-.6-.6-1.1-1.3-1.4-2.1-.3-.8-.5-1.6-.6-2.9,0-1.3,0-1.7,0-4.9s0-3.7,0-4.9c0-1.3,.3-2.1,.6-2.9,.3-.8,.8-1.5,1.4-2.1,.6-.6,1.3-1.1,2.1-1.4,.8-.3,1.6-.5,2.9-.6,1.3,0,1.7,0,4.9,0Zm0,6c-3.3,0-6,2.7-6,6s2.7,6,6,6,6-2.7,6-6-2.7-6-6-6Zm7.8-.3c0-.8-.7-1.5-1.5-1.5s-1.5,.7-1.5,1.5,.7,1.5,1.5,1.5,1.5-.7,1.5-1.5Zm-7.8,2.7c2,0,3.6,1.6,3.6,3.6s-1.6,3.6-3.6,3.6-3.6-1.6-3.6-3.6,1.6-3.6,3.6-3.6Z" />,
  "Instagram"
);

export const Youtube = createSvgIcon(
  <path d="M22.83,5.15c-.85-1.01-2.41-1.42-5.41-1.42H6.57c-3.06,0-4.65,.44-5.5,1.51-.82,1.05-.82,2.59-.82,4.72v4.07c0,4.14,.98,6.23,6.32,6.23h10.86c2.59,0,4.03-.36,4.96-1.25,.95-.91,1.36-2.4,1.36-4.98v-4.07c0-2.25-.06-3.8-.92-4.82Zm-7.5,7.41l-4.93,2.58c-.11,.06-.23,.09-.35,.09-.14,0-.27-.04-.39-.11-.23-.14-.37-.38-.37-.65v-5.14c0-.26,.14-.51,.36-.65,.23-.14,.51-.15,.74-.02l4.93,2.56c.25,.13,.41,.39,.41,.67,0,.28-.16,.54-.41,.67Z" />,
  "Youtube"
);

export const Twitter = createSvgIcon(
  <path d="M23.98,4.72c.07-.1-.03-.23-.15-.18-.81,.31-1.66,.53-2.52,.63,.96-.57,1.7-1.45,2.12-2.49,.04-.11-.07-.2-.17-.14-.89,.49-1.86,.85-2.86,1.05-.04,0-.09,0-.11-.04-.75-.79-1.74-1.32-2.82-1.5-1.1-.19-2.23,0-3.22,.52-.99,.53-1.77,1.36-2.23,2.38-.43,.97-.55,2.05-.34,3.09,.02,.08-.05,.16-.13,.15-1.95-.12-3.85-.64-5.6-1.52-1.74-.88-3.28-2.11-4.52-3.61-.06-.07-.16-.06-.2,.02-.39,.74-.59,1.56-.59,2.39,0,.83,.2,1.65,.59,2.38,.39,.73,.96,1.36,1.65,1.82-.73-.02-1.45-.2-2.1-.53-.08-.04-.19,.02-.18,.11,.05,1.09,.45,2.19,1.15,3.04,.74,.9,1.77,1.51,2.91,1.74-.44,.13-.89,.2-1.35,.21-.25,0-.5-.02-.75-.06-.09-.01-.17,.07-.13,.16,.34,.92,.94,1.72,1.73,2.31,.84,.63,1.86,.97,2.91,.99-1.78,1.4-3.97,2.16-6.23,2.17-.23,0-.46,0-.7-.02-.13,0-.19,.16-.08,.23,2.2,1.31,4.72,2,7.28,1.99,1.89,.02,3.77-.34,5.53-1.05,1.75-.71,3.35-1.77,4.69-3.11,1.34-1.34,2.4-2.93,3.12-4.68,.72-1.75,1.08-3.63,1.07-5.53v-.59s.02-.08,.05-.1c.83-.63,1.57-1.38,2.18-2.23Z" />,
  "Twitter"
);

export const Tiktok = createSvgIcon(
  <path d="M21.51,5.57c-1.24-.26-2.35-.9-3.19-1.82-.84-.92-1.36-2.07-1.48-3.29V-.06h-4.28V16.35c0,.73-.23,1.44-.67,2.03-.44,.59-1.06,1.03-1.77,1.26-.71,.23-1.48,.24-2.2,.02-.72-.22-1.35-.65-1.8-1.23-.4-.52-.65-1.13-.72-1.77-.06-.64,.06-1.29,.36-1.87s.76-1.07,1.33-1.41c.57-.34,1.22-.52,1.89-.52,.37,0,.74,.05,1.09,.16v-4.2c-.41-.05-.83-.07-1.24-.06-1.53,.04-3.01,.51-4.26,1.35-1.25,.84-2.23,2.02-2.8,3.39-.57,1.37-.72,2.87-.43,4.32,.29,1.45,1.02,2.79,2.08,3.84,1.09,1.08,2.49,1.81,4.02,2.12,1.53,.3,3.11,.16,4.55-.41,1.44-.57,2.68-1.54,3.55-2.79,.87-1.25,1.34-2.72,1.34-4.23V7.94c1.72,1.19,3.79,1.83,5.91,1.82V5.69c-.42,0-.83-.04-1.24-.12h-.05Z" />,
  "Tiktok"
);

export const AddressShippingIcon = createSvgIcon(
  <>
    <path d="M12.05,23.5c-.43,0-1.07,0-1.6-.45l-7.71-4.25c-.46-.3-.83-.68-1.1-1.07-.29-.43-.44-.99-.44-1.57V6.95c0-.24,0-.68,.35-1.09,.17-.3,.39-.5,.72-.67L10.41,.53c.42-.27,.97-.43,1.54-.43,.43,0,1.07,0,1.6,.46l8.18,4.58c.53,.13,.77,.61,.88,.84,.12,.24,.29,.58,.29,.98v9.2c0,.59-.16,1.14-.44,1.57-.26,.4-.64,.77-1.04,1.04l-7.86,4.34c-.37,.25-.93,.41-1.51,.41ZM2.9,6.9v9.25c0,.25,.06,.48,.16,.63,.14,.21,.36,.43,.56,.56l7.74,4.26c.07,.04,.13,.09,.19,.14,.05,.05,.31,.05,.5,.05,.25,0,.48-.06,.63-.16l7.86-4.34c.14-.1,.37-.32,.5-.53,.1-.15,.16-.38,.16-.63V6.95s-.07-.14-.11-.22h0s-.03-.03-.05-.04L12.63,1.99c-.07-.04-.13-.09-.19-.14-.05-.05-.31-.05-.5-.05-.25,0-.48,.06-.63,.16-.02,.01-.03,.02-.05,.03L3.07,6.69c-.03,.06-.11,.15-.17,.21ZM10.85,1.25h0Z" />
    <path d="M16.95,13.8c-.47,0-.85-.38-.85-.85v-3.4L6.53,4.19c-.41-.23-.56-.75-.33-1.16,.23-.41,.75-.56,1.16-.33l10,5.6c.27,.15,.43,.43,.43,.74v3.9c0,.47-.38,.85-.85,.85Z" />
    <path d="M8.55,14.9c-.14,0-.29-.04-.42-.11l-3.3-1.9c-.41-.23-.55-.75-.31-1.16,.23-.41,.75-.55,1.16-.31l3.3,1.9c.41,.23,.55,.75,.31,1.16-.16,.27-.44,.43-.74,.43Z" />
    <path d="M12.8,23.05h-1.7V12.35L1.53,6.99c-.28-.16-.44-.45-.43-.74,0-.14,.04-.28,.11-.41,.23-.41,.75-.56,1.16-.33l9.58,5.37,9.58-5.37c.41-.23,.93-.08,1.16,.33,.07,.13,.11,.27,.11,.41,0,.3-.16,.59-.43,.74l-9.57,5.36v10.7Z" />
  </>,
  "AddressShippingIcon"
);

export const AddressBillingIcon = createSvgIcon(
  <>
    <g>
      <path d="M21.32,.5H7.34c-.94,0-1.71,.77-1.71,1.71v7.61H2.68c-.94,0-1.71,.77-1.71,1.71v8.86c0,1.72,1.39,3.11,3.11,3.11,.16,0,.31-.01,.47-.04v.04h14.92c1.97,0,3.58-1.6,3.58-3.58V2.21c0-.94-.77-1.71-1.72-1.71ZM2.52,20.39V11.53c0-.08,.07-.15,.15-.15h2.95v9.01c0,.86-.7,1.55-1.55,1.55-.86,0-1.55-.7-1.55-1.55Zm18.95-.47c0,1.11-.9,2.02-2.02,2.02H6.77c.27-.46,.42-.99,.42-1.55V2.21c0-.08,.07-.15,.15-.15h13.98c.08,0,.15,.07,.15,.15V19.92Z" />
      <path d="M19.46,23.85H4.19c-1.95,.11-3.58-1.51-3.58-3.46V11.53c0-1.14,.93-2.06,2.06-2.06h2.6V2.21C5.28,1.07,6.2,.15,7.34,.15h13.98c1.14,0,2.06,.92,2.07,2.06V19.92c0,2.17-1.76,3.93-3.93,3.93Zm-14.57-.7h14.57c1.78,0,3.23-1.45,3.23-3.23V2.21c0-.75-.61-1.36-1.37-1.36H7.34c-.75,0-1.36,.61-1.36,1.36v7.96H2.68c-.75,0-1.36,.61-1.36,1.36v8.86c0,1.52,1.24,2.76,2.76,2.76,.14,0,.28-.01,.41-.03l.4-.06v.1Zm-.82-.86c-.5,0-.98-.2-1.34-.55-.36-.36-.56-.84-.56-1.35V11.53c0-.28,.23-.5,.5-.5h3.3v9.36c0,1.05-.85,1.9-1.9,1.9h0Zm-1.2-10.56v8.66c0,.32,.13,.62,.35,.85,.23,.23,.53,.35,.84,.35h0c.66,0,1.2-.54,1.2-1.2V11.73H2.87Zm16.58,10.56H6.16l.31-.53c.24-.42,.37-.89,.37-1.38V2.21c0-.28,.23-.5,.5-.5h13.98c.28,0,.5,.22,.5,.5V19.92c0,1.3-1.06,2.37-2.37,2.37Zm-12.14-.7h12.14c.92,0,1.67-.75,1.67-1.67V2.41H7.54V20.39c0,.41-.07,.82-.22,1.2Z" />
    </g>
    <g>
      <path d="M18.52,4.23H10.13v1.56h8.39v-1.56Z" />
      <path d="M18.87,6.14H9.78V3.88h9.09v2.26Zm-8.39-.7h7.69v-.86h-7.69v.86Z" />
    </g>
    <g>
      <path d="M18.52,7.96H10.13v1.56h8.39v-1.56Z" />
      <path d="M18.87,9.87H9.78v-2.26h9.09v2.26Zm-8.39-.7h7.69v-.86h-7.69v.86Z" />
    </g>
    <g>
      <path d="M18.52,11.69H10.13v1.56h8.39v-1.56Z" />
      <path d="M18.87,13.6H9.78v-2.26h9.09v2.26Zm-8.39-.7h7.69v-.86h-7.69v.86Z" />
    </g>
  </>,
  "AddressShippingIcon"
);

export const EditSolidIcon = createSvgIcon(
  <>
    <path d="M1.5,16L0,22.1c0,.2,0,.5,0,.7,0,.2,.2,.4,.3,.6,.1,.2,.3,.3,.5,.4,.2,.1,.4,.2,.7,.2,.1,0,.2,0,.3,0l6.2-1.4,11.8-11.8-6.6-6.6L1.5,16Z" />
    <path d="M23.5,4.9L19.1,.5c-.3-.3-.7-.5-1.1-.5s-.8,.2-1.1,.5l-2.4,2.5,6.6,6.6,2.4-2.5c.1-.1,.3-.3,.3-.5,0-.2,.1-.4,.1-.6,0-.2,0-.4-.1-.6,0-.2-.2-.4-.3-.5Z" />
  </>,
  "EditSolidIcon"
);

export const SaveIcon = createSvgIcon(
  <path d="M21.99,2.47c-1.42-1.57-3.44-2.47-5.56-2.47H3C1.35,0,0,1.35,0,3V21c0,1.65,1.35,3,3,3H21c1.65,0,3-1.35,3-3V7.58c0-1.86-.69-3.65-1.94-5.03l-.07-.08Zm-3.99,6.53c0,.82-.67,1.5-1.5,1.5H7.5c-.83,0-1.5-.68-1.5-1.5v-1.5c0-3.31,2.69-6,6-6s6,2.69,6,6v1.5Zm-1.5-4.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5v3c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5v-3Z" />,
  "SaveIcon"
);

export const PaymentIcon = createSvgIcon(
  <>
    <path d="M20.8,20.45H3.2c-.76,0-1.42-.24-2.07-.76-.58-.58-.88-1.35-.88-2.19V6.5c0-.76,.24-1.42,.76-2.07,.58-.58,1.35-.88,2.19-.88H20.8c.76,0,1.42,.24,2.07,.76,.58,.58,.88,1.35,.88,2.19v11c0,.76-.24,1.42-.76,2.07-.58,.58-1.35,.88-2.19,.88ZM3.2,5.05c-.43,0-.83,.14-1.07,.38-.25,.32-.38,.66-.38,1.07v11c0,.43,.14,.83,.38,1.07,.32,.25,.66,.38,1.07,.38H20.8c.43,0,.83-.14,1.07-.38,.25-.32,.38-.66,.38-1.07V6.5c0-.43-.14-.83-.38-1.07-.32-.25-.66-.38-1.07-.38H3.2Z" />
    <path d="M12,16.05c-2.2,0-4.05-1.85-4.05-4.05s1.85-4.05,4.05-4.05,4.05,1.85,4.05,4.05-1.85,4.05-4.05,4.05Zm0-6.6c-1.38,0-2.55,1.17-2.55,2.55s1.17,2.55,2.55,2.55,2.55-1.17,2.55-2.55-1.17-2.55-2.55-2.55Z" />
    <path d="M1,9.45c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75c.96,0,1.87-.38,2.57-1.08,.7-.7,1.08-1.61,1.08-2.57,0-.41,.34-.75,.75-.75s.75,.34,.75,.75c0,1.36-.54,2.65-1.52,3.63-.98,.98-2.27,1.52-3.63,1.52Z" />
    <path d="M18.6,20.45c-.41,0-.75-.34-.75-.75,0-1.36,.54-2.65,1.52-3.63,.98-.98,2.27-1.52,3.63-1.52,.41,0,.75,.34,.75,.75s-.34,.75-.75,.75c-.96,0-1.87,.38-2.57,1.08-.7,.7-1.08,1.61-1.08,2.57,0,.41-.34,.75-.75,.75Z" />
  </>,
  "PaymentIcon"
);

export const StatementBrandsIcon = createSvgIcon(
  <>
    <g clip-path="url(#uuid-a254ed11-4804-4095-9322-e926d2a612b7)">
      <g>
        <path d="M16.57,7.3c.6,.6,1.57,.6,2.17,0,.6-.59,.6-1.56,0-2.15-.29-.29-.68-.45-1.08-.45s-.8,.16-1.09,.45c-.6,.59-.6,1.56,0,2.15Z" />
        <path d="M1.17,10.63c-.37,.37-.67,.81-.87,1.29C.1,12.41,0,12.93,0,13.45s.1,1.04,.31,1.53c.2,.48,.5,.92,.87,1.29l6.59,6.56c1.57,1.56,4.1,1.56,5.67,0l9.34-9.29c.74-.74,1.16-1.74,1.17-2.79l.05-6.73c0-.53-.1-1.05-.3-1.54-.2-.49-.5-.93-.87-1.31-.37-.37-.82-.67-1.31-.87C21.03,.1,20.5,0,19.98,0l-6.5,.03c-1.06,0-2.07,.42-2.82,1.17L1.17,10.63Zm1.42,4.23c-.19-.19-.33-.4-.43-.65-.1-.24-.15-.5-.15-.76s.05-.52,.15-.76c.1-.24,.25-.46,.43-.65L12.07,2.61c.37-.37,.88-.58,1.41-.59l6.5-.03c1.12,0,2.02,.9,2.01,2.01l-.05,6.73c0,.53-.22,1.03-.59,1.4l-9.34,9.29c-.78,.78-2.05,.78-2.83,0L2.59,14.86Z" />
        <path d="M12.29,12.88l-.02-.04c-.04-.06-.08-.14-.14-.24l1.57-1.54c.04,.26,.02,.46,.02,.46-.02,.19,.03,.37,.15,.52,.12,.15,.29,.24,.47,.26,.39,.04,.73-.24,.78-.63,.02-.16,.07-.95-.29-1.71l.29-.29c.28-.27,.28-.72,0-1.01-.27-.26-.72-.26-.98,0l-.26,.25c-.4-.23-.88-.34-1.43-.34-.67,0-1.21,.22-1.62,.64-.7,.73-.69,1.77-.68,1.88,0,.33,.1,.73,.28,1.19l-1.5,1.46c-.14-.27-.27-.63-.26-1.05,0-.19-.07-.37-.2-.5h-.01c-.13-.14-.3-.21-.49-.21-.19,0-.37,.07-.5,.2-.13,.13-.21,.31-.21,.5,0,.9,.32,1.61,.64,2.08l-.36,.35c-.28,.27-.28,.72,0,1.01,.27,.26,.72,.26,.98,0l.4-.39c.48,.27,1.17,.51,1.97,.33l.07-.02c.11-.04,1.13-.44,1.49-1.39,.15-.38,.23-.99-.18-1.76Zm-.72-1.7s0-.08-.01-.12c0,0,0-.57,.29-.86,.14-.14,.33-.21,.61-.21,.11,0,.22,0,.31,.02l-1.2,1.17Zm-1.05,3.52c-.16,.03-.33,.02-.51-.03l1.07-1.05c.09,.21,.11,.37,.06,.52-.09,.25-.42,.47-.62,.56Z" />
      </g>
    </g>
  </>,
  "StatementBrandsIcon"
);

export const StatementJobsIcon = createSvgIcon(
  <>
    <path d="M23,22.35H1c-.55,0-1-.45-1-1V6.05c0-.55,.45-1,1-1h7.01c.34-1.62,1.52-3.4,3.99-3.4s3.65,1.78,3.99,3.4h7.01c.55,0,1,.45,1,1v15.3c0,.55-.45,1-1,1Zm-3.7-2h2.7V7.05h-2.7v13.3Zm-13.58,0h11.58V7.05H2v13.3h3.72ZM10.07,5.05h3.86c-.22-.67-.74-1.4-1.93-1.4s-1.71,.73-1.93,1.4Z" />
    <path d="M5.9,21.55c-.55,0-1-.45-1-1V6.55c0-.55,.45-1,1-1s1,.45,1,1v14c0,.55-.45,1-1,1Z" />
  </>,
  "StatementJobsIcon"
);

export const AttachFile = createSvgIcon(
  <path d="M16.36,6.27v11.54c0,2.28-1.67,4.31-3.94,4.53-2.61,.25-4.79-1.79-4.79-4.34V4.51c0-1.43,1.03-2.73,2.44-2.87,1.64-.16,3.01,1.11,3.01,2.72V15.82c0,.6-.49,1.09-1.09,1.09s-1.09-.49-1.09-1.09V6.27c0-.45-.37-.82-.82-.82s-.82,.37-.82,.82V15.66c0,1.43,1.03,2.73,2.44,2.87,1.64,.16,3.01-1.11,3.01-2.72V4.55C14.73,2.27,13.06,.24,10.79,.02c-2.6-.25-4.79,1.79-4.79,4.34v13.38c0,3.13,2.29,5.93,5.41,6.23,3.59,.33,6.59-2.47,6.59-5.98V6.27c0-.45-.37-.82-.82-.82s-.82,.37-.82,.82Z" />,
  "AttachFile"
);

export const AddIcon = createSvgIcon(
  <path d="M22.29,13.71H13.71v8.57c0,.94-.77,1.71-1.71,1.71s-1.71-.77-1.71-1.71V13.71H1.71c-.94,0-1.71-.77-1.71-1.71s.77-1.71,1.71-1.71H10.29V1.71c0-.94,.77-1.71,1.71-1.71s1.71,.77,1.71,1.71V10.29h8.57c.94,0,1.71,.77,1.71,1.71s-.77,1.71-1.71,1.71Z" />,
  "AddIcon"
);

export const OpenLink = createSvgIcon(
  <path d="M4.36,2.18c-.58,0-1.13,.23-1.54,.64-.41,.41-.64,.96-.64,1.54v15.27c0,.58,.23,1.13,.64,1.54,.41,.41,.96,.64,1.54,.64h15.27c.58,0,1.13-.23,1.54-.64,.41-.41,.64-.96,.64-1.54v-4.91c0-.29,.12-.57,.32-.77,.2-.2,.48-.32,.77-.32s.57,.12,.77,.32c.2,.2,.32,.48,.32,.77v4.91c0,1.16-.46,2.27-1.28,3.09s-1.93,1.28-3.09,1.28H4.36c-1.16,0-2.27-.46-3.09-1.28-.82-.82-1.28-1.93-1.28-3.09V4.36C0,3.21,.46,2.1,1.28,1.28,2.1,.46,3.21,0,4.36,0h4.91C9.56,0,9.84,.11,10.04,.32c.2,.2,.32,.48,.32,.77s-.11,.57-.32,.77c-.2,.2-.48,.32-.77,.32H4.36ZM13.64,1.09c0-.29,.12-.57,.32-.77C14.16,.11,14.44,0,14.73,0h8.18c.29,0,.57,.11,.77,.32,.2,.2,.32,.48,.32,.77V9.27c0,.29-.11,.57-.32,.77-.2,.2-.48,.32-.77,.32s-.57-.11-.77-.32c-.2-.2-.32-.48-.32-.77V3.72l-6.32,6.32c-.1,.1-.22,.19-.35,.24-.13,.06-.28,.09-.42,.09-.14,0-.29-.03-.42-.08-.13-.05-.26-.14-.36-.24-.1-.1-.18-.22-.24-.36s-.08-.28-.08-.42c0-.14,.03-.29,.09-.42,.06-.13,.14-.25,.24-.35l6.32-6.32h-5.55c-.29,0-.57-.11-.77-.32-.2-.2-.32-.48-.32-.77Z" />,
  "OpenLink"
);
