import Swal from "sweetalert2";
import "./SwalCustom.styles.css";

const SwalCustom = Swal.mixin({
  cancelButtonColor: "#ffffff",
  customClass: {
    popup: "swal-popup",
    icon: "swal-icon",
    title: "swal-title",
    htmlContainer: "swal-content",
    actions: "swal-actions",
    confirmButton: "swal-confirm-button",
    cancelButton: "swal-cancel-button",
    container: "swal-z-index",
  },
});

export default SwalCustom;
