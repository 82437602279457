import * as React from "react";
import { useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
// Styles
import {
  expenseCampaign as classes,
  TableRowCustom,
  TableCellCustom,
} from "../expense/Expense.styles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import SwalCustom from "../global/sweet-alert-custom/SwalCustom";
import { hAlertColor } from "../../theme";

export default function TableEmployers(props: any) {
  const { employerList, handleDelete } = props;
  const history = useHistory();

  useEffect(() => {}, [employerList]);

  const handleViewEmployer = (id: string) => {
    history.push(`/employers/${id}`);
  };

  const deleteEmployerWithAPI = async (id: string) => {
    SwalCustom.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure ?",
      confirmButtonColor: hAlertColor.warning,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
        SwalCustom.fire("Deleted!", "Employer has been deleted.", "success");
      }
    });
  };

  return (
    <Box mt={3}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: [980, 1100] }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCellCustom>Name</TableCellCustom>
              <TableCellCustom align="left">Contact</TableCellCustom>
              <TableCellCustom align="left">Business Type</TableCellCustom>
              <TableCellCustom align="center">Subscription</TableCellCustom>
              <TableCellCustom align="center">Wallet Balance</TableCellCustom>
              <TableCellCustom align="center">Actions</TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            {employerList.map((item: any) => {
              return (
                <TableRowCustom key={item.employer_id}>
                  <TableCellCustom>
                    <Stack direction="row" alignItems="center">
                      <Avatar
                        sx={classes.campaignIcon}
                        alt="Campaign"
                        src={item.organisation.attachment.url}
                      />
                      <Box ml={2.5}>
                        <Typography color="#8C499C">
                          {item.organisation.name}
                        </Typography>
                        <Typography fontSize={["12px", "14px"]}>
                          {item.email}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Stack
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Typography>{item.fullname}</Typography>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">{item.phone}</Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Stack
                      direction="column"
                      alignItems="start"
                      justifyContent="start"
                    >
                      <Typography variant="body1">
                        {item.organisation.type}
                      </Typography>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="center" sx={{ padding: "auto" }}>
                    <Box
                      sx={{
                        widt: "113px",
                        borderRadius: "50px",
                        backgroundColor: "#EBDFFF",
                        color: "#8C499C",
                      }}
                    >
                      Free
                    </Box>
                  </TableCellCustom>
                  <TableCellCustom align="center" sx={{ padding: "auto" }}>
                    <Typography variant="body1" fontWeight="600">
                      1,000 THB
                    </Typography>
                  </TableCellCustom>
                  <TableCellCustom align="center" sx={{ padding: "auto" }}>
                    <Box display="flex" flexDirection="row">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "32px",
                          height: "32px",
                          border: "1px solid #1AA1F1",
                          backgroundColor: "white",
                          margin: "auto",
                          cursor: "pointer",
                          borderRadius: "50%",
                        }}
                        onClick={() =>
                          handleViewEmployer(item.employer_full_id)
                        }
                      >
                        <RemoveRedEyeIcon sx={{ color: "#1AA1F1" }} />
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "32px",
                          height: "32px",
                          border: "1px solid #FF8484",
                          backgroundColor: "white",
                          margin: "auto",
                          cursor: "pointer",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          deleteEmployerWithAPI(item.employer_full_id);
                        }}
                      >
                        <DeleteIcon sx={{ color: "#FF8484" }} />
                      </Box>
                    </Box>
                  </TableCellCustom>
                </TableRowCustom>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
